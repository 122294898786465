Px.Editor.MobilePageDisplay = class MobilePageDisplay extends Px.Editor.PageDisplay {

  template() {
    const store = this.data.store;
    const r = this.renderChild;
    return Px.template`
      <div class="px-mobile-page-display"
           data-expanded="${Boolean(store.selected_set)}"
           data-view-mode="${store.mobile.view_mode}">
        ${r(Px.Components.ResizeDetector, 'resize-detector', {onResize: this.resize})}
        <div class="px-pages"
             data-ontouchstart="startTouchInteraction"
             data-is-animating-swipe="${this.state.is_animating_swipe}"
             data-is-swiping="${this.state.is_swiping}">
          <div class="px-prev-set-hint" style="${this.prevSetArrowStyle}">
            ${Px.raw(MobilePageDisplay.icons.arrow_left)}
          </div>
          <div class="px-next-set-hint" style="${this.nextSetArrowStyle}">
            ${Px.raw(MobilePageDisplay.icons.arrow_right)}
          </div>
          ${Px.if(store.selected_set, () => {
            return Px.template`
              <div class="px-pages-scrollable-area" style="${this.scrollableAreaStyle}">
                ${store.selected_set.pages.map(page => {
                  return Px.template`
                    <div class="px-page-display-page ${this.renderOutline(page) ? 'px-with-outline' : ''}"
                         data-page-id="${page.id}"
                         data-onclick="selectPage"
                         style="${this.pageWrapperStyle(page)}">
                      ${r(Px.Editor.Page, `page-${page.id}`, this.pageProps(page))}
                    </div>
                  `;
                })}
              </div>
            `;
          })}
        </div>
      </div>
    `;
  }

  static get properties() {
    return Object.assign(super.properties, {
      swipe_offset: {type: 'int', std: 0},
      is_swiping: {type: 'bool', std: false},
      is_animating_swipe: {type: 'bool', std: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      previewMode: function() {
        const store = this.data.store;
        return (
          store.mobile.view_mode === 'edit' || store.mobile.view_mode === 'layout-selection' ||
            store.ui.preview_mode || this.state.is_swiping
        );
      },
      croppingModePageScale: function() {
        const store = this.data.store;
        if (!store.ui.element_cropping_mode) {
          return null;
        }
        const element = store.selected_element;
        const border = element.border || 0;
        let element_width = element.width + border;
        let element_height = element.height + border;
        if (this.isAutorotatedCutPrint(element.page)) {
          [element_width, element_height] = [element_height, element_width];
        }
        let ratio = element_width / element_height;
        const dimensions = Px.Util.inscribedRectangleDimensions(
          this.state.width,
          this.state.height,
          element.rotation,
          ratio
        );
        return dimensions.width / element_width;
      },
      scrollableAreaStyle: function() {
        const store = this.data.store;
        let style = `transform:translateX(${this.state.swipe_offset}px);`;
        if (store.ui.element_cropping_mode) {
          const element = store.selected_element;
          const border = element.border || 0;
          let width = (element.width + border) * this.croppingModePageScale;
          let height = (element.height + border) * this.croppingModePageScale;
          if (this.isAutorotatedCutPrint(element.page)) {
            [width, height] = [height, width];
          }
          const dimensions = Px.Util.circumscribedRectangleDimensions(width, height, element.rotation);
          style += `width:${dimensions.width}px; height:${dimensions.height}px; overflow:hidden;`
        }
        return style;
      },
      prevSetArrowStyle: function() {
        const offset = this.state.swipe_offset;
        if (!this.canGoToPreviousSet || this.state.is_animating_swipe || offset <= 0) {
          return '';
        }
        return `opacity:${this.prevNextSetHintArrowOpacity(offset)};`;
      },
      nextSetArrowStyle: function() {
        const offset = this.state.swipe_offset;
        if (!this.canGoToNextSet || this.state.is_animating_swipe || offset >= 0) {
          return '';
        }
        return `opacity:${this.prevNextSetHintArrowOpacity(offset)};`;
      }
    });
  }

  renderOutline(page) {
    const store = this.data.store;
    return store.mobile.active_tool === 'page-layout-selector' && store.selected_page === page;
  }

  pageProps(page) {
    return {
      page: page,
      mobile_mode: true,
      scale: this.croppingModePageScale,
      available_width: this.availablePageWidth * this.zoom,
      available_height: this.availablePageHeight * this.zoom,
      preview_mode: this.previewMode,
      hide_placeholders: this.data.store.ui.preview_mode
    };
  }

  pageWrapperStyle(page) {
    const store = this.data.store;
    let style = super.pageWrapperStyle(page);
    if (store.ui.element_cropping_mode) {
      const element = store.selected_element;
      const border = element.border || 0;
      const set_pages = element.page.set.pages;
      let left = 0;
      for (let i = 0; i < set_pages.length; i++) {
        const page = set_pages[i];
        if (page === element.page) {
          break;
        }
        const page_width = page.viewBoxWidth(store.crop_bleed, store.debug_gutter);
        left += (page_width * this.croppingModePageScale) + this.gap_between_pages;
      }
      const vertices = Px.Util.rectangleVertices(element.width + border, element.height + border, element.rotation);
      const extremes = Px.Util.extremeCoords(vertices);
      left += (extremes.min_x + element.x + element.width/2) * this.croppingModePageScale;
      let top = (extremes.min_y + element.y + element.height/2) * this.croppingModePageScale;
      if (store.crop_bleed) {
        left -= page.bleed * this.croppingModePageScale;
        top -= page.bleed * this.croppingModePageScale;
      }
      const has_two_pages = page.set && page.set.pages.length === 2;
      const gutter = (store.debug_gutter && has_two_pages) ? 0 : page.gutter;
      left -= gutter * this.croppingModePageScale;
      const left_attr = Px.config.rtl ? 'right' : 'left';
      style += `position:relative; align-self:start; ${left_attr}:${-left}px; top:${-top}px;`;
    }
    return style;
  }

  isAutorotatedCutPrint(page) {
    return this.data.store.isAutorotatedCutPrint(page);
  }

  updateScroll(zoom) {
    // This just overrides the parent's method to do nothing.
  }

  // --------------
  // Event handlers
  // --------------

  startTouchInteraction(evt) {
    const store = this.data.store;
    const doc = $j(document);
    const touches = evt.originalEvent.touches;

    if (touches.length === 1) {
      if (store.ui.page_display_zoom > 1) {
        this.handleDoubleTap();
      } else if (store.mobile.view_mode === 'page') {
        this.handleSwipe(evt);
      }
    }

    if (touches.length > 2) {
      return;
    }

    evt.preventDefault();

    const scroll_parent = this.dom_node.querySelector('.px-pages');
    const scrollable_area = scroll_parent.children[0];

    const getPinchDistance = (t1, t2) => {
      return Math.sqrt(Math.pow(t2.pageX - t1.pageX, 2) + Math.pow(t2.pageY - t1.pageY, 2));
    };

    // Returns center point of pinch relative to top-left corner of the containing div.
    const getPinchCenter = (t1, t2) => {
      const rect = scrollable_area.getBoundingClientRect();
      // Get the top/left point of the div in the viewport.
      const offset_x = rect.left + scroll_parent.scrollLeft;
      const offset_y = rect.top + scroll_parent.scrollTop;
      return {
        x: (t1.pageX + t2.pageX) / 2 - offset_x,
        y: (t1.pageY + t2.pageY) / 2 - offset_y
      };
    };

    let pinch_origin = null;
    const startPinchToZoom = (t1, t2) => {
      pinch_origin = {
        zoom: store.ui.page_display_zoom,
        distance: getPinchDistance(t1, t2),
        center: getPinchCenter(t1, t2),
        scroll: {
          left: scroll_parent.scrollLeft,
          top: scroll_parent.scrollTop
        }
      };
      mobx.runInAction(() => {
        store.ui.is_zooming = true;
      });
    };

    const handleScroll = (t1, t2) => {
      const new_center = getPinchCenter(t1 ,t2);
      const zoom_ratio = store.ui.page_display_zoom / pinch_origin.zoom;
      scroll_parent.scrollLeft = zoom_ratio * (pinch_origin.scroll.left + pinch_origin.center.x) - new_center.x;
      scroll_parent.scrollTop = zoom_ratio * (pinch_origin.scroll.top + pinch_origin.center.y) - new_center.y;
    };

    let zoom_raf_id = null;
    let scroll_raf_id = null;
    const pinchToZoom = (t1, t2) => {
      if (!zoom_raf_id) {
        zoom_raf_id = requestAnimationFrame(() => {
          zoom_raf_id = null;
          store.ui.setZoom((getPinchDistance(t1, t2) * pinch_origin.zoom) / pinch_origin.distance);
          handleScroll(t1, t2);
          // Safari on iOS doesn't always correctly maintain scroll if we set it in the same frame
          // as we're redrawing the page. In order to make zooming feel as smooth as possible,
          // set scroll both immediatelly (makes it smooth, but doesn't always work), and in an
          // animation frame (always works, but is not as smooth).
          cancelAnimationFrame(scroll_raf_id);
          scroll_raf_id = requestAnimationFrame(() => {
            handleScroll(t1, t2);
          });
        });
      }
    };

    if (touches.length === 2) {
      startPinchToZoom(touches[0], touches[1]);
    }

    const onTouchMove = evt => {
      const touches = evt.originalEvent.touches;
      if (touches.length > 2) {
        return;
      }
      if (touches.length === 2) {
        if (pinch_origin === null) {
          startPinchToZoom(touches[0], touches[1]);
        } else {
          pinchToZoom(touches[0], touches[1]);
        }
      }
    };

    const onTouchEnd = evt => {
      cancelAnimationFrame(zoom_raf_id);
      cancelAnimationFrame(scroll_raf_id);
      pinch_origin = null;
      doc.off('touchmove', onTouchMove);
      doc.off('touchend touchcancel', onTouchEnd);
      mobx.runInAction(() => {
        store.ui.is_zooming = false;
      });
    }

    doc.on('touchmove', onTouchMove);
    doc.on('touchend touchcancel', onTouchEnd);
  }

  handleDoubleTap() {
    const doc = $j(document);
    const ui_store = this.data.store.ui;

    const removeListeners = () => {
      clearTimeout(this._double_tap_listener_timeout);
      this._double_tap_listener_timeout = null;
      doc.off('touchend', onTouchEnd);
      doc.off('touchcancel', onTouchCancel);
    }

    const onTouchEnd = evt => {
      removeListeners();

      const scroll_parent = this.dom_node.querySelector('.px-pages');
      const scrollable_area = scroll_parent.children[0];
      const initial_zoom = ui_store.page_display_zoom;
      const initial_scroll = {
        left: scroll_parent.scrollLeft,
        top: scroll_parent.scrollTop
      };
      const rect = scrollable_area.getBoundingClientRect();
      const offset_x = rect.left + scroll_parent.scrollLeft;
      const offset_y = rect.top + scroll_parent.scrollTop;
      const touch = evt.originalEvent.changedTouches[0];
      const center = {
        x: touch.pageX - offset_x,
        y: touch.pageY - offset_y
      };
      const total_duration = 100 * initial_zoom;
      const animation_start = Date.now();

      const handleScroll = () => {
        const zoom_ratio = ui_store.page_display_zoom / initial_zoom;
        scroll_parent.scrollLeft = zoom_ratio * (initial_scroll.left + center.x) - center.x;
        scroll_parent.scrollTop = zoom_ratio * (initial_scroll.top + center.y) - center.y;
      };

      const animateZoom = () => {
        const duration = Date.now() - animation_start;
        let zoom = 1;
        if (duration < total_duration) {
          zoom += initial_zoom * (1 - duration / total_duration);
        }
        mobx.runInAction(() => {
          ui_store.is_zooming = true;
          ui_store.page_display_zoom = zoom;
          handleScroll();
          if (zoom !== 1) {
            requestAnimationFrame(animateZoom);
          } else {
            ui_store.is_zooming = false;
          }
        });
      };

      requestAnimationFrame(animateZoom);
    };

    const onTouchCancel = evt => {
      removeListeners();
    };

    if (!this._double_tap_listener_timeout) {
      // This is the first tap. Set up a timeout to wait for the possible second tap.
      this._double_tap_listener_timeout = setTimeout(removeListeners, Px.Util.doubleTapTimeout);
      return;
    } else {
      // This is the second tap event within the double tap timeout, so zoom out the page on touchend.
      doc.on('touchend', onTouchEnd);
      doc.on('touchcancel', onTouchCancel);
    }
  }

  handleSwipe(evt) {
    const doc = $j(document);
    const start_ts = Date.now();
    const start_x = evt.originalEvent.touches[0].pageX;
    let end_x = start_x;
    let raf_id = null;

    const onTouchMove = evt => {
      const touches = evt.originalEvent.touches;
      if (touches.length > 1) {
        // This is no longer a swipe event.
        onTouchCancel();
        return;
      }
      this.state.is_swiping = true;
      cancelAnimationFrame(raf_id);
      raf_id = requestAnimationFrame(() => {
        end_x = touches[0].pageX;
        this.state.swipe_offset = end_x - start_x;
      });
    };

    const onTouchEnd = evt => {
      const diff_x = Math.abs(end_x - start_x);
      const diff_ms = Date.now() - start_ts;
      if ((diff_x > this.state.width/2) || (diff_x > Px.Util.minSwipeDistance && diff_ms < 200)) {
        if (end_x < start_x) {
          if (this.canGoToNextSet) {
            evt.preventDefault();  // prevent click event
            removeListeners();
            this.animateSwipe(this.state.swipe_offset - this.state.width, () => {
              this.goToNextSet();
              requestAnimationFrame(() => {
                this.state.swipe_offset = this.state.width;
                requestAnimationFrame(() => {
                  this.animateSwipe(0, () => this.state.is_swiping = false);
                });
              });
            });
          } else {
            onTouchCancel();
          }
        } else {
          if (this.canGoToPreviousSet) {
            evt.preventDefault();  // prevent click event
            removeListeners();
            this.animateSwipe(this.state.swipe_offset + this.state.width, () => {
              this.goToPreviousSet();
              requestAnimationFrame(() => {
                this.state.swipe_offset = -this.state.width;
                requestAnimationFrame(() => {
                  this.animateSwipe(0, () => this.state.is_swiping = false);
                });
              });
            });
          } else {
            onTouchCancel();
          }
        }
      } else {
        onTouchCancel();
      }
    };

    const onTouchCancel = () => {
      removeListeners();
      this.animateSwipe(0, () => {
        this.state.is_swiping = false;
      });
    };

    const removeListeners = () => {
      cancelAnimationFrame(raf_id);
      doc.off('touchmove', onTouchMove);
      doc.off('touchend', onTouchEnd);
      doc.off('touchcancel', onTouchCancel);
    };

    doc.on('touchmove', onTouchMove);
    doc.on('touchend', onTouchEnd);
    doc.on('touchcancel', onTouchCancel);
  }

  selectPage(evt) {
    const store = this.data.store;
    if (store.mobile.active_tool === 'page-layout-selector') {
      store.selectPageById(evt.target.getAttribute('data-page-id'));
    }
  }

  // -------
  // Private
  // -------

  prevNextSetHintArrowOpacity(offset) {
    const full_opacity_width = this.state.width * 0.75;
    return Math.abs(offset / full_opacity_width);
  }

  sizeCalculator(page) {
    return Px.Editor.Page.SizeCalculator.make(this.data.store, page, {
      scale: this.croppingModePageScale,
      available_width: this.availablePageWidth * this.zoom,
      available_height: this.availablePageHeight * this.zoom
    });
  }

  animateSwipe(value, callback) {
    this.state.is_animating_swipe = true;
    requestAnimationFrame(() => {
      this.state.swipe_offset = value;
      setTimeout(() => {
        mobx.runInAction(() => {
          this.state.is_animating_swipe = false;
          if (callback) {
            callback();
          }
        });
      }, 200);  // Keep this in sync with the CSS transition duration.
    });
  }

};

Px.Editor.MobilePageDisplay.icons = {
  arrow_right: '<svg width="24" height="24" viewBox="0 0 24 24"><polyline stroke="#8492a6" stroke-width="2" fill="none" points="12,1 23,12 12,23" /><line stroke-width="2" stroke="#8492a6" x1="2" y1="12" x2="23" y2="12"/></svg>',
  arrow_left: '<svg width="24" height="24" viewBox="0 0 24 24"><polyline stroke="#8492a6" stroke-width="2" fill="none" points="12,1 1,12 12,23" /><line stroke-width="2" stroke="#8492a6" x1="22" y1="12" x2="1" y2="12"/></svg>'
}
