Px.Editor.ColorPaletteModel = class ColorPaletteModel extends Px.BaseModel {

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null},
      name: {std: null},
      colors: {std: mobx.observable.array()},
      _status: {std: 'queued'}
    });
  }

  get actions() {
    return Object.assign(super.actions, {
      load: function() {
        this.status = 'loading';
        fetch(`/v1/color_palettes/${this.id}.json`).then((response) => {
          if (response.ok) {
            response.json().then(json => {
              this.update({colors: json.colors, name: json.name, status: 'loaded'});
            });
          } else {
            throw new Error(`HTTP error status: ${response.status}`);
          }
        }).catch(() => {
          this.update({status: 'failed'});
        });
      }
    });
  }

  // ---------------
  // Getters/setters
  // ---------------

  get status() {
    return this._status;
  }

  set status(status) {
    if (!_.include(ColorPaletteModel.VALID_STATUSES, status)) {
      throw new Error(`Invalid color palette status: ${status}`);
    }
    this._status = status;
  }

};

Px.Editor.ColorPaletteModel.VALID_STATUSES = ['queued', 'loading', 'loaded', 'failed'];
