Px.Editor.BasePdfModel = class BasePdfModel extends Px.BaseModel {

  static factory(id, attrs) {
    const type = this.REGEX.exec(id)[1];

    switch (type) {
    case 'db':
      return Px.Editor.DbPdfModel.make({id: id, data: attrs});
    case 'local':
      return Px.Editor.LocalPdfModel.make({id: id, data: attrs});
    default:
      console.error('Unsupported PDF type:' + type);
      return null;
    }
  }

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null},
      // Position of the PDF in the PDF store.
      order: {std: 0},
      // Custom data, specific to each PDF type.
      data: {std: null}
    });
  }

  static get computedProperties() {
    return {
      type: function() {
        return this._regexMatch[1];
      },
      value: function() {
        return this._regexMatch[2];
      },
      dimensions: function() {
        throw new Error('Not implemented');
      },
      width: function() {
        return this.dimensions[0];
      },
      height: function() {
        return this.dimensions[1];
      },
      aspect_ratio: function() {
        if (this.width === 0 || this.height === 0) {
          return 1;
        } else {
          return this.width / this.height;
        }
      },
      filename: function() {
        return (this.data && (this.data.filename || this.data.file && this.data.file.name)) || '';
      },
      _regexMatch: function() {
        return this.id.match(BasePdfModel.REGEX);
      }
    };
  }

  src(params) {
    throw new Error('Not implemented');
  }

};

Px.Editor.BasePdfModel.REGEX = /(^[\w\.]*):(.*)/;
