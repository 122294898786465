Px.Editor.BaseTaggedImageModel = class BaseTaggedImageModel extends Px.Editor.BaseImageModel {

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      tags: function() {
        const tags = {};
        const splat = this.value.split('#');
        if (splat[1]) {
          _.each(splat[1].split('&'), tag => {
            const nameval = tag.split('=');
            const name = decodeURIComponent(nameval[0]);
            const val = decodeURIComponent(nameval[1]);
            tags[name] = val;
          });
        }
        return tags;
      },
      dimensions: function(callback) {
        const tags = this.tags;
        if (tags.width && tags.height) {
          const width = parseInt(tags.width, 10) || 0;
          const height = parseInt(tags.height, 10) || 0;
          return [width, height];
        }
      }
    });
  }

  src(params) {
    const url = this.value.split('#')[0];
    if (this.hasEffects(params)) {
      params.url = url;
      return `/fbproxy/show?${$j.param(params)}`;
    } else {
      return url;
    }
  }

};
