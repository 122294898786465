Px.Editor.MobileAutofillPanel = class MobileAutofillPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const store = this.data.store;
    return Px.template`
      <div class="px-mobile-autofill-panel" data-active="${this.isActive}">
        ${Px.if(!this.images.length, () => {
          return Px.template`
            <div class="px-notice">${Px.t('No images selected.')}</div>
          `;
        }).else(() => {
          return Px.template`
            <div class="px-images">
              ${this.images.map(image => {
                return Px.template`
                  <div class="px-image"
                       data-image-id="${image.id}"
                       data-selected="${this.isSelected(image)}"
                       data-onclick="toggleImageSelection"
                       style="${this.imageStyle(image)}">
                      ${Px.raw(MobileAutofillPanel.icons.tick_mark)}
                      ${r(Px.Editor.ImageUploadProgressIndicator, `upload-progress-indicator-${image.id}`, {image: image})}
                  </div>
                `;
              })}
            </div>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      isActive: function() {
        return this.data.store.mobile.view_mode === 'autofill';
      },
      images: function() {
        return this.data.store.mobile.autofill_images;
      },
      imageTileSize: function() {
        // Calculate a width such that images fill the entire width of a row,
        // where the images are at least 80px wide;
        const window_width = this.data.store.ui.window_width
        const images_per_row = Math.floor(window_width / 80);
        return window_width / images_per_row;
      }
    }
  }

  imageStyle(image) {
    const url = image.src({size: 150});
    const size = this.imageTileSize;
    const styles = [
      `background-image: url('${url}')`,
      `width: ${size}px`,
      `height: ${size}px`
    ];
    return styles.join('; ');
  }

  isSelected(image) {
    return this.data.store.mobile.isAutofillImageIdSelected(image.id);
  }

  // --------------
  // Event handlers
  // --------------

  closePanel(evt) {
    this.state.active_panel_id = null;
  }

  toggleImageSelection(evt) {
    const image_id = evt.currentTarget.getAttribute('data-image-id');
    this.data.store.mobile.toggleSelectedAutofillImageId(image_id);
  }

};

Px.Editor.MobileAutofillPanel.icons = {
  tick_mark: '<svg class="px-tick-mark" width="16" height="16" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" stroke="#fff8" stroke-width="1" fill="currentColor" /><polyline stroke="#fff" stroke-width="2" fill="none" points="4,9 7,11 12,4" /></svg>'
};
