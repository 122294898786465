Px.Editor.PdfImportModal = class PdfImportModal extends Px.Components.BaseModal {

  constructor(data) {
    super(data);
    this.pdf_file_model = Px.Editor.LocalPdfModel.make({data: {file: this.data.pdf_file}});
    this.parsePdfDoc();
  }

  destroy() {
    this.pdf_file_model.destroy();
    super.destroy();
  }

  get dataProperties() {
    return {
      store: {required: true},
      page: {required: true},
      pdf_file: {required: true}
    };
  }

  static get properties() {
    return {
      pdf_doc: {type: 'obj', std: null},
      pdf_pages: {type: 'array', std: mobx.observable.array()},
      pdf_page_selections: {type: 'map', std: mobx.observable.map()},
      downsize: {type: 'bool', std: true}
    };
  }

  get title() {
    return Px.t('Import PDF');
  }

  get header() {
    const pdf_pages = this.state.pdf_pages;
    const pdf_page_selections = this.state.pdf_page_selections;

    return Px.template`
      <div class="px-actions-left">
      </div>
      ${super.header}
      <div class="px-actions-right">
        ${Px.if(pdf_pages.length > 1, () => {
          return Px.template`
            <button class="px-small px-primary-color" data-onclick="selectOrDeselectAll">
              ${pdf_page_selections.size === pdf_pages.length ? Px.t('Deselect All') : Px.t('Select All')}
            </button>
          `;
        })}
      </div>
    `;
  }

  get content() {
    const page = this.data.page;
    const pdf_doc = this.state.pdf_doc;
    const pdf_pages = this.state.pdf_pages;
    const pdf_page_selections = this.state.pdf_page_selections;

    if (!pdf_doc) {
      return Px.template`
        <div style="margin:2em auto; text-align:center;">
          ${Px.t('Loading...')}
        </div>
      `;
    }

    return Px.template`
      <div class="px-pdf-import-modal-main">
        <div class="px-pdf-previews" data-page-count="${pdf_pages.length}">
          ${pdf_pages.map((pdf_page, idx) => {
            const pagenum = idx + 1;
            const dimensions = this.getPdfPageDimensions(pdf_page);
            return Px.template`
              <div class="px-pdf-preview"
                  data-selected="${pdf_page_selections.has(pdf_page)}"
                  data-page-number="${pagenum}">
                <img src="${this.pdf_file_model.src({page: pagenum, size: this.previewSize})}"
                    data-onclick="selectOrDeselect"
                    style="aspect-ratio: ${dimensions.width} / ${dimensions.height};" />
                <div class="px-page-label">
                  ${Px.t('Page {{number}}').replace('{{number}}', pagenum)}
                </div>
              </div>
            `;
          })}
        </div>

        <div class="px-pdf-info">
          <div class="px-pdf-prop">
            <div class="px-pdf-prop-name">${Px.t('Name:')}</div>
            <div class="px-pdf-prop-value">${this.data.pdf_file.name}</div>
          </div>
          <div class="px-pdf-prop">
            <div class="px-pdf-prop-name">${Px.t('Page size:')}</div>
            <div class="px-pdf-prop-value">${this.pageSizeString}</div>
          </div>
          ${Px.if(this.pdfSizeMismatch, () => {
            return Px.template`
              <div class="px-warning">
                <div>
                  ${Px.t('Page size does not match project size.')}
                </div>
                ${Px.if(this.pdfSize[0] > page.width || this.pdfSize[1] > page.height, () => {
                  return Px.template`
                    <div class="px-checkbox">
                      <label>
                        <input type="checkbox" data-onchange="toggleResizeCheckbox"
                               ${this.state.downsize ? 'checked' : ''} />
                        ${Px.t('Resize to fit')}
                      </label>
                    </div>
                  `;
                })}
              </div>
            `;
          })}
          <div class="px-pdf-prop">
            <div class="px-pdf-prop-name">${Px.t('Pages:')}</div>
            <div class="px-pdf-prop-value">${this.pageCountString}</div>
          </div>
        </div>
      </div>
    `;
  }

  get footer() {
    if (this.state.pdf_doc) {
      return Px.template`
        <button class="px-small px-primary-color px-strong"
                ${this.state.pdf_page_selections.size === 0 ? 'disabled' : ''}
                data-onclick="importPdfPages">
          ${Px.t('Import')}
        </button>
      `;
    }
  }

  get css_class() {
    return `${super.css_class} px-pdf-import-modal`;
  }

  static get computedProperties() {
    return {
      selectedPdfPages: function() {
        return this.state.pdf_pages.filter(page => this.state.pdf_page_selections.has(page));
      },
      pageSizeString: function() {
        const first_selected_page = this.selectedPdfPages[0] || this.state.pdf_pages[0];
        if (!first_selected_page) {
          return '';
        }
        let {width, height} = this.getPdfPageDimensions(first_selected_page);
        const unit = this.data.store.project.unit === 'inch' ? 'in' : 'cm';
        if (unit === 'in') {
          // Convert from mm to inches.
          width = Px.Util.mm2in(width);
          height = Px.Util.mm2in(height);
        } else {
          // Convert from mm to cm.
          width /= 10;
          height /= 10;
        }
        const format_opts = {
          precision: 1,
          separator: Px.config.currency_format.separator,
          strip_insignificant_zeros: true
        };
        const width_str = Px.Util.formatNumber(width, format_opts);
        const height_str = Px.Util.formatNumber(height, format_opts);
        return Px.template`${width_str}${unit} &times; ${height_str}${unit}`;
      },
      pageCountString: function() {
        let str = Px.t('{{selected_count}} of {{total_count}} selected');
        str = str.replace('{{selected_count}}', this.state.pdf_page_selections.size);
        str = str.replace('{{total_count}}', this.state.pdf_pages.length);
        return str;
      },
      previewSize: function() {
        const page_count = this.state.pdf_pages.length;
        if (page_count === 1) {
          return 600;
        } else if (page_count === 2) {
          return 300;
        } else if (page_count === 3) {
          return 200;
        } else {
          return 150;
        }
      },
      pdfSize: function() {
        const first_selected_page = this.selectedPdfPages[0];
        if (first_selected_page) {
          const {width, height} = this.getPdfPageDimensions(first_selected_page);
          return [width, height];
        }
        return null;
      },
      pdfSizeMismatch: function() {
        if (this.pdfSize) {
          const [width, height] = this.pdfSize;
          const page_width = this.data.page.width;
          const page_height = this.data.page.height;
          const dw = Math.abs(page_width - width);
          const dh = Math.abs(page_height - height);
          return (dw / page_width) > 0.01 || (dh / page_height) > 0.01;
        }
        return false;
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  selectOrDeselect(evt) {
    const page_number = parseInt(evt.target.closest('.px-pdf-preview').getAttribute('data-page-number'), 10);
    const pdf_page = this.state.pdf_pages[page_number - 1];
    if (this.state.pdf_page_selections.has(pdf_page)) {
      this.state.pdf_page_selections.delete(pdf_page);
    } else {
      this.state.pdf_page_selections.set(pdf_page, true);
    }
  }

  selectOrDeselectAll(evt) {
    const pages = this.state.pdf_pages;
    const selections = this.state.pdf_page_selections;
    if (selections.size === pages.length) {
      selections.clear();
    } else {
      mobx.runInAction(() => {
        pages.forEach(page => {
          if (!selections.has(page)) {
            selections.set(page, true);
          }
        });
      });
    }
  }

  toggleResizeCheckbox(evt) {
    this.state.downsize = !this.state.downsize;
  }

  async importPdfPages(evt) {
    const store = this.data.store;
    const pdf_pages = this.selectedPdfPages;

    const page_models = store.project.pages;
    let page_idx = page_models.indexOf(this.data.page);
    const end_undo = store.undo_redo.beginWithUndo({label: 'import PDF pages'});

    let remaining_pdf_pages = await this.autofillPdfs(pdf_pages, page_models.slice(page_idx));

    if (remaining_pdf_pages.length === 0) {
      if (pdf_pages.length > 1) {
        store.showNotification(Px.t('Imported {{count}} PDF pages.').replace('{{count}}', pdf_pages.length), 'success');
      }
    } else {
      const confirm_text = Px.t('Could not fill all PDF pages.') + '\n' + Px.t('Add more pages?');
      if (store.project.can_add_pages && confirm(confirm_text)) {
        remaining_pdf_pages = await this.autofillPdfsWithNewPages(remaining_pdf_pages);
        if (remaining_pdf_pages.length === 0) {
          store.showNotification(Px.t('Filled {{count}} PDF pages.').replace('{{count}}', pdf_pages.length), 'success');
        } else {
          store.showNotification(Px.t('Could not fill all PDF pages.'), 'warning');
        }
      } else {
        store.showNotification(Px.t('Could not fill all PDF pages.'), 'warning');
      }
    }

    end_undo();
    this.close();
  }

  // -------
  // Private
  // -------

  parsePdfDoc() {
    const reader = new FileReader();
    reader.onload = () => {
      PDFLib.PDFDocument.load(reader.result).then(pdf_doc => {
        mobx.runInAction(() => {
          this.state.pdf_doc = pdf_doc;
          this.state.pdf_pages = pdf_doc.getPages();
          // Select all pages by default.
          this.state.pdf_pages.forEach(page => {
            this.state.pdf_page_selections.set(page, true);
          });
        });
      });
    };
    reader.onerror = reader.onabort = evt => {
      console.warn(`Failed reading file ${this.data.pdf_file.name}: ${reader.error.name}`);
    };
    reader.readAsArrayBuffer(this.data.pdf_file);
  }

  getPdfPageDimensions(pdf_page) {
    const cropbox = pdf_page.getCropBox();
    const rotation = pdf_page.getRotation().angle;
    let width, height;
    if (Math.abs(rotation % 180) === 90) {
      // Flip width and height from cropbox.
      width = Px.Util.pt2mm(cropbox.height);
      height = Px.Util.pt2mm(cropbox.width);
    } else {
      width = Px.Util.pt2mm(cropbox.width);
      height = Px.Util.pt2mm(cropbox.height);
    }
    return {width: width, height: height};
  }

  async autofillPdfs(pdf_pages, page_models) {
    let pdf_idx = 0;
    let page_idx = 0;

    const getNextPage = () => {
      while (page_models[page_idx]) {
        const page = page_models[page_idx];
        page_idx++;
        if (page.edit) {
          return page;
        }
      }
      return null;
    };

    for (const pdf_page of pdf_pages) {
      const page_model = getNextPage();
      if (page_model) {
        await this.importPdfPage(pdf_page, page_model);
        pdf_idx++;
      } else {
        break;
      }
    }

    return pdf_pages.slice(pdf_idx);
  }

  async autofillPdfsWithNewPages(pdf_pages) {
    const store = this.data.store;
    let unfilled_pdf_pages_count = pdf_pages.length;
    while (unfilled_pdf_pages_count > 0) {
      const page_count = store.project.pages.length;
      store.addPages();
      pdf_pages = await this.autofillPdfs(pdf_pages, store.project.pages.slice(page_count));
      if (pdf_pages.length === unfilled_pdf_pages_count) {
        // Adding new pages did not help fill any PDFs, so give up.
        break;
      }
      unfilled_pdf_pages_count = pdf_pages.length;
    }
    return pdf_pages;
  }

  async importPdfPage(pdf_page, page_model) {
    const pdf_store = this.data.store.pdfs;
    const file = this.data.pdf_file;
    const page_number = this.state.pdf_pages.indexOf(pdf_page) + 1;
    const dimensions = this.getPdfPageDimensions(pdf_page);
    const aspect_ratio = dimensions.width / dimensions.height;
    let {width, height} = dimensions
    if ((width > page_model.width || height > page_model.height) && this.state.downsize) {
      const resized_dims = Px.Util.inscribedRectangleDimensions(page_model.width, page_model.height, 0, aspect_ratio);
      width = resized_dims.width;
      height = resized_dims.height;
    }

    const unique_id = await this.uniquePdfId(file);
    const id = `local:${unique_id}`;
    if (!pdf_store.get(id)) {
      pdf_store.register(id, {file: file});
    }
    // Have to go through main image store to make sure local ID gets dereferenced,
    // if PDF is already uploaded.
    const registered_pdf = pdf_store.get(id);

    const attributes = {
      id: registered_pdf.id,
      page_number: page_number,
      x: (page_model.width - width) / 2,
      y: (page_model.height - height) / 2,
      width: width,
      height: height
    };

    const pdf_element = this.data.store.addPdfElement(attributes, page_model);
    return pdf_element;
  }

  async uniquePdfId(file) {
    // Crypto is not available in non-secure contexts,
    // and Safari < 14 does not support Blob.prototype.arrayBuffer().
    // Fall back to a less precise method of in that case.
    if (Crypto.subtle && file.arrayBuffer) {
      const array_buffer = await file.arrayBuffer();
      const hash_buffer = await Crypto.subtle.digest('SHA-256', array_buffer);
      const hash_array = Array.from(new Uint8Array(hash_buffer));
      const hash_hex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return has_hex;
    } else {
      const parts = [file.name, file.lastModified, file.size];
      return btoa(unescape(encodeURIComponent(parts.join('-'))));
    }
  }

};
