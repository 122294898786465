Px.Editor.LocalImageModel = class LocalImageModel extends Px.Editor.BaseImageModel {

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      dimensions: function() {
        const width = this.data.width || 0;
        const height = this.data.height || 0;
        return [width, height];
      },
      preview: function() {
        return this.src();
      },
      filename: function() {
        return this.data.file.name;
      }
    });
  }

  // This returns the temporary path and ignores any effects.
  // As soon as the image is being used in a book it's going to start uploading,
  // and we will use the db image once it's uploaded.
  src(params) {
    return this.data.thumb_url;
  }

};
