Px.Editor.ThemeTemplateModel = class ThemeTemplateModel extends Px.BaseModel {

  static get properties() {
    return Object.assign(super.properties, {
      page: {type: 'ref', std: null}
    });
  }

  static get computedProperties() {
    return {
      id: function() {
        return this.page.id;
      },
      name: function() {
        return this.page.name;
      },
      width: function() {
        return this.page.width;
      },
      height: function() {
        return this.page.height;
      },
      image_elements: function() {
        return this.page.image_elements;
      },
      edit: function() {
        return this.page.edit;
      }
    };
  }

};
