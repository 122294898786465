Px.Editor.ElementSelectionModel = class ElementSelectionModel extends Px.BaseModel {

  constructor(props) {
    super(props);
    this.type = 'selection';
    this.rotation = 0;
    this.absolute_rotation = 0;
  }

  static get properties() {
    return Object.assign(super.properties, {
      elements: {std: mobx.observable.array()},
      page: {std: null},
      is_selected: {std: false},
      _is_grabbed: {std: false}
    });
  }

  get is_grabbed() {
    return this._is_grabbed;
  }

  set is_grabbed(grabbed) {
    mobx.runInAction(() => {
      this._is_grabbed = grabbed;
      this.elements.forEach(element => {
        element.update({is_grabbed: grabbed});
      });
    });
  }

  static get computedProperties() {
    return {
      min_x: function() {
        return Math.min.apply(null, this.elements.map(e => e.x));
      },
      min_y: function() {
        return Math.min.apply(null, this.elements.map(e => e.y));
      },
      width: function() {
        return Math.max.apply(null, this.elements.map(e => e.x + e.width)) - this.x;
      },
      height: function() {
        return Math.max.apply(null, this.elements.map(e => e.y + e.height)) - this.y;
      },
      edit: function() {
        return this.elements.every(element => element.edit);
      },
      move: function() {
        return this.elements.every(element => element.move);
      },
      resize: function() {
        return this.elements.every(element => element.resize);
      },
      delete: function() {
        return this.elements.every(element => element['delete']);
      },
      erotation: function() {
        // Rotation is currently not implemented for element selections;
        return false;
      },
      elayer: function() {
        // Switching layers is currently not implemented for element selections;
        return false;
      },
      two_page_spread_clone: function() {
        if (this.elements.length && this.elements.every(element => element.two_page_spread_clone)) {
          const clone_page = this.elements[0].two_page_spread_clone.page;
          const clone = Px.Editor.ElementSelectionModel.make({page: clone_page});
          this.elements.forEach(element => clone.addElement(element.two_page_spread_clone));
          return clone;
        }
        return null;
      },
      is_in_viewport: function() {
        return this.elements.some(element => element.is_in_viewport);
      },
      // Used for copy/paste only.
      xml: function() {
        return `<selection>${this.elements.map(element => element.xml).join('')}</selection>`;
      }
    }
  }

  get actions() {
    const super_destroy = super.actions.destroy;
    return Object.assign(super.actions, {

      addElement: function(element) {
        if (!this.hasElement(element)) {
          this.elements.push(element);
        }
      },

      removeElement: function(element) {
        this.elements.remove(element);
      },

      setX: function(value) {
        const diff = value - this.x;
        this.elements.forEach(element => {
          element.x += diff;
        });
      },

      setY: function(value) {
        const diff = value - this.y;
        this.elements.forEach(element => {
          element.y += diff;
        });
      },

      destroy: function() {
        this.elements.forEach(element => element.destroy());
        super_destroy.call(this);
      }

    });
  }

  hasElement(element) {
    return this.elements.includes(element);
  }

  get x() {
    return this.min_x;
  }

  set x(val) {
    this.setX(val);
  }

  get y() {
    return this.min_y;
  }

  set y(val) {
    this.setY(val);
  }

  elementAlignments(precision, grid) {
    const exclude_elements = this.elements.toJS();
    let alignments = [];
    this.elements.forEach(element => {
      alignments = alignments.concat(element.elementAlignments(precision, grid, exclude_elements));
    });
    return alignments;
  }

  translationSnapPoint(x, y, grid) {
    const exclude_elements = this.elements.toJS();
    const best_snap_point = {x: null, y: null};
    this.elements.forEach(element => {
      const diff_x = element.x - this.x;
      const diff_y = element.y - this.y;
      const snap_point = element.translationSnapPoint(x + diff_x, y + diff_y, grid, exclude_elements);
      if (snap_point) {
        const snap_x = snap_point.x - diff_x;
        if (best_snap_point.x === null || Math.abs(snap_x - x) < Math.abs(best_snap_point.x - x)) {
          best_snap_point.x = snap_x;
        }
        const snap_y = snap_point.y - diff_y;
        if (best_snap_point.y === null || Math.abs(snap_y - y) < Math.abs(best_snap_point.y - y)) {
          best_snap_point.y = snap_y;
        }
      }
    });
    return best_snap_point;
  }

};
