Px.Editor.TextsPanel = class TextsPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-tab-content-panel px-texts-panel">
        <div class="px-panel-top-section">
          ${Px.if(this.selectedText, () => {
            return Px.template`<h1>${Px.t('Edit Text:')}</h1>`;
          }).else(() => {
            return Px.template`<h1>${Px.t('Texts:')}</h1>`;
          })}
        </div>

        ${Px.if(this.selectedText, () => {
          if (Px.config.advanced_edit_mode) {
            return r(Px.Editor.TextEditAccordion, 'text-edit-accordion', {text: this.selectedText});
          } else {
            return r(Px.Editor.TextEditPanel, 'text-edit-panel', {text: this.selectedText});
          }
        }).else(() => {
          return Px.template`
            <div class="px-action-description-area">
              <p>${this.addTextInstructions}</p>
            </div>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    }
  }

  static get computedProperties() {
    return {
      selectedText: function() {
        if (this.data.store.selected_element && this.data.store.selected_element.type === 'text') {
          return this.data.store.selected_element;
        }
      },
      addTextInstructions: function() {
        // Some special handling is needed to be able to splice an icon into text that
        // otherwise needs to get HTML-escaped.
        const default_translation =
              'Click the {{button_icon}} button at the top of the main area to add a text box to your project. ' +
              'Double click existing text boxes to edit them.';
        const translation = Px.t('Add text instructions', default_translation);
        const parts = translation.split('{{button_icon}}');
        const result = [];
        for (let i = 0; i < parts.length - 1; i++) {
          result.push(parts[i]);
          result.push(Px.raw(Px.Editor.PageDisplay.icons.add_text));
        }
        result.push(parts[parts.length - 1]);
        return result;
      }
    };
  }

};
