Px.Editor.MobileImageSelectorPanel = class MobileImageSelectorPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    return Px.template`
      <div class="px-mobile-image-selector-panel" data-selected-gallery="${this.selectedGalleryId}">
        <ul class="px-image-sources">
          ${this.availableGalleries.map(gallery => {
            return Px.template`
              <li>
                <label data-gallery-id="${gallery.id}" data-onclick="selectGallery">
                  <div class="px-icon">${Px.raw(gallery.icon)}</div>
                  <div class="px-title">${gallery.title}</div>
                  ${Px.if(gallery.id === 'device', () => {
                    return Px.template`
                      <input hidden
                             type="file"
                             accept="${Px.LocalFiles.Validation.VALID_FILE_TYPES.join(', ')}"
                             ${this.isMultipleMode ? 'multiple' : ''}
                             data-onchange="onFileSelected"
                      />
                    `;
                  })}
                </label>
              </li>
            `;
          })}
        </ul>
        <div class="px-gallery-panel">
          ${Px.if(Boolean(this.selectedGallery), () => {
            return Px.template`
              <div class="px-gallery-actions">
                <button class="px-back-button" data-onclick="goBack">
                  ${Px.raw(MobileImageSelectorPanel.icons.back_arrow)}
                </button>
              </div>
              <div class="px-gallery-container">
                ${r(this.selectedGallery.component, this.galleryComponentKey(this.selectedGallery))}
              </div>
            `;
          })}
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      availableGalleries: function() {
        const gallery_store = this.data.store.galleries;
        const gallery_definitions = {
          'device': {
            id: 'device',
            title: Px.t('Device'),
            icon: MobileImageSelectorPanel.icons.device
          },
          'galleries': {
            id: 'galleries',
            title: Px.t('Galleries'),
            icon: MobileImageSelectorPanel.icons.galleries,
            component: Px.Editor.MobileUserGalleryPanel,
            init: function() {
              gallery_store.user.init();
            }
          },
          'instagram': {
            id: 'instagram',
            title: Px.t('Instagram'),
            icon: MobileImageSelectorPanel.icons.instagram,
            component: Px.Editor.MobileInstagramGalleryPanel,
            init: function() {
              gallery_store.instagram.init();
            }
          },
          'facebook': {
            id: 'facebook',
            title: Px.t('Facebook'),
            icon: MobileImageSelectorPanel.icons.facebook,
            component: Px.Editor.MobileFacebookGalleryPanel,
            init: function() {
              gallery_store.facebook.init();
            }
          },
          'dropbox': {
            id: 'dropbox',
            title: Px.t('Dropbox'),
            icon: MobileImageSelectorPanel.icons.dropbox,
            component: Px.Editor.MobileDropboxGalleryPanel,
            init: function() {
              gallery_store.dropbox.init();
            }
          }
        };
        const galleries = [];
        this.data.store.image_sources.forEach(function(gallery_id) {
          const gallery = gallery_definitions[gallery_id];
          if (gallery) {
            galleries.push(gallery);
          }
        });
        return galleries;
      },
      isMultipleMode: function() {
        const store = this.data.store;
        return store.cut_print_mode || store.mobile.view_mode === 'autofill';
      },
      selectedGallery: function() {
        return this.getGalleryById(this.data.store.ui.active_image_source);
      },
      selectedGalleryId: function() {
        return this.selectedGallery ? this.selectedGallery.id : 'none';
      }
    };
  }

  getGalleryById(gallery_id) {
    let gallery = null;
    if (gallery_id) {
      gallery = this.availableGalleries.find(function(gallery) {
        return gallery.id === gallery_id;
      });
    }
    return gallery || null;
  }

  // --------------
  // Event handlers
  // --------------

  selectGallery(evt) {
    const store = this.data.store;
    const gallery_id = evt.currentTarget.getAttribute('data-gallery-id');
    const gallery = this.getGalleryById(gallery_id);
    if (gallery.init) {
      gallery.init();
    }
    if (gallery_id !== 'device') {
      store.ui.active_image_source = gallery_id;
    }
  }

  goBack(evt) {
    const gallery_component = this.subcomponents[this.galleryComponentKey(this.selectedGallery)];
    if (gallery_component.canGoUpLevel) {
      gallery_component.upLevel();
    } else {
      this.deselectGallery();
    }
  }

  onFileSelected(evt) {
    const store = this.data.store;
    const files = Px.LocalFiles.Validation.filterAndValidateFiles(evt.target.files);
    if (store.cut_print_mode) {
      store.mobile.setActiveTool(null);
      store.importCutPrintImageFiles(files, () => {
        // Scroll the cut prints panel down to the last added cut print.
        requestAnimationFrame(() => {
          const last_div = document.querySelector('.px-cut-prints .px-page-set:last-child');
          last_div.scrollIntoView({behavior: 'smooth', block: 'end'});
        });
      });
    } else {
      store.galleries.project.importImages(files, image => {
        mobx.runInAction(() => {
          if (!store.images.get(image.id)) {
            store.images.register(image.id, image.data);
          }
          if (store.mobile.view_mode === 'autofill') {
            store.mobile.addAutofillImageId(image.id);
            // Scroll the autofill panel down to the last added image.
            requestAnimationFrame(() => {
              const last_div = document.querySelector('.px-mobile-autofill-panel .px-image:last-child');
              last_div.scrollIntoView({behavior: 'smooth', block: 'end'});
            });
          } else {
            store.selected_element.update({id: image.id, placeholder: false});
          }
        });
      });
    }
  }

  // -------
  // Private
  // -------

  galleryComponentKey(gallery) {
    return `gallery-${gallery.id}`;
  }

  deselectGallery() {
    this.data.store.ui.active_image_source = null;
  }

};

Px.Editor.MobileImageSelectorPanel.icons = {
  back_arrow: '<svg width="12px" height="20px" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg"><polyline stroke-width="2" stroke-linecap="round" fill="none" stroke="currentColor" points="11,1 2,10 11,19" /></svg>',
  device: '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><rect x="6" y="1" width="14" height="24" rx="1.41" stroke-width="1.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><path d="M13,23.15a.75.75,0,0,0,0-1.5.75.75,0,0,0,0,1.5Z"/><line x1="6" y1="4" x2="20" y2="4" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><line x1="6" y1="20.5" x2="20" y2="20.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"/></svg>',
  galleries: '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><polyline points="17.84 16.9 14.28 8.57 11.16 14.79 8.05 11.4 4.51 16.9" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5"/><path d="M20.62,21.07H1.73a.56.56,0,0,1-.55-.56V1.64a.56.56,0,0,1,.56-.55l18.87,0a.56.56,0,0,1,.56.56V20.51A.56.56,0,0,1,20.62,21.07Z" fill="none" stroke="currentColor" stroke-width="1.5"/><path d="M1.18,16.9h20" fill="none" stroke="currentColor" stroke-width="1.5"/><path d="M7.84,6.9A1.67,1.67,0,1,1,6.18,5.24,1.66,1.66,0,0,1,7.84,6.9Z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5"/><line x1="21" y1="5" x2="25" y2="5" fill="none" stroke="currentColor" stroke-width="1.5"/><line x1="25" y1="25" x2="25" y2="5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><line x1="5" y1="25" x2="25" y2="25" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><line x1="5" y1="21.5" x2="5" y2="25" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>',
  facebook: '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><path d="M18.59,14.1l.67-4.34H15.09V6.94A2.17,2.17,0,0,1,17.54,4.6h1.9V.9A22.65,22.65,0,0,0,16.07.6C12.64.6,10.4,2.68,10.4,6.45V9.76H6.58V14.1H10.4V24.6h4.69V14.1Z" fill="currentColor"/></svg>',
  instagram: '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><path d="M13,6.85A6.15,6.15,0,1,0,19.15,13,6.14,6.14,0,0,0,13,6.85ZM13,17a4,4,0,1,1,4-4,4,4,0,0,1-4,4ZM20.84,6.59a1.44,1.44,0,1,1-1.43-1.43A1.43,1.43,0,0,1,20.84,6.59Zm4.08,1.46A7.16,7.16,0,0,0,23,3a7.2,7.2,0,0,0-5-1.94C16,1,10,1,8.05,1.08A7.16,7.16,0,0,0,3,3,7.11,7.11,0,0,0,1.08,8C1,10,1,16,1.08,17.94A7.12,7.12,0,0,0,3,23a7.2,7.2,0,0,0,5,1.94c2,.11,7.92.11,9.9,0A7.11,7.11,0,0,0,23,23a7.17,7.17,0,0,0,1.94-5c.11-2,.11-7.91,0-9.89Zm-2.56,12a4.06,4.06,0,0,1-2.28,2.28c-1.58.63-5.33.48-7.08.48s-5.5.14-7.07-.48a4.06,4.06,0,0,1-2.28-2.28C3,18.49,3.17,14.74,3.17,13S3,7.5,3.65,5.92A4.12,4.12,0,0,1,5.93,3.64C7.51,3,11.26,3.16,13,3.16s5.5-.14,7.08.48a4.12,4.12,0,0,1,2.28,2.28c.62,1.58.48,5.33.48,7.08S23,18.5,22.36,20.07Z" fill="currentColor"/></svg>',
  dropbox: '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><path d="M13,6.65,7,10.48l6,3.83L7,18.15,1,14.28l6-3.83L1,6.65,7,2.82l6,3.83ZM7,19.35l6-3.83,6,3.83-6,3.83Zm6-5.07,6-3.83-6-3.8,6-3.83,6,3.83-6,3.83,6,3.83-6,3.83-6-3.86Z" fill="currentColor"/></svg>'
};
