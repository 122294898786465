Px.Editor.ImageMaskPanel = class ImageMaskPanel extends Px.Editor.BaseComponent {
  template() {
    return Px.template`
      <div class="px-tab-content-panel px-image-mask-panel px-tab-panel-accordion-wrapper">
        ${this.renderChild(Px.Editor.TaggedItemsPanel, 'tagged-items', this.taggedItemsPanelProps)}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      taggedItemsPanelProps: function() {
        return {
          items: this.data.store.masks.tagged_images,
          list_component: Px.Editor.ImageMaskPanel.ImageMaskList
        };
      }
    };
  }

};

Px.Editor.ImageMaskPanel.ImageMaskList = class ImageMaskList extends Px.Editor.BaseComponent {
  template() {
    const selected_element = this.data.store.selected_element;
    return Px.template`
      <div class="px-image-masks px-image-selection">
        ${this.masks.map(image => {
          const is_selected = Boolean(selected_element && selected_element.mask === image.id);
          return Px.template`
            <div class="px-image"
                 data-selected="${is_selected}"
                 data-image-id="${image.id}"
                 data-onclick="selectMask">
              <div class="px-thumbnail">
                <img src="${image.preview}" />
              </div>
              ${is_selected ? Px.raw(Px.Editor.BaseGalleryPanel.icons.tick_mark) : ''}
            </div>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true},
      items: {required: true}
    };
  }

  static get computedProperties() {
    return {
      masks: function() {
        const image_store = this.data.store.images;
        return this.data.items.map(tagged_image => image_store.get(tagged_image.id));
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  selectMask(evt) {
    const image = this.data.store.selected_element;
    const mask_id = evt.currentTarget.getAttribute('data-image-id');
    if (mask_id === image.mask) {
      this.withUndo('remove image mask', () => {
        image.update({mask: null});
      });
    } else {
      this.withUndo('set image mask', () => {
        image.update({mask: mask_id});
      });
    }
  }

};
