Px.Editor.ClipartPanel = class ClipartPanel extends Px.Editor.BaseGalleryPanel {

  get css_class() {
    return 'px-clipart-gallery-panel';
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      galleryStore: function() {
        return this.data.store.galleries.clipart;
      },
      availableTags: function() {
        const tags = new Set();
        this.galleryStore.tagged_images.forEach(item => {
          item.tags.forEach(tag => tags.add(tag));
        });
        return Array.from(tags).sort();
      },
      items: function() {
        if (this.state.selected_gallery_id) {
          const images = [];
          this.galleryStore.tagged_images.forEach(item => {
            if (item.tags.includes(this.state.selected_gallery_id)) {
              images.push(this.buildImageItem(item.id));
            }
          });
          return images;
        } else {
          const tags = this.availableTags.map(tag => this.buildGalleryItem(tag));
          const images = [];
          this.galleryStore.tagged_images.forEach(item =>  {
            if (item.tags.length === 0) {
              images.push(this.buildImageItem(item.id));
            }
          });
          return tags.concat(images);
        }
      },
      isLoading: function() {
        return this.galleryStore.loading;
      },
      canGoUpLevel: function() {
        return this.state.selected_gallery_id !== null;
      },
      showAutofillButton: function() {
        return false;
      }
    });
  }

  imageDragOptions() {
    return Object.assign({
      can_drop_on_existing_image: false,
      image_attributes: {
        crop: false,
        stretch: true,
        replace: false
      }
    }, super.imageDragOptions());
  }

  // --------------
  // Event handlers
  // --------------

  upLevel(evt) {
    this.state.selected_gallery_id = null;
  }

  // -------
  // Private
  // -------

  buildGalleryItem(tag) {
    return {
      type: 'gallery',
      id: tag,
      caption: tag
    };
  }

  buildImageItem(image_id) {
    const image = this.data.store.images.get(image_id);
    return {
      type: 'image',
      id: image_id,
      thumb_url: image.preview,
      caption: image.filename,
      data: image.data
    };
  }

};
