Px.Editor.GridElementModel = class GridElementModel extends Px.Editor.BaseElementModel {

  static fromXMLNode(node, params) {
    const props = {
      edit:         node.getAttribute('edit') === 'true',
      x:            parseFloat(node.getAttribute('x')) || 0,
      y:            parseFloat(node.getAttribute('y')) || 0,
      width:        parseFloat(node.getAttribute('width')) || 0,
      height:       parseFloat(node.getAttribute('height')) || 0,
      rotation:     parseFloat(node.getAttribute('rotate')) || 0,
      erotation:    node.getAttribute('erotation') !== 'false',
      header:       parseFloat(node.getAttribute('header') || 0),
      sidebar:      parseFloat(node.getAttribute('sidebar') || 0),
      stroke:       parseFloat(node.getAttribute('stroke') || 1),
      color:        node.getAttribute('color') || '#000000',
      headercolor:  node.getAttribute('headercolor') || '#000000',
      sidebarcolor: node.getAttribute('sidebarcolor') || '#000000',
      tags:         node.getAttribute('tags') || null,
      clone_id:     node.getAttribute('clone_id') || null,
      layout:       node.getAttribute('layout') === 'true',
      pdf_layer:    node.getAttribute('pdf_layer') || null,
      group:        params.group || null,
      page:         params.page
    };
    return Px.Editor.GridElementModel.make(props);
  }

  static get properties() {
    return Object.assign(super.properties, {
      header: {std: 0},
      sidebar: {std: 0},
      stroke: {std: 1},
      color: {std: '#000000'},
      headercolor: {std: '#000000'},
      sidebarcolor: {std: '#000000'}
    });
  }

};

Px.Editor.GridElementModel.ELEMENT_TYPE = 'grid';
