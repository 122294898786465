Px.Editor.InlinePageEditPanel = class InlinePageEditPanel extends Px.Editor.BaseComponent {

  template() {
    const ipage = this.data.ipage;
    const r = this.renderChild;
    const CoordinateInput = Px.Editor.CoordinateInput;

    return Px.template`
      <div class="px-image-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control">
              <h2>${Px.t('Template Name')}</h2>
              ${r(Px.Components.Dropdown, `template-select-${ipage.unique_id}`, this.templateNameDropdownProps)}
            </div>

            ${Px.if(ipage.crop, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Zoom')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'zoom-slider', this.zoomSliderProps)}
                </div>
              `;
            })}
            ${Px.if(ipage.eopacity, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Opacity')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'opacity-slider', this.opacitySliderProps)}
                </div>
              `;
            })}
            ${Px.if(ipage.erotation, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Rotation')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'rotation-slider', this.rotationSliderProps)}
                </div>
              `;
            })}

            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Border Radius')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'radius-slider', this.radiusSliderProps)}
            </div>

            <div class="px-edit-control px-mask-control">
              <h2>${Px.t('Mask')}</h2>
              <div class="px-controls">
                <button class="px-select-mask" data-onclick="goToMaskSelection">
                  ${Px.t('Select...')}
                </button>
                ${Px.if(ipage.mask, () => {
                  return Px.template`
                    <button class="px-remove-mask" data-onclick="clearMask">
                      ${Px.t('Remove')}
                    </button>
                  `;
                })}
              </div>
            </div>

            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Shadow Opacity')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'shadow-opacity', this.shadowOpacitySliderProps)}
            </div>

            ${Px.if(ipage.shadow_opacity > 0, () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Shadow Properties')}</h2>
                  <div class="px-element-coords">
                    ${r(CoordinateInput, 'shadow-stdev', this.shadowStdDevInputProps)}
                    ${r(CoordinateInput, 'shadow-ox', this.shadowOffsetXInputProps)}
                    ${r(CoordinateInput, 'shadow-oy', this.shadowOffsetYInputProps)}
                  </div>
                </div>

                <div class="px-edit-control">
                  <h2>${Px.t('Shadow Color')}</h2>
                  ${r(Px.Components.ColorButton, 'shadow-color', this.shadowColorButtonProps)}
                </div>
              `;
            })}

          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setTemplateName = this.setTemplateName.bind(this);
    this.setZoom = this.setZoom.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.setRotation = this.setRotation.bind(this);
    this.setRadius = this.setRadius.bind(this);
    this.setShadowOpacity = this.setShadowOpacity.bind(this);
    this.selectShadowColor = this.selectShadowColor.bind(this);
    this.onBeforeZoomSliderDrag = this.onBeforeZoomSliderDrag.bind(this);
    this.onAfterZoomSliderDrag = this.onAfterZoomSliderDrag.bind(this);
    this.onBeforeOpacitySliderDrag = this.onBeforeOpacitySliderDrag.bind(this);
    this.onAfterOpacitySliderDrag = this.onAfterOpacitySliderDrag.bind(this);
    this.onBeforeRotationSliderDrag = this.onBeforeRotationSliderDrag.bind(this);
    this.onAfterRotationSliderDrag = this.onAfterRotationSliderDrag.bind(this);
    this.onBeforeRadiusSliderDrag = this.onBeforeRadiusSliderDrag.bind(this);
    this.onAfterRadiusSliderDrag = this.onAfterRadiusSliderDrag.bind(this);
    this.onBeforeShadowOpacitySliderDrag = this.onBeforeShadowOpacitySliderDrag.bind(this);
    this.onAfterShadowOpacitySliderDrag = this.onAfterShadowOpacitySliderDrag.bind(this);
  }

  get dataProperties() {
    return {
      ipage: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      templateNameDropdownProps: function() {
        const selected_page = this.data.store.selected_page;
        const template_names = [];
        this.data.store.theme.templates.forEach(template => {
          if (template.name !== selected_page.name) {
            template_names.push(template.name);
          }
        });
        const options = _.uniq(template_names).map(name => {
          return {name: name, value: name};
        });
        return {
          value: this.data.ipage.template,
          options: options,
          onNewValue: this.setTemplateName
        };
      },
      zoomSliderProps: function() {
        return {
          min: 0,
          max: 1000,
          step: 0.25,
          value: this.data.ipage.zoom,
          onNewValue: this.setZoom,
          onBeforeDrag: this.onBeforeZoomSliderDrag,
          onAfterDrag: this.onAfterZoomSliderDrag
        };
      },
      opacitySliderProps: function() {
        return {
          min: 20,
          max: 100,
          value: Math.round(this.data.ipage.opacity * 100),
          onNewValue: this.setOpacity,
          onBeforeDrag: this.onBeforeOpacitySliderDrag,
          onAfterDrag: this.onAfterOpacitySliderDrag
        };
      },
      rotationSliderProps: function() {
        return {
          min: -180,
          max: 180,
          step: 0.25,
          value: this.data.ipage.rotation,
          onNewValue: this.setRotation,
          onBeforeDrag: this.onBeforeRotationSliderDrag,
          onAfterDrag: this.onAfterRotationSliderDrag
        };
      },
      radiusSliderProps: function() {
        return {
          min: 0,
          max: 200,
          step: 0.25,
          value: this.data.ipage.radius,
          onNewValue: this.setRadius,
          onBeforeDrag: this.onBeforeRadiusSliderDrag,
          onAfterDrag: this.onAfterRadiusSliderDrag
        };
      },
      shadowOpacitySliderProps: function() {
        return {
          min: 0,
          max: 100,
          value: Math.round(this.data.ipage.shadow_opacity * 100),
          onNewValue: this.setShadowOpacity,
          onBeforeDrag: this.onBeforeShadowOpacitySliderDrag,
          onAfterDrag: this.onAfterShadowOpacitySliderDrag
        };
      },
      shadowStdDevInputProps: function() {
        return {
          property: 'shadow_stdev',
          label: Px.t('Blur'),
          element: this.data.ipage,
          min: 0,
          store: this.data.store
        };
      },
      shadowOffsetXInputProps: function() {
        return {
          property: 'shadow_ox',
          label: Px.t('Offset X'),
          element: this.data.ipage,
          store: this.data.store
        };
      },
      shadowOffsetYInputProps: function() {
        return {
          property: 'shadow_oy',
          label: Px.t('Offset Y'),
          element: this.data.ipage,
          store: this.data.store
        };
      },
      shadowColorButtonProps: function() {
        return {
          color: this.data.ipage.shadow_color,
          title: Px.t('Choose color'),
          onClick: this.selectShadowColor
        }
      }
    };
  }

  setTemplateName(name) {
    this.withUndo('change inline page template name', () => {
      this.data.ipage.update({template: name});
    });
  }
  setOpacity(opacity) {
    this.withUndo('change inline page opacity', () => {
      this.data.ipage.update({opacity: opacity / 100});
    });
  }
  setZoom(zoom) {
    this.withUndo('change inline page zoom', () => {
      this.data.ipage.update({zoom: zoom});
    });
  }
  setRotation(rotation) {
    this.withUndo('rotate inline page', () => {
      this.data.ipage.update({rotation: rotation});
    });
  }
  setRadius(radius) {
    this.withUndo('set border radius', () => {
      this.data.ipage.update({radius: radius});
    });
  }
  setShadowOpacity(opacity) {
    this.withUndo('set shadow opacity', () => {
      this.data.ipage.update({shadow_opacity: opacity / 100});
    });
  }
  onBeforeZoomSliderDrag() {
    this.beginWithUndo('change inline page zoom');
  }
  onAfterZoomSliderDrag() {
    this.endWithUndo('change inline page zoom');
  }
  onBeforeOpacitySliderDrag() {
    this.beginWithUndo('change inline page opacity');
  }
  onAfterOpacitySliderDrag() {
    this.endWithUndo('change inline page opacity');
  }
  onBeforeRotationSliderDrag() {
    this.beginWithUndo('change inline page rotation');
  }
  onAfterRotationSliderDrag() {
    this.endWithUndo('change inline page rotation');
  }
  onBeforeRadiusSliderDrag() {
    this.beginWithUndo('change inline page border radius');
  }
  onAfterRadiusSliderDrag() {
    this.endWithUndo('change inline page border radius');
  }
  onBeforeShadowOpacitySliderDrag() {
    this.beginWithUndo('change inline page shadow opacity');
  }
  onAfterShadowOpacitySliderDrag() {
    this.endWithUndo('change inline page shadow opacity');
  }
  // --------------
  // Event handlers
  // --------------

  clearMask(evt) {
    this.withUndo('remove ipage mask', () => {
      this.data.ipage.update({mask: null});
    });
  }

  goToMaskSelection(evt) {
    this.data.store.ui.setAdminElementsTabSection('inline-page-mask');
  }

  selectShadowColor(evt) {
    const ipage = this.data.ipage;
    const store = this.data.store;
    this.makeModal(Px.Editor.ColorPickerModal, {
      store: store,
      initial_color: ipage.shadow_color,
      usage_context: 'inline_page_shadows',
      color_type_switch: store.color_picker_type_switch_enabled,
      onColorSelected: color => {
        this.withUndo('set ipage shadow color', () => {
          ipage.update({shadow_color: color});
        });
      }
    });
  }

};
