Px.LocalFiles = {
  uploadFiles: function(files, upload_path, opts) {
    const uploader = Px.LocalFiles.Uploader.make(upload_path, {
      upload_method: opts.upload_method,
      upload_param: opts.upload_param,
      custom_headers: opts.upload_custom_headers
    });

    files.forEach(file => uploader.uploadFile(file));

    return files;
  }
};
