Px.Editor.DropboxGalleryPanel = class DropboxGalleryPanel extends Px.Editor.BaseGalleryPanel {

  get css_class() {
    return 'px-dropbox-gallery-panel'
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      galleryStore: function() {
        return this.data.store.galleries.dropbox;
      },
      items: function() {
        if (this.state.selected_gallery_id) {
          return this.galleryStore.items(this.state.selected_gallery_id);
        } else {
          return this.galleryStore.items();
        }
      },
      isLoading: function() {
        return !this.galleryStore.isGalleryLoaded(this.state.selected_gallery_id);
      },
      canGoUpLevel: function() {
        const gallery_id = this.state.selected_gallery_id;
        return gallery_id !== null && gallery_id !== '';
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  onGalleryClick(evt) {
    super.onGalleryClick(evt);
    this.galleryStore.load(this.state.selected_gallery_id);
  }

  upLevel(evt) {
    if (!this.state.selected_gallery_id) {
      if (this.data.onExit) {
        this.data.onExit();
      }
      return;
    }
    const path_segments = this.state.selected_gallery_id.split('/');
    path_segments.pop();
    if (path_segments.length === 1) {
      this.state.selected_gallery_id = '';
    } else {
      this.state.selected_gallery_id = path_segments.join('/');
    }
  }

  // -------
  // Private
  // -------

  registerGalleryImage(image_id) {
    const image_store = this.data.store.images;
    if (!image_store.get(image_id)) {
      const gallery_image = this.images.find(image => image.id === image_id);
      image_store.register(gallery_image.id, gallery_image.data);
    }
  }

};
