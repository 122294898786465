Px.Editor.GalleryStore = class GalleryStore extends Px.BaseStore {

  constructor(main_store) {
    super();

    this._upload_status_timeouts = {};

    this.main_store = main_store;

    this.project = Px.Editor.ProjectGalleryStore.make(main_store.project_id, main_store.images);
    this.clipart = Px.Editor.ClipartGalleryStore.make(main_store.theme_id, main_store.images);
    this.instagram = Px.Editor.InstagramGalleryStore.make();
    this.facebook = Px.Editor.FacebookGalleryStore.make();
    this.dropbox = Px.Editor.DropboxGalleryStore.make();
    this.user = Px.Editor.UserGalleryStore.make();
    this.groups = Px.Editor.GroupGalleryStore.make(main_store.project);
    this.theme_resources = Px.Editor.ThemeGalleryStore.make(main_store.theme_id, main_store.images);
  }

  static get properties() {
    return {
      // Upload status is one of: pending, initialized, uploading, complete.
      _uploads: {std: mobx.observable.map()}
    };
  }

  static get computedProperties() {
    return {
      image_sources: function() {
        const filtered_sources = [];
        const sources = Px.config.image_sources || [];

        sources.forEach(gallery_id => {
          if (gallery_id === 'instagram' && !Px.config.instagram_client_id) {
            return;
          }
          filtered_sources.push(gallery_id);
        });

        if (filtered_sources.length > 0) {
          return filtered_sources;
        } else {
          return GalleryStore.DEFAULT_IMAGE_SOURCES;
        }
      }
    };
  }

  getUploadStatus(upload_token) {
    const upload = this._uploads.get(upload_token);
    return upload ? upload.status : null;
  }

  trackUpload(upload_token, gallery_id) {
    if (this._uploads.get(upload_token)) {
      return;  // upload already tracked
    }

    this._uploads.set(upload_token, {
      status: 'pending',
      gallery_id: gallery_id,
      image_ids: []
    });

    this.refreshUploadStatus(upload_token);
  }

  scheduleUploadCleanup(upload_token) {
    setTimeout(() => {
      // If upload is still pending some time after we've already closed the upload window,
      // mark it as complete to stop tracking it.
      const upload = this._uploads.get(upload_token);
      if (upload && upload.status === 'pending') {
        upload.status = 'complete';
        clearTimeout(this._upload_status_timeouts[upload_token]);
      }
    }, GalleryStore.UPLOAD_STATUS_REFRESH_TIMEOUT * 3);
  }

  // -------
  // Private
  // -------

  refreshUploadStatus(upload_token) {
    clearTimeout(this._upload_status_timeouts[upload_token]);

    const upload = this._uploads.get(upload_token);

    fetch(`/upload/upload_status.json?upload_token=${upload_token}`).then(r => r.json()).then(json => {
      const previous_status = upload.status;
      const previous_image_ids = upload.image_ids;
      const latest_image_ids = json.images.map(image => image.id);
      mobx.runInAction(() => {
        if (json.status === 'unknown') {
          // If an existing upload disappears, then it must have finished.
          // Otherwise we assume the user didn't open up the upload panel on their phone yet.
          upload.status = previous_status === 'pending' ? 'pending' : 'complete';
        } else {
          upload.status = json.status;
        }
        upload.image_ids = latest_image_ids;
      });
      // Add newly uploaded images to the appropriate gallery.
      latest_image_ids.forEach(image_id => {
        if (!previous_image_ids.includes(image_id)) {
          if (upload.gallery_id === this.project.gallery_id) {
            this.project.loadGalleryImages().then(() => {
              if (this.main_store.cut_print_mode) {
                // In cut print mode, we automatically import new images as cut prints every time
                // the book gallery is loaded (so that QR-uploaded images get added as cut prints).
                const image_item = this.project.images.find(image => image.id === `db:${image_id}`);
                this.main_store.importCutPrintImage(image_item);
              }
            });
          } else {
            this.user.loadGalleryImages(upload.gallery_id);
          }
        }
      });
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      // If upload isn't finished yet, schedule another refresh.
      if (upload.status !== 'complete') {
        this._upload_status_timeouts[upload_token] = setTimeout(
          () => this.refreshUploadStatus(upload_token),
          GalleryStore.UPLOAD_STATUS_REFRESH_TIMEOUT
        );
      }
    });
  }

};

Px.Editor.GalleryStore.DEFAULT_IMAGE_SOURCES = ['device'];
Px.Editor.GalleryStore.UPLOAD_STATUS_REFRESH_TIMEOUT = 4000;
