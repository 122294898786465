Px.Editor.TextAlignmentButtons = class TextAlignmentButtons extends Px.Components.RadioButtons {

  template() {
    return Px.template`<div class="px-text-alignment-buttons">${super.template()}</div>`;
  }

  get dataProperties() {
    return {
      value: {required: false},
      onNewValue: {std: function(value) {
        this.state.internal_value = value;
      }},
      buttons: {
        std: [
          {
            id: 'left',
            content: '<svg width="25px" height="22px" viewBox="0 0 25 22" xmlns="http://www.w3.org/2000/svg"><rect stroke="none" fill="currentColor" x="0" y="0"  width="21" height="2" /><rect stroke="none" fill="currentColor" x="0" y="4"  width="17" height="2" /><rect stroke="none" fill="currentColor" x="0" y="8"  width="25" height="2" /><rect stroke="none" fill="currentColor" x="0" y="12" width="25" height="2" /><rect stroke="none" fill="currentColor" x="0" y="16" width="17" height="2" /><rect stroke="none" fill="currentColor" x="0" y="20" width="21" height="2" /></svg>'
          },
          {
            id: 'center',
            content: '<svg width="25px" height="22px" viewBox="0 0 25 22" xmlns="http://www.w3.org/2000/svg"><rect stroke="none" fill="currentColor" x="2" y="0"  width="21" height="2" /><rect stroke="none" fill="currentColor" x="4" y="4"  width="17" height="2" /><rect stroke="none" fill="currentColor" x="0" y="8"  width="25" height="2" /><rect stroke="none" fill="currentColor" x="0" y="12" width="25" height="2" /><rect stroke="none" fill="currentColor" x="4" y="16" width="17" height="2" /><rect stroke="none" fill="currentColor" x="2" y="20" width="21" height="2" /></svg>'
          },
          {
            id: 'right',
            content: '<svg width="25px" height="22px" viewBox="0 0 25 22" xmlns="http://www.w3.org/2000/svg"><rect stroke="none" fill="currentColor" x="4" y="0"  width="21" height="2" /><rect stroke="none" fill="currentColor" x="8" y="4"  width="17" height="2" /><rect stroke="none" fill="currentColor" x="0" y="8"  width="25" height="2" /><rect stroke="none" fill="currentColor" x="0" y="12" width="25" height="2" /><rect stroke="none" fill="currentColor" x="8" y="16" width="17" height="2" /><rect stroke="none" fill="currentColor" x="4" y="20" width="21" height="2" /></svg>'
          }
        ]
      }
    };
  }
};
