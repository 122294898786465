Px.Editor.FacebookGalleryPanel = class FacebookGalleryPanel extends Px.Editor.BaseGalleryPanel {

  get css_class() {
    return 'px-facebook-gallery-panel';
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      galleryStore: function() {
        return this.data.store.galleries.facebook;
      },
      items: function() {
        if (this.state.selected_gallery_id) {
          return this.galleryStore.galleryImages(this.state.selected_gallery_id);
        } else {
          return Array.from(this.galleryStore.galleries.values());
        }
      },
      isLoading: function() {
        return !this.galleryStore.isGalleryLoaded(this.state.selected_gallery_id);
      },
      canGoUpLevel: function() {
        return this.state.selected_gallery_id !== null;
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  onGalleryClick(evt) {
    super.onGalleryClick(evt);
    this.galleryStore.loadGalleryImages(this.state.selected_gallery_id);
  }

  upLevel(evt) {
    if (this.state.selected_gallery_id !== null) {
      this.state.selected_gallery_id = null;
    } else if (this.data.onExit) {
      this.data.onExit();
    }
  }

};
