Px.Editor.DatesEditPanel = class DatesEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const element = this.data.element;
    const ToggleButton = Px.Components.ToggleButton;
    const Dropdown = Px.Components.Dropdown;

    return Px.template`
      <div class="px-dates-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            ${this.data.store.theme.available_dateseries.map(dateseries => {
              const uniq_id = [dateseries, element.unique_id].join('-');
              return Px.template`
                <div class="px-dateseries">
                  <h1>Dateseries: ${dateseries}</h1>
                  ${r(ToggleButton, `dateseries-enable-${uniq_id}`, this.enableDateseriesButtonProps(dateseries))}
                  ${Px.if(this.isDateseriesEnabled(dateseries) && element.type === 'text', () => {
                    return Px.template`
                      <div class="px-edit-control">
                        <h2>Fill With</h2>
                        ${r(Dropdown, `dateseries-fill-${uniq_id}`, this.dateseriesFillDropdownProps(dateseries))}
                      </div>
                      ${Px.if(this.isWebsiteCalendarEntryFill(dateseries), () => {
                        return Px.template`
                          <div class="px-edit-control">
                            <h2>Calendar Code</h2>
                            <input class="px-calendar-code"
                                   value="${this.getWebsiteCalendarEntryCode(dateseries)}"
                                   data-dateseries="${dateseries}"
                                   data-onchange="setWebsiteCalendarEntryCode"
                            />
                          </div>
                        `;
                      })}
                    `;
                  })}
                  ${Px.if(this.isDateseriesEnabled(dateseries), () => {
                    return Px.template`
                      <div class="px-edit-control">
                        <h2>Date Index</h2>
                        <input class="px-date-index" type="number" min="1" step="1"
                               value="${this.getDateseriesIndex(dateseries)}"
                               data-dateseries="${dateseries}"
                               data-onchange="setDateseriesIndex"
                        />
                      </div>
                    `;
                  })}
                </div>
              `;
            })}
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    //this.enableDateseries = this.enableDateseries.bind(this);
  }

  get dataProperties() {
    return {
      element: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
    };
  }

  enableDateseriesButtonProps(dateseries) {
    return {
      content: 'Enable',
      pressed: this.isDateseriesEnabled(dateseries),
      onToggle: this.enableDateseries.bind(this, dateseries)
    };
  }

  dateseriesFillDropdownProps(dateseries) {
    const options = [
      {
        name: '-- none --',
        value: ''
      },
      {
        name: 'day number',
        value: 'day'
      },{
        name: 'month number',
        value: 'month'
      },
      {
        name: 'month name',
        value: 'month-name'
      },
      {
        name: 'year',
        value: 'year'
      },
      {
        name: 'weekday name',
        value: 'weekday-name'
      },
      {
        name: 'week number',
        value: 'week-number'
      },
      {
        name: 'any calendar entry',
        value: 'any-calendar'
      },
      {
        name: 'website calendar entry',
        value: 'website-calendar'
      },
      {
        name: 'user calendar entry',
        value: 'user-calendar'
      }
    ];
    const regex = new RegExp(`^cal:${dateseries}:fill:(.+)$`);
    const tag = this.data.element.tags.find(tag => tag.match(regex));
    return {
      value: tag && tag.match(regex)[1] || '',
      options: options,
      onNewValue: this.setDateseriesFill.bind(this, dateseries)
    };
  }

  isDateseriesEnabled(dateseries) {
    return this.data.element.tags.some(t => t.startsWith(`cal:${dateseries}:`));
  }

  isWebsiteCalendarEntryFill(dateseries) {
    return this.data.element.tags.includes(`cal:${dateseries}:fill:website-calendar`);
  }

  enableDateseries(dateseries, enable) {
    const element = this.data.element;
    if (enable) {
      this.withUndo('enabledateseries', () => {
        element.tags.push(`cal:${dateseries}:idx:1`);
      });
    } else {
      this.disableDateseries(dateseries);
    }
  }

  setDateseriesFill(dateseries, fill) {
    const element = this.data.element;
    const old_tag = element.tags.find(tag => tag.startsWith(`cal:${dateseries}:fill:`));
    const new_tag = `cal:${dateseries}:fill:${fill}`;
    if (old_tag !== new_tag) {
      this.withUndo('set dateseries fill', () => {
        element.tags.remove(old_tag);
        element.tags.push(new_tag);
      });
    }
  }

  getWebsiteCalendarEntryCode(dateseries) {
    const regex = new RegExp(`^cal:${dateseries}:website-calendar:(.+)$`);
    const tag = this.data.element.tags.find(tag => tag.match(regex));
    return tag && tag.match(regex)[1] || '';
  }

  setWebsiteCalendarEntryCode(evt) {
    const code = evt.target.value.trim();
    const dateseries = evt.target.getAttribute('data-dateseries');
    const element = this.data.element;
    const old_tag = element.tags.find(tag => tag.startsWith(`cal:${dateseries}:website-calendar:`));
    const new_tag = code ? `cal:${dateseries}:website-calendar:${code}` : null;
    if (old_tag !== new_tag) {
      this.withUndo('set website calendar code', () => {
        element.tags.remove(old_tag);
        if (new_tag) {
          element.tags.push(new_tag);
        }
      });
    }
  }

  getDateseriesIndex(dateseries) {
    const regex = new RegExp(`^cal:${dateseries}:idx:(\\d+)$`);
    const tag = this.data.element.tags.find(tag => tag.match(regex));
    return tag && tag.match(regex)[1] || '1';
  }

  setDateseriesIndex(evt) {
    const index = evt.target.value.trim();
    if (!index.match(/^\d+$/)) {
      // Index not numeric; ignore.
      return;
    }
    const dateseries = evt.target.getAttribute('data-dateseries');
    const element = this.data.element;
    const old_tag = element.tags.find(tag => tag.startsWith(`cal:${dateseries}:idx:`));
    const new_tag = `cal:${dateseries}:idx:${index}`;
    if (old_tag !== new_tag) {
      this.withUndo('set dateseries index', () => {
        element.tags.remove(old_tag);
        element.tags.push(new_tag);
      });
    }
  }

  disableDateseries(dateseries) {
    const element = this.data.element;
    const tags_to_remove = element.tags.filter(t => t.startsWith(`cal:${dateseries}:`));
    this.withUndo('disable dateseries', () => {
      tags_to_remove.forEach(tag => element.tags.remove(tag));
    });
  }

};
