Px.Editor.NotificationStore = class NotificationStore extends Px.BaseStore {

  static get properties() {
    return {
      notifications: {std: mobx.observable.array()},
    }
  }

  get actions() {
    return {
      notify: function(message, opts) {
        var notification = this.getActiveMessage(message);
        if (!notification || notification.is_disposing) {
          var props = _.extend({message: message}, opts);
          notification = Px.Editor.NotificationModel.make(props);
          this.notifications.push(notification);
        }
        this.startTimer(notification);
      }
    };
  }

  // -------
  // Private
  // -------

  startTimer(notification) {
    if (notification.timeout_id) {
      clearTimeout(notification.timeout_id);
    }
    notification.timeout_id = setTimeout(() => {
      notification.update({is_disposing: true});
      notification.timeout_id = setTimeout(() => {
        this.notifications.remove(notification);
      }, NotificationStore.DISPOSAL_DURATION);
    }, NotificationStore.NOTIFICATION_DURATION);
  }

  getActiveMessage(message) {
    return _.find(this.notifications, function(notification) {
      return notification.message === message && !notification.is_disposing;
    });
  }

};

Px.Editor.NotificationStore.NOTIFICATION_DURATION = 5000;
Px.Editor.NotificationStore.DISPOSAL_DURATION = 300;
