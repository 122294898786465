Px.Editor.PageDefinitionModel = class PageDefinitionModel extends Px.BaseModel {

  static fromXMLNode(node, in_inches) {
    const filters = [];

    const binding_element = node.querySelector('filter[type="binding"]');
    if (binding_element) {
      filters.push({
        type: 'binding',
        map: binding_element.getAttribute('map')
      });
    }
    const layflat_binding_element = node.querySelector('filter[type="binding-layflat"]');
    if (layflat_binding_element) {
      filters.push({type: 'binding-layflat'});
    }

    const toMM = function(val) {
      if (in_inches) {
        return Px.Util.in2mm(val);
      }
      return val;
    }

    const width_str = node.getAttribute('width');
    let width, max_width;
    if (width_str && width_str.includes('..')) {
      [width, max_width] = width_str.split('..').map(s => toMM(parseFloat(s)));
    } else {
      width = max_width = toMM(parseFloat(width_str));
    }

    const height_str = node.getAttribute('height');
    let height, max_height;
    if (height_str && height_str.includes('..')) {
      [height, max_height] = height_str.split('..').map(s => toMM(parseFloat(s)));
    } else {
      height = max_height = toMM(parseFloat(height_str));
    }

    const props = {
      _type:            node.getAttribute('type'),
      width:            width,
      height:           height,
      max_width:        max_width,
      max_height:       max_height,
      bleed:            toMM(parseFloat(node.getAttribute('bleed') || 0)),
      margin:           toMM(parseFloat(node.getAttribute('margin') || 0)),
      hinge:            toMM(parseFloat(node.getAttribute('hinge') || 0)),
      gutter:           toMM(parseFloat(node.getAttribute('gutter') || 0)),
      snap_points:      (node.getAttribute('snap') || '').split(',').map(n => toMM(parseFloat(n.trim()))),
      dates:            node.getAttribute('dates') || null,
      _position:        node.getAttribute('position') || null,
      filters:          filters
    };

    return Px.Editor.PageDefinitionModel.make(props);
  }

  static get properties() {
    return Object.assign(super.properties, {
      _type: {type: 'str', std: null},
      width: {type: 'float', std: 0},
      height: {type: 'float', std: 0},
      max_width: {type: 'float', std: 0},
      max_height: {type: 'float', std: 0},
      bleed: {type: 'float', std: 0},
      margin: {type: 'float', std: 0},
      hinge: {type: 'float', std: 0},
      gutter: {type: 'float', std: 0},
      dates: {type: 'str', std: null},
      snap_points: {type: 'array', std: mobx.observable.array()},
      filters: {type: 'array', std: mobx.observable.array()},
      _position: {type: 'str', std: null},
      set_definition: {type: 'ref', std: null}
    });
  }

  static get computedProperties() {
    return {
      template_names: function() {
        return this._type.split(',').map(function(name) {
          return name.trim();
        });
      },
      position: function() {
        let position = this._position;
        if (this._position !== 'left-right') {
          if (this.set_definition.pages[0] === this) {
            position = 'left';
          } else {
            position = 'right';
          }
        }
        return position;
      },
      is_double: function() {
        return this.position === 'left-right';
      },
      has_variable_width: function() {
        return this.max_width !== this.width;
      },
      has_variable_height: function() {
        return this.max_height !== this.height;
      },
      binding_map_name: function() {
        const binding_filter = this.filters.find(f => f.type === 'binding');
        return binding_filter ? binding_filter.map : null;
      }
    };
  }

};
