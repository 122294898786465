Px.Components.Dropdown = class Dropdown extends Px.Component {

  template() {
    let content;
    if (this.state.panel_displayed) {
      let input;
      if (this.data.freeform_input) {
        input = Px.template`
          <input class="px-freeform-input"
                type="text"
                value="${this.selectedValue}"
                data-onclick="selectFreeformInput"
                data-onkeydown="setFreeformInput"
          />
        `;
      } else {
        input = Px.template`<span class="px-selected-value">${this.selectedOptionName}</span>`;
      }
      content = Px.template`
        ${input}
        <div class="px-dropdown-panel">
          <div class="px-scrollable-wrapper">
            <ul>
              ${this.data.options.map((option, idx) => {
                return Px.template`
                  <li data-idx="${idx}"
                      data-selected="${this.isSelected(option)}"
                      data-onclick="selectOption">
                    ${this.panelOptionName(option)}
                  </li>
                `;
              })}
            </ul>
          </div>
        </div>
        <div class="px-outside-click-area" data-onclick="closePanel"></div>
      `;
    } else {
      content = Px.template`<span class="px-selected-value">${this.selectedOptionName}</span>`;
    }

    return Px.template`
      <div class="px-control px-dropdown" data-onclick="onDropdownClick">
        ${content}
      </div>
    `;
  }

  constructor(props) {
    super(props);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    window.addEventListener('click', this.onDocumentClick, true);
  }

  destroy() {
    window.removeEventListener('click', this.onDocumentClick);
    super.destroy();
  }

  get dataProperties() {
    return {
      // Options should be an array of objects with keys: `value`, `name`, `panel_name`.
      // Only the `value` property is required. `panel_name` can contain HTML.
      options: {required: true},
      value: {std: null},
      freeform_input: {std: false},
      onNewValue: {std: function(new_value) {
        this.state.internal_value = new_value;
      }}
    };
  }

  static get properties() {
    return {
      internal_value: {type: 'any', std: null},
      panel_displayed: {type: 'bool', std: false}
    };
  }

  static get computedProperties() {
    return {
      selectedValue: function() {
        if (this.state.internal_value !== null) {
          return this.state.internal_value;
        }
        return this.data.value;
      },
      selectedOptionName: function() {
        const value = this.selectedValue;
        const option = _.find(this.data.options, function(option) {
          return String(option.value) === String(value);
        });
        return (option && option.name) || value;
      }
    };
  }

  panelOptionName(option) {
    return option.panel_name || option.name || option.value || Px.raw('&nbsp;');
  }

  setValue(val) {
    if (this.selectedValue !== val) {
      this.data.onNewValue.call(this, val);
    }
  }

  // -------
  // Private
  // -------

  isSelected(option) {
    return String(option.value) === String(this.selectedValue);
  }

  // --------------
  // Event handlers
  // --------------

  closePanel(evt) {
    mobx.runInAction(() => {
      this.state.panel_displayed = false;
    });
  }

  onDropdownClick(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    mobx.runInAction(() => {
      const panel_displayed = !this.state.panel_displayed;
      this.state.panel_displayed = panel_displayed;
      if (panel_displayed) {
        this.once('update', () => {
          if (this.data.freeform_input) {
            this.dom_node.querySelector('.px-freeform-input').select();
          }
        });
      }
    });
  }

  selectOption(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    const idx = evt.currentTarget.getAttribute('data-idx');
    const value = this.data.options[idx].value;
    mobx.runInAction(() => {
      this.setValue(value);
      this.state.panel_displayed = false;
    });
  }

  selectFreeformInput(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    evt.currentTarget.select();
  }

  setFreeformInput(evt) {
    mobx.runInAction(() => {
      if (evt.which === Px.Util.keyCodes.enter || evt.which === Px.Util.keyCodes.tab) {
        const value = evt.currentTarget.value;
        this.setValue(value);
        this.state.panel_displayed = false;
      } else if (evt.which === Px.Util.keyCodes.escape) {
        this.state.panel_displayed = false;
      }
    });
  }

  onDocumentClick(evt) {
    if (!this.state.panel_displayed) {
      // Nothing to do if the panel is not displayed.
      return;
    }
    // Ignore clicks within this component.
    let element = document.elementFromPoint(evt.clientX, evt.clientY);
    while (element) {
      if (element === this.dom_node) {
        return;
      }
      element = element.parentElement;
    }
    // Click anywhere outside the component should close the dropdown panel.
    mobx.runInAction(() => {
      this.state.panel_displayed = false;
    });
  }

};
