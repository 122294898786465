Px.Editor.LocalPdfModel = class LocalPdfModel extends Px.Editor.BasePdfModel {

  constructor(props) {
    super(props);

    if (!this.data.file) {
      // This can only happen if there's a bug where project still containing local PDFs
      // somehow gets stored and then the page is refreshed.
      // Rather than completely break the editor, ignore local PDFs in that case, which
      // renders them as broken images in the editor.
      return;
    }

    this.preview_queue = [];

    this.parsePdfDoc();
  }

  destroy() {
    for (const [pagenum, object_url] of this.object_urls.entries()) {
      URL.revokeObjectURL(object_url);
    }
    super.destroy();
  }

  static get properties() {
    return Object.assign(super.properties, {
      object_urls: {type: 'map', std: mobx.observable.map()},
      pdf_doc: {type: 'obj', std: null}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      dimensions: function() {
        const width = this.data.width || 0;
        const height = this.data.height || 0;
        return [width, height];
      },
      filename: function() {
        return this.data.file.name;
      }
    });
  }

  // This returns the temporary preview.
  // As soon as the PDF is being used in a book it's going to start uploading,
  // and we will use the db PDF model once it's uploaded.
  src(params) {
    if (!this.pdf_doc) {
      return '';
    }

    const cache_key = new URLSearchParams(params).toString();
    if (this.object_urls.has(cache_key)) {
      return this.object_urls.get(cache_key);
    } else {
      this.enqueuePreview(params);
      return '';
    }
  }

  // -------
  // Private
  // -------

  parsePdfDoc() {
    const reader = new FileReader();
    reader.onload = () => {
      pdfjsLib.getDocument({data: reader.result}).promise.then(doc => {
        this.pdf_doc = doc;
      }).catch(err => {
        console.warn(`Failed parsing PDF file`, err);
      });
    };

    reader.onerror = reader.onabort = evt => {
      console.warn(`Failed reading file ${this.data.file.name}: ${reader.error.name}`);
    };

    reader.readAsArrayBuffer(this.data.file);
  }

  enqueuePreview(params) {
    this.preview_queue.push(params);
    if (this.preview_queue.length === 1) {
      this.workNextPreview();
    }
  }

  async workNextPreview() {
    if (this.preview_queue.length === 0) {
      return;
    }

    const params = this.preview_queue[0];
    const cache_key = new URLSearchParams(params).toString();
    if (this.object_urls.has(cache_key)) {
      this.preview_queue.shift();
      return;
    }

    try {
      const object_url = await this.renderPreview(params);
      this.object_urls.set(cache_key, object_url);
    } catch(err) {
      console.warn(`Failed rendering PDF preview`, err);
    }
    this.preview_queue.shift();
    this.workNextPreview();
  }

  async renderPreview(params) {
    if (!this.pdf_doc) {
      return;
    }

    const page_number = params.page;
    const size = params.size;

    const page = await this.pdf_doc.getPage(page_number);

    const canvas = document.createElement('canvas');
    canvas.style.imageRendering = 'optimizeQuality';

    let viewport = page.getViewport({scale: 1});
    let width = viewport.width;
    let height = viewport.height;

    if (size && (size < width || size < height)) {
      const aspect_ratio = viewport.width / viewport.height;
      const resized_dims = Px.Util.inscribedRectangleDimensions(size, size, 0, aspect_ratio);
      width = resized_dims.width;
      height = resized_dims.height;
      viewport = page.getViewport({scale: width / viewport.width});
    }

    canvas.width = width;
    canvas.height = height;

    const render_opts = {
      canvasContext: canvas.getContext('2d'),
      viewport: viewport,
      background: 'rgba(0, 0, 0, 0)'
    };

    await page.render(render_opts).promise;
    const object_url = await new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(URL.createObjectURL(blob));
      });
    });

    return object_url;
  }

};
