Px.Editor.ImageEditAccordion = class ImageEditAccordion extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-tab-panel-accordion-wrapper">
        ${this.renderChild(Px.Components.Accordion, 'accordion', this.accordionProperties)}
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.onItemExpanded = this.onItemExpanded.bind(this);
  }

  get dataProperties() {
    return {
      image: {required: true},
      store: {required: true}
    };
  }

  static get properties() {
    return {
      expanded_item_id: {type: 'str', std: 'standard'}
    }
  }

  static get computedProperties() {
    return {
      accordionProperties: function() {
        const items = [
          {
            id: 'standard',
            title: Px.t('Standard'),
            component: Px.Editor.ImageEditPanel,
            data: {store: this.data.store, image: this.data.image}
          },
          {
            id: 'admin',
            title: Px.t('Admin'),
            component: Px.Editor.AdvancedEditPanel,
            data: {store: this.data.store, element: this.data.image}
          }
        ];
        if (this.data.store.resource_type === 'theme_templates') {
          items.push({
            id: 'dates',
            title: Px.t('Dates'),
            component: Px.Editor.DatesEditPanel,
            data: {store: this.data.store, element: this.data.image}
          });
        }
        return {
          items: items,
          expanded_item_id: this.state.expanded_item_id,
          onItemExpanded: this.onItemExpanded
        };
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  onItemExpanded(item_id) {
    this.state.expanded_item_id = item_id;
  }

};
