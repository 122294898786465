Px.Editor.UndoSnapshotModel = class UndoSnapshotModel extends Px.BaseModel {

  static get properties() {
    return Object.assign(super.properties, {
      layout_snapshot: {std: null},
      options_snapshot: {std: null},
      template_options_snapshot: {std: null},
      metadata: {std: null}
    });
  }

};
