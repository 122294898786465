Px.Editor.VerticalTabbedMenu = class VerticalTabbedMenu extends Px.Component {

  template() {
    const r = this.renderChild;
    return Px.template`
      <div class="px-vertical-tabbed-menu">
        <div class="px-tabs">
          ${this.data.tab_groups.map(group => {
            const buttons = group.map(tab => {
              return Px.template`
                <button class="px-tab"
                        data-id="${tab.id}"
                        data-expanded="${this.isExpanded(tab)}"
                        data-onclick="onTabClick"
                        data-px-tooltip="${tab.title}"
                        ${tab.disabled ? 'disabled' : ''}>
                  <div class="px-icon">${Px.raw(tab.icon)}</div>
                </button>
              `;
            });
            const divider = this.isLastGroup(group) ? '' : Px.template`<div class="px-divider"></div>`;
            return Px.template`
              <div class="px-tab-group">
                ${buttons}
              </div>
              ${divider}
            `;
          })}
        </div>
        <div class="px-tab-panel" data-expanded="${Boolean(this.expandedTab)}">
          ${Px.if(this.expandedTab && this.expandedTab.component, () => {
            return r(this.expandedTab.component, `tab-panel-${this.expandedTab.id}`, this.expandedTab.data);
          })}
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      // `tab_groups` should be a nested list of objects defining each tab group with the contained
      // tabs and their panels.
      // First level of arrays represents a group. A group is simply a collection of tabs which are
      // visually separate from the other groups.
      // Second level should be a list of objects describing the tabs.
      // Each object should include: `id`, `icon`, and `title`. Each object should either contain `onClick`,
      //  `component` and `data` properties, or all three.
      // Also supported is `disabled`, which disables the button.
      tab_groups: {required: true},
      expanded_tab_id: {std: null},
      onTabExpanded: {std: function(tab_id) {
        this.state.internal_expanded_tab_id = tab_id;
      }}
    }
  }

  static get properties() {
    return {
      internal_expanded_tab_id: {type: 'str', std: undefined}
    }
  }

  static get computedProperties() {
    return {
      expandedTabId: function() {
        if (this.state.internal_expanded_tab_id !== undefined) {
          return this.state.internal_expanded_tab_id;
        }
        return this.data.expanded_tab_id;
      },
      expandedTab: function() {
        if (this.expandedTabId) {
          return this.getTab(this.expandedTabId);
        }
      }
    }
  }

  getTab(id) {
    for (let i = 0; i < this.data.tab_groups.length; i++) {
      for (let j = 0; j < this.data.tab_groups[i].length; j++) {
        if (this.data.tab_groups[i][j].id === id) {
          return this.data.tab_groups[i][j];
        }
      }
    }
  }

  isExpanded(tab) {
    return tab.id === this.expandedTabId;
  }

  isLastGroup(group) {
    return group === this.data.tab_groups[this.data.tab_groups.length - 1];
  }

  // --------------
  // Event handlers
  // --------------

  onTabClick(evt) {
    const id = evt.currentTarget.getAttribute('data-id');
    const tab = this.getTab(id);
    if (tab.component) {
      this.data.onTabExpanded.call(this, id);
    }
    if (tab.onClick && !tab.disabled) {
      tab.onClick(id);
    }
  }

};
