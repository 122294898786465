// Invisible component which emits resize events when its offset parent resizes.
// Note that if you want to detect changes of the components immediate parent element,
// that parent element has to be positioned (cannot be position:static).
Px.Components.ResizeDetector = class ResizeDetector extends Px.Component {

  template() {
    if (this.use_polyfill) {
      // Backwards compatibility for iOS < 13.4
      return '<object class="px-resize-detector"></object>';
    }
    return '<div class="px-resize-detector"></div>';
  }

  constructor(data) {
    super(data);

    this.use_polyfill = !('ResizeObserver' in window);

    this.on('mount', () => {
      if (this.use_polyfill) {
        return this.onPolyfillMount();
      }
      this.resize_observer = new ResizeObserver(this.data.onResize);
      this.resize_observer.observe(this.dom_node);
    });
  }

  destroy() {
    if (this.resize_observer) {
      this.resize_observer.unobserve(this.dom_node);
    }
    super.destroy();
  }

  get dataProperties() {
    return {
      onResize: {required: true}
    };
  }

  onPolyfillMount() {
    const object = this.dom_node;
    object.tabIndex = -1;
    object.type = 'text/html';
    object.onload = evt => {
      object.contentDocument.defaultView.addEventListener('resize', this.data.onResize);
    };
    object.data = 'about:blank';
  }

};
