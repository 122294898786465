Px.Editor.CalendarEditPanel = class CalendarEditPanel extends Px.Editor.BaseComponent {

  template() {
    const ToggleButton = Px.Components.ToggleButton;
    const r = this.renderChild;

    return Px.template`
      <div class="px-calendar-edit-panel px-edit-panel">
        <div class="px-delete-element-section">
          <button class="px-small px-warning-color" data-onclick="deleteCalendar">
            ${Px.t('Delete')}
          </button>
        </div>

        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control">
              <h2>${Px.t('Options')}</h2>
              <div class="px-option-buttons">
                ${r(ToggleButton, 'week-numbers-button', this.weekNumbersButtonProps)}
                ${r(ToggleButton, 'captions-button', this.captionsButtonProps)}
              </div>
            </div>

            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Rotation')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'rotation-slider', this.rotationSliderProps)}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setWeekNumbers = this.setWeekNumbers.bind(this);
    this.setCaptions = this.setCaptions.bind(this);
    this.setRotation = this.setRotation.bind(this);

    this.onBeforeRotationSliderDrag = this.onBeforeRotationSliderDrag.bind(this);
    this.onAfterRotationSliderDrag = this.onAfterRotationSliderDrag.bind(this);
  }

  get dataProperties() {
    return {
      calendar: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      weekNumbersButtonProps: function() {
        return {
          content: Px.t('Week Numbers'),
          pressed: this.data.calendar.week_numbers,
          onToggle: this.setWeekNumbers
        };
      },
      captionsButtonProps: function() {
        return {
          content: Px.t('Captions'),
          pressed: this.data.calendar.captions,
          onToggle: this.setCaptions
        };
      },
      rotationSliderProps: function() {
        return {
          min: -180,
          max: 180,
          step: 0.25,
          value: this.data.calendar.rotation,
          onNewValue: this.setRotation,
          onBeforeDrag: this.onBeforeRotationSliderDrag,
          onAfterDrag: this.onAfterRotationSliderDrag
        };
      }
    };
  }

  setWeekNumbers(bool) {
    this.withUndo('set week numbers flag', () => {
      this.data.calendar.update({week_numbers: bool});
    });
  }
  setCaptions(bool) {
    this.withUndo('set captions flag', () => {
      this.data.calendar.update({captions: bool});
    });
  }
  setRotation(rotation) {
    this.withUndo('rotate calendar', () => {
      this.data.calendar.update({rotation: rotation});
    });
  }

  onBeforeRotationSliderDrag() {
    this.beginWithUndo('change calendar rotation');
  }
  onAfterRotationSliderDrag() {
    this.endWithUndo('change calendar rotation');
  }

  // --------------
  // Event handlers
  // --------------

  deleteCalendar(evt) {
    this.withUndo('delete calendar element', () => {
      this.data.store.deleteElement(this.data.calendar);
    });
  }

};

Px.Editor.CalendarEditPanel.HeaderEditPanel = class HeaderEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-calendar-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Header Height')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'header-height-slider', this.headerHeightSliderProps)}
            </div>

            <div class="px-edit-control">
              <h2>${Px.t('Header Color')}</h2>
              ${r(Px.Components.ColorPicker, 'header-color-picker', this.headerColorPickerProps)}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setHeader = this.setHeader.bind(this);
    this.setHeaderColor = this.setHeaderColor.bind(this);
    this.onBeforeHeaderSliderDrag = this.onBeforeHeaderSliderDrag.bind(this);
    this.onAfterHeaderSliderDrag = this.onAfterHeaderSliderDrag.bind(this);
    this.onBeforeHeaderColorPickerDrag = this.onBeforeHeaderColorPickerDrag.bind(this);
    this.onAfterHeaderColorPickerDrag = this.onAfterHeaderColorPickerDrag.bind(this);
  }

  get dataProperties() {
    return {
      calendar: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      headerHeightSliderProps: function() {
        return {
          min: 0,
          max: 25,
          step: 0.25,
          value: this.data.calendar.header * 100,
          onNewValue: this.setHeader,
          onBeforeDrag: this.onBeforeHeaderSliderDrag,
          onAfterDrag: this.onAfterHeaderSliderDrag
        };
      },
      headerColorPickerProps: function() {
        return {
          width: 190,
          color: this.data.calendar.header_color,
          onNewValue: this.setHeaderColor,
          onBeforeDrag: this.onBeforeHeaderColorPickerDrag,
          onAfterDrag: this.onAfterHeaderColorPickerDrag
        };
      }
    };
  }

  setHeader(header) {
    this.withUndo('set calendar header', () => {
      this.data.calendar.update({header: header/100});
    });
  }
  setHeaderColor(color) {
    this.withUndo('change calendar header color', () => {
      this.data.calendar.update({header_color: color});
    });
  }

  onBeforeHeaderSliderDrag() {
    this.data.calendar.is_resizing = true;
    this.beginWithUndo('change calendar header');
  }
  onAfterHeaderSliderDrag() {
    this.data.calendar.is_resizing = false;
    this.endWithUndo('change calendar header');
  }
  onBeforeHeaderColorPickerDrag() {
    this.beginWithUndo('change calendar header color');
  }
  onAfterHeaderColorPickerDrag() {
    this.endWithUndo('change calendar header color');
  }
};

Px.Editor.CalendarEditPanel.SidebarEditPanel = class SidebarEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-calendar-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Sidebar Width')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'sidebar-width-slider', this.sidebarWidthSliderProps)}
            </div>

            <div class="px-edit-control">
              <h2>${Px.t('Sidebar Color')}</h2>
              ${r(Px.Components.ColorPicker, 'sidebar-color-picker', this.sidebarColorPickerProps)}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setSidebar = this.setSidebar.bind(this);
    this.setSidebarColor = this.setSidebarColor.bind(this);
    this.onBeforeSidebarSliderDrag = this.onBeforeSidebarSliderDrag.bind(this);
    this.onAfterSidebarSliderDrag = this.onAfterSidebarSliderDrag.bind(this);
    this.onBeforeSidebarColorPickerDrag = this.onBeforeSidebarColorPickerDrag.bind(this);
    this.onAfterSidebarColorPickerDrag = this.onAfterSidebarColorPickerDrag.bind(this);
  }

  get dataProperties() {
    return {
      calendar: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      sidebarWidthSliderProps: function() {
        return {
          min: 0,
          max: 25,
          step: 0.25,
          value: this.data.calendar.sidebar * 100,
          onNewValue: this.setSidebar,
          onBeforeDrag: this.onBeforeSidebarSliderDrag,
          onAfterDrag: this.onAfterSidebarSliderDrag
        };
      },
      sidebarColorPickerProps: function() {
        return {
          width: 190,
          color: this.data.calendar.sidebar_color,
          onNewValue: this.setSidebarColor,
          onBeforeDrag: this.onBeforeSidebarColorPickerDrag,
          onAfterDrag: this.onAfterSidebarColorPickerDrag
        };
      }
    };
  }

  setSidebar(sidebar) {
    this.withUndo('set calendar sidebar', () => {
      this.data.calendar.update({sidebar: sidebar/100});
    });
  }
  setSidebarColor(color) {
    this.withUndo('change calendar sidebar color', () => {
      this.data.calendar.update({sidebar_color: color});
    });
  }

  onBeforeSidebarSliderDrag() {
    this.data.calendar.is_resizing = true;
    this.beginWithUndo('change calendar sidebar width');
  }
  onAfterSidebarSliderDrag() {
    this.data.calendar.is_resizing = false;
    this.endWithUndo('change calendar sidebar width');
  }
  onBeforeSidebarColorPickerDrag() {
    this.beginWithUndo('change calendar sidebar color');
  }
  onAfterSidebarColorPickerDrag() {
    this.endWithUndo('change calendar sidebar color');
  }

};

Px.Editor.CalendarEditPanel.GridLinesEditPanel = class GridLinesEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-calendar-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Line Width')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'line-width-slider', this.lineWidthSliderProps)}
            </div>

            <div class="px-edit-control">
              <h2>${Px.t('Line Color')}</h2>
              ${r(Px.Components.ColorPicker, 'line-color-picker', this.lineColorPickerProps)}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setLineWidth = this.setLineWidth.bind(this);
    this.setLineColor = this.setLineColor.bind(this);
    this.onBeforeLineWidthSliderDrag = this.onBeforeLineWidthSliderDrag.bind(this);
    this.onAfterLineWidthSliderDrag = this.onAfterLineWidthSliderDrag.bind(this);
    this.onBeforeLineColorPickerDrag = this.onBeforeLineColorPickerDrag.bind(this);
    this.onAfterLineColorPickerDrag = this.onAfterLineColorPickerDrag.bind(this);
  }

  get dataProperties() {
    return {
      calendar: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      lineWidthSliderProps: function() {
        return {
          min: 0,
          max: 10,
          step: 0.25,
          value: this.data.calendar.line_width,
          onNewValue: this.setLineWidth,
          onBeforeDrag: this.onBeforeLineWidthSliderDrag,
          onAfterDrag: this.onAfterLineWidthSliderDrag
        };
      },
      lineColorPickerProps: function() {
        return {
          width: 190,
          color: this.data.calendar.line_color,
          onNewValue: this.setLineColor,
          onBeforeDrag: this.onBeforeLineColorPickerDrag,
          onAfterDrag: this.onAfterLineColorPickerDrag
        };
      }
    };
  }

  setLineWidth(width) {
    this.withUndo('set calendar line width', () => {
      this.data.calendar.update({line_width: width});
    });
  }
  setLineColor(color) {
    this.withUndo('change calendar line color', () => {
      this.data.calendar.update({line_color: color});
    });
  }

  onBeforeLineWidthSliderDrag() {
    this.data.calendar.is_resizing = true;
    this.beginWithUndo('change calendar line width');
  }
  onAfterLineWidthSliderDrag() {
    this.data.calendar.is_resizing = false;
    this.endWithUndo('change calendar line width');
  }
  onBeforeLineColorPickerDrag() {
    this.beginWithUndo('change calendar line color');
  }
  onAfterLineColorPickerDrag() {
    this.endWithUndo('change calendar line color');
  }
};

Px.Editor.CalendarEditPanel.TextEditPanel = class TextEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-calendar-text-edit-panel">
        ${r(Px.Editor.TextEditPanel, 'edit-panel', this.textEditPanelProps)}
      </div>
    `;
  }

  constructor(props) {
    super(props);
    this.fake_text_model = Px.Editor.TextElementModel.make(this.selectedTextProperties);
    this.registerReaction(() => this.fakeTextProps, props => {
      Object.keys(props).forEach(name => {
        this.textModels.forEach(text => {
          if (!((name === 'align' || name === 'valign') && text.tags.includes('overflow-cell'))) {
            text[name] = props[name];
          }
        });
      });
    }, {
      name: 'Px.Editor.CalendarEditPanel.TextEditPanel::textPropertiesReaction'
    });
  }

  destroy() {
    super.destroy();
    this.fake_text_model = null;
  }

  get dataProperties() {
    return {
      calendar: {required: true},
      tag: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      textModels: function() {
        return this.data.calendar.getElementsByTag(this.data.tag);
      },
      selectedTextProperties: function() {
        const props = this.textProperties(this.textModels[0]);
        props.erotation = false;
        return props;
      },
      fakeTextProps: function() {
        return this.textProperties(this.fake_text_model);
      },
      textEditPanelProps: function() {
        return {
          text: this.fake_text_model,
          store: this.data.store
        };
      }
    }
  }

  textProperties(model) {
    const props = {};
    const defs = model.constructor.properties;
    TextEditPanel.ATTRIBUTE_NAMES.forEach(name => {
      props[name] = model[name];
    });
    return props;
  }
};

Px.Editor.CalendarEditPanel.TextEditPanel.ATTRIBUTE_NAMES = [
  'opacity', 'pointsize', 'color', 'palette', 'fontpalette', 'font',
  'align', 'valign', 'kerning', 'leading', 'tp', 'bp', 'lp', 'rp', 'path'
];
