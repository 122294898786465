Px.Editor.Integrations.Shopify = {

  name: 'shopify',

  postMessage: function(type, data) {
    window.parent.postMessage({pixfizz: true, type: type, data: data || {}}, '*');
  },

  closeEditor: function(store) {
    Px.Editor.Integrations.Shopify.postMessage('close_project');
  },

  // The save button is hidden by default in Shopify mode because setting up saved project in
  // Shopify takes some extra effort and we don't assume all Shopify installations have it on.
  // When it's enable,d the account page in editor configuration should be set to "../account",
  // which translates to "/site/../account" which hackishly translates to /account, which is the
  // URL of the default Shopify account page.
  // If account page setting is present, we assume Shopify saved projects are enabled
  // and we show the save button.
  showSaveButton: function(store) {
    return Boolean(Px.config.account_page_name);
  },

  // Exit button asks to save unsaved changes, which is why we don't want to show it if we don't
  // have support for saved projects enabled.
  showExitButton: function(store) {
    return Boolean(Px.config.account_page_name);
  },

  addToCart: function(store) {
    const query = Px.urlQuery();
    const quantity = store.cut_print_mode ? store.total_cut_print_quantity : store.quantity;
    // Collect addon product info (extra pages or options that are mapped to Shopify addon products).
    const addons = Px.Editor.Integrations.Shopify._collectAddons(store, query.page_addon, query.option_addons);
    // Collect all chosen variant and template options to set them on the shopify orderline,
    // so that they are displayed in the cart.
    const properties = Px.Editor.Integrations.Shopify._collectProjectOptionsForDisplay(store);
    // Set project id as a hidden (hence the _ prefix ) property on the orderline.
    properties._pixfizz_project_id = store.project_id;

    if (query.cart === 't' && query.line_item_key) {
      Px.Editor.Integrations.Shopify.postMessage('update_cart_item', {
        key: query.line_item_key,
        quantity: quantity,
        properties: properties,
        addons: addons
      });
    } else {
      Px.Editor.Integrations.Shopify.postMessage('add_to_cart', {
        shopify_variant_id: store.project.options.get('shopify_variant_id'),
        quantity: quantity,
        properties: properties,
        addons: addons
      });
    }
  },

  goToUrl: function(store, url) {
    Px.Editor.Integrations.Shopify.postMessage('goto', {
      url: url
    });
  },

  _collectProjectOptionsForDisplay: function(store) {
    const properties = {};
    // Product variants.
    for (let key of store.project.options.keys()) {
      const option = store.options.getOptionByCode(key);
      if (option) {
        let value = store.project.options.get(key);
        if (option.type === 'multiple_choice') {
          const option_value = option.values.find(v => v.code === value);
          if (option_value) {
            properties[option.name] = option_value.name;
          } else {
            properties[option.name] = value;
          }
        } else if (option.type === 'text') {
          properties[option.name] = value;
        }
      }
    }
    // Template options.
    for (let key of store.project.template_options.keys()) {
      const option = store.options.getTemplateOptionByCode(key);
      if (option) {
        let value = store.project.template_options.get(key);
        if (option.type === 'multiple_choice') {
          const option_value = option.values.find(v => v.code === value);
          if (option_value) {
            properties[option.name] = option_value.name;
          } else {
            properties[option.name] = value;
          }
        } else if (option.type === 'text') {
          properties[option.name] = value;
        }
      }
    }
    return properties;
  },

  _collectAddons: function(store, page_addon, option_addons) {
    const addons = [];
    if (page_addon) {
      // Extra pages.
      const extra_page_count = Math.max(0, store.project.page_count - store.theme.min_pages);
      addons.push({
        variant_id: page_addon,
        quantity: extra_page_count
      });
    }
    if (option_addons) {
      const option_addons_map = JSON.parse(option_addons);
      Object.keys(option_addons_map).forEach(option_type_code => {
        const value = store.project.template_options.get(option_type_code) || store.project.options.get(option_type_code);
        Object.keys(option_addons_map[option_type_code]).forEach(option_value_code => {
          addons.push({
            variant_id: option_addons_map[option_type_code][option_value_code],
            quantity: (option_value_code === value) ? 1 : 0
          });
        });
      });
    }
    return addons;
  }

};
