Px.Editor.SVGElementMixin = Base => class extends Base {

  inPixels(len) {
    return len * this.data.scale;
  }

  inSvgUnits(pixels) {
    if (this.data.scale === 0) {
      return 0;
    }
    return pixels / this.data.scale;
  }

};
