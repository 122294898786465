Px.Editor.BarcodeEditPanel = class BarcodeEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-image-edit-panel px-edit-panel">
        <div class="px-delete-element-section">
          <button class="px-small px-warning-color" data-onclick="deleteBarcode">
            ${Px.t('Delete')}
          </button>
        </div>

        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Rotation')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'rotation-slider', this.rotationSliderProps)}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setRotation = this.setRotation.bind(this);
    this.onBeforeRotationSliderDrag = this.onBeforeRotationSliderDrag.bind(this);
    this.onAfterRotationSliderDrag = this.onAfterRotationSliderDrag.bind(this);
  }

  get dataProperties() {
    return {
      barcode: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      rotationSliderProps: function() {
        return {
          min: -180,
          max: 180,
          step: 0.25,
          value: this.data.barcode.rotation,
          onNewValue: this.setRotation,
          onBeforeDrag: this.onBeforeRotationSliderDrag,
          onAfterDrag: this.onAfterRotationSliderDrag
        };
      }
    };
  }

  setRotation(rotation) {
    this.withUndo('rotate barcode', () => {
      this.data.barcode.update({rotation: rotation});
    });
  }
  onBeforeRotationSliderDrag() {
    this.beginWithUndo('change barcode rotation');
  }
  onAfterRotationSliderDrag() {
    this.endWithUndo('change barcode rotation');
  }

  // --------------
  // Event handlers
  // --------------

  deleteBarcode(evt) {
    this.withUndo('delete barcode element', () => {
      this.data.store.deleteElement(this.data.barcode);
    });
  }

};
