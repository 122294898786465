Px.Editor.CalendarElementModel = class CalendarElementModel extends Px.Editor.GroupElementModel {

  constructor(props) {
    super(props);

    this.registerReaction(() => {
      if (this._grid) {
        return [this._grid.width, this._grid.height, this.header, this.sidebar, this.line_height];
      }
    }, res => {
      if (res) {
        this.repositionTexts();
      }
    }, {
      name: 'Px.Editor.CalendarElementModel::repositionTextsReaction'
    });

    this.registerReaction(() => this.week_numbers, week_numbers => {
      if (week_numbers) {
        this.buildWeekNumberTexts();
        this.sidebar = 0.05;
      } else {
        this.destroyWeekNumberTexts();
        this.sidebar = 0;
      }
    }, {
      name: 'Px.Editor.CalendarElementModel::weekNumbersReaction'
    });

    this.registerReaction(() => this.captions, captions => {
      if (captions) {
        this.buildCaptionTexts();
      } else {
        this.destroyCaptionTexts();
      }
    }, {
      name: 'Px.Editor.CalendarElementModel::captionsReaction'
    });
  }

  // --------------------
  // Static/class methods
  // --------------------

  static build(params) {
    const calendar_element = this.make({
      x: params.x,
      y: params.y,
      edit: true,
      resize: true,
      tags: ['calendar-element'],
      page: params.page
    });

    // Grid element
    const grid_element = Px.Editor.GridElementModel.make({
      width: params.width,
      height: params.height,
      header: 0.1,
      sidebar: 0,
      tags: ['calendar-grid'],
      page: params.page
    });

    const elements = [grid_element];

    const header_height = grid_element.header * grid_element.height;
    const stroke_width = grid_element.stroke;
    const txt_width = (grid_element.width / 7) - stroke_width;
    const pointsize = Math.round(txt_width * 0.75);
    const padding = Math.round(txt_width / 10);

    // Header texts.
    for (let c = 0; c < CalendarElementModel.COLS; c++) {
      elements.push(
        Px.Editor.TextElementModel.make({
          text: `%wd${c + 1}%`,
          color: '#ffffff',
          edit: false,
          resize: false,
          move: false,
          pointsize: pointsize / 2,
          align: 'center',
          valign: 'center',
          lp: padding,
          rp: padding,
          tp: padding,
          bp: padding,
          tags: ['header-cell', `col:${c + 1}`],
          page: params.page
        })
      );
    }

    // Body texts.
    let c, r;
    for (c = 0; c < CalendarElementModel.COLS; c++) {
      for (r = 0; r < CalendarElementModel.ROWS; r++) {
        elements.push(
          Px.Editor.TextElementModel.make({
            text: `%d${(r * 7) + c + 1}%`,
            edit: true,
            resize: false,
            move: false,
            pointsize: pointsize,
            align: 'left',
            valign: 'top',
            lp: padding,
            rp: padding,
            tp: padding,
            bp: padding,
            tags: ['date-cell', `col:${c + 1}`, `row:${r + 1}`],
            page: params.page
          })
        );
      }
    }

    // Overflow texts, for when a month starts on a Saturday or Sunday
    // and a 5x7 grid just doesn't have enough cells.
    const opts = {
      edit: true,
      resize: false,
      move: false,
      pointsize: pointsize,
      lp: padding,
      rp: padding,
      tp: padding,
      bp: padding,
      group: calendar_element,
      page: params.page
    };

    elements.push(
      Px.Editor.TextElementModel.make(Object.assign({}, opts, {
        text: '%do1%',
        align: 'right',
        valign: 'top',
        tags: ['date-cell', 'overflow-cell', 'col:1', 'row:5']
      }))
    );

    elements.push(
      Px.Editor.TextElementModel.make(Object.assign({}, opts, {
        text: '%do2%',
        align: 'right',
        valign: 'top',
        tags: ['date-cell', 'overflow-cell', 'col:2', 'row:5']
      }))
    );

    elements.push(
      Px.Editor.TextElementModel.make(Object.assign({}, opts, {
        text: '%do3%',
        align: 'left',
        valign: 'bottom',
        tags: ['date-cell', 'overflow-cell', 'col:1', 'row:5']
      }))
    );

    elements.push(
      Px.Editor.TextElementModel.make(Object.assign({}, opts, {
        text: '%do4%',
        align: 'left',
        valign: 'bottom',
        tags: ['date-cell', 'overflow-cell', 'col:2', 'row:5']
      }))
    );

    calendar_element.elements = elements;
    calendar_element.repositionTexts();

    return calendar_element;
  }

  static get properties() {
    return Object.assign(super.properties, {
      week_numbers: {std: false},
      captions: {std: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      _grid: function() {
        return this.getElementsByTag('calendar-grid')[0] || null;
      }
    });
  }

  get actions() {
    return Object.assign(super.actions, {

      buildWeekNumberTexts: function() {
        const grid_element = this._grid;
        const sidebar_width = grid_element.sidebar * grid_element.width;
        const stroke_width = grid_element.stroke;
        const txt_width = ((grid_element.width - sidebar_width) / 7) - stroke_width;
        const pointsize = Math.round(txt_width * 0.75);
        const padding = Math.round(txt_width / 10);

        mobx.runInAction(() => {
          for (let r = 0; r < CalendarElementModel.ROWS; r++) {
            this.addElement(
              Px.Editor.TextElementModel.make({
                text: `%wn${r + 1}%`,
                color: '#ffffff',
                edit: false,
                resize: false,
                move: false,
                pointsize: pointsize / 2,
                align: 'right',
                valign: 'center',
                lp: padding,
                rp: padding,
                tp: padding,
                bp: padding,
                tags: ['week-number-cell', `row:${r + 1}`]
              })
            );
          }
        });

        this.repositionTexts();
      },

      destroyWeekNumberTexts: function() {
        this.getElementsByTag('week-number-cell').forEach(element => element.destroy());
      },

      buildCaptionTexts: function() {
        const grid_element = this._grid;
        const sidebar_width = grid_element.sidebar * grid_element.width;
        const stroke_width = grid_element.stroke;
        const txt_width = ((grid_element.width - sidebar_width) / 7) - stroke_width;
        const pointsize = Math.round(txt_width * 0.75);
        const padding = Math.round(txt_width / 10);
        const caption_pointsize = Math.round(pointsize / 2);

        let c, r;
        for (c = 0; c < CalendarElementModel.COLS; c++) {
          for (r = 0; r < CalendarElementModel.ROWS; r++) {
            this.addElement(
              Px.Editor.TextElementModel.make({
                text: `%dc${(r * 7) + c + 1}%`,
                edit: true,
                resize: false,
                move: false,
                pointsize: caption_pointsize,
                align: 'left',
                valign: 'bottom',
                lp: padding,
                rp: padding,
                tp: padding,
                bp: padding,
                tags: ['caption-cell', `col:${c + 1}`, `row:${r + 1}`]
              })
            );
          }
        }

        // Overflow texts, for when a month starts on a Saturday or Sunday
        // and a 5x7 grid just doesn't have enough cells.
        const caption_overflow_opts = {
          edit: true,
          resize: false,
          move: false,
          pointsize: caption_pointsize,
          lp: padding,
          rp: padding,
          tp: padding,
          bp: padding
        };

        this.addElement(
          Px.Editor.TextElementModel.make(Object.assign({}, caption_overflow_opts, {
            text: '%doc1%',
            align: 'right',
            valign: 'bottom',
            tags: ['caption-cell', 'overflow-cell', 'col:1', 'row:1']
          }))
        );

        this.addElement(
          Px.Editor.TextElementModel.make(Object.assign({}, caption_overflow_opts, {
            text: '%doc2%',
            align: 'right',
            valign: 'bottom',
            tags: ['caption-cell', 'overflow-cell', 'col:2', 'row:1']
          }))
        );

        this.addElement(
          Px.Editor.TextElementModel.make(Object.assign({}, caption_overflow_opts, {
            text: '%doc3%',
            align: 'left',
            valign: 'top',
            tags: ['caption-cell', 'overflow-cell', 'col:1', 'row:2']
          }))
        );

        this.addElement(
          Px.Editor.TextElementModel.make(Object.assign({}, caption_overflow_opts, {
            text: '%doc4%',
            align: 'left',
            valign: 'top',
            tags: ['caption-cell', 'overflow-cell', 'col:2', 'row:2']
          }))
        );

        this.repositionTexts();
      },

      destroyCaptionTexts: function() {
        this.getElementsByTag('caption-cell').forEach(element => element.destroy());
      },

      repositionTexts: function() {
        mobx.runInAction(() => {
          const grid_height = this._grid.height;
          const grid_width = this._grid.width;
          const header_height = grid_height * this.header;
          const sidebar_width = grid_width * this.sidebar;
          const stroke_width = this.line_width;
          const txt_width = ((grid_width - sidebar_width) / 7) - stroke_width;
          const txt_height = ((grid_height - header_height) / 5) - stroke_width;

          this.text_elements.forEach(txt => {
            const coords = this.getCoords(txt);

            // header texts
            if (txt.tags.includes('header-cell')) {
              txt.update({
                height: header_height - stroke_width,
                width: txt_width,
                x: sidebar_width + ((coords.col-1) * (txt_width + stroke_width)) + (stroke_width / 2),
                y: stroke_width / 2
              });
            // date cell texts
            } else if (txt.tags.includes('date-cell') || txt.tags.includes('hebrew-date-cell') || txt.tags.includes('hebrew-month-cell')) {
              txt.update({
                width: txt_width,
                height: txt_height,
                x: sidebar_width + ((coords.col-1) * (txt_width + stroke_width)) + (stroke_width / 2),
                y: header_height + ((coords.row-1) * (txt_height + stroke_width)) + (stroke_width / 2)
              });
            // caption texts
            } else if (txt.tags.includes('caption-cell')) {
              if (txt.tags.includes('overflow-cell')) {
                txt.update({
                  x: sidebar_width + stroke_width/2 + (coords.col === 2 ? (txt_width + stroke_width) : 0),
                  y: header_height + (4 * (txt_height + stroke_width)) + (stroke_width / 2) + (coords.row === 2 ? (txt_height/2) : 0),
                  width: txt_width,
                  height: txt_height / 2,
                });
              } else {
                txt.update({
                  width: txt_width,
                  height: txt_height,
                  x: sidebar_width + ((coords.col-1) * (txt_width + stroke_width)) + (stroke_width / 2),
                  y: header_height + ((coords.row-1) * (txt_height + stroke_width)) + (stroke_width / 2)
                });
              }
            // sidebar texts
            } else if (txt.tags.includes('week-number-cell')) {
              txt.update({
                width: sidebar_width - stroke_width,
                height: txt_height,
                x: (stroke_width / 2),
                y: header_height + ((coords.row-1) * (txt_height + stroke_width)) + (stroke_width / 2)
              });
            }
          });

        });
      }
    });
  }

  // ---------------
  // Getters/setters
  // ---------------

  get text_elements() {
    return this.getElementsByType('text');
  }

  get header() {
    return this._grid.header;
  }

  set header(val) {
    this._grid.header = val;
  }

  get sidebar() {
    return this._grid.sidebar;
  }

  set sidebar(val) {
    this._grid.sidebar = val;
  }

  get line_width() {
    return this._grid.stroke;
  }

  set line_width(val) {
    this._grid.stroke = val;
  }

  get header_color() {
    return this._grid.headercolor;
  }

  set header_color(val) {
    this._grid.headercolor = val;
  }

  get sidebar_color() {
    return this._grid.sidebarcolor;
  }

  set sidebar_color(val) {
    this._grid.sidebarcolor = val;
  }

  get line_color() {
    return this._grid.color;
  }

  set line_color(val) {
    this._grid.color = val;
  }

  // -------
  // Private
  // -------

  getCoords(cell) {
    const coords = {};
    const rowtag = cell.tags.find(tag => tag.match(/^row:\d/));
    if (rowtag) {
      coords.row = parseInt(rowtag.substring('row:'.length), 10);
    }
    const coltag = cell.tags.find(tag => tag.match(/^col:\d/));
    if (coltag) {
      coords.col = parseInt(coltag.substring('col:'.length), 10);
    }
    return coords;
  }
};

Px.Editor.CalendarElementModel.ELEMENT_TYPE = 'calendar';
Px.Editor.CalendarElementModel.ROWS = 5;
Px.Editor.CalendarElementModel.COLS = 7;
