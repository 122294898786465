Px.Editor.BackgroundStore = class BackgroundStore extends Px.Editor.BaseThemeGalleryStore {

  constructor(theme_id, image_store, project_store, layout_store) {
    super(theme_id, image_store);

    // Prefill the suggested colors with existing page background colors
    // and existing layout background colors, in that order.
    mobx.when(() => project_store.loaded, () => {
      project_store.editor_pages.forEach(page => {
        if (page.bgcolor) {
          this.addColor(page.bgcolor);
        }
      });
    });
    mobx.when(() => project_store.loaded && layout_store.loaded, () => {
      layout_store.layouts.forEach(layout => {
        if (layout.bgcolor) {
          this.addColor(layout.bgcolor);
        }
      });
    });
  }

  static get properties() {
    return Object.assign(super.properties, {
      colors: {std: mobx.observable.array()},
    });
  }

  get actions() {
    return Object.assign(super.actions, {
      addColor: function(color) {
        if (!this.hasColor(color)) {
          this.colors.push(color);
        }
      }
    });
  }

  hasImage(image_id) {
    return this.images.indexOf(image_id) !== -1;
  }

  hasColor(color) {
    return this.colors.indexOf(color) !== -1;
  }

};

Px.Editor.BaseThemeGalleryStore.GALLERY_ID = 'backgrounds';
