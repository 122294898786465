Px.Editor.ThemeStore = class ThemeStore extends Px.BaseStore {

  constructor(theme_id, image_store, pdf_store) {
    super();
    this.theme_id = theme_id;
    this.image_store = image_store;
    this.pdf_store = pdf_store;
  }

  static get properties() {
    return {
      loaded: {std: false},
      name: {std: ''},
      print_product_name: {std: ''},
      min_pages: {std: 0},
      max_pages: {std: 0},
      page_increments: {std: 0},
      unit: {std: 'mm'},
      min_dpi: {std: 100},
      cut_print: {std: false},
      min_cut_print_quantity: {std: 1},
      max_cut_print_quantity: {std: 0},
      background_transparent: {std: null},
      pdf_layers: {std: mobx.observable.array()},
      templates: {std: mobx.observable.array()},
      mapped_previews: {std: mobx.observable.array()},
      preview_sections: {std: mobx.observable.array()},
      _template_definition: {std: null}
    }
  }

  static get computedProperties() {
    return {
      image_elements: function() {
        var self = this;
        var images = [];
        this.templates.forEach(function(template) {
          template.image_elements.forEach(function(image) {
            images.push(image);
          });
        });
        return images;
      },
      _image_elements_by_id: function() {
        var images = {};
        this.image_elements.forEach(function(image) {
          if (!images[image.id]) {
            images[image.id] = [];
          }
          images[image.id].push(image);
        });
        return images;
      },
      _parsed_template_definition: function() {
        if (this._template_definition) {
          return Px.Util.parseXML(this._template_definition).firstChild;
        } else {
          return null;
        }
      },
      set_structure: function() {
        if (!this._parsed_template_definition) {
          return [];
        }
        return this.parseSetStructure(this._parsed_template_definition, this.unit === 'inch');
      },
      set_definitions: function() {
        const collectSetDefinitions = (structs) => {
          let set_defs = [];
          structs.forEach(struct => {
            if (struct.set) {
              set_defs.push(struct.set);
            } else {
              set_defs = set_defs.concat(collectSetDefinitions(struct.structure));
            }
          });
          return set_defs;
        };
        return collectSetDefinitions(this.set_structure);
      },
      grow_set_definition: function() {
        for (const set of this.set_definitions) {
          if (set.grow) {
            return set;
          }
        }
        return null;
      },
      value_maps: function() {
        var self = this;
        var maps = {};
        var map_elements = this._parsed_template_definition.querySelectorAll('map');
        _.each(map_elements, function(map_element) {
          var map = {};
          _.each(map_element.querySelectorAll('val'), function(val_element) {
            var binding_value = parseFloat(val_element.textContent.trim());
            if (self.unit === 'inch') {
              binding_value = Px.Util.in2mm(binding_value);
            }
            var key = val_element.getAttribute('key');
            if (key) {
              if (key.match(/\.\./)) {
                var parts = key.split('..');
                var start = parseInt(parts[0], 10);
                var end = parseInt(parts[1], 10);
                for (var i=start; i <= end; i++) {
                  map[i] = binding_value;
                }
              } else {
                map[key] = binding_value;
              }
            }
          });
          maps[map_element.getAttribute('name')] = map;
        });
        return maps;
      },
      available_dateseries: function() {
        const collectDateseries = (structures) => {
          let dateseries = [];
          structures.forEach(struct => {
            if (struct.set) {
              struct.set.pages.forEach(page => {
                if (page.dates && page.dates !== 'true') {
                  dateseries = dateseries.concat(page.dates.split(' ').filter(s => s.length));
                }
              });
            } else {
              dateseries = dateseries.concat(collectDateseries(struct.structure));
            }
          });
          return dateseries;
        };
        return _.uniq(collectDateseries(this.set_structure));
      }
    };
  }

  get actions() {
    return {
      load: function() {
        var self = this;
        var xhr = $j.getJSON(`/v1/themes/${this.theme_id}.json`);
        xhr.done(function(data) {
          mobx.runInAction(function() {
            data.templates.forEach(function(item) {
              var template = item.print_page;
              template.images.forEach(function(image) {
                var img_id = `db:${image.id}`;
                if (!self.image_store.get(img_id)) {
                  self.image_store.register(img_id, image);
                }
              });
              template.pdfs.forEach(function(pdf) {
                var pdf_id = `db:${pdf.id}`;
                if (!self.pdf_store.get(pdf_id)) {
                  self.pdf_store.register(pdf_id, pdf);
                }
              });
              var model = Px.Editor.ThemeTemplateModel.make({
                page: Px.Editor.PageModel.fromJSON(
                  template,
                  {
                    image_store: self.image_store,
                    pdf_store: self.pdf_store
                  }
                )
              });
              self.templates.push(model);
            });
            self.unit = data.unit;
            self.minimum_dpi = data.minimum_dpi;
            self.name = data.name;
            if (data.print_product) {
              self.print_product_name = data.print_product.name;
              self.min_pages = data.print_product.min_pages;
              self.max_pages = data.print_product.max_pages;
              self.page_increments = data.print_product.page_increments;
              self.cut_print = data.print_product.cut_print;
              self.min_cut_print_quantity = data.print_product.min_cut_print_quantity;
              self.max_cut_print_quantity = data.print_product.max_cut_print_quantity;
              self.background_transparent = data.print_product.background_transparent;
              self.mapped_previews = data.print_product.mapped_previews;
              self.preview_sections = data.print_product.preview_sections;
              self._template_definition = data.print_product.layout;
              self.pdf_layers.replace(self.parsePdfLayers());
            }
            self.loaded = true;
          });
        });
      },

      // Sometimes the last growable set is already partially filled,
      // in which case `last_set_page_count` may be less than the total number
      // of pages in the set definition, and we need to start adding pages at an offset.
      addPages: function(growable_sets) {
        var grow_set_definition = this.grow_set_definition;
        var pages_in_set_count = grow_set_definition.pages.length;
        var last_set_page_count = growable_sets.length ? growable_sets[growable_sets.length - 1].pages.length : 0;
        var offset = last_set_page_count % pages_in_set_count;

        const templateIterator = (page_definition, offset) => {
          let idx = (offset || 0) % page_definition.template_names.length;
          return {
            next: () => {
              const next_name = page_definition.template_names[idx];
              if (idx === page_definition.template_names.length - 1) {
                idx = 0;
              } else {
                idx++;
              }
              return next_name;
            }
          };
        };

        var template_iterators = {};
        grow_set_definition.pages.forEach((page, i) => {
          const start = offset === 0 || i < offset ? growable_sets.length : growable_sets.length - 1;
          template_iterators[i] = templateIterator(page, start);
        });

        var new_pages = [];
        let page_idx = offset;
        _.times(this.page_increments, () => {
          var page_definition = grow_set_definition.pages[page_idx];
          var template_name = template_iterators[page_idx].next();
          var template = this.getTemplateByName(template_name);
          var new_page = template.page.clone({
            image_store: this.image_store,
            pdf_store: this.pdf_store
          });
          new_page.bleed = page_definition.bleed;
          new_page.margin = page_definition.margin;
          new_page.gutter = page_definition.gutter;
          new_page.snap_points = page_definition.snap_points;
          new_page.version = ThemeStore.CURRENT_PAGE_VERSION;
          new_pages.push(new_page);
          page_idx = (page_idx + 1) % pages_in_set_count;
        });

        return new_pages;
      }
    }
  }

  getTemplateByName(name) {
    return this.templates.find(template => template.name === name);
  }

  getPdfLayerByName(name) {
    return this.pdf_layers.find(layer => layer.name === name);
  }

  getImageElementsByImageId(id) {
    return this._image_elements_by_id[id] || [];
  }

  generateDates(start_date, name) {
    const dates_nodes = this._parsed_template_definition.querySelectorAll('dates');
    let dates_nodes_map = {};
    for (const node of dates_nodes) {
      dates_nodes_map[node.getAttribute('name')] = node;
    }
    const generator = new Px.Editor.ThemeStore.DateGenerator(start_date, dates_nodes_map, name);
    return generator.dates;
  }

  // -------
  // Private
  // -------

  parsePdfLayers() {
    const layers = [];
    Array.from(this._parsed_template_definition.querySelectorAll('layer')).forEach(layer_node => {
      const visibility = layer_node.getAttribute('visibility');
      const is_enabled = visibility === 'on' || (visibility === 'fulfillment' && Px.config.advanced_edit_mode);
      layers.push({
        name: layer_node.getAttribute('name'),
        enabled: is_enabled
      });
    });
    return layers;
  }

  parseSetStructure(root_node, in_inches) {
    const set_structure = [];
    root_node.querySelectorAll(':scope > set, :scope > foreachdate').forEach(node => {
      if (node.nodeName === 'foreachdate') {
        set_structure.push({
          type: 'foreachdate',
          dates_name: node.getAttribute('name'),
          structure: this.parseSetStructure(node, in_inches)
        });
      } else {
        const set = Px.Editor.SetDefinitionModel.fromXMLNode(node, in_inches);
        if (set.foreachdate) {
          set_structure.push({
            type: 'foreachdate',
            dates_name: set.foreachdate,
            structure: [{type: 'set', set: set}]
          });
        } else {
          set_structure.push({type: 'set', set: set});
        }
      }
    });
    return set_structure;
  }

};

// Ported from ruby class ProductDefinition::DateGenerator.
Px.Editor.ThemeStore.DateGenerator = class DateGenerator {

  constructor(base_date, dates_nodes, name) {
    this.dates_nodes = dates_nodes;
    this.named_dates = {};
    this.generated_dates = [];
    this.generateForNode(base_date, this.dates_nodes[name]);
    const byString = d => d.toISOString();
    this.generated_dates = _.sortBy(_.uniq(this.generated_dates, byString), byString);
  }

  get dates() {
    return this.generated_dates;
  }

  // Node can be either a <dates> or a <dateshift> node.
  generateForNode(base_date, node) {
    for (const child of node.children) {
      switch (child.tagName) {
      case 'defdate':
        this.processDefdateNode(base_date, child);
        break;
      case 'date':
        this.processDateNode(base_date, child);
        break;
      case 'deldate':
        this.processDeldateNode(base_date, child);
        break;
      case 'dategen':
        this.processDategenNode(base_date, child);
        break;
      case 'datesfrom':
        this.processDatesfromNode(base_date, child);
        break;
      case 'dateshift':
        this.processDateshiftNode(base_date, child);
        break;
      }
    }
  }

  processDefdateNode(base_date, node) {
    const shifted_date = this.shiftDate(base_date, node);
    this.named_dates[node.getAttribute('name')] = shifted_date;
  }

  processDateNode(base_date, node) {
    const shifted_date = this.shiftDate(base_date, node);
    this.generated_dates.push(shifted_date);
  }

  processDeldateNode(base_date, node) {
    const shifted_date = this.shiftDate(base_date, node);
    this.generated_dates = this.generated_dates.filter(date => date !== shifted_date);
  }

  processDategenNode(base_date, node) {
    const rrule_dates = this.generateRRuleDates(base_date, node);
    this.generated_dates = this.generated_dates.concat(rrule_dates);
  }

  processDeldategenNode(base_date, node) {
    const rrule_dates = this.generateRRuleDates(base_date, node);
    rrule_dates.forEach(rrule_date => {
      this.generated_dates = this.generated_dates.filter(date => date !== rrule_date);
    });
  }

  processDatesfromNode(base_date, node) {
    const name = node.getAttribute('name');
    const source_node = this.dates_nodes[name];
    if (!source_node) {
      console.error(`Could not find dates with name: ${name}`)
    }
    this.generateForNode(base_date, source_node);
  }

  processDateshiftNode(base_date, node) {
    const shifted_date = this.shiftDate(base_date, node);
    this.generateForNode(shifted_date, node);
  }

  generateRRuleDates(base_date, node) {
    let rrule_str = `FREQ=${node.getAttribute('freq').toUpperCase()}`;
    ['interval', 'count', 'wkst', 'byday', 'bymonth', 'bymonthday'].forEach(attr => {
      if (node.getAttribute(attr)) {
        rrule_str += `;${attr.toUpperCase()}=${node.getAttribute(attr).toUpperCase()}`;
      }
    });
    if (node.getAttribute('until')) {
      const until_date = this.named_dates[node.getAttribute('until')];
      if (until_date) {
        rrule_str += `;UNTIL=${this.icalDateFormat(until_date)}`;
      } else {
        console.error(`Date not defined: ${node.getAttribute('until')}`);
      }
    }
    if (!(node.getAttribute('count') || node.getAttribute('until'))) {
      rrule_str += ';COUNT=999';
    }

    const generator = rrule.RRule.fromString(`DTSTART:${this.icalDateFormat(base_date)}\nRRULE:${rrule_str}`);
    return generator.all();
  }

  icalDateFormat(date) {
    return luxon.DateTime.fromJSDate(date, {zone: 'UTC'}).toFormat('yyyyMMdd') + 'T000000Z';
  }

  // Takes a base date and a <date> or <dateshift> node and returns a new date shifted for the
  // appropriate amount.
  shiftDate(base_date, node) {
    let date = luxon.DateTime.fromJSDate(base_date, {zone: 'UTC'});
    if (node.getAttribute('to')) {
      const named_date = this.named_dates[node.getAttribute('to')];
      if (named_date) {
        date = luxon.DateTime.fromJSDate(named_date, {zone: 'UTC'});
      } else {
        console.error(`Date not defined: ${node.getAttribute('to')}`);
      }
    }
    if (node.getAttribute('year')) {
      date = date.plus({year: this.getOffset(node.getAttribute('year'), date.year)});
    }
    if (node.getAttribute('month')) {
      date = date.plus({month: this.getOffset(node.getAttribute('month'), date.month)});
    }
    if (node.getAttribute('weekday')) {
      const desired_weekday_pos = this.weekdayPosition(node.getAttribute('weekday'));
      // In luxon Sunday is 0. We have to adjust that to our 1-indexed, Monday based convention.
      const current_weekday_pos = date.weekday === 0 ? 7 : date.weekday;
      date = date.plus({day: desired_weekday_pos - current_weekday_pos});
    }
    if (node.getAttribute('day')) {
      date = date.plus({day: this.getOffset(node.getAttribute('day'), date.day)});
    }
    return date.toJSDate();
  }

  getOffset(str, base) {
    if (str[0] === '+' || str[0] === '-') {
      return parseInt(str, 10);
    } else {
      return parseInt(str, 10) - base;
    }
  }

  weekdayPosition(str) {
    const positions = {MO: 1, TU: 2, WE: 3, TH: 4, FR: 5, SA: 6, SU: 7};
    return positions[str];
  }

};

Px.Editor.ThemeStore.CURRENT_PAGE_VERSION = 2;  // Keep in sync with PreintPage::CURRENT_VERSION on the Ruby side.
