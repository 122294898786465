Px.Editor.PaletteColorPicker = class PaletteColorPicker extends Px.Component {

  template() {
    return Px.template`
      <div class="px-palette-color-picker">
        ${this.data.colors.map(color => {
          return Px.template`
            <div class="px-color"
                data-color="${color.value}"
                data-selected="${this.selectedColor === color.value}"
                data-onclick="onColorClick"
                data-px-tooltip="${color.name || this.colorDisplayName(color.value)}"
                style="background-color:${Px.Util.colorForDisplay(color.value)}">
            </div>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      color: {std: '#000000'},
      colors: {std: mobx.observable.array()},
      onNewValue: {std: function(new_color) {
        this.state.internal_color = new_color;
      }}
    }
  }

  static get properties() {
    return {
      internal_color: {type: 'str', std: null},
    };
  }

  static get computedProperties() {
    return {
      selectedColor: function() {
        return this.state.internal_color || this.data.color;
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  onColorClick(evt) {
    const color = evt.currentTarget.getAttribute('data-color');
    if (color !== this.selectedColor) {
      this.data.onNewValue.call(this, color);
    }
  }

  // -------
  // Private
  // -------

  colorDisplayName(color_str) {
    if (color_str.slice(0, 4) === 'cmyk') {
      const cmyk = Px.Util.parseCmykString(color_str);
      return `CMYK(${cmyk.c}, ${cmyk.m}, ${cmyk.y}, cmyk.k)`;
    } else {
      return color_str;
    }
  }


};
