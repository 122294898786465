Px.Editor.SetDefinitionModel = class SetDefinitionModel extends Px.BaseModel {

  static fromXMLNode(node, in_inches) {
    var left_caption = '';
    var center_caption = '';
    var right_caption = '';
    var captions_node = node.querySelector('captions');
    if (captions_node) {
      _.each(captions_node.children, function(node) {
        switch (node.nodeName) {
        case 'left':
          left_caption = node.textContent || '';
          break;
        case 'center':
          center_caption = node.textContent || '';
          break;
        case 'right':
          right_caption = node.textContent || '';
          break;
        }
      });
    }

    var pages = _.map(node.querySelectorAll('page'), function(page_node) {
      return Px.Editor.PageDefinitionModel.fromXMLNode(page_node, in_inches);
    });

    var props = {
      count:          node.getAttribute('count') !== 'false',
      grow:           node.getAttribute('grow') === 'true',
      editor:         node.getAttribute('editor') !== 'false',
      fulfillment:    node.getAttribute('fulfillment') !== 'false',
      left_caption:   left_caption,
      center_caption: center_caption,
      right_caption:  right_caption,
      foreachdate:    node.getAttribute('foreachdate') || null,
      pages: pages
    };

    var set_definition = Px.Editor.SetDefinitionModel.make(props);

    pages.forEach(function(page) {
      page.set_definition = set_definition;
    });

    return set_definition;
  }

  static get properties() {
    return Object.assign(super.properties, {
      count: {type: 'bool', std: true},
      grow: {type: 'bool', std: false},
      editor: {type: 'bool', std: true},
      fulfillment: {type: 'bool', std: true},
      left_caption: {type: 'str', std: ''},
      center_caption: {type: 'str', std: ''},
      right_caption: {type: 'str', std: ''},
      foreachdate: {type: 'str', std: null},
      pages: {type: 'array', std: mobx.observable.array()}
    });
  }

};
