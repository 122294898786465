Px.Editor.ElementIcon = class ElementIcon extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    return Px.template`
      <g class="px-element-icon"
         transform="${this.transformAttribute}"
        ${Px.raw(this.tooltipAttribute)}>
        ${Px.raw(this.sizedIcon)}
      </g>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true},
      scale: {required: true},
      element: {required: true},
      icon: {required: true},
      title: {std: null},
      popup_style: {std: null},
      width: {std: 20},
      height: {std: 20},
      position: {std: 'top left'},
      x_offset: {std: 6},
      y_offset: {std: 6}
    };
  }

  static get computedProperties() {
    return {
      tooltipAttribute: function() {
        if (this.data.title) {
          return Px.template`data-px-tooltip="${this.data.title}"`;
        } else {
          return '';
        }
      },
      positionSpec: function() {
        const position = this.data.position;
        if (position === 'center') {
          return ['center', 'center'];
        } else if (position.match(/^(top|bottom|center) (left|right|center)$/)) {
          return position.split(' ');
        } else {
          throw new Error(`Bad icon position: ${position}`);
        }
      },
      x: function() {
        const element_width = this.data.element.width;
        const icon_width = this.data.width;
        const center_x = (element_width - this.inSvgUnits(icon_width)) / 2;
        switch (this.positionSpec[1]) {
        case 'left':
          return Math.min(this.inSvgUnits(this.data.x_offset), center_x);
        case 'right':
          return Math.max(element_width - this.inSvgUnits(icon_width + this.data.x_offset), center_x);
        case 'center':
          return center_x;
        }
      },
      y: function() {
        const element_height = this.data.element.height;
        const icon_height = this.data.height;
        const center_y = (element_height - this.inSvgUnits(icon_height)) / 2;
        switch (this.positionSpec[0]) {
        case 'top':
          return Math.min(this.inSvgUnits(this.data.y_offset), center_y);
        case 'bottom':
          return Math.max(element_height - this.inSvgUnits(icon_height + this.data.y_offset), center_y);
        case 'center':
          return center_y;
        }
      },
      width: function() {
        return this.inSvgUnits(this.data.width);
      },
      height: function() {
        return this.inSvgUnits(this.data.height);
      },
      transformAttribute: function() {
        let transform = `translate(${this.x}, ${this.y})`;
        // If the page is rotated in the editor, we have to apply the reverse rotation so that the icon
        // is still positioned upside down.
        if (this.data.store.isAutorotatedCutPrint(this.data.element.page)) {
          transform += ` rotate(90, ${this.width/2}, ${this.height/2})`;
        }
        return transform;
      },
      sizedIcon: function() {
        // Replace any hardcoded width and height values with dimensions specified in data properties.
        const icon_xml = Px.Util.parseXML(this.data.icon);
        const svg_node = icon_xml.firstChild;
        svg_node.setAttribute('width', this.width);
        svg_node.setAttribute('height', this.height);
        return Px.Util.serializeXML(icon_xml);
      }
    };
  }

  // -------
  // Private
  // -------

  optimalPopupPosition() {
    const bounding_rect = this.dom_node.getBoundingClientRect();
    const margin = 10;
    const top = bounding_rect.y;
    const space_on_left = bounding_rect.x - window.scrollX;
    const space_on_right = window.innerWidth - bounding_rect.x - bounding_rect.width + window.scrollX;
    let left = null;
    let right = null;
    if (space_on_left > space_on_right) {
      right = window.innerWidth - bounding_rect.x + window.scrollX + margin;
    } else {
      left = bounding_rect.x + bounding_rect.width - window.scrollX + margin;
    }
    return {left: left, right: right, top: top};
  }
};
