Px.Editor.QuicksaveProjectModal = class QuicksaveProjectModal extends Px.Components.BaseModal {

  get title() {
    return Px.t('We suggest you regularly save your work.');
  }

  get footer() {
    return Px.template`
      <button class="px-cancel"
              data-onclick="destroy"
              ${this.state.disabled ? 'disabled' : ''}>
        ${Px.t('Cancel')}
      </button>
      <button class="px-ok px-save"
              data-onclick="saveAndContinue"
              ${this.state.disabled ? 'disabled' : ''}>
        ${Px.t('Save Now')}
      </button>
    `;
  }

  get css_class() {
    return `${super.css_class} px-confirmation-modal`;
  }

  get dataProperties() {
    return {
      saveAndContinue: {required: true}
    };
  }

  static get properties() {
    return {
      disabled: {type: 'bool', std: false}
    };
  }

  // --------------
  // Event handlers
  // --------------

  saveAndContinue(evt) {
    this.state.disabled = true;
    this.data.saveAndContinue();
    this.destroy();
  }

};
