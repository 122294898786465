Px.Components.ColorPicker.ColorPickerRGB = class ColorPickerRGB extends Px.Component {

  template() {
    const r = this.renderChild;
    const width = this.data.width;

    return Px.template`
      <div class="px-color-picker-rgb" style="width:${width}px">
        <div class="px-color-wheel">
          <canvas width="${width}"
                  height="${width}"
                  data-ontouchstart="onColorWheelMousedown"
                  data-onmousedown="onColorWheelMousedown">
          </canvas>
          <div class="px-crosshair" style="${this.crosshairStyle}">
            ${Px.raw(Px.Components.ColorPicker.icons.crosshair)}
          </div>
        </div>
        <div class="px-lightness">
          <canvas width="${width}" height="${this.data.slider_height}"></canvas>
          ${r(Px.Components.ColorPicker.ColorSlider, 'lightness-slider', this.sliderProps)}
        </div>
        <div class="px-bottom-controls">
          <span>#</span>
          <input class="px-hex-input"
                 type="text"
                 value="${this.selectedColorRgb.replace('#', '')}"
                 onfocus="this.select()"
                 data-onchange="onInputChange"
          />
          <div class="px-color-preview"
               style="background-color:${this.selectedColorRgb};"
               data-color-scheme="${Px.Util.isColorDark(this.selectedColorRgb) ? 'dark': 'light'}">
            ${Px.raw(Px.Components.ColorPicker.icons.tick)}
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.onLightnessChange = this.onLightnessChange.bind(this);

    this.registerReaction(() => {
      const normalized = this.normalizeHexColor(this.data.color);
      return this.isValidHexColor(normalized) ? normalized : null;
    }, color => {
      this.setColorValue(color);
    }, {
      fireImmediately: true,
      name: 'Px.Components.ColorPicker::SetColorReaction'
    });

    this.on('mount', () => {
      this.registerAutorun(() => {
        this.drawCanvasElements();
      }, {
        name: 'Px.Components.ColorPicker::drawCanvasElements'
      });
    });
  }

  get dataProperties() {
    return {
      color: {std: '#000000'},
      width: {std: 220},
      slider_height: {std: 8},
      onNewValue: {std: (new_color) => {
        this.setColorValue(new_color);
      }},
      onBeforeDrag: {std: function() {}},
      onAfterDrag: {std: function() {}}
    };
  }

  static get properties() {
    return {
      selected_hue: {type: 'float', std: 0},
      selected_saturation: {type: 'float', std: 0},
      selected_lightness: {type: 'float', std: 0}
    };
  }

  static get computedProperties() {
    return {
      radius: function() {
        return this.data.width / 2;
      },
      selectedColorRgb: function() {
        const hsl = {
          h: this.state.selected_hue,
          s: this.state.selected_saturation,
          l: this.state.selected_lightness
        };
        const rgb = Px.Util.hslToRgb(hsl);
        return Px.Util.generateRgbString(rgb);
      },
      crosshairPosition: function() {
        const angle = (2 * Math.PI * this.state.selected_hue) + (Math.PI / 2);
        const len = this.radius * this.state.selected_saturation;
        const x = (Math.sin(angle) * len) + this.radius;
        const y = (Math.cos(angle) * len) + this.radius;
        return {x: x, y: y};
      },
      crosshairStyle: function() {
        const left = this.crosshairPosition.x;
        const bottom = this.crosshairPosition.y;
        return `left: ${left}px; bottom: ${bottom}px`;
      },
      sliderValue: function() {
        return (1 - this.state.selected_lightness);
      },
      sliderProps: function() {
        return {
          value: this.sliderValue,
          step: 0,
          min: 0,
          max: 1,
          onNewValue: this.onLightnessChange,
          onBeforeDrag: this.data.onBeforeDrag,
          onAfterDrag: this.data.onAfterDrag
        };
      }
    };
  }

  drawCanvasElements() {
    this.drawColorWheel();
    this.drawLightnessStrip();
  }

  drawColorWheel() {
    const canvas = this.dom_node && this.dom_node.querySelector('.px-color-wheel canvas');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const cx = canvas.width / 2;
      const cy = canvas.height / 2;
      let start_angle, end_angle, grad;

      for (let angle = 0; angle <= 360; angle++) {
        start_angle = (angle - 2) * Math.PI/180;
        end_angle = angle * Math.PI/180;
        ctx.beginPath();
        ctx.moveTo(cx, cy);
        ctx.arc(cx, cy, this.radius, start_angle, end_angle);
        ctx.closePath();
        grad = ctx.createRadialGradient(cx, cy, 0, cx, cy, this.radius);
        grad.addColorStop(0, `hsl(${angle}, 0%, 100%)`);
        grad.addColorStop(1, `hsl(${angle}, 100%, 50%)`);
        ctx.fillStyle = grad;
        ctx.fill();
      }
    }
  }

  drawLightnessStrip() {
    const canvas = this.dom_node && this.dom_node.querySelector('.px-lightness canvas');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const h = 360 * this.state.selected_hue;
      const s = 100 * this.state.selected_saturation;
      const grad = ctx.createLinearGradient(0, 0, this.data.width, 0);

      for (let i = 0; i <= 100; i++) {
        grad.addColorStop(i/100, `hsl(${h}, ${s}%, ${100 - i}%)`);
      }
      ctx.fillStyle = grad;
      ctx.fillRect(0, 0, this.data.width, this.data.slider_height);
    }
  }

  normalizeHexColor(color) {
    color = color.replace('#', '');
    if (color) {
      if (color.length === 3) {
        color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
      }
      return '#' + color.toUpperCase();
    }
  }

  isValidHexColor(color) {
    if (color) {
      return color.match(/^#[0-9A-F]{6}$/);
    } else {
      return false;
    }
  }

  setColorValue(value) {
    if (value === this.selectedColorRgb) {
      // Abort if new hex color is the same as selected color to avoid
      // rounding errors in RGB <-> HSL transformations.
      return;
    }
    const rgb = Px.Util.parseRgbString(value);
    const hsl = Px.Util.rgbToHsl(rgb);
    mobx.runInAction(() => {
      // If lightness is 0 (black) or 1 (white), we keep original
      // hue and saturation values, so that it does not reset to h=0 & s=0.
      if (!(hsl.l === 0 || hsl.l === 1)) {
        this.state.selected_hue = hsl.h;
        this.state.selected_saturation = hsl.s;
      }
      this.state.selected_lightness = hsl.l;
    });
  }

  setColorHsl(hsl) {
    const old_color_str = this.selectedColorRgb;
    mobx.runInAction(() => {
      this.state.selected_hue = Math.max(0, Math.min(1, hsl.h));
      this.state.selected_saturation = Math.max(0, Math.min(1, hsl.s));
      this.state.selected_lightness = Math.max(0, Math.min(1, hsl.l));
    });
    const new_color = Px.Util.hslToRgb({
      h: this.state.selected_hue,
      s: this.state.selected_saturation,
      l: this.state.selected_lightness
    });
    const new_color_str = Px.Util.generateRgbString(new_color);
    // Only invoke callback if new color in RGB hex representation is different
    // from old color to avoid rounding errors.
    if (new_color_str !== old_color_str) {
      this.data.onNewValue(new_color_str);
    }
  }

  // --------------
  // Event handlers
  // --------------

  onInputChange(evt) {
    const color = this.normalizeHexColor(evt.target.value);
    if (this.isValidHexColor(color)) {
      this.data.onNewValue(color);
    }
    evt.target.value = this.selectedColorRgb.replace('#', '');  // make sure values are in sync
  }

  onColorWheelMousedown(evt) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }
    const doc = $j(document);
    const colorwheel = $j(this.dom_node).find('.px-color-wheel canvas');
    const offset = colorwheel.offset();

    this._colorwheel_pos = {
      left: Math.round(offset.left),
      top: Math.round(offset.top)
    };

    const getCoords = evt => {
      const pageX = 'pageX' in evt ? evt.pageX : evt.originalEvent.targetTouches[0].pageX;
      const pageY = 'pageY' in evt ? evt.pageY : evt.originalEvent.targetTouches[0].pageY;
      const x = pageX - (this._colorwheel_pos.left + this.radius);
      const y = pageY - (this._colorwheel_pos.top + this.radius);
      const r = Math.sqrt(Math.abs(x * x) + Math.abs(y * y));
      const angle = Math.atan2(y, x);
      return {x: x, y: y, r: r, angle: angle};
    };

    const coords = getCoords(evt);
    const is_inside_wheel = Math.round(coords.r) <= this.radius;
    if (!is_inside_wheel) {
      return;
    }

    const setColor = (r, angle) => {
      mobx.runInAction(() => {
        if (angle < 0) {
          angle += (2 * Math.PI);
        }
        const new_hue = angle / (2 * Math.PI);
        const new_saturation = r / this.radius;
        let new_lightness = this.state.selected_lightness;
        // If current lightness is 0 (black) or 1 (white), automatically set it to 0.5,
        // otherwise it can be confusing for the users when they're dragging the crosshair
        // around but the selected color remains black (or white).
        if (new_lightness === 0 || new_lightness === 1) {
          new_lightness = 0.5;
        }
        this.setColorHsl({
          h: new_hue,
          s: new_saturation,
          l: new_lightness
        });
      });
    };

    evt.preventDefault();
    this.data.onBeforeDrag();
    setColor(coords.r, coords.angle);

    const setValueFromEvent = evt => {
      var coords = getCoords(evt);
      setColor(coords.r, coords.angle);
    };

    let raf_id = null;

    const drag_move_events = 'mousemove touchmove';
    const drag_end_events = 'mouseup touchend touchcancel';

    const onDragMove = evt => {
      if (raf_id) {
        cancelAnimationFrame(raf_id);
      }
      raf_id = requestAnimationFrame(() => {
        setValueFromEvent(evt);
        raf_id = null;
      });
    };

    const onDragEnd = evt => {
      doc.off(drag_move_events, onDragMove);
      doc.off(drag_end_events, onDragEnd);
      cancelAnimationFrame(raf_id);
      setValueFromEvent(evt);
      this.data.onAfterDrag();
    };

    doc.on(drag_move_events, onDragMove);
    doc.on(drag_end_events, onDragEnd);
  }

  onLightnessChange(new_value) {
    const new_lightness = 1 - new_value;
    this.setColorHsl({
      h: this.state.selected_hue,
      s: this.state.selected_saturation,
      l: new_lightness
    });
  }

};
