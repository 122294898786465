Px.Editor.CoordinateInput = class CoordinateInput extends Px.Editor.BaseComponent {

  template() {
    const min = this.data.min === null ? '' : `min="${this.data.min}"`;
    return Px.template`
      <label class="px-coordinate-input">
        ${Px.if(this.data.show_label, () => {
          return Px.template`
            <span class="px-prop-name">${this.data.label || this.data.property}:</span>
          `;
        })}
        <input type="number" step="any" ${min} value="${this.value}" data-onchange="setValue" />
        <span class="px-prop-units">${this.data.store.project.unit === 'mm' ? 'mm' : 'in'}</span>
      </label>
    `;
  }

  get dataProperties() {
    return {
      property: {required: true},
      element: {required: true},
      store: {required: true},
      label: {std: null},
      show_label: {std: true},
      min: {std: null}
    };
  }

  static get computedProperties() {
    return {
      value: function() {
        const val_in_mm = this.data.element[this.data.property];
        return this.toUserUnits(val_in_mm).toFixed(3);
      }
    };
  }

  toUserUnits(val) {
    if (this.data.store.project.unit === 'inch') {
      return Px.Util.mm2in(val);
    }
    return val;
  }

  fromUserUnits(val) {
    if (this.data.store.project.unit === 'inch') {
      return Px.Util.in2mm(val);
    }
    return val;
  }

  // --------------
  // Event handlers
  // --------------

  setValue(evt) {
    const val = parseFloat(evt.target.value);
    this.withUndo(`set element ${this.data.property}`, () => {
      this.data.element[this.data.property] = this.fromUserUnits(val);
    });
  }

};
