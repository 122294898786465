Px.Editor.GroupEditPanel = class GroupEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-image-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control px-action-button">
              <button class="px-small px-secondary-color" data-onclick="ungroupElements">
                ${Px.t('Ungroup Elements')}
              </button>
            </div>

            <div class="px-edit-control px-slider-control">
              <h2>${Px.t('Rotation')}</h2>
              ${r(Px.Components.SliderWithNumericInput, 'rotation-slider', this.rotationSliderProps)}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setRotation = this.setRotation.bind(this);
    this.onBeforeRotationSliderDrag = this.onBeforeRotationSliderDrag.bind(this);
    this.onAfterRotationSliderDrag = this.onAfterRotationSliderDrag.bind(this);
  }

  get dataProperties() {
    return {
      group: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      rotationSliderProps: function() {
        return {
          min: -180,
          max: 180,
          step: 0.25,
          value: this.data.group.rotation,
          onNewValue: this.setRotation,
          onBeforeDrag: this.onBeforeRotationSliderDrag,
          onAfterDrag: this.onAfterRotationSliderDrag
        };
      }
    };
  }

  setRotation(rotation) {
    this.withUndo('rotate group', () => {
      this.data.group.update({rotation: rotation});
    });
  }
  onBeforeRotationSliderDrag() {
    this.beginWithUndo('change group rotation');
  }
  onAfterRotationSliderDrag() {
    this.endWithUndo('change group rotation');
  }

  // --------------
  // Event handlers
  // --------------

  ungroupElements(evt) {
    const store = this.data.store;
    const group = this.data.group;
    this.withUndo('ungroup elements', () => {
      group.elements.forEach(element => {
        store.ungroupElement(element);
      });
      group.destroy();
    });
  }

};
