Px.Editor.QrCodeElementModel = class QrCodeElementModel extends Px.Editor.BaseElementModel {

  static fromXMLNode(node, params) {
    const props = {
      content:         node.textContent || '',
      x:               parseFloat(node.getAttribute('x')),
      y:               parseFloat(node.getAttribute('y')),
      z:               parseInt(node.getAttribute('z'), 10) || 0,
      size:            parseFloat(node.getAttribute('size')) || 0,
      color:           node.getAttribute('color') || '#000000',
      rotation:        parseFloat(node.getAttribute('rotate')) || 0,
      ec:              node.getAttribute('ec') || 'm',
      name:            node.getAttribute('name') || null,
      tags:            node.getAttribute('tags') || null,
      layout:          node.getAttribute('layout') === 'true',
      pdf_layer:       node.getAttribute('pdf_layer') || null,
      placeholder:     node.getAttribute('placeholder') === 'true',
      show_on_preview: node.getAttribute('show_on_preview') === 'true',
      tags:            node.getAttribute('tags') || null,
      clone_id:        node.getAttribute('clone_id') || null,
      group:           params.group || null,
      page:            params.page
    };
    return Px.Editor.QrCodeElementModel.make(props);
  }

  static get properties() {
    return Object.assign(super.properties, {
      content: {std: '', serialize: false},
      size: {std: 0},
      color: {std: '#000000'},
      ec: {std: 'm'},
      placeholder: {std: false},
      show_on_preview: {std: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      qr_matrix: function() {
        const ec_level = this.ec.toUpperCase();
        const qr = new JSQRCode(0, ec_level);
        qr.addData(this.content, 'Byte');
        qr.make();
        const module_count = qr.getModuleCount();
        const matrix = [];
        for (let x = 0; x < module_count; x++) {
          for (let y = 0; y < module_count; y++) {
            if (x === 0) {
              matrix.push([]);
            }
            matrix[x][y] = qr.isDark(x, y);
          }
        }
        return matrix;
      },
      // This is a JS version of code from:
      // https://github.com/whomwah/rqrcode/blob/e4a5bf2320afdad59/lib/rqrcode/export/svg.rb#L19-L46
      qr_edge_matrix: function() {
        const modules_array = this.qr_matrix;
        const matrix_width = modules_array.length + 1;
        const matrix_height = modules_array.length + 1;
        const empty_row = [Array(matrix_width - 1).fill(false)];
        const edge_matrix = Array(matrix_height);
        for (let i = 0; i < matrix_height; i++) {
          edge_matrix[i] = Array(matrix_width);
        }

        const expanded_array = [].concat(empty_row).concat(modules_array).concat(empty_row);
        expanded_array.forEach((first_row, row_idx) => {
          if (row_idx >= matrix_height) {
            return;
          }
          const second_row = expanded_array[row_idx + 1];

          // Horizontal edges.
          first_row.forEach((column_top, column_idx) => {
            const column_bottom = second_row[column_idx];
            let edge;
            if (column_top && !column_bottom) {
              edge = {
                direction: 'left',
                start_x: column_idx + 1,
                start_y: row_idx,
                end_x: column_idx,
                end_y: row_idx
              };
            } else if (!column_top && column_bottom) {
              edge = {
                direction: 'right',
                start_x: column_idx,
                start_y: row_idx,
                end_x: column_idx + 1,
                end_y: row_idx
              };
            }
            if (edge) {
              if (!edge_matrix[edge.start_y][edge.start_x]) {
                edge_matrix[edge.start_y][edge.start_x] = [];
              }
              edge_matrix[edge.start_y][edge.start_x].push(edge);
            }
          });

          // Vertical edges.
          const expanded_row = [false].concat(second_row).concat([false]);
          expanded_row.forEach((column_left, column_idx) => {
            if (column_idx >= matrix_width) {
              return;
            }
            const column_right = expanded_row[column_idx + 1];

            let edge;
            if (column_left && !column_right) {
              edge = {
                direction: 'down',
                start_x: column_idx,
                start_y: row_idx,
                end_x: column_idx,
                end_y: row_idx + 1
              };
            } else if (!column_left && column_right) {
              edge = {
                direction: 'up',
                start_x: column_idx,
                start_y: row_idx + 1,
                end_x: column_idx,
                end_y: row_idx
              };
            }
            if (edge) {
              if (!edge_matrix[edge.start_y][edge.start_x]) {
                edge_matrix[edge.start_y][edge.start_x] = [];
              }
              edge_matrix[edge.start_y][edge.start_x].push(edge);
            }
          });
        });
        return edge_matrix;
      },
      xml: function() {
        var escaped_content = this.content.replace(/\&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return `<qrcode ${this.xmlizeAttributes()}>${escaped_content}</qrcode>`;
      }
    });
  }

  serializableAttributes() {
    const attrs = super.serializableAttributes();
    delete attrs.width;
    delete attrs.height;
    return attrs;
  }

  get width() {
    return this.size;
  }

  get height() {
    return this.size;
  }

  set width(width) {
    this.size = width;
  }

  set height(height) {
    this.size = height;
  }

};

Px.Editor.QrCodeElementModel.ELEMENT_TYPE = 'qrcode';
