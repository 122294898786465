Px.Components.UploadModal = class UploadModal extends Px.Components.BaseModal {
  get title() {
    return 'Upload';
  }

  get content() {
    return Px.template`
      <div class="px-upload-modal">
        ${this.uploader.files.map(file => {
          return Px.template`
            <div class="px-file-item px-status-${this.uploadStatus(file)}">
              ${file.file.name}
              <span class="px-file-size">(${this.humanFileSize(file.file.size)})</span>
              <div class="px-progress">
                <div class="px-progress-bar" style="width: ${file.upload_progress_percent}%"></div>
              </div>
              <div class="px-error">${file.upload_error}</div>
            </div>
          `;
         })}
        <input class="px-file-input"
               type="file"
               accept="${this.data.accept}"
               ${this.data.multiple ? 'multiple' : ''}
               data-onchange="selectFiles" />
      </div>
    `;
  }

  get dataProperties() {
    return {
      upload_path: {required: true},
      upload_method: {std: 'post'},
      multiple: {std: true},
      accept: {std: '.jpg,.jpeg,.png'},
      max_file_size: {std: Px.LocalFiles.Validation.MAX_FILE_SIZE},
      onFileUploaded: {std: function(data) {}},
      onClose: {std: function() {}}
    };
  }

  static get properties() {
    return {
      uploads: {type: 'array', std: []}
    };
  }

  constructor(props) {
    props.close_on_background_click = false;

    super(props);

    this.uploader = Px.LocalFiles.Uploader.make(this.data.upload_path, {
      upload_method: this.data.upload_method
    });

    this.on('mount', () => {
      this.dom_node.querySelector('.px-file-input').addEventListener('cancel', () => {
        this.close();
      });
    });

    this.registerReaction(() => this.allUploadsSucceeded, success => {
      if (success) {
        this.close();
      }
    }, {
      name: 'Px.Components.UploadModal::allUploadsSucceededReaction'
    });
  }

  static get computedProperties() {
    return {
      allUploadsSucceeded: function() {
        return this.uploader.files.every(file => file.upload_success);
      }
    };
  }

  humanFileSize(bytes) {
    const threshold = 1000;
    if (Math.abs(bytes) < threshold) {
      return bytes + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
      bytes /= threshold;
      ++u;
    } while (Math.abs(bytes) >= threshold && u < units.length - 1);

    return bytes.toFixed(1) + ' ' + units[u];
  }

  uploadStatus(file) {
    if (file.upload_queued) {
      return 'queued';
    } else if (file.upload_in_progress) {
      return 'started';
    } else if (file.upload_failure) {
      return 'error';
    } else if (file.upload_success) {
      return 'success';
    } else {
      // Should never happen in theory.
      return 'undefined';
    }
  }

  // V validates selected files to ensure that file size is not too large.
  validateSelectedFiles(files) {
    const max_file_size = this.data.max_file_size;
    const too_large = files.filter(file => file.size > max_file_size);

    if (too_large.length) {
      const file_names = too_large.map(file => file.name);
      const megabytes = (max_file_size / (1024 * 1024)).toFixed(1);
      const msg = `Some files are too large. Maximum allowed file size is ${megabytes} MB.`;
      alert(msg + '\n\n' +  file_names.join('\n'));
      return false;
    }

    return true;
  }

  close() {
    super.close();
    this.data.onClose();
  }

  // --------------
  // Event handlers
  // --------------

  selectFiles(evt) {
    // Clone the files list so that redrawing the component doesn't clear the list while
    // we're iterating through the files.
    const files = Array.from(evt.target.files).slice();
    if (this.validateSelectedFiles(files)) {
      files.forEach(file => {
        const local_file = Px.LocalFiles.LocalFileModel.make(file);
        mobx.when(() => local_file.upload_success || local_file.upload_failure, () => {
          if (local_file.upload_success) {
            this.data.onFileUploaded(local_file.upload_response);
          }
        });
        this.uploader.uploadFile(local_file);
      });
    }
  }

  // Triggers click event on the hidden input[type=file] element to
  // open the file selection dialog.
  // Note that this only works when invoked from a click event handler
  // due to browser restrictions.
  triggerFileDialog(evt) {
    $j(this.dom_node).find('input.px-file-input').click();
  }

};
