Px.Editor.ScoreElementModel = class ScoreElementModel extends Px.Editor.BaseElementModel {

  static fromXMLNode(node, params) {
    const props = {
      name:     node.getAttribute('name') || '',
      x:        parseFloat(node.getAttribute('x') || 0),
      y:        parseFloat(node.getAttribute('y') || 0),
      width:    parseFloat(node.getAttribute('width') || 0),
      height:   parseFloat(node.getAttribute('height') || 0),
      group:    params.group || null,
      page:     params.page
    };
    return Px.Editor.ScoreElementModel.make(props);
  }

}

Px.Editor.ScoreElementModel.ELEMENT_TYPE = 'score';

// Score elements do not participate in two page spread.
// This improves performance because it avoids generating the clones.
Px.Editor.ScoreElementModel.TWO_PAGE_SPREAD_ENABLED = false;
