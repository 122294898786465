Px.Editor.ImagesPanel = class ImagesPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-tab-content-panel px-images-panel">
        <div class="px-panel-top-section">
          ${Px.if(this.displayedSection === 'no-image-notice', () => {
            return Px.template`
              <h1>${Px.t('Images:')}</h1>
            `;
          }).elseIf(this.displayedSection === 'image-mask', () => {
            return Px.template`
              ${Px.if(!this.data.store.cut_print_mode, () => {
                return Px.template`
                  <h1>
                    ${Px.t('Image Mask:')}
                    <button data-onclick="backToImageEdit">
                      ${Px.raw(Px.Editor.BaseGalleryPanel.icons.action_back)}
                      ${Px.t('Back')}
                    </button>
                  </h1>
                `;
              })}
            `;
          }).else(() => {
            return Px.template`
              <h1>${Px.t('Edit Image:')}</h1>
            `;
          })}
        </div>

        ${Px.if(this.displayedSection === 'image-edit', () => {
          if (Px.config.advanced_edit_mode) {
            return r(Px.Editor.ImageEditAccordion, 'image-edit-accordion', {image: this.selectedImage});
          } else {
            return r(Px.Editor.ImageEditPanel, 'image-edit-panel', {image: this.selectedImage});
          }
        }).elseIf(this.displayedSection === 'image-mask', () => {
          return r(Px.Editor.ImageMaskPanel, 'image-mask-panel')
        }).else(() => {
          return Px.template`
            <div class="px-action-description-area">
              <p>${this.addImageInstructions}</p>
            </div>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      selectedImage: function() {
        if (this.data.store.selected_element && this.data.store.selected_element.type === 'image') {
          return this.data.store.selected_element;
        }
      },
      displayedSection: function() {
        const store = this.data.store;
        let section = store.ui.image_tab_section;
        if ((section === 'image-edit' || section === 'image-mask') &&
            (!this.selectedImage || (this.selectedImage.placeholder && !Px.config.advanced_edit_mode))) {
          section = 'no-image-notice';
        }
        return section;
      },
      addImageInstructions: function() {
        // Some special handling is needed to be able to splice an icon into text that
        // otherwise needs to get HTML-escaped.
        const default_translation =
              'Drag an image to a page in your project or click the {{button_icon}} button at the top of the main area ' +
              'to add a new image box to your project.';
        const translation = Px.t('Add image instructions', default_translation);
        const parts = translation.split('{{button_icon}}');
        const result = [];
        for (let i = 0; i < parts.length - 1; i++) {
          result.push(parts[i]);
          result.push(Px.raw(Px.Editor.PageDisplay.icons.add_image));
        }
        result.push(parts[parts.length - 1]);
        return result;
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  backToImageEdit(evt) {
    mobx.runInAction(() => {
      this.data.store.ui.setImageTabSection('image-edit');
    });
  }

};
