Px.Editor.TextEditPanel = class TextEditPanel extends Px.Editor.BaseComponent {

  template() {
    const text = this.data.text;
    const r = this.renderChild;
    return Px.template`
      <div class="px-text-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            <div class="px-edit-control">
              <h2>${Px.t('Alignment')}</h2>
              ${r(Px.Editor.TextAlignmentButtons, 'text-alignment-buttons', this.alignmentButtonsProps)}
            </div>

            <div class="px-edit-control">
              <h2>${Px.t('Font')}</h2>
              ${r(Px.Components.Dropdown, `font-select-${text.unique_id}`, this.fontDropdownProps)}
            </div>

            ${Px.if(text.efontsize, () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Size')}</h2>
                  ${r(Px.Components.Dropdown, `font-size-select-${text.unique_id}`, this.fontSizeDropdownProps)}
                </div>
              `;
            })}

            ${Px.if(text.ecolor, () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Color')}</h2>
                  ${Px.if(this.colorPaletteId, () => {
                    return r(Px.Editor.PaletteColorPicker, 'palette-color-picker', this.paletteColorPickerProps);
                  }).else(() => {
                    return r(Px.Components.ColorButton, 'border-color', this.colorButtonProps);
                  })}
                </div>
              `;
            })}

            ${Px.if(text.eopacity, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Opacity')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'opacity-slider', this.opacitySliderProps)}
                </div>
              `;
            })}

            ${Px.if(text.erotation, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Rotation')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'rotation-slider', this.rotationSliderProps)}
                </div>
              `;
            })}
          </div>
        </div>
      </div>
    `;
  }

  font_preview_template(font) {
    return Px.template`
      <img class="px-font-dropdown-option"
          alt="${font.name}"
          title="${font.name}"
          src="/print_fonts/${font.id}/preview.svg?size=22&width=200&height=45"
      />
    `;
  }

  constructor(props) {
    super(props);

    this._end_color_drag_with_undo = null;
    this._end_opacity_drag_with_undo = null;
    this._end_rotation_drag_with_undo = null;


    this.setFont = this.setFont.bind(this);
    this.setFontSize = this.setFontSize.bind(this);
    this.setAlignment = this.setAlignment.bind(this);
    this.setColor = this.setColor.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.setRotation = this.setRotation.bind(this);
    this.selectColor = this.selectColor.bind(this);
    this.onBeforeOpacitySliderDrag = this.onBeforeOpacitySliderDrag.bind(this);
    this.onAfterOpacitySliderDrag = this.onAfterOpacitySliderDrag.bind(this);
    this.onBeforeRotationSliderDrag = this.onBeforeRotationSliderDrag.bind(this);
    this.onAfterRotationSliderDrag =this.onAfterRotationSliderDrag.bind(this);

    this.registerReaction(() => this.colorPaletteId, palette_id => {
      if (palette_id) {
        this.data.store.color_palettes.load(palette_id);
      }
    }, {
      fireImmediately: true,
      name: 'Px.Editor.TextEditPanel::LoadColorPaletteReaction'
    });

    this.registerReaction(() => this.data.text.fontpalette, palette_id => {
      this.data.store.font_palettes.load(palette_id);
    }, {
      fireImmediately: true,
      name: 'Px.Editor.TextEditPanel::LoadFontPaletteReaction'
    });
  }

  get dataProperties() {
    return {
      text: {required: true},
      store: {required: true}
    }
  }

  static get computedProperties() {
    return {
      alignmentButtonsProps: function() {
        return {
          value: this.data.text.align,
          onNewValue: this.setAlignment
        };
      },
      paletteColorPickerProps: function() {
        return {
          color: this.data.text.color,
          colors: this.paletteColors,
          onNewValue: this.setColor
        }
      },
      colorButtonProps: function() {
        return {
          color: this.data.text.color,
          title: Px.t('Choose color'),
          onClick: this.selectColor,
        };
      },
      opacitySliderProps: function() {
        return {
          min: 20,
          max: 100,
          value: Math.round(this.data.text.opacity * 100),
          onNewValue: this.setOpacity,
          onBeforeDrag: this.onBeforeOpacitySliderDrag,
          onAfterDrag: this.onAfterOpacitySliderDrag
        };
      },
      rotationSliderProps: function() {
        return {
          min: -180,
          max: 180,
          step: 0.25,
          value: this.data.text.rotation,
          onNewValue: this.setRotation,
          onBeforeDrag: this.onBeforeRotationSliderDrag,
          onAfterDrag: this.onAfterRotationSliderDrag
        };
      },
      colorPaletteId: function() {
        return this.data.text.palette || Px.config.default_text_color_palette_id;
      },
      paletteColors: function() {
        let colors = [];
        if (this.colorPaletteId) {
          const palette = this.data.store.color_palettes.get(this.colorPaletteId);
          if (palette) {
            colors = palette.colors.map(function(color) {
              return {
                value: color.value,
                name: color.name || ''
              };
            });
          }
        }
        return colors;
      },
      fontOptions: function() {
        const palette = this.data.store.font_palettes.get(this.data.text.fontpalette);
        if (!palette) {
          return [];
        }
        return palette.fonts.map(font => {
          return {
            value: font.id,
            name: font.name,
            panel_name: this.font_preview_template(font)
          };
        });
      },
      fontDropdownProps: function() {
        let value = this.data.text.font;
        const matching_option = _.find(this.fontOptions, option => {
          return String(option.value) === String(value);
        });
        // If currently assigned font does not exist in current font palette, set the value to an empty string,
        // so that the font dropdown doesn't display confusing font IDs, but is empty instead.
        if (!matching_option) {
          value = '';
        }
        return {
          value: value,
          options: this.fontOptions,
          onNewValue: this.setFont
        };
      },
      fontSizeOptions: function() {
        const sequence = this.generateTypeScaleSequence();
        const size = this.data.text.pointsize;
        const index = _.findIndex(sequence, function(val) {
          return val > size;
        });
        const start = Math.max(0, index - 6);
        const end = start + 13;
        const options = [];
        for (let i = start; i < end; i++) {
          options.push({value: sequence[i]});
        }
        return options;
      },
      fontSizeDropdownProps: function() {
        return {
          value: this.data.text.pointsize,
          options: this.fontSizeOptions,
          freeform_input: true,
          onNewValue: this.setFontSize
        };
      }
    };
  }

  setAlignment(align) {
    this.withUndo('change text alignment', () => {
      this.data.text.update({align: align});
    });
  }
  setColor(color) {
    this.withUndo('change text color', () => {
      this.data.text.update({color: color});
    });
  }
  setOpacity(opacity) {
    this.withUndo('change text opacity', () => {
      this.data.text.update({opacity: opacity / 100});
    });
  }
  setRotation(rotation) {
    this.withUndo('rotate text', () => {
      this.data.text.update({rotation: rotation});
    });
  }

  onBeforeColorPickerDrag() {
    this.beginWithUndo('change text color');
  }
  onBeforeOpacitySliderDrag() {
    this.beginWithUndo('change text opacity');
  }
  onAfterOpacitySliderDrag() {
    this.endWithUndo('change text opacity');
  }
  onBeforeRotationSliderDrag() {
    this.beginWithUndo('rotate text');
  }
  onAfterRotationSliderDrag() {
    this.endWithUndo('rotate text');
  }

  // --------------
  // Event handlers
  // --------------

  setFont(val) {
    this.withUndo('set font', () => {
      this.data.text.update({font: val});
    });
  }

  setFontSize(str_val) {
    const value = parseInt(str_val, 10);
    if (value > 0) {
      this.withUndo('set font size', () => {
        this.data.text.update({pointsize: value});
      });
    }
  }

  selectColor(evt) {
    const text = this.data.text;
    const store = this.data.store;
    this.makeModal(Px.Editor.ColorPickerModal, {
      store: store,
      initial_color: text.color,
      palette_id: this.colorPaletteId,
      usage_context: 'texts',
      color_type_switch: store.color_picker_type_switch_enabled,
      onColorSelected: color => {
        this.withUndo('set text color', () => {
          text.update({color: color});
        });
      }
    });
  }

  // -------
  // Private
  // -------

  // Generates a type scale sequence of 100 font sizes, starting at 12pt.
  // Using the type scale algorithm explained in http://carbondesignsystem.com/style/typography/overview.
  generateTypeScaleSequence() {
    const length = 100;
    const sequence = [12];
    for (let i = 1; i < length; i++) {
      sequence.push(sequence[i - 1] + 2*(Math.floor(((i + 1) - 2) / 4) + 1));
    }
    return sequence;
  }

};
