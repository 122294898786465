Px.Components.ColorPicker = class ColorPicker extends Px.Component {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-color-picker">
        ${Px.if(this.data.color_type_switch, () => {
          return Px.template`
            <div class="px-color-type-switch" style="width:${this.data.width}px">
              ${r(Px.Components.Dropdown, 'color-type-switch', this.colorTypeDropdownProps)}
            </div>
          `;
        })}
        ${r(this.pickerComponent, `picker-${this.colorType}`, this.pickerComponentData)}
      </div>
    `;
  }

  constructor(data) {
    super(data);

    if (!(data && data.onNewValue)) {
      // We're using this component in uncontrolled mode, so initialize the internal state.
      this.state.internal_color = this.data.color;
    }

    this.convertToColorType = this.convertToColorType.bind(this);
  }

  get dataProperties() {
    return {
      color: {std: '#000000'},
      width: {std: 220},
      slider_height: {std: 4},
      onNewValue: {std: (new_color) => {
        this.state.internal_color = new_color;
      }},
      onBeforeDrag: {std: function() {}},
      onAfterDrag: {std: function() {}},
      color_type_switch: {std: false}
    };
  }

  static get properties() {
    return {
      // Only used in tests when mounting the component in non-controlled mode.
      // In normal operations the color should be passed in as a data prop and
      // updated externally by a custom onNewValue function.
      internal_color: {type: 'str', std: null}
    };
  }

  static get computedProperties() {
    return {
      selectedColor: function() {
        return this.state.internal_color || this.data.color;
      },
      colorType: function() {
        if (this.selectedColor.slice(0, 4) === 'cmyk') {
          return 'CMYK';
        } else {
          return 'RGB';
        }
      },
      pickerComponent: function() {
        if (this.colorType === 'CMYK') {
          return ColorPicker.ColorPickerCMYK;
        } else {
          return ColorPicker.ColorPickerRGB;
        }
      },
      pickerComponentData: function() {
        const data = {};
        Object.keys(ColorPicker.prototype.dataProperties).forEach(key => {
          if (key === 'color') {
            data.color = this.selectedColor;
          } else {
            data[key] = this.data[key];
          }
        });
        return data;
      },
      colorTypeDropdownProps: function() {
        return {
          value: this.colorType,
          options: [{value: 'RGB'}, {value: 'CMYK'}],
          onNewValue: this.convertToColorType
        };
      }
    };
  }

  convertToColorType(type) {
    if (this.colorType === 'RGB') {
      const rgb = Px.Util.parseRgbString(this.selectedColor);
      if (type === 'CMYK') {
        const cmyk = Px.Util.rgbToCmyk(rgb);
        this.data.onNewValue(Px.Util.generateCmykString(cmyk));
      }
    } else if (this.colorType === 'CMYK') {
      const cmyk = Px.Util.parseCmykString(this.selectedColor);
      if (type === 'RGB') {
        const rgb = Px.Util.cmykToRgb(cmyk);
        this.data.onNewValue(Px.Util.generateRgbString(rgb));
      }
    }
  }

};

Px.Components.ColorPicker.icons = {
  crosshair: '<svg class="crosshair" width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><circle cx="9" cy="9" r="6" stroke="#000" fill="none" /><line x1="9" y1="0" x2="9" y2="6" stroke="#000" /><line x1="18" y1="9" x2="12" y2="9" stroke="#000" /><line x1="9" y1="18" x2="9" y2="12" stroke="#000" /><line x1="0" y1="9" x2="6" y2="9" stroke="#000" /></svg>',
  tick: '<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.2498 8.14995L1.6248 5.52494C1.55616 5.45546 1.47441 5.4003 1.38428 5.36265C1.29416 5.325 1.19747 5.30561 1.0998 5.30561C1.00213 5.30561 0.905432 5.325 0.815312 5.36265C0.725191 5.4003 0.64344 5.45546 0.574798 5.52494C0.505319 5.59359 0.450155 5.67534 0.412505 5.76546C0.374856 5.85558 0.355469 5.95228 0.355469 6.04994C0.355469 6.14761 0.374856 6.24431 0.412505 6.33443C0.450155 6.42455 0.505319 6.5063 0.574798 6.57494L3.7173 9.71745C4.0098 10.0099 4.4823 10.0099 4.7748 9.71745L12.7248 1.77494C12.7943 1.7063 12.8494 1.62455 12.8871 1.53443C12.9247 1.44431 12.9441 1.34761 12.9441 1.24994C12.9441 1.15228 12.9247 1.05558 12.8871 0.965458C12.8494 0.875338 12.7943 0.793587 12.7248 0.724945C12.6562 0.655465 12.5744 0.600301 12.4843 0.562652C12.3942 0.525002 12.2975 0.505615 12.1998 0.505615C12.1021 0.505615 12.0054 0.525002 11.9153 0.562652C11.8252 0.600301 11.7434 0.655465 11.6748 0.724945L4.2498 8.14995Z" fill="currentColor"/></svg>'
};

Px.Components.ColorPicker.ColorSlider = class ColorSlider extends Px.Components.Slider {
  get dataProperties() {
    return Object.assign(super.dataProperties, {
      handle_height: {std: 14},
      handle_width: {std: 6}
    });
  }
};
