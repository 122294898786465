Px.Editor.ElementBleedWarning = class ElementBleedWarning extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    let bleed_rect = '';
    if (this.showBleedWarning || this.showMarginWarning) {
      bleed_rect = Px.template`
        <rect x="0"
              y="0"
              width="${this.data.element.width}"
              height="${this.data.element.height}"
              stroke-width="${this.data.element.border || 0}"
              transform="${this.transformAttribute}"
              stroke="#ff0000"
              fill="#ff0000"
              opacity="0.25"
        />
      `;
    }
    return Px.template`
      <g pointer-events="none">
        <defs>
          <clipPath id="${this.clipPathId}">
            ${this.clipPathRects.map(vertices => {
              return Px.template`
                <rect x="${vertices[0]}"
                      y="${vertices[1]}"
                      width="${vertices[2]}"
                      height="${vertices[3]}"
                />
              `;
            })}
          </clipPath>
        </defs>
        <g clip-path="url(#${this.clipPathId})">
          ${bleed_rect}
        </g>
      </g>
    `;
  }

  constructor(props) {
    super(props);
    this._bleed_warning_timeout = null;
    this._margin_warning_timeout = null;
    this.deactivateBleedWarning = this.deactivateBleedWarning.bind(this);
    this.deactivateMarginWarning = this.deactivateMarginWarning.bind(this);

    const element = this.data.element;
    this.registerReaction(() => {
      const defer = element.is_resizing || element.is_grabbed || element.destroyed;
      // We cannot simply return this.isInBleedArea here because then bleed warnings would
      // not trigger if you move or resize an element while it's already bleeding,
      // it would only trigger if you pull it out of the bleed and then back again.
      // We have to make sure that any change to element's extreme coordinates is tracked.
      return defer ? null : this.extremeCoords;
    }, extreme_coords => {
      // TODO: Bleed warnings don't work in the admin (they don't work in the old editor either).
      if (extreme_coords && (element.move || Px.config.advanced_edit_mode)) {
        if (this.isInBleedArea) {
          this.activateBleedWarning();
        } else if (this.isInMarginArea) {
          this.activateMarginWarning();
        }
      }
    }, {
      name: 'Px.Editor.ElementBleedWarning::activateBleedWarning',
      compareStructural: true
    });
  }

  get dataProperties() {
    return {
      store: {required: true},
      scale: {required: true},
      element: {required: true}
    };
  }

  static get properties() {
    return {
      bleed_warning_enabled: {type: 'bool', std: false},
      margin_warning_enabled: {type: 'bool', std: false}
    }
  }

  static get computedProperties() {
    return {
      transformAttribute: function() {
        const M = this.data.element.transform_matrix;
        const attr = `matrix(${ M.join(',') })`;
        return attr;
      },
      showBleedWarning: function() {
        return this.state.bleed_warning_enabled && this.isInBleedArea;
      },
      showMarginWarning: function() {
        return this.state.margin_warning_enabled && this.isInMarginArea;
      },
      extremeCoords: function() {
        return this.data.element.absolute_extreme_coords;
      },
      // Returns true if any part of this element is in the bleed area.
      isInBleedArea: function() {
        const element = this.data.element;
        const page = element.page;
        const bleed = page.bleed;
        const gutter = page.gutter;
        if (bleed > 0) {
          const exs = this.extremeCoords;
          // There is no bleed on the inner side when using gutters.
          const check_left_side = !gutter || page.in_leftmost_position;
          const check_right_side = !gutter || page.in_rightmost_position;
          const bleeds_horizontally =
              (check_left_side && (exs.min_x < bleed && exs.max_x > 0)) ||
              (check_right_side && (exs.min_x < page.width && exs.max_x > page.width - bleed));
          const bleeds_vertically =
              (exs.min_y < bleed && exs.max_y > 0) ||
              (exs.min_y < page.height && exs.max_y > page.height - bleed);
          return bleeds_horizontally || bleeds_vertically;
        } else {
          return false;
        }
      },
      // Returns true if any part of this element is in the margin area.
      isInMarginArea: function() {
        const element = this.data.element;
        const page = element.page;
        const bleed = page.bleed;
        const margin = page.margin;
        if (margin > 0) {
          const exs = this.extremeCoords;
          const bleeds_horizontally =
              (exs.min_x < (bleed + margin) && exs.max_x > bleed) ||
              (exs.min_x < (page.width - bleed) && exs.max_x > page.width - bleed - margin);
          const bleeds_vertically =
              (exs.min_y < bleed + margin && exs.max_y > bleed) ||
              (exs.min_y < page.height - bleed && exs.max_y > page.height - bleed - margin);
          return bleeds_horizontally || bleeds_vertically;
        } else {
          return false;
        }
      },
      clipPathId: function() {
        return `px-bleedmask-${this._component_id}-${this.data.element.unique_id}`;
      },
      clipPathRects: function() {
        const page = this.data.element.page;
        const rects = [];
        if (page.width && page.height && (page.bleed || page.margin)) {
          rects.push([0, 0, page.width, page.bleed + page.margin]);
          rects.push([0, page.height - page.bleed - page.margin, page.width, page.bleed + page.margin]);
          rects.push([0, 0, page.bleed + page.margin, page.height]);
          rects.push([page.width - page.bleed - page.margin, 0, page.bleed + page.margin, page.height]);
        }
        return rects;
      }
    };
  }

  // -------
  // Private
  // -------

  activateBleedWarning() {
    if (this._bleed_warning_timeout) {
      clearTimeout(this._bleed_warning_timeout);
    }
    mobx.runInAction(() => {
      this.state.bleed_warning_enabled = true;
      const message = Px.t('Element is in bleed area and may be cropped.');
      if (message) {
        this.data.store.showNotification(message, 'warning');
      }
    });
    this._bleed_warning_timeout = setTimeout(this.deactivateBleedWarning, ElementBleedWarning.BLEED_WARNING_TIMEOUT_MS);
  }

  deactivateBleedWarning() {
    if (this._bleed_warning_timeout) {
      clearTimeout(this._bleed_warning_timeout);
      this._bleed_warning_timeout = null;
    }
    mobx.runInAction(() => {
      this.state.bleed_warning_enabled = false;
    });
  }

  activateMarginWarning() {
    if (this._margin_warning_timeout) {
      clearTimeout(this._margin_warning_timeout);
    }
    mobx.runInAction(() => {
      this.state.margin_warning_enabled = true;
      const message = Px.t('Element is outside of the safe area.');
      if (message) {
        this.data.store.showNotification(message, 'warning');
      }
    });
    this._margin_warning_timeout = setTimeout(this.deactivateMarginWarning, ElementBleedWarning.BLEED_WARNING_TIMEOUT_MS);
  }

  deactivateMarginWarning() {
    if (this._margin_warning_timeout) {
      clearTimeout(this._margin_warning_timeout);
      this._margin_warning_timeout = null;
    }
    mobx.runInAction(() => {
      this.state.margin_warning_enabled = false;
    });
  }
};

Px.Editor.ElementBleedWarning.BLEED_WARNING_TIMEOUT_MS = 750;
