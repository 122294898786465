Px.Editor.PageNavigation = class PageNavigation extends Px.Editor.BaseComponent {

  template() {
    const store = this.data.store;
    const r = this.renderChild;
    return Px.template`
      <div class="px-page-navigation">
        <div class="px-page-sets">
          ${store.project.editor_page_sets.map(set => {
            const is_selected = store.selected_set === set;
            return r(Px.Editor.PageNavigation.PageSet, `set-${set.id}`, {set: set, selected: is_selected});
          })}
        </div>
        ${Px.if(this.showAddAndRemovePageButtons, () => {
          return Px.template`
            <div class="px-actions">
              <button data-onclick="addPages"
                      ${this.addPagesButtonEnabled ? '' : 'disabled'}
                      data-px-tooltip="${this.addPagesButtonTooltip}">
                ${Px.raw(PageNavigation.icons.add_pages)}
              </button>
              <button data-onclick="removePages"
                      ${this.removePagesEnabled ? '' : 'disabled'}
                      data-px-tooltip="${this.removePagesButtonTooltip}">
                ${Px.raw(PageNavigation.icons.remove_pages)}
              </button>
            </div>
          `;
        })}
        ${Px.if(this.canOrganizePages, () => {
          return Px.template`
            <button class="px-organize-pages-button"
                    data-onclick="organizePages">
              ${Px.raw(Px.Editor.Main.icons.organize_pages)}
              <span>${Px.t('Organize pages')}</span>
              ${Px.raw(PageNavigation.icons.up_arrow)}
            </button>
          `;
        })}
      </div>
    `;
  }

  constructor(props) {
    super(props);
    // Set up a reaction to automatically scroll the page navigation panel if selected page
    // is scrolled outside of the visible area.
    this.registerReaction(() => {
      return this.data.store.selected_set && this.data.store.selected_set.id;
    }, this.scrollSelectedSetIntoView.bind(this), {
      name: 'Px.Editor.PageNavigation::navigationScrollReaction'
    });
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      showAddAndRemovePageButtons: function() {
        const theme_store = this.data.store.theme;
        if (this.data.store.resource_type !== 'book') {
          return false;
        }
        return theme_store.page_increments && (theme_store.max_pages !== theme_store.min_pages);
      },
      canOrganizePages: function() {
        if (this.data.store.resource_type !== 'book') {
          return false;
        }
        return this.data.store.theme.grow_set_definition !== null;
      },
      canAddPagesAtSelectedSet: function() {
        const store = this.data.store;
        if (!store.selected_set) {
          return false;
        }
        const selected_set_grows = store.selected_set.grow;
        const next_set_grows = store.project.last_fixed_start_set === store.selected_set;
        return selected_set_grows || next_set_grows;
      },
      canRemovePagesAtSelectedSet: function() {
        const store = this.data.store;
        if (!store.selected_set) {
          return false;
        }
        return store.selected_set.grow && store.selected_set.pages.length === store.theme.page_increments;
      },
      canRemoveIndividualSets: function() {
        const theme_store = this.data.store.theme;
        const grow_set_def = theme_store.grow_set_definition;
        return grow_set_def && grow_set_def.pages.length === theme_store.page_increments;
      },
      addPagesButtonEnabled: function() {
        return this.data.store.project.can_add_pages && this.canAddPagesAtSelectedSet;
      },
      removePagesEnabled: function() {
        return this.data.store.project.can_delete_pages &&
          (this.canRemovePagesAtSelectedSet || !this.canRemoveIndividualSets);
      },
      addPagesButtonTooltip: function() {
        if (!this.data.store.project.can_add_pages) {
          return Px.t('Cannot add more pages');
        } else if (!this.canAddPagesAtSelectedSet) {
          return Px.t('Cannot add pages at selected position');
        } else {
          return Px.t('Add pages');
        }
      },
      removePagesButtonTooltip: function() {
        if (!this.data.store.project.can_delete_pages) {
          return Px.t('Cannot remove any more pages');
        } else if (this.canRemoveIndividualSets && !this.canRemovePagesAtSelectedSet) {
          return Px.t('Cannot remove selected page');
        } else {
          return Px.t('Remove pages');
        }
      }
    };
  }

  // -------
  // Private
  // -------

  scrollSelectedSetIntoView(set_id) {
    if (this.__scroll_raf) {
      cancelAnimationFrame(this.__scroll_raf);
    }
    this.__scroll_raf = requestAnimationFrame(() => {
      if (set_id && this.dom_node) {
        const node = $j(this.dom_node).find(`.px-page-set[data-set-id="${set_id}"]`);
        if (node.length) {
          const container = node.parent();
          // Stop any currently running animations.
          container.stop(true);
          const container_width = container.width();
          const container_scroll = container.scrollLeft();
          const position = node.position().left;
          const width = node.width();
          // If less than `min_visible` pixels of the set element are currently visible, scroll it automatically.
          const min_visible = 20;
          // TODO: Use node.scrollIntoView({behavior: 'smooth'}) when browser support gets better.
          if (position > container_width - min_visible) {
            container.animate({scrollLeft: container_scroll + position - container_width + width + min_visible});
          } else if (position < - width + min_visible) {
            container.animate({scrollLeft: container_scroll + position - min_visible});
          }
        }
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  organizePages(evt) {
    this.data.store.ui.setMainView('organize-pages');
  }

  addPages(evt) {
    if (this.addPagesButtonEnabled) {
      const store = this.data.store;
      let position = store.selected_set.position + 1;
      let editor_position = store.selected_set.editor_position + 1;
      if (editor_position === store.project.editor_page_sets.length) {
        if (store.selected_set.pages.length < store.theme.grow_set_definition.pages.length) {
          // If we're trying to add after the last set, but the last set is not full yet,
          // set the position to the last set instead.
          position = store.selected_set.position;
          editor_position = store.selected_set.editor_position;
        }
      }
      mobx.runInAction(() => {
        store.addPages(position);
        store.selectSet(store.project.editor_page_sets[editor_position]);
      });
    }
  }

  removePages(evt) {
    if (this.removePagesEnabled) {
      if (this.canRemovePagesAtSelectedSet) {
        const store = this.data.store;
        mobx.runInAction(() => {
          const previous_set = store.project.editor_page_sets[store.selected_set.editor_position - 1];
          store.deleteSets([store.selected_set]);
          if (previous_set) {
            store.selectSet(previous_set);
          }
        });
      } else {
        this.organizePages(evt);
      }
    }
  }

};

Px.Editor.PageNavigation.icons = {
  up_arrow: '<svg width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M0.999999 8L6 3L11 8L10.1125 8.8875L6 4.775L1.8875 8.8875L0.999999 8Z" fill="currentColor"/></svg>',
  add_pages: '<svg width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M6.33301 10.3333H7.66634V7.66659H10.333V6.33325H7.66634V3.66659H6.33301V6.33325H3.66634V7.66659H6.33301V10.3333ZM6.99967 13.6666C6.07745 13.6666 5.21079 13.4915 4.39967 13.1413C3.58856 12.7915 2.88301 12.3166 2.28301 11.7166C1.68301 11.1166 1.20812 10.411 0.858341 9.59992C0.508119 8.78881 0.333008 7.92214 0.333008 6.99992C0.333008 6.0777 0.508119 5.21103 0.858341 4.39992C1.20812 3.58881 1.68301 2.88325 2.28301 2.28325C2.88301 1.68325 3.58856 1.20814 4.39967 0.857919C5.21079 0.508141 6.07745 0.333252 6.99967 0.333252C7.9219 0.333252 8.78856 0.508141 9.59967 0.857919C10.4108 1.20814 11.1163 1.68325 11.7163 2.28325C12.3163 2.88325 12.7912 3.58881 13.141 4.39992C13.4912 5.21103 13.6663 6.0777 13.6663 6.99992C13.6663 7.92214 13.4912 8.78881 13.141 9.59992C12.7912 10.411 12.3163 11.1166 11.7163 11.7166C11.1163 12.3166 10.4108 12.7915 9.59967 13.1413C8.78856 13.4915 7.9219 13.6666 6.99967 13.6666Z" fill="currentColor"/></svg>',
  remove_pages: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.52 1.33325C9.79984 1.33332 10.0726 1.42144 10.2995 1.58512C10.5265 1.74881 10.6962 1.97975 10.7847 2.24525L11.1467 3.33325H13.3333C13.5101 3.33325 13.6797 3.40349 13.8047 3.52851C13.9298 3.65354 14 3.82311 14 3.99992C14 4.17673 13.9298 4.3463 13.8047 4.47132C13.6797 4.59635 13.5101 4.66658 13.3333 4.66658L13.3313 4.71392L12.7533 12.8093C12.7173 13.3137 12.4915 13.7857 12.1214 14.1303C11.7513 14.4749 11.2644 14.6665 10.7587 14.6666H5.24133C4.73564 14.6665 4.24874 14.4749 3.87864 14.1303C3.50855 13.7857 3.28274 13.3137 3.24667 12.8093L2.66867 4.71325C2.66746 4.69772 2.66679 4.68216 2.66667 4.66658C2.48986 4.66658 2.32029 4.59635 2.19526 4.47132C2.07024 4.3463 2 4.17673 2 3.99992C2 3.82311 2.07024 3.65354 2.19526 3.52851C2.32029 3.40349 2.48986 3.33325 2.66667 3.33325H4.85333L5.21533 2.24525C5.3038 1.97964 5.47362 1.74862 5.70073 1.58493C5.92784 1.42124 6.20071 1.33318 6.48067 1.33325H9.52ZM6 6.66658C5.83671 6.66661 5.67911 6.72656 5.55709 6.83506C5.43506 6.94357 5.3571 7.09308 5.338 7.25525L5.33333 7.33325V11.3333C5.33352 11.5032 5.39859 11.6666 5.51523 11.7902C5.63188 11.9137 5.7913 11.9881 5.96093 11.998C6.13056 12.008 6.29759 11.9528 6.42789 11.8437C6.55819 11.7347 6.64193 11.58 6.662 11.4113L6.66667 11.3333V7.33325C6.66667 7.15644 6.59643 6.98687 6.4714 6.86185C6.34638 6.73682 6.17681 6.66658 6 6.66658ZM10 6.66658C9.82319 6.66658 9.65362 6.73682 9.5286 6.86185C9.40357 6.98687 9.33333 7.15644 9.33333 7.33325V11.3333C9.33333 11.5101 9.40357 11.6796 9.5286 11.8047C9.65362 11.9297 9.82319 11.9999 10 11.9999C10.1768 11.9999 10.3464 11.9297 10.4714 11.8047C10.5964 11.6796 10.6667 11.5101 10.6667 11.3333V7.33325C10.6667 7.15644 10.5964 6.98687 10.4714 6.86185C10.3464 6.73682 10.1768 6.66658 10 6.66658ZM9.52 2.66659H6.48L6.258 3.33325H9.742L9.52 2.66659Z" fill="currentColor"/></svg>'
};


Px.Editor.PageNavigation.PageSet = class PageSet extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-page-set"
           data-set-id="${this.data.set.id}"
           data-selected="${this.data.selected}"
           data-onclick="selectPageSet">
        <div class="px-page-thumbs">
          ${this.data.set.pages.map(page => {
            return this.renderChild(Px.Editor.Page, `page-${page.id}`, this.pageProps(page));
          })}
        </div>
        <div class="px-page-captions">
          ${Px.if(this.data.set.left_caption, () => {
            return Px.template`
              <div class="px-page-caption">${this.data.set.left_caption}</div>
            `;
          })}
          ${Px.if(this.data.set.center_caption, () => {
            return Px.template`
              <div class="px-page-caption">${this.data.set.center_caption}</div>
            `;
          })}
          ${Px.if(this.data.set.right_caption, () => {
            return Px.template`
              <div class="px-page-caption">${this.data.set.right_caption}</div>
            `;
          })}
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.on('mount', () => {
      this._intersection_observer = new IntersectionObserver(entries => {
        this.state.is_visible = entries[0].isIntersecting;
      }, {root: this.dom_node.parentElement});
      this._intersection_observer.observe(this.dom_node);
    });

    this.on('update', () => {
      if (this._intersection_observer && this.dom_node) {
        this._intersection_observer.disconnect();
        this._intersection_observer.observe(this.dom_node);
      }
    });
  }

  destroy() {
    super.destroy();

    if (this._intersection_observer) {
      this._intersection_observer.disconnect();
    }
  }

  get dataProperties() {
    return {
      set: {required: true},
      selected: {std: false},
      store: {required: true}
    };
  }

  static get properties() {
    return {
      is_visible: {type: 'bool', std: false}
    };
  }

  pageProps(page) {
    const height = 96;
    const scale = page.height / height;
    const width = scale === 0 ? 0 : page.width / scale;
    return {
      page: page,
      available_width: width,
      available_height: height,
      preview_mode: true,
      render_controls: false,
      render_content: this.state.is_visible
    };
  }

  // --------------
  // Event handlers
  // --------------

  selectPageSet(evt) {
    this.data.store.selectSet(this.data.set);
  }

};
