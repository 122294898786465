Px.Editor.CalendarEditAccordion = class CalendarEditAccordion extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-tab-panel-accordion-wrapper">
        ${this.renderChild(Px.Components.Accordion, 'accordion', this.accordionProperties)}
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.onItemExpanded = this.onItemExpanded.bind(this);
  }

  get dataProperties() {
    return {
      calendar: {required: true},
      store: {required: true}
    };
  }

  static get properties() {
    return {
      expanded_item_id: {type: 'str', std: 'standard'}
    }
  }

  static get computedProperties() {
    return {
      accordionProperties: function() {
        const store = this.data.store;
        const calendar = this.data.calendar;
        const items = [];

        items.push({
          id: 'standard',
          title: Px.t('Standard'),
          component: Px.Editor.CalendarEditPanel,
          data: {store: store, calendar: calendar}
        });

        items.push({
          id: 'header',
          title: Px.t('Header'),
          component: Px.Editor.CalendarEditPanel.HeaderEditPanel,
          data: {store: store, calendar: calendar}
        });

        if (calendar.week_numbers) {
          items.push({
            id: 'sidebar',
            title: Px.t('Sidebar'),
            component: Px.Editor.CalendarEditPanel.SidebarEditPanel,
            data: {store: store, calendar: calendar}
          });
        }

        items.push({
          id: 'grid-lines',
          title: Px.t('Grid Lines'),
          component: Px.Editor.CalendarEditPanel.GridLinesEditPanel,
          data: {store: store, calendar: calendar}
        });

        items.push({
          id: 'date-text',
          title: Px.t('Date Text'),
          component: Px.Editor.CalendarEditPanel.TextEditPanel,
          data: {store: store, calendar: calendar, tag: 'date-cell'}
        });

        items.push({
          id: 'header-text',
          title: Px.t('Header Text'),
          component: Px.Editor.CalendarEditPanel.TextEditPanel,
          data: {store: store, calendar: calendar, tag: 'header-cell'}
        });

        if (calendar.week_numbers) {
          items.push({
            id: 'week-number-text',
            title: Px.t('Week Number Text'),
            component: Px.Editor.CalendarEditPanel.TextEditPanel,
            data: {store: store, calendar: calendar, tag: 'week-number-cell'}
          });
        }

        if (calendar.captions) {
          items.push({
            id: 'caption-text',
            title: Px.t('Caption Text'),
            component: Px.Editor.CalendarEditPanel.TextEditPanel,
            data: {store: store, calendar: calendar, tag: 'caption-cell'}
          });
        }
        items.push({
          id: 'admin',
          title: Px.t('Admin'),
          component: Px.Editor.AdvancedEditPanel,
          data: {store: store, element: calendar}
        });

        return {
          items: items,
          expanded_item_id: this.state.expanded_item_id,
          onItemExpanded: this.onItemExpanded
        };
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  onItemExpanded(item_id) {
    this.state.expanded_item_id = item_id;
  }

};
