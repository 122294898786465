Px.Editor.SpinnerModal = class SpinnerModal extends Px.Components.BaseModal {

  get content() {
    return Px.template`
      ${this.renderChild(Px.Components.Spinner, 'spinner')}
      ${Px.if(this.data.message, () => {
        return Px.template`
          <p>${this.data.message}</p>
        `;
      })}
    `;
  }

  get css_class() {
    return `${super.css_class} px-spinner-modal`;
  }

  constructor(props) {
    super(props);
    this.close_on_background_click = false;
    this.render_close_button = false;
  }

  get dataProperties() {
    return {
      message: {std: null}
    };
  }

};
