Px.Components.Slider = class Slider extends Px.Component {

  template() {
    return Px.template`
      <div class="px-control px-slider" data-onmousedown="onSliderMousedown" data-ontouchstart="onSliderMousedown">
        <div class="px-slider-strip">
          <a class="px-handle" style="${this.handleStyle}"></a>
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      value: {std: 0},
      min: {std: 0},
      max: {std: 100},
      step: {std: 1},  // for no steps set step=0
      handle_width: {std: 12},
      handle_height: {std: 12},
      onNewValue: {std: function(new_value) {
        this.state.internal_value = new_value;
      }},
      onBeforeDrag: {std: function() {}},
      onAfterDrag: {std: function() {}}
    };
  }

  static get properties() {
    return {
      internal_value: {type: 'float', std: null}
    };
  }

  static get computedProperties() {
    return {
      selectedValue: function() {
        if (this.state.internal_value !== null) {
          return this.state.internal_value;
        }
        return this.data.value;
      },
      handlePosition: function() {
        const range = this.data.max - this.data.min;
        const val = this.selectedValue;
        const percentage = (val - this.data.min) / range;
        // We use a calc trick to correcly position the handle. Using percentage alone would not work correctly for
        // any value other than 0% - for example at left:100% you want the right edge of the handle to be at 100%,
        // not the left one.
        // This produces a calc expression such as: calc(0% - 23px*0.0), calc(50% - 23px*0.5), etc.
        return `calc(${(100*percentage).toFixed(2)}% - ${this.handleWidth}px*${percentage.toFixed(2)})`;
      },
      handleWidth: function() {
        return this.data.handle_width;
      },
      handleHeight: function() {
        return this.data.handle_height;
      },
      handleStyle: function() {
        const height = this.handleHeight;
        const width = this.handleWidth;
        const left = this.handlePosition;
        return `height: ${height}px; width: ${width}px; left: ${left};`;
      }
    };
  }

  setValue(val) {
    if (val > this.data.max) {
      val = this.data.max;
    }
    if (val < this.data.min) {
      val = this.data.min;
    }

    // "round" to nearest step point.
    const rem = this.data.step > 0 ? (Math.abs(val) % this.data.step) : 0;
    const sign = val > 0 ? 1 : -1;
    if (rem > 0) {
      if (rem >= this.data.step/2) {
        // round up
        val = sign * (Math.abs(val) - rem + this.data.step);
      } else {
        // round down
        val = sign * (Math.abs(val) - rem);
      }
    }
    if (this.selectedValue !== val) {
      this.data.onNewValue.call(this, val);
    }
  }

  // --------------
  // Event handlers
  // --------------

  onSliderMousedown(evt) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }
    evt.preventDefault();  // prevent native browser drag.
    evt.stopPropagation();

    const doc = $j(document);
    const slider = $j(this.dom_node);
    const handle = slider.find('.px-handle');
    const pageX = 'pageX' in evt ? evt.pageX : evt.originalEvent.targetTouches[0].pageX;

    this._slider_pos = {
      left: slider.offset().left,
      width: slider.width()
    };

    const scoped_mousemove = `mousemove.${this._component_id}, touchmove.${this._component_id}`;
    const scoped_mouseup = `mouseup.${this._component_id}, touchend.${this._component_id}`;

    const setValueFromEvent = evt => {
      const pageX = 'pageX' in evt ? evt.pageX : evt.originalEvent.targetTouches[0].pageX;
      const x = pageX;
      const min_x = this._slider_pos.left + this.handleWidth/2;
      const max_x = min_x + this._slider_pos.width - this.handleWidth;
      if (x < min_x) {
        this.setValue(this.data.min);
      } else if (x > max_x)  {
        this.setValue(this.data.max);
      } else {
        const percent = (x - min_x) / (max_x - min_x);
        const range = this.data.max - this.data.min;
        this.setValue(this.data.min + range*percent);
      }
    };

    this.data.onBeforeDrag();
    setValueFromEvent(evt);

    var raf_id = null;

    doc.on(scoped_mousemove, evt => {
      evt.preventDefault();
      if (!raf_id) {
        raf_id = requestAnimationFrame(() => {
          setValueFromEvent(evt);
          raf_id = null;
        });
      }
    });

    doc.on(scoped_mouseup, evt => {
      doc.off(scoped_mousemove);
      doc.off(scoped_mouseup);
      cancelAnimationFrame(raf_id);
      setValueFromEvent(evt);
      this.data.onAfterDrag();
    });
  }
};
