Px.Editor.ThemeGalleryStore = class ThemeGalleryStore extends Px.Editor.BaseUploadGalleryStore {

  constructor(theme_id, image_store) {
    super(image_store);
    this.theme_id = theme_id;
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      gallery_url: function() {
        return  `/v1/themes/${this.theme_id}/resources.json`;
      }
    });
  }

};
