Px.Editor.DragAndDropToPageMixin = Base => class extends Base {

  startDrag(evt, $element, onDrop) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }
    evt.preventDefault();

    const coords = Px.Util.pointerCoords(evt);
    const ox = coords.pageX;
    const oy = coords.pageY;
    const $doc = $j(document);
    const offset = $element.offset();
    let $dragged_element = null;

    let raf_id = null;
    const dragMove = function(evt) {
      if (!$dragged_element) {
        $dragged_element = $element.clone().addClass('px-dragged');
        $dragged_element.css({
          display: 'block',
          position: 'absolute',
          width: $element.width() + 'px',
          height: $element.height() + 'px',
          left: offset.left,
          top: offset.top
        });
        $dragged_element.appendTo('body');
      }
      cancelAnimationFrame(raf_id);
      raf_id = requestAnimationFrame(() => {
        const coords = Px.Util.pointerCoords(evt);
        $dragged_element.css({
          left: offset.left + (coords.pageX - ox),
          top: offset.top + (coords.pageY - oy)
        });
      });
    };

    const dragEnd = function(evt) {
      cancelAnimationFrame(raf_id);
      $doc.off('mousemove touchmove', dragMove);
      $doc.off('mouseup touchend touchcancel', dragEnd);
      if ($dragged_element) {
        $dragged_element.remove();
        onDrop(evt);
      }
    };

    $doc.on('mouseup touchend touchcancel', dragEnd);
    $doc.on('mousemove touchmove', dragMove);
  }

  getDropTarget(evt, opts) {
    opts = opts || {};
    const store = this.data.store;
    // Let's see where we dropped the image.
    const coords = Px.Util.pointerCoords(evt);
    const clientX = coords.clientX;
    const clientY = coords.clientY;
    const elements = document.elementsFromPoint(clientX, clientY);
    let drop_target = null;
    for (const element of elements) {
      const image_node = element.closest('.px-image-element');
      if (image_node && opts.can_drop_on_existing_image !== false) {
        const image = store.project.getElementByUniqueId(image_node.getAttribute('data-element-id'));
        if (image.edit && image.replace && !(store.galleries.clipart.images.includes(image.id))) {
          drop_target = image;
          break;
        }
      } else if (element.matches('.px-page-display .px-page')) {
        const page = store.project.getPageById(element.getAttribute('data-page-id'));
        if (page.edit) {
          drop_target = page;
        } else {
          store.showNotification(Px.t('Cannot add images to this page.'), 'warning');
        }
        break;
      } else if (!element.closest('.px-page-display .px-page')) {
        // Element was dropped outside the page area, so ignore.
        break;
      }
    }
    return drop_target;
  }

  _dropImageToPlaceholder(image_id, image) {
    this.withUndo('replace image', () => {
      image.update({id: image_id, left: 0, top: 0, zoom: 0, placeholder: false});
    });
  }

  _dropImageToPage(evt, image_id, page, opts) {
    opts = opts || {};
    const store = this.data.store;
    const image_model = store.images.get(image_id);
    const element = $j(this.dom_node).closest('.px-editor').find(`.px-page[data-page-id="${page.id}"]`);
    const offset = element.offset();
    const page_scale = element.width() / page.width;
    // Make diagonal of the image equal 1/3 of the diagonal of the page.
    const aspect_ratio = image_model.aspect_ratio;
    const diag = Math.sqrt(page.width*page.width + page.height*page.height);
    const height = diag / (3 * Math.sqrt(aspect_ratio*aspect_ratio + 1));
    const width = height * aspect_ratio;
    const coords = Px.Util.pointerCoords(evt);
    const x = (coords.pageX - offset.left) / page_scale - width/2;
    const y = (coords.pageY - offset.top) / page_scale - height/2;
    const defaults = {
      id: image_id,
      x: x,
      y: y,
      width: width,
      height: height
    };
    const image_attributes = _.extend(defaults, opts.image_attributes);
    const image = store.addImage(image_attributes, page);
    store.selectElement(image);
    return image;
  }

  dropImageToTarget(evt, image_id, opts) {
    opts = opts || {};
    const drop_target = this.getDropTarget(evt, opts);
    if (drop_target) {
      mobx.runInAction(() => {
        if (opts.onBeforeDrop) {
          opts.onBeforeDrop(image_id);
        }
        let image = null;
        if (drop_target instanceof Px.Editor.ImageElementModel) {
          image = drop_target;
          this._dropImageToPlaceholder(image_id, image);
        } else if (drop_target instanceof Px.Editor.PageModel) {
          image = this._dropImageToPage(evt, image_id, drop_target, opts);
        }
        if (image && opts.onAfterDrop) {
          opts.onAfterDrop(image);
        }
      });
    }
  }

  startImageToPageDrag(evt, opts) {
    opts = opts || {};
    evt.preventDefault();
    const store = this.data.store;
    let image_element = evt.currentTarget;
    while (image_element && !image_element.classList.contains('px-image')) {
      image_element = image_element.parentElement;
    }
    const image_id = image_element.getAttribute('data-image-id');
    const img = $j(image_element).find('img');

    this.startDrag(evt, img, evt => {
      this.dropImageToTarget(evt, image_id, opts);
    });
  }

};
