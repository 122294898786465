Px.Editor.BackgroundsPanel = class BackgroundsPanel extends Px.Editor.BaseComponent {
  template() {
    const page = this.data.store.selected_page;
    return Px.template`
      <div class="px-tab-content-panel px-backgrounds-panel">
        <div class="px-panel-top-section">
          <h1>${Px.t('Backgrounds:')}</h1>
        </div>

        ${Px.if(this.taggedBackgrounds.length > 0, () => {
          return this.renderChild(Px.Editor.TaggedItemsPanel, 'tagged-items', this.taggedItemsPanelProps)
        }).else(() => {
          return Px.template`
            <p class="px-no-items-text">
              ${Px.t('No backgrounds available for this page.')}
            </p>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      colorsTag: function() {
        return Px.t('Colors');
      },
      untaggedImagesTag: function() {
        return Px.t('Images');
      },
      taggedItemsPanelProps: function() {
        return {
          items: this.taggedBackgrounds,
          list_component: Px.Editor.BackgroundsPanel.BackgroundList
        };
      },
      hasCustomTags: function() {
        return this.data.store.backgrounds.tagged_images.some(image => {
          return image.tags.length > 0 && this.applicableBackgroundImageIds.includes(image.id);
        });
      },
      applicableBackgroundImageIds: function() {
        const store = this.data.store;
        const image_ids = store.backgrounds.images;
        const images = image_ids.map(id => store.images.get(id));
        return this.filterApplicableBackgrounds(images).map(image => image.id);
      },
      taggedBackgrounds: function() {
        const store = this.data.store;
        const background_store = store.backgrounds;
        const background_images = [];
        const background_colors = [];
        if (!(store.selected_page && store.selected_page.edit)) {
          return [];
        }
        background_store.tagged_images.forEach(image => {
          if (this.applicableBackgroundImageIds.includes(image.id)) {
            // Note we convert observable to plain JS because we might have to update its tags below.
            background_images.push(mobx.toJS(image));
          }
        });
        if (Px.config.background_colors) {
          if (!this.hasCustomTags) {
            // If images are not tagged, tag them all with "Images" so that they contrast nicely with "Colors".
            background_images.forEach(item => item.tags = [this.untaggedImagesTag]);
          }
          const color_tags = background_images.length > 0 ? [this.colorsTag] : [];
          if (Px.config.background_color_palette_id) {
            const palette = this.data.store.color_palettes.get(Px.config.background_color_palette_id);
            if (palette) {
              palette.colors.forEach(color => {
                background_colors.push({
                  type: 'color',
                  color: color.value,
                  name: color.name,
                  tags: color_tags
                });
              });
            }
          } else {
            background_store.colors.forEach(color => {
              background_colors.push({
                type: 'color',
                color: color,
                tags: color_tags
              })
            });
            background_colors.push({
              type: 'add-color-button',
              tags: color_tags
            });
          }
        }
        return background_colors.concat(background_images);
      }
    };
  }

  static backgroundImageApplicableToPage(image, page) {
    if (page.bgfill) {
      // We don't care about aspect ratios when using the 'full page background' option.
      return true;
    } else {
      const aspect_ratio_diff = (image.width/image.height) / (page.width/page.height);
      return Math.abs(1 - aspect_ratio_diff) <= 0.05;
    }
  }

  // --------------
  // Event handlers
  // --------------

  clearBackground(evt) {
    this.withUndo('clear background', function() {
      this.data.store.selected_page.update({bgcolor: null, src: null});
    });
  }

  // -------
  // Private
  // -------

  filterApplicableBackgrounds(images) {
    return images.filter(image => {
      return BackgroundsPanel.backgroundImageApplicableToPage(image, this.data.store.selected_page);
    });
  }

};

Px.Editor.BackgroundsPanel.icons = {
  tick_mark: '<svg class="px-tick-mark" width="16" height="16" viewBox="0 0 16 16"><polyline stroke="currentColor" stroke-width="2" fill="none" points="4,9 7,11 12,4" /></svg>'
}

Px.Editor.BackgroundsPanel.BackgroundList = class BackgroundsList extends Px.Editor.BaseComponent {
  template() {
    const selected_page = this.data.store.selected_page;
    return Px.template`
      <div class="px-backgrounds">
        ${this.data.items.map(item => {
          if (item.type === 'add-color-button') {
            return Px.template`
              <div class="px-background px-color px-add-color-button"
                  data-onclick="openColorModal">
                <div class="px-thumbnail">
                  <svg viewBox="0 0 10 10">
                    <line x1="0" y1="5" x2="10" y2="5" stroke="currentColor" stroke-width="1"/>
                    <line x1="5" y1="0" x2="5" y2="10" stroke="currentColor" stroke-width="1"/>
                  </svg>
                </div>
                <div class="px-label">
                  ${Px.t('Add color')}
                </div>
              </div>
            `;
          } else if (item.type === 'color') {
            const display_name = item.name || this.colorDisplayName(item.color);
            const is_selected = selected_page && selected_page.bgcolor === item.color;
            return Px.template`
              <div class="px-background px-color"
                   data-selected="${is_selected}"
                   data-onclick="selectBackgroundColor"
                   data-color="${item.color}"
                   data-px-tooltip="${display_name}"
                   data-color-scheme="${Px.Util.isColorDark(item.color) ? 'dark': 'light'}">
                <div class="px-thumbnail">
                  <img class="px-background-img"
                       src="${this.backgroundColorDataUri(item.color)}"
                  />
                  ${is_selected ? Px.raw(Px.Editor.BackgroundsPanel.icons.tick_mark) : ''}
                </div>
                <div class="px-label">
                  ${display_name}
                </div>
              </div>
            `;
          } else {
            const image = this.data.store.images.get(item.id);
            const is_selected = selected_page && selected_page.src === image.id;
            return Px.template`
              <div class="px-background"
                   data-selected="${is_selected}"
                   data-onclick="selectBackgroundImage"
                   data-image-id="${image.id}">
                <img class="px-background-img"
                     src="${image.src({size: 150})}"
                     loading="lazy"
                     alt="${image.filename}"
                     title="${image.filename}"
                />
                ${is_selected ? Px.raw(Px.Editor.BaseGalleryPanel.icons.tick_mark) : ''}
              </div>
            `;
          }
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true},
      items: {required: true}
    }
  }

  // --------------
  // Event handlers
  // --------------

  openColorModal(evt) {
    const store = this.data.store;
    this.makeModal(Px.Editor.ColorPickerModal, {
      store: store,
      palette_id: Px.config.background_color_palette_id,
      button_text: Px.t('Save Background'),
      usage_context: 'backgrounds',
      color_type_switch: store.color_picker_type_switch_enabled,
      onColorSelected: this.addColorBackground.bind(this)
    });
  }

  addColorBackground(color) {
    const store = this.data.store;
    mobx.runInAction(() => {
      store.backgrounds.addColor(color);
      this.withUndo('set background color', function() {
        store.selected_page.update({bgcolor: color, src: null});
      });
    });
  }

  selectBackgroundColor(evt) {
    const color = evt.currentTarget.getAttribute('data-color');
    const page = this.data.store.selected_page;

    if (page.bgcolor === color) {
      this.withUndo('remove background color', function() {
        page.update({bgcolor: null, src: null});
      });
    }else {
      this.withUndo('set background color', function() {
        page.update({bgcolor: color, src: null});
      });
    }
  }

  selectBackgroundImage(evt) {
    const image_id = evt.currentTarget.getAttribute('data-image-id');
    const page = this.data.store.selected_page;

    const image = this.data.store.images.get(image_id);
    if (page.src === image.id) {
      this.withUndo('remove background image', () => {
        page.update({src: null, bgcolor: null});
      });
    } else {
      this.withUndo('set background image', () => {
        page.update({src: image.id, bgcolor: null});
      });
    }
  }

  // -------
  // Private
  // -------

  colorDisplayName(color_str) {
    if (color_str.slice(0, 4) === 'cmyk') {
      const cmyk = Px.Util.parseCmykString(color_str);
      return `CMYK(${cmyk.c}, ${cmyk.m}, ${cmyk.y}, cmyk.k)`;
    } else {
      return color_str;
    }
  }

  backgroundColorDataUri(color) {
    const fill = Px.Util.colorForDisplay(color).replace('#', '%23');
    const rect = `<rect x="0" y="0" width="100%" height="100%" fill="${fill}"></rect>`;
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 4 4">${rect}</svg>`;
    return `data:image/svg+xml,${svg}`;
  }

};
