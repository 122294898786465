// The AlbumGalleryPanel merges UserGalleryPanel with social galleries.
Px.Editor.AlbumGalleryPanel = class AlbumGalleryPanel extends Px.Editor.UserGalleryPanel {

  template() {
    const r = this.renderChild;
    const social_gallery = this.selectedSocialGallery;
    if (social_gallery) {
      return Px.template`
        <div style="display:contents">
          ${r(social_gallery.component, `social-gallery-${social_gallery.id}`, this.socialGalleryComponentData)}
        </div>
      `;
    } else {
      return super.template();
    }
  }

  constructor(data) {
    super(data);
    this.onEmbeddedGalleryExit = this.onEmbeddedGalleryExit.bind(this);
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      availableSocialGalleries: function() {
        const store = this.data.store;
        const gallery_store = store.galleries;
        const gallery_definitions = {
          instagram: {
            id: 'instagram',
            title: Px.t('Instagram'),
            component: Px.Editor.InstagramGalleryPanel,
            init: function() {
              gallery_store.instagram.init();
            }
          },
          facebook: {
            id: 'facebook',
            title: Px.t('Facebook'),
            component: Px.Editor.FacebookGalleryPanel,
            init: function() {
              gallery_store.facebook.init();
            }
          },
          dropbox: {
            id: 'dropbox',
            title: Px.t('Dropbox'),
            component: Px.Editor.DropboxGalleryPanel,
            init: function() {
              gallery_store.dropbox.init();
            }
          }
        };
        if (Px.urlQuery().groups) {
          gallery_definitions.groups = {
            id: 'groups',
            title: Px.t('Group Galleries'),
            component: Px.Editor.GroupGalleryPanel,
            init: function() {
              gallery_store.groups.init();
            }
          };
        }
        const galleries = [];
        store.image_sources.forEach(id => {
          const gallery = gallery_definitions[id];
          if (gallery) {
            galleries.push(gallery);
          }
        });
        return galleries;
      },
      socialGalleryComponentData: function() {
        return {
          store: this.data.store,
          onExit: this.onEmbeddedGalleryExit,
          is_embedded: true
        };
      },
      selectedSocialGallery: function() {
        return this.getSocialGalleryById(this.state.selected_gallery_id);
      },
      items: function() {
        const selected_gallery_id = this.state.selected_gallery_id;
        if (selected_gallery_id) {
          return this.galleryStore.galleryImages(selected_gallery_id);
        } else {
          let galleries = this.availableSocialGalleries.map(gallery => {
            return this.buildSocialGalleryItem(gallery);
          });
          if (this.data.store.image_sources.includes('galleries')) {
            galleries = galleries.concat(Array.from(this.galleryStore.galleries.values()));
          }
          return galleries;
        }
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  onGalleryClick(evt) {
    const gallery_id = evt.currentTarget.getAttribute('data-gallery-id');
    if (this.getSocialGalleryById(gallery_id)) {
      Px.Editor.BaseGalleryPanel.prototype.onGalleryClick.call(this, evt);
      this.selectedSocialGallery.init();
    } else {
      Px.Editor.UserGalleryPanel.prototype.onGalleryClick.call(this, evt);
    }
  }

  onEmbeddedGalleryExit() {
    this.state.selected_gallery_id = null;
  }

  // -------
  // Private
  // -------

  getSocialGalleryById(gallery_id) {
    let gallery = null;
    if (gallery_id) {
      gallery = this.availableSocialGalleries.find(gallery => {
        return gallery.id === gallery_id;
      });
    }
    return gallery || null;
  }

  buildSocialGalleryItem(gallery) {
    return {
      type: 'gallery',
      id: gallery.id,
      caption: gallery.title,
      icon: AlbumGalleryPanel.icons[`folder_${gallery.id}`]
    };
  }

};

Px.Editor.AlbumGalleryPanel.icons = {
  folder_facebook: '<svg viewBox="0 0 320 320"><path fill="currentColor" d="M257.5,74.2857h-75.918c-8.6328,0-16.9102-3.5893-23.0039-10.0179l-13.457-14.25c-6.0938-6.4286-14.3711-10.0179-23.0039-10.0179H62.5c-17.9258,0-32.5,15.375-32.5,34.2858V245.7142c0,18.9107,14.5742,34.2857,32.5,34.2857h195c17.9258,0,32.5-15.375,32.5-34.2857V108.5714c0-18.9107-14.5742-34.2857-32.5-34.2857Zm-76.4474,69.3826h-6.2307c-6.1382,0-8.0526,3.809-8.0526,7.7166v9.2689h13.7052l-2.1919,14.2833h-11.5133v34.529h-15.441v-34.529h-12.5459v-14.2833h12.5459v-10.8859c0-12.3842,7.3776-19.2253,18.6643-19.2253,5.4059,0,11.0601,.965,11.0601,.965v12.1607Z"/></svg>',
  folder_instagram: '<svg viewBox="0 0 320 320"><path fill="currentColor" d="M190.4971,146.4748c-1.3922-3.4719-4.0535-6.1332-7.5078-7.5079-5.1814-2.0444-17.5359-1.5862-23.2815-1.5862s-18.0823-.4758-23.2814,1.5862c-3.4719,1.3923-6.1332,4.0535-7.5078,7.5079-2.0444,5.1815-1.5862,17.5359-1.5862,23.2814s-.476,18.0823,1.5862,23.2814c1.3922,3.4719,4.0536,6.1332,7.5078,7.5079,5.1815,2.0444,17.536,1.5862,23.2814,1.5862s18.0824,.4758,23.2815-1.5862c3.4719-1.3923,6.1332-4.0535,7.5078-7.5079,2.062-5.1815,1.5862-17.5359,1.5862-23.2814s.4758-18.0823-1.5862-23.2814Zm-30.7893,43.5315c-11.2089,0-20.25-9.0411-20.25-20.2501s9.0411-20.2501,20.25-20.2501,20.2502,9.0411,20.2502,20.2501-9.0413,20.2501-20.2502,20.2501Zm21.0785-36.6052c-2.626,0-4.7233-2.1149-4.7233-4.7233s2.115-4.7233,4.7233-4.7233,4.7233,2.1149,4.7233,4.7233c0,2.626-2.1149,4.7233-4.7233,4.7233Z"/><path fill="currentColor" d="M257.5,74.2857h-75.918c-8.6328,0-16.9102-3.5893-23.0039-10.0179l-13.457-14.25c-6.0938-6.4286-14.3711-10.0179-23.0039-10.0179H62.5c-17.9258,0-32.5,15.375-32.5,34.2858V245.7142c0,18.9107,14.5742,34.2857,32.5,34.2857h195c17.9258,0,32.5-15.375,32.5-34.2857V108.5714c0-18.9107-14.5742-34.2857-32.5-34.2857Zm-58.5786,111.7552c-.3171,6.327-1.7623,11.9315-6.3799,16.549-4.6174,4.6351-10.2219,6.0803-16.5491,6.3799-6.5208,.3701-26.0659,.3701-32.5869,0-6.327-.3173-11.9138-1.7625-16.549-6.3799s-6.0803-10.222-6.38-16.549c-.3701-6.5209-.3701-26.066,0-32.587,.3173-6.327,1.7449-11.9315,6.38-16.549,4.6351-4.6175,10.2396-6.0627,16.549-6.3623,6.521-.3701,26.0662-.3701,32.5869,0,6.3271,.3173,11.9316,1.7625,16.5491,6.3799,4.6353,4.6175,6.0803,10.222,6.3799,16.549,.3701,6.5209,.3701,26.0484,0,32.5693Z"/><path fill="currentColor" d="M159.7077,156.591c-7.261,0-13.165,5.9041-13.165,13.1652s5.9216,13.1652,13.165,13.1652,13.1653-5.9041,13.1653-13.1652-5.9041-13.1652-13.1653-13.1652Z"/></svg>',
  folder_dropbox: '<svg viewBox="0 0 320 320"><path fill="currentColor" d="M257.5001,74.2857h-75.918c-8.6328,0-16.9102-3.5893-23.0039-10.0179l-13.457-14.25c-6.0938-6.4286-14.3711-10.0179-23.0039-10.0179H62.5001c-17.9258,0-32.5,15.375-32.5,34.2858V245.7142c0,18.9107,14.5742,34.2857,32.5,34.2857h195c17.9258,0,32.5-15.375,32.5-34.2857V108.5714c0-18.9107-14.5742-34.2857-32.5-34.2857ZM119.6479,149.1431l19.7949-12.613,19.765,12.613-19.75,12.6131,19.75,12.6131-19.75,12.613-19.8099-12.7328,19.7949-12.613-19.7949-12.4934Zm39.4402,54.4172l-19.75-12.613,19.75-12.613,19.75,12.613-19.75,12.613Zm39.5598-29.2061l-19.7948,12.6131-19.6453-12.7178,19.75-12.613-19.75-12.5084,19.6453-12.5981,19.7948,12.613-19.7948,12.6131,19.7948,12.5981Z"/></svg>'
};
