Px.Editor.TextEditModal = class TextEditModal extends Px.Components.BaseModal {

  get content() {
    return Px.template`
      <textarea dir="${this.data.text_model.dir}">${this.data.text_model.raw_text}</textarea>
    `;
  }

  get footer() {
    return Px.template`
      <div class="px-action-buttons">
        <button class="px-cancel" data-onclick="destroy">
          ${Px.t('Cancel')}
        </button>
        <button class="px-ok" data-onclick="saveText">
          ${Px.t('OK')}
        </button>
      </div>
    `;
  }

  get css_class() {
    return `${super.css_class} px-text-edit-modal`;
  }

  constructor(props) {
    super(props);
    this.close_on_background_click = false;

    const text_model = this.data.text_model;

    this.on('mount', () => {
      const textarea = $j(this.dom_node).find('textarea');
      textarea.focus();
      if ((text_model.placeholder || text_model.text === Px.Editor.TextElementModel.default_text_content) &&
          this.data.store.ui.editor_mode !== 'mobile') {
        textarea.select();
      } else {
        // Move cursor to end of text. In mobile Safari it sometimes ends up before the first character
        // for some reason.
        const textlen = text_model.text.length;
        textarea[0].setSelectionRange(textlen, textlen);
      }
    });
  }

  get dataProperties() {
    return {
      store: {required: true},
      text_model: {required: true}
    };
  }

  // --------------
  // Event handlers
  // --------------

  saveText(evt) {
    const text_model = this.data.text_model;
    const text_value = $j(this.dom_node).find('textarea').val();
    this.data.store.undo_redo.withUndo(() => {
      mobx.runInAction(() => {
        text_model.text = text_value;
        text_model.placeholder = false;
      });
    }, {
      label: 'change text',
      set_id: text_model.page.set.id
    });
    this.destroy();
  }

};
