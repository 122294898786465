Px.Editor.MobileGalleryPanelMixin = Base => class extends Base {

  template() {
    const r = this.renderChild;
    return Px.template`
      <div class="px-mobile-gallery-panel ${this.css_class}">
        ${Px.if(this.items.length === 0, () => {
          return this.no_items_template();
        }).else(() => {
          return Px.template`
            <div class="px-gallery-items">
              ${this.itemsWithUsage.map(item_with_usage => this.item_template(item_with_usage.item))}
              ${Px.if(this.canLoadMoreItems && !this.isLoading, () => {
                return Px.template`
                  <div class="px-gallery-item px-action-item">
                    <button class="px-small px-secondary-color" data-onclick="loadMoreItems">
                      ${Px.raw(Px.Editor.MobileGalleryPanelMixin.icons.plus)}
                    </button>
                    <div class="caption">${Px.t('Load More')}</div>
                  </div>
                `;
              })}
              ${Px.if(this.isLoading, () => {
                return r(Px.Components.Spinner, 'gallery-loading-spinner', {active: true});
              }).else(() => {
                return this.extra_actions_template();
              })}
            </div>
          `;
        })}
      </div>
    `;
  }

  no_items_template() {
    return '';
  }

  extra_actions_template() {
    return '';
  }

  item_template(item) {
    if (item.type === 'image') {
      return Px.template`
        <div class="px-gallery-item px-image"
            data-image-id="${item.id}"
            data-used="${this.isImageUsed(item)}"
            data-onclick="selectImage">
          <img src="${item.thumb_url}" />
          <div class="caption">${item.caption}</div>
        </div>
      `;
    } else if (item.type === 'gallery') {
      return Px.template`
        <div class="px-gallery-item px-gallery"
            data-gallery-id="${item.id}"
            data-onclick="onGalleryClick">
          ${Px.raw(Px.Editor.MobileGalleryPanelMixin.icons.folder)}
          <div class="caption">${item.caption}</div>
        </div>
      `;
    }
  }

  // --------------
  // Event handlers
  // --------------

  selectImage(evt) {
    const store = this.data.store;
    const image_id = evt.currentTarget.getAttribute('data-image-id');

    mobx.runInAction(() => {
      this.registerImage(image_id);
      if (store.cut_print_mode) {
        store.project.addCutPrintImage(image_id);
        // Scroll the cut prints panel down to the last added cut print.
        requestAnimationFrame(() => {
          const last_div = document.querySelector('.px-cut-prints .px-page-set:last-child');
          last_div.scrollIntoView({behavior: 'smooth', block: 'end'});
        });
      } else if (store.mobile.view_mode === 'autofill') {
        store.mobile.addAutofillImageId(image_id);
        // Scroll the autofill panel down to the last added image.
        requestAnimationFrame(() => {
          const last_div = document.querySelector('.px-mobile-autofill-panel .px-image:last-child');
          last_div.scrollIntoView({behavior: 'smooth', block: 'end'});
        });
      } else {
        this.withUndo('set image', () => {
          store.selected_element.update({id: image_id, placeholder: false});
        });
      }
    });
  }

  // -------
  // Private
  // -------

  registerImage(image_id) {
    const store = this.data.store;
    if (!store.images.get(image_id)) {
      const gallery_image = this.images.find(image => image.id === image_id);
      store.images.register(image_id, gallery_image.data);
    }
  }

  isImageUsed(image) {
    const store = this.data.store;
    const usage = store.project.getImageUsageByImageId(image.id);
    if (store.cut_print_mode) {
      return usage.length > 0;
    } else if (store.mobile.view_mode === 'autofill') {
      return Boolean(store.mobile.autofill_images.find(img => img.id === image.id));
    } else {
      return usage.includes(store.selected_element);
    }
  }

  galleryItemStyle(item) {
    return `background-image: url(${item.thumb_url}); `;
  }

};

Px.Editor.MobileGalleryPanelMixin.icons = {
  folder: '<svg viewBox="20 20 280 280"><path fill="currentColor" d="M129.3074,166.0538l8.7692-8.7692,24.1157,24.1156,15.3459-15.3464,13.1539,13.1539v-39.9001h-61.3847v26.7462Zm41.388-16.0506c1.2335-1.2335,2.9062-1.9264,4.6508-1.9264s3.4168,.6929,4.6503,1.9264c1.2335,1.2334,1.9266,2.9062,1.9266,4.6506s-.6932,3.4172-1.9266,4.6506c-1.2335,1.2334-2.9062,1.9263-4.6503,1.9263s-3.4172-.6929-4.6508-1.9263c-1.2335-1.2334-1.9261-2.9063-1.9261-4.6506s.6927-3.4172,1.9261-4.6506Z"/><path fill="currentColor" d="M257.5,74.2857h-75.918c-8.6328,0-16.9102-3.5893-23.0039-10.0179l-13.457-14.25c-6.0938-6.4286-14.3711-10.0179-23.0039-10.0179H62.5c-17.9258,0-32.5,15.375-32.5,34.2858V245.7142c0,18.9107,14.5742,34.2857,32.5,34.2857h195c17.9258,0,32.5-15.375,32.5-34.2857V108.5714c0-18.9107-14.5742-34.2857-32.5-34.2857Zm-58.0385,130.7914c0,1.1626-.4621,2.2781-1.2843,3.1002-.8221,.8222-1.9376,1.2843-3.1002,1.2843H124.9228c-1.1628,0-2.2781-.4621-3.1004-1.2843-.8223-.8221-1.2842-1.9376-1.2842-3.1002v-70.1542c0-1.1628,.462-2.2781,1.2842-3.1004s1.9376-1.2842,3.1004-1.2842h70.1542c1.1626,0,2.2781,.462,3.1002,1.2842,.8222,.8223,1.2843,1.9376,1.2843,3.1004v70.1542Z"/></svg>'
};
