Px.Editor.BaseProjectStore = class BaseProjectStore extends Px.BaseStore {

  static get properties() {
    return {
      loaded: {std: false},
      name: {std: ''},
      theme_id: {std: null},
      product_id: {std: null},
      product_name: {std: ''},
      minimum_dpi: {std: 100},
      unit: {std: 'mm'},
      group_id: {std: null},
      page_sets: {std: mobx.observable.array()},
      saving: {std: false},
      options: {std: mobx.observable.map()},
      template_options: {std: mobx.observable.map()},
      // Layout snapshot currently stored on the server. We use this to calculate the differences
      // compared to the stored version next time we want to save the project.
      saved_snapshot: {std: null},
    };
  }

  static get computedProperties() {
    return {
      loading: function() {
        return !this.loaded || this.saving;
      },
      _elements_by_unique_id: function() {
        var elements = {};
        this.forEachElement(function(element) {
          elements[element.unique_id] = element;
        });
        return elements;
      },
      editor_page_sets: function() {
        return this.page_sets.filter(set => set.editor);
      },
      images: function() {
        var images = [];
        this.forEachPage(function(page) {
          page.image_elements.forEach(image => images.push(image));
        });
        return images;
      },
      local_images: function() {
        return _.filter(this.images, image => image.image.type === 'local');
      },
      pdfs: function() {
        var pdfs = [];
        this.forEachPage(function(page) {
          page.pdf_elements.forEach(pdf => pdfs.push(pdf));
        });
        return pdfs;
      },
      local_pdfs: function() {
        return _.filter(this.pdfs, pdf => pdf.pdf.type === 'local');
      },
      texts: function() {
        var texts = [];
        this.forEachPage(function(page) {
          page.text_elements.forEach(text => texts.push(text));
        });
        return texts;
      },
      unedited_elements: function() {
        var unedited = [];
        this.forEachPage(function(page) {
          page.unedited_elements.forEach(element => unedited.push(element));
        });
        return unedited;
      },
      _images_by_id: function() {
        var images = {};
        this.images.forEach(function(image) {
          if (!images[image.id]) {
            images[image.id] = [];
          }
          images[image.id].push(image);
        });
        return images;
      },
      _sets_by_id: function() {
        var sets = {};
        this.page_sets.forEach(function(set) {
          sets[set.id] = set;
        });
        return sets;
      },
      pages: function() {
        var pages = [];
        this.forEachPage(function(page) {
          pages.push(page);
        });
        return pages;
      },
      editor_pages: function() {
        var pages = [];
        this.forEachEditorPage(function(page) {
          pages.push(page);
        });
        return pages;
      },
      _pages_by_id: function() {
        var pages = {};
        this.forEachPage(function(page) {
          pages[page.id] = page;
        });
        return pages;
      },
      _pages_by_background_image_id: function() {
        var self = this;
        var pages = {};
        this.forEachPage(function(page) {
          if (page.src) {
            if (!pages[page.src]) {
              pages[page.src] = [];
            }
            pages[page.src].push(page);
          }
        });
        return pages;
      },
      // Number of pages, skpping pages that are marked count=false.
      page_count: function() {
        var count = 0;
        this.page_sets.forEach(function(set) {
          if (set.count) {
            count += set.pages.length;
          }
        });
        return count;
      },
      can_add_pages: function() {
        return false;
      },
      can_delete_pages: function() {
        return false;
      },
      fillable_placeholders: function() {
        let placeholders = [];
        this.forEachPage(page => {
          placeholders = placeholders.concat(page.fillable_placeholders);
        });
        return placeholders;
      },
      layout_snapshot: this.computed(function() {
        var self = this;
        var layout = [];
        this.page_sets.forEach(function(set_model) {
          var pages = _.map(set_model.pages, function(page_model) {
            return {
              id: page_model.id,
              name: page_model.name,
              data: page_model.xml,
              double: set_model.double_page,
              snap_points: page_model.snap_points.toJS()
            };
          });
          layout.push({
            print_page: pages,
            grow: set_model.grow,
            count: set_model.count,
            editor: set_model.editor,
            fulfillment: set_model.fulfillment,
            left_caption: set_model._left_caption_template,
            center_caption: set_model._center_caption_template,
            right_caption: set_model._right_caption_template
          });
        });
        return layout;
      }, {keepAlive: true}),
      // Takes this.saved_snapshot and current this.layout_snapshot, and returns the minimal amount of data
      // that needs to be sent (omitting pages that haven't changed) to the save API endpoint in order to store
      // the changes since last save.
      data_for_save_endpoint: function() {
        var saved_pages = {};
        var saved_idx = 0;
        this.saved_snapshot.forEach(function(set) {
          set.print_page.forEach(function(page) {
            saved_pages[page.id] = {
              data: page.data,
              position: saved_idx
            };
            saved_idx++;
          });
        });
        var current_pages = {};
        var current_idx = 0;
        this.layout_snapshot.forEach(function(set) {
          set.print_page.forEach(function(page) {
            current_pages[page.id] = {
              data: page.data,
              name: page.name,
              position: current_idx
            };
            current_idx++;
          });
        });
        var data = {
          pages: {},
          page_meta: {},
          page_numbers: {},
          deleted_pages: []
        };
        _.each(current_pages, function(page, id) {
          var saved_page = saved_pages[id];
          if (saved_page) {
            if (saved_page.data !== page.data) {
              data.pages[id] = page.data;
            }
            if (saved_page.position !== page.position) {
              data.page_numbers[id] = page.position;
            }
          } else {
            data.pages[id] = page.data;
            data.page_meta[id] = {name: page.name};
            data.page_numbers[id] = page.position;
          }
        });
        _.each(saved_pages, function(page, id) {
          if (!current_pages[id]) {
            data.deleted_pages.push(id);
          }
        });

        if (this.are_options_dirty) {
          data.options = mobx.toJS(this.options);
        }

        if (this.are_template_options_dirty) {
          data.template_options = mobx.toJS(this.template_options);
        }

        if (Px.urlQuery().parent_orderline) {
          data.saved = 'false';
        }

        return data;
      },
      has_unsaved_changes: function() {
        if (!(this.loaded && this.layout_snapshot && this.saved_snapshot)) {
          return false;
        }
        var data = this.data_for_save_endpoint;
        return (data.deleted_pages.length !== 0 ||
                Object.keys(data.pages).length !== 0 ||
                Object.keys(data.page_numbers).length !== 0 ||
                Object.keys(data.options || {}).length !== 0 ||
                Object.keys(data.template_options || {}).length !== 0);
      }
    }
  }

  get actions() {
    return {
      load: function() {
        throw new Error('Implement in child class');
      },

      setLayout: function(layout_json) {
        this.page_sets.replace(this.makeLayout(layout_json));
      },

      setLayoutAndStoreSavedSnapshot: function(layout_json) {
        this.setLayout(layout_json);
        // Schedule saved_snapshot to be stored after all other already scheduled reactions
        // observing the layout hash already finish running. This is neccessary to make sure
        // element clones and other modifications to the layout that happen as reaction effects
        // when the layout is loaded are already in place before we store the snapshot.
        mobx.when(
          () => true,
          () => this.saved_snapshot = this.layout_snapshot,
          {name: 'Px.Editor.BaseProjectStore::StoreSavedSnapshotReaction'}
        );
      },

      // Autofills provided `image_ids` into available placeholders.
      // Returns an array of image ids that it wasn't able to fill.
      autofill: function(image_ids) {
        image_ids = image_ids.slice();
        const pages = this.pages.slice();
        let image_id = image_ids.shift();
        let page = pages.shift();
        while (image_id && page) {
          if (page.fillImage(image_id)) {
            image_id = image_ids.shift();
          } else {
            page = pages.shift();
          }
        }
        // If we didn't successfully fill the last image_id, we need to add it back to the array before returning.
        if (image_id) {
          image_ids.unshift(image_id);
        }
        return image_ids;
      },

      moveSet: function(set, position) {
        var original_position = set.position;
        this.page_sets.splice(original_position, 1);
        if (original_position < position) {
          position -= 1;
        }
        this.page_sets.splice(position, 0, set);
      },

      save: function() {
        throw new Error('Implement in child class');
      }

    };
  }

  forEachPage(fn) {
    this.page_sets.forEach(function(set) {
      set.pages.forEach(fn);
    });
  }

  forEachEditorPage(fn) {
    this.editor_page_sets.forEach(function(set) {
      set.pages.forEach(fn);
    });
  }

  forEachElement(fn) {
    this.forEachPage(function(page) {
      page.forEachElement(fn);
    });
  }

  getSetById(id) {
    return this._sets_by_id[id] || null;
  }

  getPageById(id) {
    return this._pages_by_id[id] || null;
  }

  getPagesByBackgroundImageId(id) {
    return this._pages_by_background_image_id[id] || [];
  }

  getElementByUniqueId(id) {
    return this._elements_by_unique_id[id] || null;
  }

  getImageElementsByImageId(id) {
    return this._images_by_id[id] || [];
  }

  getImageUsageByImageId(id) {
    const elements = this.getImageElementsByImageId(id);
    return elements.filter(element => {
      return element.is_in_viewport;
    });
  }

  // -------
  // Private
  // -------

  // Whenever changing this, make sure to also change the output of this.layout_snapshot!
  makeLayout(layout_json) {
    var self = this;
    var layout = [];
    _.each(layout_json, function(set) {
      var page_models = _.map(set.print_page, function(page_json) {
        var page_model = Px.Editor.PageModel.fromJSON(page_json, {
          image_store: self.image_store,
          pdf_store: self.pdf_store
        });
        return page_model;
      });
      var set_model = Px.Editor.PageSetModel.make({
        double_page: Boolean(set.print_page[0] && set.print_page[0].double),
        grow: Boolean(set.grow),
        count: Boolean(set.count),
        editor: Boolean(set.editor),
        fulfillment: Boolean(set.fulfillment),
        _left_caption_template: set.left_caption,
        _center_caption_template: set.center_caption,
        _right_caption_template: set.right_caption,
        // Circular dependency.
        project_store: self
      });
      page_models.forEach(function(page_model) {
        set_model.addPage(page_model);
      });
      layout.push(set_model);
    });
    return layout;
  }

};
