Px.Editor.ElementLayerIcons = class ElementLayerIcons extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    // The px-control-handle <rect>s are there only to catch any click events when an active
    // element is partially covered by another element.
    // See comment about `grabHandle` below.

    return Px.template`
      <g class="px-control-component"
         cursor="pointer"
         data-onclick="onClick"
         transform="translate(${this.data.x}, ${this.data.y})">

        <g data-px-tooltip="${Px.t('Move to front')}">
          <rect width="${this.iconWidth}"
                height="${this.iconHeight}"
                fill="none"
                stroke="none"
                class="px-control-handle"
                pointer-events="all"
                data-move-to="top"
                data-onmousedown="grabHandle"
                data-ontouchstart="grabHandle"
          />
          <svg width="${this.iconWidth - 2*this.iconPadding}"
               height="${this.iconHeight - 2*this.iconPadding}"
               viewBox="0 0 23 23"
               pointer-events="none">
            <path d="M2.56561 12.556L10.7443 17.1249C10.8789 17.2005 11.0307 17.2403 11.1852 17.2405C11.3396 17.2406 11.4915 17.2011 11.6263 17.1258L19.7651 12.5932C19.9064 12.5145 20.0242 12.3995 20.1061 12.26C20.1881 12.1205 20.2312 11.9616 20.231 11.7999C20.2308 11.6381 20.1873 11.4793 20.1051 11.34C20.0228 11.2007 19.9048 11.086 19.7632 11.0076L11.5846 6.47504C11.4504 6.40061 11.2994 6.36149 11.146 6.36133C10.9925 6.36117 10.8415 6.39998 10.7071 6.47413L2.56833 10.9705C2.42672 11.0487 2.30863 11.1634 2.2263 11.3027C2.14397 11.4419 2.10041 11.6007 2.10013 11.7624C2.09985 11.9242 2.14287 12.0831 2.22472 12.2226C2.30657 12.3622 2.42427 12.4773 2.56561 12.556Z" fill="var(--icon-color)"/>
            <path d="M3.44673 14.6353L2.5665 16.2199L10.7252 20.7526C10.8598 20.8273 11.0113 20.8666 11.1653 20.8666C11.3193 20.8666 11.4708 20.8273 11.6054 20.7526L19.7641 16.2199L18.8839 14.6353L11.1658 18.9232L3.44673 14.6353ZM18.418 8.17546V6.36242H20.231V4.54937H18.418V2.73633H16.6049V4.54937H14.7919V6.36242H16.6049V8.17546H18.418Z" fill="var(--icon-color)"/>
          </svg>
        </g>

        <g transform="translate(${this.iconWidth + this.iconMargin}, 0)"
           data-px-tooltip="${Px.t('Move to back')}">
          <rect width="${this.iconWidth}"
                height="${this.iconHeight}"
                fill="none"
                stroke="none"
                class="px-control-handle"
                pointer-events="all"
                data-move-to="bottom"
                data-onmousedown="grabHandle"
                data-ontouchstart="grabHandle"
          />
          <svg width="${this.iconWidth - 2*this.iconPadding}"
               height="${this.iconHeight - 2*this.iconPadding}"
               viewBox="0 0 23 23"
               pointer-events="none">
            <path d="M2.56561 12.556L10.7443 17.1249C10.8789 17.2005 11.0307 17.2403 11.1852 17.2405C11.3396 17.2406 11.4915 17.2011 11.6263 17.1258L19.7651 12.5932C19.9064 12.5145 20.0242 12.3995 20.1061 12.26C20.1881 12.1205 20.2312 11.9616 20.231 11.7999C20.2308 11.6381 20.1873 11.4793 20.1051 11.34C20.0228 11.2007 19.9048 11.086 19.7632 11.0076L11.5846 6.47504C11.4504 6.40061 11.2994 6.36149 11.146 6.36133C10.9925 6.36117 10.8415 6.39998 10.7071 6.47413L2.56833 10.9705C2.42672 11.0487 2.30863 11.1634 2.2263 11.3027C2.14397 11.4419 2.10041 11.6007 2.10013 11.7624C2.09985 11.9242 2.14287 12.0831 2.22472 12.2226C2.30657 12.3622 2.42427 12.4773 2.56561 12.556Z" fill="var(--icon-color)"/>
            <path d="M3.44673 14.6353L2.5665 16.2199L10.7252 20.7526C10.8598 20.8273 11.0113 20.8666 11.1653 20.8666C11.3193 20.8666 11.4708 20.8273 11.6054 20.7526L19.7641 16.2199L18.8839 14.6353L11.1658 18.9232L3.44673 14.6353ZM18.418 8.17546V6.36242 H20.231 V4.54937 H14.7919 V6.36242 H18.418Z" fill="var(--icon-color)"/>
          </svg>
        </g>

      </g>
    `;
  }

  get dataProperties() {
    return {
      element: {required: true},
      x: {required: true},
      y: {required: true},
      scale: {required: true},
      store: {required: true},
    }
  }

  static get computedProperties() {
    return {
      iconWidth: function() {
        return (this.inSvgUnits(ElementLayerIcons.SIZE[0]) - this.iconMargin) / 2;
      },
      iconHeight: function() {
        return this.inSvgUnits(ElementLayerIcons.SIZE[1]);
      },
      iconPadding: function() {
        return this.inSvgUnits(1);
      },
      iconMargin: function() {
        return this.inSvgUnits(3);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  // Prevent click from propagating to the element.
  onClick(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.stopPropagation();
  }

  // Note: We're using mousedown rather than a more straightforward click event,
  // because it makes it easier to handle proxying the event when the active element
  // is partially covered by another element. See `grabHandle` in `Px.Editor.BaseElementComponent`.
  // Also note that the handler *has* to be called `grabHandle` for this to work.
  grabHandle(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    const direction = evt.currentTarget.getAttribute('data-move-to');
    if (direction === 'top') {
      this.moveElementToTopOfLayer(this.data.element)
    } else {
      this.moveElementToBottomOfLayer(this.data.element);
    }
  }

  // -------
  // Private
  // -------

  moveElementToTopOfLayer(element) {
    const clone_element = element.two_page_spread_clone;
    this.withUndo(`move ${element.type} to the top`, () => {
      element.container.moveElementToTopOfLayer(element, element.z);
      if (clone_element) {
        clone_element.container.moveElementToTopOfLayer(clone_element, clone_element.z);
      }
    });
  }

  moveElementToBottomOfLayer(element) {
    const clone_element = element.two_page_spread_clone;
    this.withUndo(`move ${element.type} element to the bottom`, () => {
      element.container.moveElementToBottomOfLayer(element, element.z);
      if (clone_element) {
        clone_element.container.moveElementToBottomOfLayer(clone_element, clone_element.z);
      }
    });
  }

};

Px.Editor.ElementLayerIcons.SIZE = [59, 25];
