Px.Editor.PdfEditAccordion = class PdfEditAccordion extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-tab-panel-accordion-wrapper">
        ${this.renderChild(Px.Components.Accordion, 'accordion', this.accordionProperties)}
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.onItemExpanded = this.onItemExpanded.bind(this);
  }

  get dataProperties() {
    return {
      pdf: {required: true},
      store: {required: true}
    };
  }

  static get properties() {
    return {
      expanded_item_id: {type: 'str', std: 'standard'}
    }
  }

  static get computedProperties() {
    return {
      accordionProperties: function() {
        return {
          items: [
            {
              id: 'standard',
              title: Px.t('Standard'),
              component: Px.Editor.PdfEditPanel,
              data: {store: this.data.store, pdf: this.data.pdf}
            },
            {
              id: 'admin',
              title: Px.t('Admin'),
              component: Px.Editor.AdvancedEditPanel,
              data: {store: this.data.store, element: this.data.pdf}
            }
          ],
          expanded_item_id: this.state.expanded_item_id,
          onItemExpanded: this.onItemExpanded
        };
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  onItemExpanded(item_id) {
    this.state.expanded_item_id = item_id;
  }

};
