Px.Editor.ImageElement = class ImageElement extends Px.Editor.BaseElementComponent {

  template() {
    const ui_store = this.data.store.ui;
    const element = this.data.element;
    const r = this.renderChild;
    const borderwrap_positions = [
      'top-left',
      'top',
      'top-right',
      'right',
      'bottom-right',
      'bottom',
      'bottom-left',
      'left'
    ];

    return Px.template`
      <g class="px-image-element" data-element-id="${element.unique_id}" data-selected="${this.isSelected}">
        <g transform="${this.transformAttribute}" pointer-events="${this.pointerEventsAttribute}">

          ${Px.if(this.data.render_content || (this.data.render_controls && this.showCroppingOverflow), () => {
            return Px.template`
              <defs>
                <clipPath id="${this.clipPathId}">
                  <rect x="0"
                        y="0"
                        width="${element.width}"
                        height="${element.height}"
                        rx="${element.radius}"
                        ry="${element.radius}"
                  />
                </clipPath>
                ${Px.if(element.borderwrap, () => {
                  return Px.template`
                    ${borderwrap_positions.map(position => {
                      const attrs = this.borderwrapClipPathAttributes(position);
                      return Px.template`
                        <clipPath id="${this.borderwrapClipPathId(position)}">
                          <rect x="${attrs.x}"
                                y="${attrs.y}"
                                width="${attrs.width}"
                                height="${attrs.height}"
                          />
                        </clipPath>
                      `;
                    })}
                  `;
                })}
                <mask id="${this.maskId}">
                  ${Px.if(this.showCroppingOverflow, () => {
                    return Px.template`
                      <circle cx="${element.width / 2}"
                              cy="${element.height / 2}"
                              r="${Math.max.apply(null, element.svg_image_dimensions)}"
                              fill="#444"
                      />
                    `;
                  })}
                  ${Px.if(element.mask, () => {
                    return Px.template`
                      <image x="0"
                            y="0"
                            width="${element.width}"
                            height="${element.height}"
                            preserveAspectRatio="none"
                            xlink:href="${this.maskSrc}"
                      />
                    `;
                  }).else(() => {
                    return Px.template`
                      <rect x="0"
                            y="0"
                            width="${element.width}"
                            height="${element.height}"
                            fill="#fff"
                      />
                    `;
                  })}
                </mask>
              </defs>

              <g mask="url(#${this.maskId})"
                clip-path="${this.showCroppingOverflow ? 'none' : `url(#${this.clipPathId})`}">

                ${this.svgImageTemplate('center')}

                ${Px.if(element.borderwrap, () => {
                  return borderwrap_positions.map(position => {
                    const translation = this.borderwrapImageTranslation(position);
                    return Px.template`
                      <g clip-path="url(#${this.borderwrapClipPathId(position)})"
                        transform="translate(${translation[0]}, ${translation[1]})">
                        ${this.svgImageTemplate(position)}
                      </g>
                    `;
                  })
                })}
              </g>
            `;
          })}

          ${Px.if(this.data.render_content, () => {
            return Px.template`
              ${Px.if(this.showLoadingIcon, () => {
                return r(Px.Editor.ElementIcon, 'loading-indicator', this.loadingIndicatorProps);
              })}

              ${Px.if(this.isFailed, () => {
                return Px.template`
                  <rect width="${element.width}"
                        height="${element.height}"
                        opacity="0.5"
                        stroke-width="${this.inSvgUnits(1)}"
                        stroke="#ff0000"
                        fill-opacity="0"
                        rx="${element.radius}"
                        ry="${element.radius}"
                  />
                  ${r(Px.Editor.ElementIcon, 'failed-indicator', this.failedIndicatorProps)}
                `;
              })}

              <rect width="${element.width}"
                    height="${element.height}"
                    opacity="${element.border ? 1 : 0}"
                    stroke-width="${element.border || 0}"
                    stroke="${Px.Util.colorForDisplay(element.bordercolor)}"
                    fill-opacity="0"
                    rx="${element.radius}"
                    ry="${element.radius}"
              />
            `;
          })}

          ${Px.if(this.data.render_controls, () => {
            return Px.template`
              ${Px.if(Px.config.image_size_box && element.resize && this.isSelected && !this.data.preview_mode, () => {
                return Px.template`
                  <rect pointer-events="none"
                        width="${this.imageSizeBoxDimensions.width}"
                        height="${this.imageSizeBoxDimensions.height}"
                        fill-opacity="0.8"
                        fill="#ffffff"
                        x="${element.width - this.imageSizeBoxDimensions.width}"
                        y="0"
                  />
                  <text pointer-events="none"
                        dominant-baseline="middle"
                        text-anchor="middle"
                        font-size="${this.inSvgUnits(10)}px"
                        x="${element.width - this.imageSizeBoxDimensions.width/2}"
                        y="${this.imageSizeBoxDimensions.height/2}"
                  >
                    ${this.imageSizeBoxText}
                  </text>
                `;
              })}

              ${Px.if(this.showImageSwapIcon, () => {
                if (this.isImageSwapSource) {
                  return Px.template`
                    <rect class="px-image-swap-overlay"
                          x="${this.selectionOutlineX}"
                          y="${this.selectionOutlineY}"
                          width="${this.selectionOutlineWidth}"
                          height="${this.selectionOutlineHeight}"
                          stroke-width="${this.inSvgUnits(3)}"
                          stroke="var(--icon-color)"
                          fill="var(--icon-color)"
                          fill-opacity="0.33"
                          pointer-events="all"
                          data-onmousedown="cancelSwap"
                          data-ontouchstart="cancelSwap"
                          ${Px.if(element.radius, () => {
                            return Px.template`
                              rx="${element.radius}"
                              ry="${element.radius}"
                            `;
                          })}
                    />
                    <g transform="translate(${element.width / 2} ${element.height / 2})"
                      pointer-events="none">
                      <circle r="${this.inSvgUnits(23)}"
                              stroke="none"
                              fill="var(--icon-color)"
                      />
                    </g>
                  `;
                } else {
                  return Px.template`
                    <rect class="px-image-swap-target"
                          x="${this.selectionOutlineX}"
                          y="${this.selectionOutlineY}"
                          width="${this.selectionOutlineWidth}"
                          height="${this.selectionOutlineHeight}"
                          stroke-width="${this.inSvgUnits(3)}"
                          stroke="var(--icon-action-color)"
                          cursor="pointer"
                          fill="var(--icon-action-color)"
                          fill-opacity="0.33"
                          pointer-events="auto"
                          data-px-tooltip="${Px.t('Click to swap image')}"
                          data-onmousedown="swapImage"
                          data-ontouchstart="swapImage"
                          ${Px.if(element.radius, () => {
                            return Px.template`
                              rx="${element.radius}"
                              ry="${element.radius}"
                            `;
                          })}
                    />
                    <g transform="translate(${element.width / 2} ${element.height / 2})"
                      pointer-events="none">
                      <circle r="${this.inSvgUnits(23)}"
                              stroke="none"
                              fill="var(--icon-action-color)"
                      />
                    </g>
                  `;
                }
              }).else(() => {
                return Px.template`
                  ${this.selection_outline_template()}
                  ${this.data.preview_mode ? '' : this.renderEditControls()}
                `;
              })}
            `;
          })}

          ${Px.if(this.data.render_content || (this.data.render_controls && this.isSelected), () => {
            return Px.template`
              ${this.resolutionTooLow ? r(Px.Editor.ElementIcon, 'resolution-warning', this.resolutionIconProps) : ''}
              ${this.showBadlyCroppedCutPrintIcon ? r(Px.Editor.ElementIcon, 'bad-crop-warning', this.badlyCroppedIconProps) : ''}
            `;
          })}

          ${Px.if(this.showImageSwapIcon, () => {
            return r(Px.Editor.ElementIcon, 'image-swap', this.swapIconProps);
          })}
        </g>

        ${Px.if(this.data.render_content && !this.data.preview_mode, () => {;
          return this.renderBleedWarning();
        })}
      </g>
    `;
  }

  svgImageTemplate(position) {
    const element = this.data.element;
    const bwrap = element.borderwrap;
    const width = element.width - 2 * bwrap;
    const height = element.height - 2 * bwrap;

    const scale = this.borderwrapImageScale(position);
    const scale_x = scale[0];
    const scale_y = scale[1];
    let x = bwrap * scale_x;
    let y = bwrap * scale_y;

    switch (position) {
    case 'top-right':
      x = -(2 * width + bwrap);
      break;
    case 'right':
      x = -(2 * width + bwrap);
      break;
    case 'bottom-right':
      x = -(2 * width + bwrap);
      y = -(2 * height + bwrap);
      break;
    case 'bottom':
      y = -(2 * height + bwrap);
      break;
    case 'bottom-left':
      y = -(2 * height + bwrap);
      break;
    }

    const svg_element = Px.template`
      <svg x="${x}"
           y="${y}"
           width="${width}"
           height="${height}"
           viewBox="${bwrap} ${bwrap} ${width} ${height}">
        <g transform="${this.imageGroupTransformAttribute}">
          <image width="${element.svg_image_dimensions[0]}"
                 height="${element.svg_image_dimensions[1]}"
                 image-rendering="optimizeQuality"
                 fill="#000"
                 opacity="${element.opacity}"
                 preserveAspectRatio="none"
                 xlink:href="${this.src}"
          />
        </g>
      </svg>
    `;

    if (position === 'center') {
      return svg_element;
    } else {
      return Px.template`
        <g transform="scale(${scale_x}, ${scale_y})">
          ${svg_element}
        </g>
      `;
    }
  }

  constructor(props) {
    super(props);

    this._pinch_origin = null;

    this.registerReaction(() => {
      return this.data.element.image.id;
    }, () => {
      return this.state.cached_src = null;
    }, {
      name: 'Px.Editor.ImageElement::clearCachedSrcReaction'
    });

    this.registerReaction(() => {
      const mask_image = this.data.element.mask_image;
      return mask_image && mask_image.id;
    }, () => {
      this.state.cached_mask_src = null;
    }, {
      name: 'Px.Editor.ImageElement::clearCachedMaskSrcReaction'
    });

    this.registerReaction(() => {
      return this.hiresSrc;
    }, src => {
      if (src === null) {
        this.state.image_status = 'failed';
      } else {
        const image = new Image();
        this.state.image_status = 'loading';
        image.onload = () => {
          this.state.image_status = 'loaded';
        };
        image.onerror = () => {
          this.state.image_status = 'failed';
        };
        image.src = src;
      }
    }, {
      fireImmediately: true,
      name: 'Px.Editor.ImageElement::loadImageReaction'
    });

    this.registerReaction(() => {
      const mask_image = this.data.element.mask_image;
      return mask_image && mask_image.id && this.maskHiresSrc;
    }, src => {
      if (src === null) {
        this.state.mask_image_status = 'loaded';
      } else {
        const image = new Image();
        this.state.mask_image_status = 'loading';
        image.onload = () => {
          this.state.mask_image_status = 'loaded';
        };
        image.onerror = () => {
          this.state.mask_image_status = 'failed';
        };
        image.src = src;
      }
    }, {
      fireImmediately: true,
      name: 'Px.Editor.ImageElement::loadMaskImageReaction'
    });

    this.registerReaction(() => {
      return this.isSelected && this.resolutionTooLow && !this.data.preview_mode && !this.data.mobile_mode;
    }, show_warning => {
      if (show_warning) {
        const msg = Px.t("Image resolution is lower than required for high quality printing, " +
                         "to optimize print quality try zooming out, reducing size of image area or using " +
                         "an image with higher resolution.");
        this.data.store.showNotification(msg, 'warning');
      }
    }, {
      name: 'Px.Editor.ImageElement::resolutionWarningReaction'
    });

    this.registerReaction(() => this.isBadlyCroppedCutPrint, show_warning => {
      if (show_warning) {
        const store = this.data.store;
        if (store.ui.editor_mode === 'mobile' && store.mobile.view_mode !== 'project') {
          // We only want to show cropping warnings in project view when on mobile.
          return;
        }
        const msg = Px.t("Some images are cropped. You might want to try using a different print size.");
        this.data.store.showNotification(msg, 'error');
      }
    }, {
      name: 'Px.Editor.ImageElement::badlyCroppedCutPrintReaction',
      fireImmediately: true
    });
  }

  static get properties() {
    return {
      image_status: {type: 'str', std: 'loading'},  // one of 'loading', 'loaded', 'failed'
      mask_image_status: {type: 'str', std: 'loading'},  // one of 'loading', 'loaded', 'failed'
      cached_src: {type: 'str', std: null},
      cached_mask_src: {type: 'str', std: null}
    };
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      isLoading: function() {
        return this.state.image_status === 'loading';
      },
      isLoaded: function() {
        return this.state.image_status === 'loaded';
      },
      isFailed: function() {
        return this.state.image_status === 'failed';
      },
      isMaskLoading: function() {
        return this.state.mask_image_status === 'loading';
      },
      isMaskLoaded: function() {
        return this.state.mask_image_status === 'loaded';
      },
      isMaskFailed: function() {
        return this.state.mask_image_status === 'failed';
      },
      isCropping: function() {
        return this.isSelected && this.data.store.ui.element_cropping_mode;
      },
      isImageSwapSource: function() {
        const ui_store = this.data.store.ui;
        if (!ui_store.image_swap_mode) {
          return false;
        }
        const element = this.data.element;
        const swap_source = ui_store.image_swap_source;
        return (swap_source === element || swap_source === element.two_page_spread_clone);
      },
      showLoadingIcon: function() {
        return (
          this.isLoading ||
            this.isMaskLoading ||
            (this.data.element.image.type === 'local' && !this.data.preview_mode)
        );
      },
      showCroppingOverflow: function() {
        return this.isCropping && this.data.element.is_grabbed && !this.data.preview_mode;
      },
      pointerEventsAttribute: function() {
        const store = this.data.store;
        const element = this.data.element;
        if (this.data.mobile_mode && element.edit && element.is_selected &&
            (store.ui.element_cropping_mode || store.mobile.view_mode === 'edit')) {
          return 'auto';
        }
        if (this.data.preview_mode) {
          return 'none';
        }
        if (Px.config.advanced_edit_mode) {
          return 'auto';
        }
        return this.data.element.edit ? 'auto' : 'none';
      },
      pointerEventHandlers: function() {
        let handlers = [];
        const element = this.data.element
        if (this.data.mobile_mode) {
          const store = this.data.store;
          if (element.edit && element.page && element.page.set === store.selected_set) {
            handlers = ['data-onclick="selectElement"'];
            if (this.isCropping) {
              handlers.push('data-ontouchstart="grabElement"');
            }
          }
        } else {
          handlers = [
            'data-onmousedown="grabElement"',
            'data-ontouchstart="grabElement"',
            'data-ondblclick="expandImageTab"',
            'data-onmouseenter="hoverElement"',
            'data-onmouseleave="unhoverElement"'
          ];
          if (Px.config.large_format &&
              element.edit && element.replace &&
              (element.placeholder || !element.id) &&
              !Px.config.advanced_edit_mode) {
            handlers.push('data-onclick="uploadDialog"');
          }
        }
        return Px.raw(handlers.join(' '));
      },
      maskId: function() {
        return `px-mask-${this._component_id}-${this.data.element.unique_id}`;
      },
      clipPathId: function() {
        return `px-clip-path-${this._component_id}-${this.data.element.unique_id}`;
      },
      src: function(params) {
        if (this.isLoading) {
          return this.state.cached_src || this.data.element.image.preview;
        }
        return this.hiresSrc;
      },
      maskSrc: function() {
        const mask_image = this.data.element.mask_image;
        if (!mask_image) {
          return null;
        }
        if (this.isMaskLoading) {
          return this.state.cached_mask_src || mask_image.preview;
        }
        return this.maskHiresSrc;
      },
      hiresSrc: function() {
        // "Freeze" the image while resizing/zooming  so that images do not
        // annoyingly start refreshing while manipulation is in progress.
        if (this.state.cached_src &&
            (this.data.element.is_resizing || this.data.store.ui.is_zooming)) {
          return this.state.cached_src;
        }

        const element = this.data.element;
        const params = {};

        params.size = this.imageSrcSize;

        if (element.color && (element.color !== '#000000')) {
          params.color = element.color.replace('#', '');
        }
        if (element.sepia) { params.sepia = true; }
        if (element.flip) { params.flip = true; }
        if (element.grayscale) { params.grayscale = true; }
        if (element.sharpen) { params.sharpen = true; }
        if (element.normalize) { params.normalize = true; }
        if (element.blur) { params.blur = true; }
        if (element.equalize) { params.equalize = true; }
        if (element.brightness) { params.brightness = true; }
        if (element.contrast) { params.contrast = true; }

        const src = this.data.element.image.src(params);
        // We cannot modify cached_src inside the computed function, so do it async.
        setTimeout(() => this.state.cached_src = src);
        return src;
      },
      maskHiresSrc: function() {
        // "Freeze" the image while resizing/zooming  so that images do not
        // annoyingly start refreshing while manipulation is in progress.
        if (this.state.cached_mask_src &&
            (this.data.element.is_resizing || this.data.store.ui.is_zooming)) {
          return this.state.cached_mask_src;
        }
        const params = {
          size: this.imageSrcSize
        };
        const src = this.data.element.mask_image.src(params);
        // We cannot modify cached_src inside the computed function, so do it async.
        setTimeout(() => this.state.cached_mask_src = src);
        return src;
      },
      imageSrcSize: function() {
        const size = this.data.scale * (Math.max.apply(null, this.data.element.svg_image_dimensions));
        return size * (window.devicePixelRatio || 1);
      },
      imageGroupTransformAttribute: function() {
        const element = this.data.element;
        const image_dimensions = element.svg_image_dimensions;
        const w = image_dimensions[0];
        const h = image_dimensions[1];
        // Translate the svg image to the center of the image frame.
        let transform = `translate(${(element.width - w)/2}, ${(element.height - h)/2})`;
        // Rotate around svg image's center.
        if (element.crotation) {
          transform += ` rotate(${element.crotation}, ${w/2}, ${h/2})`;
        }
        // Translate the svgImage according to the left & top attributes.
        const x = w * element.left/100;
        const y = h * element.top/100;
        transform += ` translate(${x}, ${y})`;
        return transform;
      },
      imageSizeBoxText: function() {
        const unit = this.data.store.theme.unit;
        let width = this.data.element.width;
        let height = this.data.element.height;
        let precision = 0;
        let unit_info = 'mm';
        if (unit === 'inch') {
          width = Px.Util.mm2in(width);
          height = Px.Util.mm2in(height);
          precision = 2;
          unit_info = 'in';
        }
        const w = width.toFixed(precision);
        const h = height.toFixed(precision);
        return Px.template`${w} &times; ${h} ${unit_info}`;
      },
      imageSizeBoxDimensions: function() {
        // Just some random values that seem to work fine enough.
        const width = this.imageSizeBoxText.length * 6;
        const height = 30;
        return {
          width: this.inSvgUnits(width),
          height: this.inSvgUnits(height)
        };
      },
      resolutionTooLow: function() {
        const element = this.data.element;
        // Show resolution warnings on uneditable images in admin, but not on the frontend.
        if (element.placeholder || !element.id || (!element.edit && !Px.config.advanced_edit_mode)) {
          return false;
        }
        const pixel_dimensions = element.image.dimensions;
        const target_dpi = this.data.store.project.minimum_dpi;
        const min_per_mm = target_dpi / Px.Util.mmPerInch;
        const aspect_ratio = pixel_dimensions[0] / pixel_dimensions[1];
        const svg_dimensions = element.svg_image_dimensions;
        const pixels_w = pixel_dimensions[0] - (pixel_dimensions[0] * element.zoom/100);
        const pixels_h = pixel_dimensions[1] - (pixel_dimensions[1] * element.zoom/100);
        return (pixels_w/svg_dimensions[0] < min_per_mm) || (pixels_h/svg_dimensions[1] < min_per_mm);
      },
      isBadlyCroppedCutPrint: function() {
        const element = this.data.element;
        if (!this.data.store.cut_print_mode) {
          return false;
        }
        if (element.zoom !== 0) {
          // If the placeholder's zoom isn't zero, we assume the user intentionally cropped the image
          // and don't display any warnings.
          return false;
        }
        if (!this.data.store.cut_print_mode || element.placeholder || !element.id || !element.edit) {
          return false;
        }
        const placeholder_area = element.width * element.height;
        const image_area = element.svg_image_dimensions[0] * element.svg_image_dimensions[1];
        const cropped_area = image_area - placeholder_area;
        if (image_area === 0) {
          return false;
        }
        return (cropped_area / image_area) > 0.1;
      },
      showBadlyCroppedCutPrintIcon: function() {
        // We only show the warning icon when we are in single photo view in cut print mode,
        // not when in all photos view, because the icons can make the user thing something
        // is terribly wrong.
        return this.data.store.selected_set && this.isBadlyCroppedCutPrint;
      },
      showImageSwapIcon: function() {
        if (this.data.render_controls && !this.data.preview_mode) {
          const element = this.data.element;
          return this.data.store.ui.image_swap_mode && element.edit && element.replace && !element.stretch;
        }
        return false;
      },
      iconPosition: function() {
        if (this.data.store.isAutorotatedCutPrint(this.data.element.page)) {
          return 'top right';
        } else {
          return 'top left';
        }
      },
      resolutionIconProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: ImageElement.icons.resolution_warning,
          title: Px.t('image resolution warning', 'Image resolution is low.'),
          position: this.iconPosition,
          width: 35 * this.iconScale,
          height: 35 * this.iconScale,
          x_offset: 15 * this.iconScale,
          y_offset: 15 * this.iconScale
        };
      },
      badlyCroppedIconProps: function() {
        const store = this.data.store;
        const element = this.data.element;
        let x_offset = 15;
        let y_offset = 15;
        if (this.resolutionTooLow) {
          if (store.isAutorotatedCutPrint(element.page)) {
            y_offset += 35;
          } else {
            x_offset += 35;
          }
        }
        return {
          store: store,
          element: element,
          scale: this.data.scale,
          icon: ImageElement.icons.badly_cropped_warning,
          title: Px.t('badly cropped warning', 'Image is cropped.'),
          position: this.iconPosition,
          width: 30 * this.iconScale,
          height: 30 * this.iconScale,
          x_offset: x_offset * this.iconScale,
          y_offset: y_offset * this.iconScale,
          popup_style: 'error',
        };
      },
      swapIconProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: ImageElement.icons.swap_image,
          position: 'center',
          width: 24 * this.iconScale,
          height: 24 * this.iconScale
        };
      },
      loadingIndicatorProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: ImageElement.icons.loading_indicator,
          title: Px.t('Loading...'),
          position: 'center',
          width: 40 * this.iconScale,
          height: 20 * this.iconScale
        };
      },
      failedIndicatorProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: ImageElement.icons.failed_indicator,
          title: Px.t('Image failed to load'),
          position: 'center',
          width: 38 * this.iconScale,
          height: 38 * this.iconScale
        };
      }
    });
  }

  borderwrapClipPathId(position) {
    return `px-clip-path-bw-${position}-${this._component_id}-${this.data.element.unique_id}`;
  }

  borderwrapClipPathAttributes(position) {
    const element = this.data.element;
    let x = 0;
    let y = 0;
    let width = element.borderwrap;
    let height = element.borderwrap;

    switch (position) {
    case 'top-left':
      break;
    case 'top':
      x = element.borderwrap;
      width = element.width - (2 * element.borderwrap);
      break;
    case 'top-right':
      x = element.width - element.borderwrap;
      break;
    case 'right':
      x = element.width - element.borderwrap;
      y = element.borderwrap;
      height = element.height - (2 * element.borderwrap);
      break;
    case 'bottom-right':
      x = element.width - element.borderwrap;
      y = element.height - element.borderwrap;
      break;
    case 'bottom':
      x = element.borderwrap;
      y = element.height - element.borderwrap;
      width = element.width - (2 * element.borderwrap);
      break;
    case 'bottom-left':
      y = element.height - element.borderwrap;
      break;
    case 'left':
      y = element.borderwrap;
      height = element.height - (2 * element.borderwrap);
      break;
    }

    return {x: x, y: y, width: width, height: height};
  }

  borderwrapImageScale(position) {
    let scale_x = 1;
    let scale_y = 1;

    switch (position) {
    case 'top-left':
      scale_x = -1;
      scale_y = -1;
      break;
    case 'top':
      scale_y = -1;
      break;
    case 'top-right':
      scale_x = -1;
      scale_y = -1;
      break;
    case 'right':
      scale_x = -1;
      break;
    case 'bottom-right':
      scale_x = -1;
      scale_y = -1;
      break;
    case 'bottom':
      scale_y = -1;
      break;
    case 'bottom-left':
      scale_x = -1;
      scale_y = -1;
      break;
    case 'left':
      scale_x = -1;
      break;
    }

    return [scale_x, scale_y];
  }

  borderwrapImageTranslation(position) {
    // When positioning mirrored borders, we nudge them half a pixel inwards
    // to avoid ugly 1px white strips due to rounding errors.
    const epsilon = this.inSvgUnits(0.5);
    let tx = 0;
    let ty = 0;

    switch (position) {
    case 'top-left':
      tx = epsilon;
      ty = epsilon;
      break;
    case 'top':
      ty = epsilon;
      break;
    case 'top-right':
      tx = -epsilon;
      ty = epsilon;
      break;
    case 'right':
      tx = -epsilon;
      break;
    case 'bottom-right':
      tx = -epsilon;
      ty = -epsilon;
      break;
    case 'bottom':
      ty = -epsilon;
      break;
    case 'bottom-left':
      tx = epsilon;
      ty = -epsilon;
      break;
    case 'left':
      tx = epsilon;
      break;
    }

    return [tx, ty];
  }

  // --------------
  // Event handlers
  // --------------

  expandImageTab(evt) {
    if (this.isSelected) {
      // Usually when you're working with an image, the image tab will already be automatically expanded.
      // But there are cases such as when working with clipart where you want a click on an already selected
      // image to force expand the image tab.
      this.data.store.ui.expandTab('images');
    }
  }

  dragCrop(evt) {
    const oevt = evt.originalEvent;
    let pageX = 'pageX' in oevt ? oevt.pageX : oevt.targetTouches[0].pageX;
    let pageY = 'pageY' in oevt ? oevt.pageY : oevt.targetTouches[0].pageY;

    const element = this.data.element;
    const image_width = element.svg_image_dimensions[0];
    const image_height = element.svg_image_dimensions[1];
    const drag_origin = this.data.store.ui.current_drag.origin;

    // The change of x and y in the user's coordinate system.
    let dx = this.inSvgUnits(pageX - drag_origin.pageX);
    let dy = this.inSvgUnits(pageY - drag_origin.pageY);

    let origin_left = drag_origin.element_left;
    let origin_top = drag_origin.element_top;

    let zoom = element.zoom;
    const touches = oevt && oevt.touches ? oevt.touches : [];
    if (touches.length === 2) {
      if (this._pinch_origin === null) {
        this.startCropPinch(evt);
        return;
      } else {
        const distance = this._getPinchDistance(touches[0], touches[1]);
        const pinch_center = this._getPinchCenter(touches[0], touches[1]);
        zoom = (100 + this._pinch_origin.zoom) * (distance / this._pinch_origin.distance) - 100;
        zoom = Math.max(0, zoom);
        zoom = Math.min(Px.Editor.ImageElementModel.MAX_ZOOM, zoom);
        dx = this.inSvgUnits(pinch_center.x - this._pinch_origin.center.x);
        dy = this.inSvgUnits(pinch_center.y - this._pinch_origin.center.y);
        origin_left = this._pinch_origin.left;
        origin_left = this._pinch_origin.top;
      }
    }

    if (this.data.store.isAutorotatedCutPrint(element.page)) {
      // Swap dx and dy.
      [dx, dy] = [-dy, dx];
    }

    // The change of x and y in the element's (possibly rotated)
    // coordinate system.
    const rotated = Px.Util.rotatePoint(dx, dy, element.absolute_rotation);
    const shift_x = rotated[0];
    const shift_y = rotated[1];

    const transposed = Px.Util.rotatePoint(shift_x, shift_y, element.crotation);
    const left = (transposed[0] * 100/image_width) + drag_origin.element_left;
    const top = (transposed[1] * 100/image_height) + drag_origin.element_top;

    element.update({
      left: left,
      top: top,
      zoom: zoom
    });
  }

  startCropPinch(evt) {
    const store = this.data.store;
    const element = this.data.element;
    const touches = evt.originalEvent.touches;

    this._pinch_origin = {
      distance: this._getPinchDistance(touches[0], touches[1]),
      center: this._getPinchCenter(touches[0], touches[1]),
      zoom: element.zoom,
      left: element.left,
      top: element.top
    };

    store.ui.is_zooming = true;
  }

  uploadDialog(evt) {
    const input = $j('<input type="file" style="display:none;"/>');
    // Chrome sometimes fails to trigger the onchange handler if we don't add the input element to the DOM.
    input.appendTo('body');

    input[0].onchange = evt => {
      const files = Px.LocalFiles.Validation.filterAndValidateFiles(evt.target.files);
      if (files) {
        this.data.store.galleries.project.importImage(files[0], image => {
          mobx.runInAction(() => {
            const image_store = this.data.store.images;
            if (!image_store.get(image.id)) {
              image_store.register(image.id, image.data);
            }
            // Have to go through main image store to make sure local ID gets dereferenced,
            // if image is already uploaded.
            const registered_image = image_store.get(image.id);

            this.withUndo('replace image', () => {
              this.data.element.update({id: registered_image.id, placeholder: false});
            });
          });
        });
      }
      input.remove();
    };

    input.click();
  }

  cancelSwap(evt) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }
    evt.stopPropagation();
    this.data.store.ui.disableImageSwapMode();
  }

  swapImage(evt) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }
    evt.stopPropagation();

    this.withUndo('swap image', () => {
      const store = this.data.store;
      const target_image = this.data.element;
      const target_image_id = target_image.id;
      const target_placeholder_flag = target_image.placeholder;
      const source_image = this.data.store.ui.image_swap_source;
      const source_image_id = source_image.id;
      const new_target_attrs = {
        id: source_image_id,
        placeholder: false
      };
      const new_source_attrs = {
        id: target_image_id,
        placeholder: target_placeholder_flag
      };
      const element_ar_matches = target_image.aspect_ratio === source_image.aspect_ratio;
      const image_ar_matches = (target_image.image && source_image.image) &&
                               (target_image.image.aspect_ratio === source_image.image.aspect_ratio);
      if (element_ar_matches && image_ar_matches) {
        new_target_attrs.left = source_image.left;
        new_target_attrs.top = source_image.top;
        new_target_attrs.zoom = source_image.zoom;
        new_source_attrs.left = target_image.left;
        new_source_attrs.top = target_image.top;
        new_source_attrs.zoom = target_image.zoom;
      } else {
        new_target_attrs.left = 0;
        new_target_attrs.top = 0;
        new_target_attrs.zoom = 0;
        new_source_attrs.left = 0;
        new_source_attrs.top = 0;
        new_source_attrs.zoom = 0;
      }
      const element_to_select = target_image.group || target_image;

      mobx.runInAction(() => {
        target_image.update(new_target_attrs);
        source_image.update(new_source_attrs);
        store.selectSet(element_to_select.page.set);
        store.selectElement(element_to_select);
      });
    });
  }

  // -------
  // Private
  // -------

  _getPinchDistance(t1, t2) {
    return Math.sqrt(Math.pow(t2.pageX - t1.pageX, 2) + Math.pow(t2.pageY - t1.pageY, 2));
  }

  // Returns center point of pinch relative to top-left corner of the containing div.
  _getPinchCenter(t1, t2) {
    return {
      x: (t1.pageX + t2.pageX) / 2,
      y: (t1.pageY + t2.pageY) / 2
    };
  }

};

Px.Editor.ImageElement.icons = {
  resolution_warning: '<svg width="24" height="23" viewBox="-4 -4 32 31"><rect x="-4" y="-4" width="100%" height="100%" rx="10" ry="10" fill-opacity="0.6" fill="#fff" /><line stroke-width="2" stroke="#fff" x1="12" y1="3" x2="12" y2="18"/><path d="M10.03 3.50618C10.886 2.02268 13.111 2.02268 13.967 3.50618L21.713 16.9238C22.543 18.3613 21.458 20.1246 19.744 20.1246H4.25401C2.53901 20.1246 1.45401 18.3613 2.28401 16.9238L10.03 3.50714V3.50618ZM12.997 16.2913C13.0012 16.1631 12.9786 16.0354 12.9303 15.9157C12.882 15.7961 12.8091 15.687 12.716 15.5948C12.6229 15.5027 12.5114 15.4295 12.3881 15.3794C12.2648 15.3294 12.1324 15.3036 11.9985 15.3036C11.8647 15.3036 11.7322 15.3294 11.6089 15.3794C11.4857 15.4295 11.3742 15.5027 11.281 15.5948C11.1879 15.687 11.115 15.7961 11.0667 15.9157C11.0185 16.0354 10.9958 16.1631 11 16.2913C11.0082 16.5398 11.117 16.7756 11.3034 16.9486C11.4897 17.1216 11.739 17.2184 11.9985 17.2184C12.258 17.2184 12.5073 17.1216 12.6936 16.9486C12.88 16.7756 12.9888 16.5398 12.997 16.2913ZM12.738 8.76551C12.7121 8.58502 12.6156 8.4207 12.4681 8.30577C12.3206 8.19085 12.1331 8.1339 11.9434 8.14644C11.7537 8.15897 11.5761 8.24007 11.4465 8.37332C11.3168 8.50657 11.2448 8.68203 11.245 8.86422L11.249 13.1777L11.256 13.2754C11.2819 13.4559 11.3784 13.6202 11.5259 13.7352C11.6734 13.8501 11.861 13.907 12.0506 13.8945C12.2403 13.882 12.4179 13.8009 12.5476 13.6676C12.6772 13.5344 12.7492 13.3589 12.749 13.1767L12.745 8.86231L12.738 8.76551Z" fill="var(--icon-warning-color)"/></svg>',
  badly_cropped_warning: '<svg width="20" height="20" viewBox="0 0 20 20"><polygon points="10,1 19,19 1,19" fill="#ffffff" stroke-width="1.5" stroke="#ff5216" stroke-linejoin="round" /><g transform="translate(5 7.5) scale(0.01)"><circle cx="500.8" cy="502.2" r="11.7"/><path fill="#ff5216" d="M235.6,831c16.6-50.5,62.4-81.1,101.9-68c39.6,13,58.2,64.7,41.6,115.3c-16.6,50.5-62.4,81.1-101.9,68.1c-16.1-5.2-29.1-17-37.7-34C227.7,888.9,226.3,859.3,235.6,831L235.6,831z M551.2,505.5c14.9-36.1,88.3-210.7,122.2-332.9C712,33.4,704.2,11.3,691.9,10L450.4,505.5c-50.1,107.2-28.7,113.7-80.9,156.7c-33.2,27.3-81.3,53.3-105.4,72c-29.8,16.7-54.7,46.2-67.2,84c-12.7,38.5-10.4,79.6,6.3,112.5c13.3,26.5,35.2,45.8,61.3,54.4c60.9,20,125-23.9,153.4-94.1c18.1-44.9,7.1-168.5,18.8-212.1c7.9-29.5,37.5-66.6,64.1-98.1C518.8,561.1,536.3,541.7,551.2,505.5z"/><path fill="#ff5216" d="M760.4,912.3c-8.6,17-21.6,28.8-37.6,34c-39.6,13-85.2-17.6-102-68.1c-16.6-50.5,2.1-102.3,41.6-115.4c39.6-13,85.3,17.6,102,68.1C773.7,859.3,772.3,888.9,760.4,912.3L760.4,912.3z M499.2,580.8c26.6,31.5,56.3,68.6,64.1,98.2c11.7,43.6,0.6,167.1,18.7,212c28.4,70.2,92.5,114.2,153.5,94.2c26.2-8.6,48.1-27.9,61.4-54.4c16.6-33,19-74,6.3-112.5c-12.5-37.8-37.4-67.3-67.1-84c-24.2-18.7-72.3-44.8-105.5-72c-52.3-42.9-30.9-49.5-80.9-156.7L308.1,10c-12.3,1.3-20.1,23.4,18.5,162.6c33.9,122.2,107.3,296.8,122.2,332.9C463.7,541.7,481.2,561.1,499.2,580.8z"/></g></svg>',
  loading_indicator: '<svg width="6" height="2" viewBox="0 0 6 2" class="px-image-loading-indicator"><circle class="px-circ-2" cx="3" cy="1" r="0.5" fill="#000000" opacity="0.5" stroke="#ffffff" stroke-width="0.25"></circle><circle class="px-circ-1" cx="1" cy="1" r="0.5" fill="#000000" opacity="0.5" stroke="#ffffff" stroke-width="0.25"></circle><circle class="px-circ-3" cx="5" cy="1" r="0.5" fill="#000000" opacity="0.5" stroke="#ffffff" stroke-width="0.25"></circle></svg>',
  failed_indicator: '<svg width="38" height="38" viewBox="-6 -6 38 38"><g stroke-width="1" fill="none" stroke="#ff0000" opacity="0.5" fill-rule="evenodd"><g transform="translate(-1123.000000, -19.000000)"><g transform="translate(1096.000000, 0.000000)"><g transform="translate(28.000000, 20.000000)"><polyline stroke-linejoin="round" points="20 19 15.7226667 9 11.9875556 16.4710477 8.25125926 12.395637 4 19"></polyline><polygon points="24 24 0 24 0.024 0.023976024 24 0"></polygon><path d="M0,19 L24,19"></path><path d="M8,7 C8,8.105 7.1045,9 6,9 C4.895,9 4,8.105 4,7 C4,5.896 4.895,5 6,5 C7.1045,5 8,5.896 8,7 L8,7 Z" stroke-linejoin="round"></path></g></g></g><line x1="32" y1="-6" x2="-6" y2="32" /></g></svg>',
  swap_image: '<svg width="10" height="10" viewBox="0 0 23 23" fill="none"><path d="M14.635 2.86497C14.5406 2.76365 14.4267 2.68238 14.3002 2.62602C14.1737 2.56965 14.0372 2.53935 13.8987 2.5369C13.7603 2.53446 13.6227 2.55993 13.4943 2.6118C13.3659 2.66366 13.2492 2.74086 13.1513 2.83879C13.0534 2.93671 12.9762 3.05336 12.9243 3.18177C12.8725 3.31018 12.847 3.44772 12.8494 3.58619C12.8519 3.72465 12.8822 3.86121 12.9386 3.98771C12.9949 4.11421 13.0762 4.22806 13.1775 4.32247L14.855 5.99997H5.65625C5.38275 5.99997 5.12044 6.10862 4.92705 6.30201C4.73365 6.49541 4.625 6.75771 4.625 7.03122C4.625 7.30472 4.73365 7.56702 4.92705 7.76042C5.12044 7.95382 5.38275 8.06247 5.65625 8.06247H14.855L13.1775 9.73997C12.9953 9.93546 12.8962 10.194 12.9009 10.4612C12.9056 10.7283 13.0138 10.9833 13.2028 11.1722C13.3917 11.3611 13.6466 11.4694 13.9138 11.4741C14.1809 11.4788 14.4395 11.3796 14.635 11.1975L18.0725 7.75997C18.2656 7.56661 18.3741 7.3045 18.3741 7.03122C18.3741 6.75794 18.2656 6.49583 18.0725 6.30247L14.635 2.86497ZM9.8225 13.26C10.0047 13.0645 10.1038 12.8059 10.0991 12.5387C10.0944 12.2716 9.98617 12.0167 9.79723 11.8277C9.60829 11.6388 9.35338 11.5306 9.08622 11.5259C8.81905 11.5211 8.56049 11.6203 8.365 11.8025L4.9275 15.24C4.73438 15.4333 4.62591 15.6954 4.62591 15.9687C4.62591 16.242 4.73438 16.5041 4.9275 16.6975L8.365 20.135C8.56049 20.3171 8.81905 20.4163 9.08622 20.4116C9.35338 20.4069 9.60829 20.2986 9.79723 20.1097C9.98617 19.9208 10.0944 19.6658 10.0991 19.3987C10.1038 19.1315 10.0047 18.873 9.8225 18.6775L8.145 17H17.3438C17.6173 17 17.8796 16.8913 18.073 16.6979C18.2663 16.5045 18.375 16.2422 18.375 15.9687C18.375 15.6952 18.2663 15.4329 18.073 15.2395C17.8796 15.0461 17.6173 14.9375 17.3438 14.9375H8.145L9.8225 13.26Z" fill="white"/></svg>'
};
