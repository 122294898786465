Px.Editor.TaggedItemsPanel = class TaggedItemsPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    if (this.hasCustomTags) {
      return Px.template`
        <div class="px-tab-panel-accordion-wrapper">
          ${r(Px.Components.Accordion, 'accordion', this.accordionProperties)}
        </div>
      `;
    } else {
      return Px.template`
        <div class="px-panel-scrollable-section">
          ${r(this.data.list_component, 'list-component', {items: this.data.items})}
        </div>
      `;
    }
  }

  get dataProperties() {
    return {
      store: {required: true},
      items: {required: true},
      list_component: {required: true}
    };
  }

  static get properties() {
    return {
      selected_tag: {std: null}
    };
  }

  constructor(data) {
    super(data);
    // Open the first item by default.
    this.state.selected_tag = this.availableTags[0] || null;
  }

  static get computedProperties() {
    return {
      defaultTag: function() {
        return Px.t('Other');
      },
      accordionProperties: function() {
        const items = this.availableTags.map(tag => {
          return {
            id: tag,
            title: tag,
            component: this.data.list_component,
            data: {
              store: this.data.store,
              items: this.itemsByTag[tag]
            }
          };
        });
        return {
          items: items,
          expanded_item_id: this.state.selected_tag || null
        };
      },
      itemsByTag: function() {
        const items_by_tag = {};
        this.data.items.forEach(item => {
          if (item.tags.length) {
            item.tags.forEach(tag => {
              if (!items_by_tag[tag]) {
                items_by_tag[tag] = [];
              }
              items_by_tag[tag].push(item);
            });
          } else {
            if (!items_by_tag[this.defaultTag]) {
              items_by_tag[this.defaultTag] = [];
            }
            items_by_tag[this.defaultTag].push(item);
          }
        });
        return items_by_tag;
      },
      availableTags: function() {
        let tags = Object.keys(this.itemsByTag).sort();
        const special_tags = [this.defaultTag];
        special_tags.forEach(special_tag => {
          if (tags.includes(special_tag)) {
            tags = tags.filter(tag => tag !== special_tag);
            tags.push(special_tag);
          }
        });
        return tags;
      },
      hasCustomTags: function() {
        return this.availableTags.some(tag => tag !== this.defaultTag);
      }
    };
  }

};
