Px.Editor.PdfStore = class PdfStore extends Px.BaseStore {

  constructor(options) {
    super();
    this.onPdfUploaded = options.onPdfUploaded;
    this.uploader = Px.LocalFiles.Uploader.make('/upload/pdf');
  }

  static get properties() {
    return {
      _pdfs: {std: mobx.observable.map()},
      _replaced_pdfs: {std: mobx.observable.map()}
    };
  }

  static get computedProperties() {
    return {
      pdfs: function() {
        return _.sortBy(Array.from(this._pdfs.values()), 'order');
      },
      local_pdfs: function() {
        return this.pdfs.filter(function(pdf) {
          return pdf.type === 'local';
        });
      }
    };
  }

  get actions() {
    return {
      register: function(pdf_id, attrs, opts) {
        if (this.get(pdf_id)) {
          console.error(`Cannot register PDF ${pdf_id}; PDF already exists`);
          return;
        }
        const model = Px.Editor.BasePdfModel.factory(pdf_id, attrs);
        if (!model) {
          throw new Error(`Unsupported PDF type: ${pdf_id}`);
        }

        model.order = this._pdfs.size;
        this._pdfs.set(pdf_id, model);

        if (model.type === 'local' && model.data.file) {
          const local_file = Px.LocalFiles.LocalFileModel.make(model.data.file);
          model.data.local_file = local_file;
          mobx.when(() => local_file.upload_success, () => this.onFileUploaded(model, local_file.upload_response));
          this.uploader.uploadFile(local_file);
        }

        return this.get(pdf_id);
      },

      onFileUploaded: function(local_pdf, data) {
        const database_pdf = this.register(`db:${data.id}`, data);
        database_pdf.order = local_pdf.order;
        this._pdfs.delete(local_pdf.id);
        // Store reference to the new database PDF under the old local key into _replaced_pdfs.
        // We need this so that we can properly resolve any 'local:*' PDFs that have been stored
        // in page snapshots, for example.
        this._replaced_pdfs.set(local_pdf.id, database_pdf);
        local_pdf.destroy();
        if (this.onPdfUploaded) {
          this.onPdfUploaded.call(null, local_pdf.id, database_pdf.id);
        }
      }
    };
  }

  uploadedAsLocalFileThisSession(db_pdf) {
    const entry = Array.from(this._replaced_pdfs.entries()).find(entry => entry[1] === db_pdf);
    return Boolean(entry && entry[0].match(/^local:/));
  }

  // Returns a PDF by id.
  get(pdf_id) {
    return this._pdfs.get(pdf_id) || this._replaced_pdfs.get(pdf_id) || null;
  }

};
