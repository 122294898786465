Px.Editor.InlinePageMaskPanel = class InlinePageMaskPanel extends Px.Editor.BaseComponent {
  template() {
    const selected_element = this.data.store.selected_element;
    return Px.template`
      <div class="px-tab-content-panel px-inline-page-mask-panel">
        <div class="px-inline-page-masks px-image-selection">
          ${this.images.map(image => {
            const is_selected = Boolean(selected_element && selected_element.mask === image.id);
            return Px.template`
              <div class="px-image"
                   data-selected="${is_selected}"
                   data-image-id="${image.id}"
                   data-onclick="selectMask">
                <div class="px-thumbnail">
                  <img src="${image.preview}" />
                </div>
                ${is_selected ? Px.raw(Px.Editor.BaseGalleryPanel.icons.tick_mark) : ''}
              </div>
            `;
          })}
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      images: function() {
        const store = this.data.store;
        const theme_resources_store = store.galleries.theme_resources;
        const image_store = store.images;
        const images = theme_resources_store.images.map(image => image_store.get(image.id));
        // There may be local images in the gallery that haven't finished uploading yet. Filter these out.
        return images.filter(image => image);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  selectMask(evt) {
    const ipage = this.data.store.selected_element;
    const mask_id = evt.currentTarget.getAttribute('data-image-id');
    if (mask_id === ipage.mask) {
      this.withUndo('remove ipage mask', () => {
        ipage.update({mask: null});
      });
    } else {
      this.withUndo('set ipage mask', () => {
        ipage.update({mask: mask_id});
      });
    }
  }

};
