Px.Editor.Notification = class Notification extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-notification px-notification-${this.notification.notification_type}"
          data-is-disposing="${this.notification.is_disposing}">
        <p>${this.notification.message}</p>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true},
      notification: {required: true}
    };
  }

  static get computedProperties() {
    return {
      notification: function() {
        return this.data.notification;
      }
    };
  }

};
