Px.Editor.UneditedElementsModal = class UneditedElementsModal extends Px.Components.BaseModal {

  get title() {
    return Px.t('Unedited Placeholders');
  }

  get content() {
    return Px.t('You have unedited placeholders. Continue to cart?');
  }

  get footer() {
    return Px.template`
      <button class="px-cancel" data-onclick="cancel">
        ${Px.t('Cancel and Fix')}
      </button>
      <button class="px-ok" data-onclick="saveAndContinue">
        ${Px.t('Continue Anyway')}
      </button>
    `;
  }

  get css_class() {
    return `${super.css_class} px-confirmation-modal`;
  }

  constructor(props) {
    super(props);
    this.close_on_background_click = false;
  }

  get dataProperties() {
    return {
      saveAndContinue: {required: true},
      cancel: {required: true}
    }
  }

  // --------------
  // Event handlers
  // --------------

  saveAndContinue(evt) {
    this.data.saveAndContinue();
    this.destroy();
  }

  cancel(evt) {
    this.data.cancel();
    this.destroy();
  }

};
