Px.LocalFiles.LocalFileModel = class LocalFileModel extends Px.BaseModel {

  constructor(file, opts) {
    opts = opts || {};
    super();
    this.file = file;
  }

  static get properties() {
    return {
      file: {type: 'any', std: null},
      upload_queued: {type: 'bool', std: true},
      upload_in_progress: {type: 'bool', std: false},
      upload_progress_percent: {type: 'float', std: 0},
      upload_tries: {type: 'int', std: 0},
      upload_success: {type: 'bool', std: false},
      upload_response: {type: 'any', std: null},
      upload_failure: {type: 'bool', std: false},
      upload_error: {type: 'str', std: null}
    };
  }


  static get computedProperties() {
    return {
      // Resizable image types will be automatically downscaled in the browser if they
      // are larger than `Px.config.upload_size`.
      // Note that some images types (for example heic) are not natively supported by
      // browsers, so we don't want to resize them until we have a high quality solution
      // that respects color profiles.
      is_resizable_image: function() {
        const type = this.file.type;
        return type === 'image/jpeg' || type === 'image/png' || type === 'image/webp';
      }
    };
  }

  get actions() {
    return Object.assign(super.actions, {
      queueForUpload: function() {
        this.update({
          upload_in_progress: false,
          upload_progress_percent: 0,
          upload_queued: true
        });
      },
      onUploadStarted: function() {
        this.update({
          upload_in_progress: true,
          upload_queued: false,
          upload_tries: this.upload_tries + 1,
          upload_progress_percent: 0
        });
      },
      onUploadProgress: function(progress) {
        this.upload_progress_percent = progress;
      },
      onUploadSuccess: function(response) {
        this.update({
          upload_success: true,
          upload_response: response,
          upload_failure: false,
          upload_error: null,
          upload_in_progress: false,
          upload_progress_percent: 100
        });
      },
      onUploadError: function(errmsg) {
        this.update({
          upload_in_progress: false,
          upload_progress_percent: 0,
          upload_failure: true,
          upload_error: errmsg
        });
      }
    });
  }

};
