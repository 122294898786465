Px.Editor.MobileTextContentPanel = class MobileTextContentPanel extends Px.Editor.BaseComponent {
  template() {
    const text_element = this.data.store.selected_element;
    return Px.template`
      <div class="px-mobile-text-content-editor">
        <div class="px-controls" data-onclick="openModal">
          <textarea disabled>${text_element.text}</textarea>
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  // --------------
  // Event handlers
  // --------------

  openModal(evt) {
    const store = this.data.store;
    this.makeModal(Px.Editor.TextEditModal, {
      store: store,
      text_model: store.selected_element
    });
  }

  setText(evt) {
    const text_value = this.dom_node.querySelector('textarea').value;
    this.data.store.selected_element.update({text: text_value, placeholder: false});
  }
};

Px.Editor.MobileTextAlignmentPanel = class MobileTextAlignmentPanel extends Px.Editor.BaseComponent {
  template() {
    return Px.template`
      <div class="px-mobile-text-alignment-editor">
        <div class="px-controls">
          ${this.renderChild(Px.Editor.TextAlignmentButtons, 'text-alignment-buttons', this.buttonsProps)}
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);
    this.setValue = this.setValue.bind(this);
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      buttonsProps: function() {
        return {
          value: this.data.store.selected_element.align,
          onNewValue: this.setValue
        };
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  setValue(align) {
    this.data.store.selected_element.update({align: align});
  }

};

Px.Editor.MobileTextFontPanel = class MobileTextFontPanel extends Px.Editor.BaseComponent {
  template() {
    return Px.template`
      <div class="px-mobile-text-font-editor">
        <div class="px-controls">
          <select data-onchange="setFont">
            ${this.fontOptions.map(font => {
              return Px.template`
                <option value="${font.value}"
                        ${font.selected ? 'selected' : ''}>
                  ${font.name}
                </option>
              `;
            })}
          </select>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    const store = this.data.store;

    this.registerReaction(() => {
      return store.selected_element.fontpalette;
    }, (palette_id) => {
      store.font_palettes.load(palette_id);
    }, {
      fireImmediately: true,
      name: 'Px.Editor.MobileTextFontPanel::LoadFontPaletteReaction'
    });
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      fontOptions: function() {
        const store = this.data.store;
        const text_element = store.selected_element;
        const palette = store.font_palettes.get(text_element.fontpalette);
        if (!palette) {
          return [];
        }
        return palette.fonts.map((font) => {
          return {
            value: font.id,
            name: font.name,
            selected: String(font.id) === String(text_element.font)
          };
        });
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  setFont(evt) {
    const font_id = evt.currentTarget.value;
    this.data.store.selected_element.update({font: font_id});
  }

};

Px.Editor.MobileTextSizePanel = class MobileTextSizePanel extends Px.Editor.BaseComponent {
  template() {
    const current_size = this.data.store.selected_element.pointsize;
    return Px.template`
      <div class="px-mobile-text-size-editor">
        <div class="px-controls">
          <select data-onchange="setSize">
            ${this.fontSizeOptions.map(size => {
              return Px.template`
                <option value="${size}"
                        ${size === current_size ? 'selected' : ''}>
                  ${size}
                </option>
              `;
            })}
          </select>
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      fontSizeOptions: function() {
        const current_size = this.data.store.selected_element.pointsize;
        const start = Math.max(1, current_size - 32);
        const end = current_size + 32;
        const sizes = [];
        for (let i = start; i <= end; i++) {
          sizes.push(i);
        }
        return sizes;
      }
    }
  }

  // --------------
  // Event handlers
  // --------------

  setSize(evt) {
    const size = parseInt(evt.currentTarget.value, 10);
    this.data.store.selected_element.update({pointsize: size});
  }

};
