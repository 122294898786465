Px.Editor.UndoRedoStore = class UndoRedoStore extends Px.BaseStore {

  constructor(main_store) {
    super();
    this.main_store = main_store;
    this.project_store = this.main_store.project;
  }

  static get properties() {
    return {
      undo_queue: {std: mobx.observable.array()},
      redo_queue: {std: mobx.observable.array()},
      transaction_idx: {std: 0}
    };
  }

  static get computedProperties() {
    return {
      can_undo: function() {
        return this.undo_queue.length > 0;
      },
      can_redo: function() {
        return this.redo_queue.length > 0;
      },
      in_transaction: function() {
        return this.transaction_idx > 0;
      }
    };
  }

  get actions() {
    return {
      undo: function() {
        if (this.can_undo) {
          var undo_snapshot = this.undo_queue.pop();
          var redo_snapshot = this.makeSnapshot(undo_snapshot.metadata);
          this.redo_queue.push(redo_snapshot);
          console.debug('Undoing', mobx.toJS(undo_snapshot.metadata));
          this._activateSnapshot(undo_snapshot);
        } else {
          console.debug('Nothing to undo.');
        }
      },
      redo: function() {
        if (this.can_redo) {
          var redo_snapshot = this.redo_queue.pop();
          var undo_snapshot = this.makeSnapshot(redo_snapshot.metadata);
          this.undo_queue.push(undo_snapshot);
          console.debug('Redoing', mobx.toJS(redo_snapshot.metadata));
          this._activateSnapshot(redo_snapshot);
        } else {
          console.debug('Nothing to redo.');
        }
      },
      withUndo: function(fn, metadata) {
        var end = this.beginWithUndo(metadata);
        var error = null;
        try {
          fn();
        } catch(e) {
          error = e;
        }
        end();
        if (error) {
          throw error;
        }
      },
      beginWithUndo: function(metadata) {
        var self = this;
        this._enterTransaction(metadata);
        var end_fn = function() {
          self._exitTransaction(metadata);
        };
        return end_fn;
      }
    };
  }

  // -------
  // Private
  // -------

  makeSnapshot(metadata) {
    return Px.Editor.UndoSnapshotModel.make({
      layout_snapshot: this.project_store.layout_snapshot,
      options_snapshot: new Map(this.project_store.options),
      template_options_snapshot: new Map(this.project_store.template_options),
      metadata: metadata
    });
  }

  _activateSnapshot(snapshot) {
    var set_id = snapshot.metadata.set_id;
    if (!set_id && this.main_store.selected_set) {
      set_id = this.main_store.selected_set.id;
    }
    this.project_store.setLayout(snapshot.layout_snapshot);
    this.project_store.options.replace(snapshot.options_snapshot);
    this.project_store.template_options.replace(snapshot.template_options_snapshot);
    var set = this.project_store.getSetById(set_id);
    if (set) {
      this.main_store.selectSet(set);
    }
  }

  _enterTransaction(metadata) {
    if (!this.in_transaction) {
      console.debug('-> Entering undo transaction', metadata);
      var snapshot_model = this.makeSnapshot(metadata);
      this._snapshot_model = snapshot_model;
    }
    this.transaction_idx++;
  }

  _exitTransaction(metadata) {
    this.transaction_idx--;
    if (!this.in_transaction) {
      console.debug('<- Exiting undo transaction', metadata);
      // Only store the snapshot if anything changed while user was performing the action.
      const layout_snapshot = mobx.toJS(this._snapshot_model.layout_snapshot);
      const options_snapshot = this._snapshot_model.options_snapshot;
      const template_options_snapshot = this._snapshot_model.template_options_snapshot;
      if (!(_.isEqual(layout_snapshot, this.project_store.layout_snapshot) &&
            Px.Util.isMapEqual(options_snapshot, this.project_store.options) &&
            Px.Util.isMapEqual(template_options_snapshot, this.project_store.template_options))) {
        this.undo_queue.push(this._snapshot_model);
        if (this.undo_queue.length > UndoRedoStore.MAX_QUEUE_SIZE) {
          this.undo_queue.shift();
        }
        this.redo_queue.clear();
      }
    }
  }

};

Px.Editor.UndoRedoStore.MAX_QUEUE_SIZE = 250;
