Px.Components.Spinner = class Spinner extends Px.Component {
  template() {
    return Px.template`
      <span class="px-spinner" data-active="${this.data.active}" style="${this.style}">
        <span class="px-spinner-inner">
          <svg width="${this.data.size}"
               height="${this.data.size}"
               viewBox="-1 -1 12 12"
               version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M 5 0 A 5 5 0 0 1 5 10"
                  fill="none"
                  stroke="${this.data.color}"
                  stroke-width="1"
                  stroke-linecap="round"
            />
          </svg>
        </span>
      </span>
    `;
  }

  get dataProperties() {
    return {
      size: {std: 40},
      color: {std: 'var(--icon-disabled-color)'},
      active: {std: true}
    };
  }

  static get computedProperties() {
    return {
      style: function() {
        return `width:${this.data.size}px; height:${this.data.size}px;`;
      }
    };
  }
};
