Px.Editor.ClipboardStore = class ClipboardStore extends Px.BaseStore {

  constructor(image_store, pdf_store) {
    super();
    this.image_store = image_store;
    this.pdf_store = pdf_store;

    // Set up cross-tab copy/paste handler.
    this.broadcast_channel = null;
    if ('BroadcastChannel' in window) {
      this.broadcast_channel = new BroadcastChannel('clipboard-store-channel');
      this.broadcast_channel.onmessage = evt => {
        mobx.runInAction(() => {
          evt.data.images.forEach(image_attrs => {
            if (!this.image_store.get(image_attrs.id)) {
              this.image_store.register(image_attrs.id, image_attrs.data);
            }
          });
          evt.data.pdfs.forEach(pdf_attrs => {
            if (!this.pdf_store.get(pdf_attrs.id)) {
              this.pdf_store.register(pdf_attrs.id, pdf_attrs.data);
            }
          });
          this.copied_element_xml = evt.data.xml;
        });
      };
    }
  }

  static get properties() {
    return {
      copied_element_xml: {std: null},
    };
  }

  static get computedProperties() {
    return {
      empty: function() {
        return this.copied_element_xml === null;
      }
    };
  }

  get actions() {
    return {
      copy: function(element) {
        this.copied_element_xml = element.xml;
        if (this.broadcast_channel) {
          this.broadcast_channel.postMessage({
            images: this.collectImageAttrs(element),
            pdfs: this.collectPdfAttrs(element),
            xml: this.copied_element_xml
          });
        }
      },

      paste: function(page) {
        if (!this.copied_element_xml) {
          return;
        }
        let pasted_element;
        const xmlnode = Px.Util.parseXML(this.copied_element_xml).firstChild;
        if (xmlnode.tagName.toLowerCase() === 'selection') {
          const selection = Px.Editor.ElementSelectionModel.make({page: page});
          Array.from(xmlnode.childNodes).forEach(node => {
            const element = this.makeElement(node, page);
            page.addElement(element);
            selection.addElement(element);
          });
          pasted_element = selection;
        } else {
          const element = this.makeElement(xmlnode, page);
          page.addElement(element);
          pasted_element = element;
        }
        if (!pasted_element.is_in_viewport) {
          // If element is not in viewport after pasting,
          // move it to the center of the page.
          pasted_element.update({
            x: (page.width - pasted_element.width) / 2,
            y: (page.height - pasted_element.height) / 2
          });
        }
        return pasted_element;
      }
    };
  }

  // -------
  // Private
  // -------

  makeElement(xmlnode, page) {
    const element = Px.Editor.BaseElementModel.fromXMLNode(xmlnode, {
      page: page,
      image_store: this.image_store,
      pdf_store: this.pdf_store
    });
    element.layout = false;
    element.clone_id = null;
    return element;
  }

  collectImageAttrs(element) {
    let images = [];
    if (element.type === 'image' && element.id) {
      images.push({
        id: element.id,
        data: mobx.toJS(element.image.data)
      });
    } else if (element.elements) {
      element.elements.forEach(child => {
        images = images.concat(this.collectImageAttrs(child));
      });
    }
    return images;
  }

  collectPdfAttrs(element) {
    let pdfs = [];
    if (element.type === 'pdf' && element.id) {
      pdfs.push({
        id: element.id,
        data: mobx.toJS(element.pdf.data)
      });
    } else if (element.elements) {
      element.elements.forEach(child => {
        pdfs = pdfs.concat(this.collectPdfAttrs(child));
      });
    }
    return pdfs;
  }

};
