Px.Editor.ImageTurnIcons = class ImageTurnIcons extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    // The px-control-handle <rect>s are there only to catch any click events when an active
    // element is partially covered by another element.
    // See comment about `grabHandle` below.

    return Px.template`
      <g class="px-control-component"
         cursor="pointer"
         data-onclick="onClick"
         transform="translate(${this.data.x}, ${this.data.y})">

        <g data-px-tooltip="${Px.t('Turn left')}">
          <rect width="${this.iconWidth}"
                height="${this.iconHeight}"
                fill="none"
                stroke="none"
                class="px-control-handle"
                pointer-events="all"
                data-flip-to="left"
                data-onmousedown="grabHandle"
                data-ontouchstart="grabHandle"
          />
          <svg width="${this.iconWidth - 2*this.iconPadding}"
               height="${this.iconHeight - 2*this.iconPadding}"
               viewBox="0 0 17 16"
               pointer-events="none">
            <path d="M13.4513 12.3067C11.6846 14.6067 8.71131 15.2201 6.29131 14.1667C5.88464 13.9867 5.79798 13.4401 6.11131 13.1267L6.14464 13.0934C6.33798 12.9001 6.62464 12.8601 6.87798 12.9667C8.85131 13.7934 11.2713 13.2134 12.598 11.2201C13.6513 9.64006 13.6313 7.64006 12.5646 6.06672C11.6246 4.68672 10.158 4.02006 8.69131 4.02006V5.19339C8.69131 5.49339 8.33131 5.64006 8.11798 5.43339L6.25798 3.57339C6.12464 3.44006 6.12464 3.23339 6.25798 3.10006L8.12464 1.24006C8.17061 1.19275 8.2297 1.1603 8.29428 1.1469C8.35886 1.1335 8.42598 1.13976 8.48698 1.16487C8.54797 1.18999 8.60004 1.2328 8.63646 1.28779C8.67288 1.34279 8.69199 1.40743 8.69131 1.47339V2.67339C10.5313 2.67339 12.3713 3.50672 13.5846 5.19339C15.1046 7.30672 15.0513 10.2467 13.4513 12.3067Z" fill="var(--icon-color)"/>
            <path d="M4.22465 5.14003C4.28633 5.07823 4.35959 5.0292 4.44024 4.99574C4.52089 4.96229 4.60734 4.94507 4.69465 4.94507C4.78197 4.94507 4.86842 4.96229 4.94907 4.99574C5.02972 5.0292 5.10298 5.07823 5.16465 5.14003L8.22465 8.19336C8.28646 8.25504 8.33549 8.3283 8.36894 8.40895C8.4024 8.4896 8.41962 8.57605 8.41962 8.66336C8.41962 8.75068 8.4024 8.83713 8.36894 8.91778C8.33549 8.99843 8.28646 9.07169 8.22465 9.13336L5.16465 12.1934C4.90465 12.4534 4.48465 12.4534 4.22465 12.1934L1.16465 9.13336C1.10285 9.07169 1.05382 8.99843 1.02036 8.91778C0.986909 8.83713 0.969691 8.75068 0.969691 8.66336C0.969691 8.57605 0.986909 8.4896 1.02036 8.40895C1.05382 8.3283 1.10285 8.25504 1.16465 8.19336L4.22465 5.14003Z" fill="var(--icon-color)"/>
          </svg>
        </g>

        <g transform="translate(${this.iconWidth + this.iconMargin}, 0)"
           data-px-tooltip="${Px.t('Turn right')}">
          <rect width="${this.iconWidth}"
                height="${this.iconHeight}"
                fill="none"
                stroke="none"
                class="px-control-handle"
                pointer-events="all"
                data-flip-to="right"
                data-onmousedown="grabHandle"
                data-ontouchstart="grabHandle"
          />
          <svg width="${this.iconWidth - 2*this.iconPadding}"
               height="${this.iconHeight - 2*this.iconPadding}"
               viewBox="0 0 17 16"
               pointer-events="none">
            <path d="M2.598 12.3067C4.36467 14.6067 7.33801 15.2201 9.75801 14.1667C10.1647 13.9867 10.2513 13.4401 9.93801 13.1267L9.90467 13.0934C9.71134 12.9001 9.42467 12.8601 9.17134 12.9667C7.19801 13.7934 4.778 13.2134 3.45134 11.2201C2.398 9.64006 2.418 7.64006 3.48467 6.06672C4.42467 4.68672 5.89134 4.02006 7.35801 4.02006V5.19339C7.35801 5.49339 7.718 5.64006 7.93134 5.43339L9.79134 3.57339C9.92467 3.44006 9.92467 3.23339 9.79134 3.10006L7.92467 1.24006C7.87871 1.19275 7.81962 1.1603 7.75504 1.1469C7.69045 1.1335 7.62333 1.13976 7.56234 1.16487C7.50135 1.18999 7.44928 1.2328 7.41286 1.28779C7.37644 1.34279 7.35733 1.40743 7.35801 1.47339V2.67339C5.518 2.67339 3.678 3.50672 2.46467 5.19339C0.944671 7.30672 0.998004 10.2467 2.598 12.3067Z" fill="var(--icon-color)"/>
            <path d="M11.8247 5.14003C11.763 5.07823 11.6897 5.0292 11.6091 4.99574C11.5284 4.96229 11.442 4.94507 11.3547 4.94507C11.2674 4.94507 11.1809 4.96229 11.1002 4.99574C11.0196 5.0292 10.9463 5.07823 10.8847 5.14003L7.82466 8.19336C7.76286 8.25504 7.71383 8.3283 7.68037 8.40895C7.64692 8.4896 7.6297 8.57605 7.6297 8.66336C7.6297 8.75068 7.64692 8.83713 7.68037 8.91778C7.71383 8.99843 7.76286 9.07169 7.82466 9.13336L10.8847 12.1934C11.1447 12.4534 11.5647 12.4534 11.8247 12.1934L14.8847 9.13336C14.9465 9.07169 14.9955 8.99843 15.029 8.91778C15.0624 8.83713 15.0796 8.75068 15.0796 8.66336C15.0796 8.57605 15.0624 8.4896 15.029 8.40895C14.9955 8.3283 14.9465 8.25504 14.8847 8.19336L11.8247 5.14003Z" fill="var(--icon-color)"/>
          </svg>
        </g>
      </g>
    `;
  }

  get dataProperties() {
    return {
      element: {required: true},
      x: {required: true},
      y: {required: true},
      scale: {required: true},
      store: {required: true},
    }
  }

  static get computedProperties() {
    return {
      iconWidth: function() {
        return (this.inSvgUnits(ImageTurnIcons.SIZE[0]) - this.iconMargin) / 2;
      },
      iconHeight: function() {
        return this.inSvgUnits(ImageTurnIcons.SIZE[1]);
      },
      iconPadding: function() {
        return this.inSvgUnits(2);
      },
      iconMargin: function() {
        return this.inSvgUnits(3);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  // Prevent click from propagating to the element.
  onClick(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.stopPropagation();
  }

  // Note: We're using mousedown rather than a more straightforward click event,
  // because it makes it easier to handle proxying the event when the active element
  // is partially covered by another element. See `grabHandle` in `Px.Editor.BaseElementComponent`.
  // Also note that the handler *has* to be called `grabHandle` for this to work.
  grabHandle(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    const direction = evt.currentTarget.getAttribute('data-flip-to');
    if (direction === 'left') {
      this.withUndo('turn image left', () => this.turnImage(-90));
    } else {
      this.withUndo('turn image right', () => this.turnImage(+90));
    }
  }

  // -------
  // Private
  // -------

  turnImage(amount) {
    const image = this.data.element;
    const w = image.width;
    const h = image.height;
    const x = image.x;
    const y = image.y;
    const diff = w - h;
    const new_x = x + diff/2;
    const new_y = y - diff/2;

    if (image.erotation) {
      image.rotation = image.rotation + amount;
    } else {
      image.crotation = image.crotation + amount;
    }
  }


};

Px.Editor.ImageTurnIcons.SIZE = [59, 23];
