Px.Editor.MissingImageModel = class MissingImageModel extends Px.Editor.BaseImageModel {

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      type: function() {
        return null;
      },
      value: function() {
        return null;
      },
      dimensions: function() {
        return [1, 1];
      },
      preview: function() {
        return this.src();
      }
    });
  }

  src(params) {
    return MissingImageModel.DEFAULT_IMAGE;
  }

};
