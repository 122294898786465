Px.Editor.MobilePageList = class MobilePageList extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const store = this.data.store;
    return Px.template`
      <div class="px-mobile-page-list" data-is-scrollable="${Boolean(!store.ui.current_drag)}">
        <div class="px-page-sets">
          ${store.project.editor_sets_with_definitions.map(set_with_def => {
            const set = set_with_def.set;
            const definition = set_with_def.definition;
            return Px.template`
              ${r(Px.Editor.MobilePageList.PageSet, `set-${set.id}`, this.pageSetProps(set_with_def))}
              ${Px.if(this.displayAddPagesButton(set, definition), () => {
                return Px.template`
                  <div class="px-page-set px-add-set">
                    <button data-onclick="addPages">
                      ${Px.raw(Px.Editor.MobilePageList.icons.add_page)}
                    </button>
                    <div class="px-page-captions">${Px.t('Add Page')}</div>
                  </div>
                `;
              })}
            `;
          })}
        </div>
      </div>
    `;
  }

  constructor(props) {
    super(props);

    this.selectOrDeselectSet = this.selectOrDeselectSet.bind(this);
    this.setDimensions = this.setDimensions.bind(this);

    this.on('mount', this.setDimensions);
    window.addEventListener('resize', this.setDimensions);
  }

  destroy() {
    window.removeEventListener('resize', this.setDimensions);
    super.destroy();
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get properties() {
    return {
      width: {std: 0, type: 'float'},
      height: {std: 0, type: 'float'}
    };
  }

  static get computedProperties() {
    return {
      availableWidth: function() {
        const available_width = this.state.width - (MobilePageList.MARGIN_LEFT + MobilePageList.MARGIN_RIGHT);
        return Math.max(0, available_width);
      },
      availableHeight: function() {
        const margin = MobilePageList.MARGIN_TOP + MobilePageList.MARGIN_BOTTOM;
        return Math.max(0, this.state.height - margin);
      },
      pageSetScale: function() {
        let scale = null;
        this.data.store.project.editor_page_sets.forEach(set => {
          let width = 0;
          let height = 0;
          set.pages.forEach(page => {
            width += page.width;
            if (page.height > height) {
              height = page.height;
            }
          });
          const available_width = this.availableWidth - (MobilePageList.PAGE_INNER_MARGIN * (set.pages.length - 1));
          const available_height = this.availableHeight;
          const set_scale = Math.min(available_width / width, available_height / height);
          if (scale === null || set_scale < scale) {
            scale = set_scale;
          }
        });
        return scale || 0;
      },
      setsPerIncrement: function() {
        const theme_store = this.data.store.theme;
        const grow_set_definition = theme_store.grow_set_definition;
        if (grow_set_definition) {
          return theme_store.page_increments / grow_set_definition.pages.length;
        } else {
          return null;
        }
      },
    };
  }

  isSelected(set) {
    return _.include(this.data.store.mobile.selected_sets, set);
  }

  selectOrDeselectSet(set) {
    const selected_sets = this.data.store.mobile.selected_sets;
    mobx.runInAction(() => {
      if (this.isSelected(set)) {
        selected_sets.remove(set);
      } else {
        selected_sets.push(set);
        if (selected_sets.length > this.setsPerIncrement) {
          selected_sets.shift();
        }
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  addPages(evt) {
    this.data.store.addPages();
  }

  // -------
  // Private
  // -------

  displayAddPagesButton(set, definition) {
    const project_store = this.data.store.project;
    const theme_store = this.data.store.theme;
    if (this.data.store.ui.share_view) {
      return false;
    }
    if (!project_store.can_add_pages) {
      return false;
    }
    // If this is the last growable set, we can put the button after it.
    if (set === project_store.last_growable_set) {
      return true;
    }
    // The only other valid option where we can display the add pages button is
    // when non-growable sets are completely filled, and the next page would belong to the grow set.
    if (!project_store.last_growable_set &&
        set.pages.length === definition.pages.length &&
        set === project_store.last_fixed_start_set &&
        theme_store.grow_set_definition) {
      return true;
    }
    return false;
  }

  pageSetProps(set_with_definition) {
    const set = set_with_definition.set;
    const definition = set_with_definition.definition;
    return {
      set: set,
      set_definition: definition,
      selected: this.isSelected(set),
      scale: this.pageSetScale,
      inner_margin: MobilePageList.PAGE_INNER_MARGIN,
      selectOrDeselectSet: this.selectOrDeselectSet
    };
  }

  setDimensions() {
    cancelAnimationFrame(this.__dimensions_raf);
    this.__dimensions_raf = requestAnimationFrame(() => {
      const node = $j(this.dom_node);
      const width = node.width();
      const height = node.height();
      const state = this.state;
      mobx.runInAction(() => {
        state.width = width;
        state.height = height;
      });
    });
  }

};

Px.Editor.MobilePageList.icons = {
  add_page: '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><path d="M21.34,5.59,17.4,1.66A2.25,2.25,0,0,0,15.81,1H6.25A2.25,2.25,0,0,0,4,3.25v19.5A2.25,2.25,0,0,0,6.25,25h13.5A2.25,2.25,0,0,0,22,22.75V7.19A2.27,2.27,0,0,0,21.34,5.59Z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1.5"/><line x1="16" y1="1" x2="16" y2="7" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><line x1="22" y1="7" x2="16" y2="7" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><line x1="14.5" y1="20" x2="19.5" y2="20" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><line x1="17" y1="17.5" x2="17" y2="22.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>'
}

Px.Editor.MobilePageList.PageSet = class PageSet extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-page-set" data-set-id="${this.data.set.id}" data-selected="${this.data.selected}">
        <div class="px-page-thumbs" data-onclick="editPageSet" data-ontouchstart="startLongPress">
          ${this.data.set.pages.map((page, idx) => {
            return Px.template`
              <div class="px-page-wrapper" data-page-idx="${idx}" >
                ${this.renderChild(Px.Editor.Page, `page-${page.id}`, this.pageProps(page))}
              </div>
            `;
          })}
        </div>
        <div class="px-page-captions" style="width:${this.captionsWidth}px">
          ${Px.if(this.data.set.left_caption, () => {
            return Px.template`
              <div class="px-page-caption">${this.data.set.left_caption}</div>
            `;
          })}
          ${Px.if(this.data.set.center_caption, () => {
            return Px.template `
              <div class="px-page-caption">${this.data.set.center_caption}</div>
            `;
          })}
          ${Px.if(this.data.set.right_caption, () => {
            return Px.template`
              <div class="px-page-caption">${this.data.set.right_caption}</div>
            `;
          })}
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.on('mount', () => {
      this._intersection_observer = new IntersectionObserver(entries => {
        this.state.is_visible = entries[0].isIntersecting;
      });
      this._intersection_observer.observe(this.dom_node);
    });
  }

  destroy() {
    super.destroy();

    if (this._intersection_observer) {
      this._intersection_observer.disconnect();
    }
  }

  get dataProperties() {
    return {
      set: {required: true},
      set_definition: {required: true},
      selected: {std: false},
      scale: {required: true},
      inner_margin: {required: true},
      selectOrDeselectSet: {required: true},
      store: {required: true}
    };
  }

  static get properties() {
    return {
      is_visible: {type: 'bool', std: false}
    };
  }

  static get computedProperties() {
    return {
      isSelectable: function() {
        const set = this.data.set;
        const definition = this.data.set_definition;
        // Only FULL sets from the grow set can be deleted/duplicated.
        // Partially filled sets can never be deleted/duplicated or moved around.
        return definition.grow && set.pages.length === definition.pages.length;
      },
      captionsWidth: function() {
        const pages = this.data.set.pages;
        const scale = this.data.scale;
        let width = 0;
        pages.forEach(function(page) {
          width += page.width * scale;
        });
        return width + (this.data.inner_margin * (pages.length - 1));
      },
      canAddOrRemovePages: function() {
        const theme_store = this.data.store.theme;
        return theme_store.page_increments && (theme_store.max_pages !== theme_store.min_pages);
      }
    };
  }

  pageProps(page) {
    return {
      page: page,
      scale: this.data.scale,
      mobile_mode: true,
      preview_mode: true,
      render_controls: false,
      render_content: this.state.is_visible
    };
  }

  // --------------
  // Event handlers
  // --------------

  editPageSet(evt) {
    const store = this.data.store;
    if (store.mobile.selected_sets.length) {
      this.data.selectOrDeselectSet(this.data.set);
    } else {
      store.mobile.animate(() => store.selectSet(this.data.set));
    }
  }

  startLongPress(evt) {
    const doc = $j(document);

    if (!this.canAddOrRemovePages) {
      return;
    }

    const touches = evt.originalEvent.targetTouches;

    if (touches.length !== 1) {
      return;
    }

    evt.preventDefault();
    const drag_origin = {x: touches[0].pageX, y: touches[0].pageY};
    let distance = 0;

    const timeout = setTimeout(() => {
      doc.off('touchmove', onDragMove);
      doc.off('touchend touchcancel', onDragCancel);

      if (distance <= 10) {
        if (!this.isSelectable) {
          this.data.store.showNotification(Px.t('This page cannot be removed or duplicated.'));
        } else {
          this.data.selectOrDeselectSet(this.data.set);
        }
      }
    }, Px.Util.longPressDuration);

    const onDragMove = evt => {
      const dx = evt.originalEvent.targetTouches[0].pageX - drag_origin.x;
      const dy = evt.originalEvent.targetTouches[0].pageY - drag_origin.y;
      distance = Math.sqrt(dx*dx + dy*dy);
    };

    const onDragCancel = evt => {
      doc.off('touchmove', onDragMove);
      doc.off('touchend touchcancel', onDragCancel);
      clearTimeout(timeout);
    };

    doc.on('touchmove', onDragMove);
    doc.on('touchend touchcancel', onDragCancel);
  }

};

// Margin between page sets and the edges of the page list div.
Px.Editor.MobilePageList.MARGIN_LEFT = 36;
Px.Editor.MobilePageList.MARGIN_RIGHT = 36;
Px.Editor.MobilePageList.MARGIN_TOP = 18;
Px.Editor.MobilePageList.MARGIN_BOTTOM = 36;
Px.Editor.MobilePageList.PAGE_TOP_MARGIN = 18;
// Padding + captions div height.
Px.Editor.MobilePageList.PAGE_BOTTOM_MARGIN = 18 + 28;
Px.Editor.MobilePageList.PAGE_INNER_MARGIN = 2;
