Px.Editor.InstagramGalleryStore = class InstagramGalleryStore extends Px.BaseStore {

  static get properties() {
    return {
      access_token: {std: null},
      gallery: {std: null}
    };
  }

  static get computedProperties() {
    return {
      images: function() {
        return this.gallery ? this.gallery.images : [];
      },
      next_url: function() {
        return this.gallery ? this.gallery.next_url : null;
      },
      is_gallery_loaded: function() {
        return this.gallery ? !this.gallery.loading : false;
      },
      can_load_more_images: function() {
        return this.next_url !== null;
      }
    }
  }

  get actions() {
    return {
      init: function() {
        if (this.images.length === 0) {
          this.loadNextPage();
        }
      },

      loadNextPage: function() {
        this._ensureAccessToken(() => {
          if (this.next_url) {
            this.fetchItems(this.next_url, {});
          } else {
            const url = 'https://graph.instagram.com/me/media';
            const data = {
              access_token: this.access_token,
              limit: InstagramGalleryStore.PAGE_SIZE,
              fields: 'media_type,caption,media_url'
            };
            this.fetchItems(url, data);
          }
        });
      },

      _ensureAccessToken: function(callback) {
        if (this.access_token === null) {
          this.getAccessToken(token => {
            if (token) {
              this.access_token = token;
              callback();
            } else {
              alert('Failed to obtain Instagram access token.');
            }
          });
        } else {
          callback();
        }
      }
    };
  }

  // -------
  // Private
  // -------

  createGallery() {
    const gallery = mobx.observable({
      images: [],
      next_url: null,
      loading: true
    });
    this.gallery = gallery;
    return gallery;
  }

  fetchItems(url, data) {
    const gallery = this.gallery || this.createGallery();
    gallery.loading = true;
    const xhr = $j.ajax({url: url, data: data});
    xhr.done(response => {
      mobx.runInAction(() => {
        gallery.loading = false;
        if (response.paging && response.paging.next) {
          gallery.next_url = response.paging.next;
        } else {
          gallery.next_url = null;
        }
        response.data.forEach(item => {
          if (item.media_type === 'IMAGE') {
            gallery.images.push(this.buildImageItem(item));
          }
        });
      });
    });
  }

  buildImageItem(data) {
    // TODO: Instagram graph API currenlty provides a single image size only, without any info on the dimensions.
    //       We are forced to load the images and determine the dimensions on image load :(
    const image_url = data.media_url;
    data.pixfizz_thumb_url = image_url;
    const item = {
      type: 'image',
      id: `instagram:${image_url}#width=640&height=640`,
      thumb_url: image_url,
      caption: data.caption,
      data: data
    };
    const img = new Image();
    img.onload = () => {
      if (img.naturalWidth && img.naturalHeight) {
        const image = this.gallery.images.find(i => i.id === item.id);
        image.id = `instagram:${image_url}#width=${img.naturalWidth}&height=${img.naturalHeight}`;
      }
    };
    img.src = image_url;
    return item;
  }

  getAccessToken(callback) {
    const client_id = Px.config.instagram_client_id;
    const redirect_uri = `${window.location.protocol}//${window.location.host}/v1/social_app_tokens/instagram`;
    const params = {
      redirect_uri: redirect_uri,
      response_type: 'code',
      client_id: client_id,
      scope: 'user_profile,user_media'
    };
    const popup_url = `https://api.instagram.com/oauth/authorize/?${$j.param(params)}`;
    const popup = Px.Util.openPopup(popup_url, {width: 627, height: 485});
    let popup_interval = null;
    const stopPopupInterval = function() {
      clearInterval(popup_interval);
      popup_interval = null;
    };
    // The only reliable way to exchange message with popup on a different domain, see:
    // http://stackoverflow.com/questions/18625733
    popup_interval = setInterval(function() {
      if (popup_interval && (!popup || popup.closed)) {
        stopPopupInterval();
        callback(null);
      } else {
        try {
          if (popup.window.location.href.match('/social_app_tokens/instagram')) {  // will throw if cross-domain
            if (popup.document.readyState === 'complete') {
              stopPopupInterval();
              const token = popup.window.location.hash.substring(1);
              popup.close();
              callback(token);
            }
          }
        } catch (e) {
          // ignore
        }
      }
    }, 250);
  }

};

Px.Editor.InstagramGalleryStore.PAGE_SIZE = 50;
