Px.Editor.InstagramGalleryPanel = class InstagramGalleryPanel extends Px.Editor.BaseGalleryPanel {

  get css_class() {
    return 'px-instagram-gallery-panel';
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      galleryStore: function() {
        return this.data.store.galleries.instagram;
      },
      items: function() {
        return this.galleryStore.images;
      },
      isLoading: function() {
        return !this.galleryStore.is_gallery_loaded;
      },
      canLoadMoreItems: function() {
        return this.galleryStore.can_load_more_images;
      }
    });
  }


  // --------------
  // Event handlers
  // --------------

  loadMoreItems(evt) {
    this.galleryStore.loadNextPage(this.data.tag);
  }

  upLevel(evt) {
    if (this.data.onExit) {
      this.data.onExit();
    }
  }

};
