Px.Editor.GroupElementModel = class GroupElementModel extends Px.Util.mixin(
  Px.Editor.BaseElementModel,
  Px.Editor.ElementContainerMixin
) {

  constructor(props) {
    super(props)
    this.dimensions_before_resize = null;
    this._cached_width = null;
    this._cached_height = null;

    this.registerReaction(() => this.is_resizing, is_resizing => {
      if (is_resizing) {
        this.dimensions_before_resize = this.getResizeDimensions();
      } else {
        this.dimensions_before_resize = null;
      }
    }, {
      name: 'Px.Editor.GroupElementModel::storeResizeDimensionsReaction'
    });

    let previous_dimensions = {width: this.width, height: this.height};
    this.registerReaction(
      () => {
        return {
          extreme_coords: this.elements.map(e => e.extreme_coords),
          is_suppressed: this.suppressRepositioning
        };
      }, (data) => {
        if (!data.is_suppressed) {
          const previous_width = previous_dimensions.width;
          const previous_height = previous_dimensions.height;
          // We have to store previous_dimensions *before* repositioning.
          previous_dimensions = {width: this.width, height: this.height};
          this.reposition(previous_width, previous_height);
        }
      }, {
        name: 'Px.Editor.GroupElementModel::repositionGroup',
        fireImmediately: true
      }
    );
  }


  // --------------------
  // Static/class methods
  // --------------------

  static fromXMLNode(node, params) {
    const child_nodes = Array.from(node.children);
    const child_elements = child_nodes.map(child_node => {
      return Px.Editor.BaseElementModel.fromXMLNode(child_node, Object.assign({}, params));
    });

    return this.make({
      edit:        node.getAttribute('edit') === 'true',
      x:           parseFloat(node.getAttribute('x')) || 0,
      y:           parseFloat(node.getAttribute('y')) || 0,
      z:           parseInt(node.getAttribute('z'), 10) || 0,
      width:       parseFloat(node.getAttribute('width')) || 0,
      height:      parseFloat(node.getAttribute('height')) || 0,
      rotation:    parseFloat(node.getAttribute('rotate')) || 0,
      resize:      node.getAttribute('resize') !== 'false',
      move:        node.getAttribute('move') !== 'false',
      erotation:   node.getAttribute('erotation') !== 'false',
      elayer:      node.getAttribute('elayer') !== 'false',
      'delete':    node.getAttribute('delete') !== 'false',
      name:        node.getAttribute('name') || null,
      tags:        node.getAttribute('tags') || null,
      clone_id:    node.getAttribute('clone_id') || null,
      layout:      node.getAttribute('layout') === 'true',
      pdf_layer:   node.getAttribute('pdf_layer') || null,
      group:       params.group || null,
      page:        params.page,
      image_store: params.image_store,
      elements: child_elements
    });
  }

  static get properties() {
    return Object.assign(super.properties, {
      elayer: {std: true},
      // Reference to the ImageStore; Group element needs access to it to be able to create child Images.
      image_store: {std: null, two_page_spread: false, serialize: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      suppressRepositioning: function() {
        // Supress repositioning if ony one of the child elements is selected.
        // That means the user could be manipulating the child directly and we only want to reposition the group
        // after the user has finished manipulating the child to avoid excessive repositioning.
        return this.elements.some(element => element.is_selected);
      },
      calculatedWidth: function() {
        if (this.suppressRepositioning && this._cached_width !== null) {
          return this._cached_width;
        }
        if (this.elements.length === 0) {
          this._cached_width = 0;
        } else {
          let min_x = Infinity;
          let max_x = -Infinity;
          this.elements.forEach(e => {
            const extremes = e.extreme_coords;
            min_x = Math.min(min_x, extremes.min_x);
            max_x = Math.max(max_x, extremes.max_x);
          });
          this._cached_width = max_x - min_x;
        }
        return this._cached_width;
      },
      calculatedHeight: function() {
        if (this.suppressRepositioning && this._cached_height !== null) {
          return this._cached_height;
        }
        if (this.elements.length === 0) {
          this._cached_height = 0;
        } else {
          let min_y = Infinity;
          let max_y = -Infinity;
          this.elements.forEach(e => {
            const extremes = e.extreme_coords;
            min_y = Math.min(min_y, extremes.min_y);
            max_y = Math.max(max_y, extremes.max_y);
          });
          this._cached_height = max_y - min_y;
        }
        return this._cached_height;
      },
      xml: function() {
        const parts = [
          `<g ${this.xmlizeAttributes()}>`,
          this.xmlizeElements(),
          '</g>'
        ];
        return parts.join('\n');
      }
    });
  }

  get actions() {
    return Object.assign(super.actions, {

      addElement: function(element) {
        this._elements.push(element);
        element.group = this;
        element.page = this.page;
      },

      removeElement: function(element) {
        const prev_width = this.width;
        const prev_height = this.height;
        this._elements.remove(element);
        element.group = null;
        // We have to manually invoke reposition here because if we're removing multiple elements
        // in a transaction, the reaction will only be triggered once, after all elements have been
        // removed, which can break the group positioning.
        this.reposition(prev_width, prev_height);
      },

      reposition: function(old_width, old_height) {
        if (this.elements.length && !this.suppressRepositioning) {
          const old_x = this.x;
          const old_y = this.y;
          const new_width = this.width;
          const new_height = this.height;

          let min_x = Infinity;
          let min_y = Infinity;
          let max_x = -Infinity;
          let max_y = -Infinity;

          this.elements.forEach(e => {
            const extremes = e.extreme_coords;
            min_x = Math.min(min_x, extremes.min_x);
            max_x = Math.max(max_x, extremes.max_x);
            min_y = Math.min(min_y, extremes.min_y);
            max_y = Math.max(max_y, extremes.max_y);
          });
          // We need to have some tolerance so that the group doesn't go into an infinite
          // repositining loop due to floating point error.
          const tolerance = 1e-8;
          if (Math.abs(min_x) < tolerance) {
            min_x = 0;
          }
          if (Math.abs(min_y) < tolerance) {
            min_y = 0;
          }

          this.elements.forEach(e => e.x -= min_x);
          this.elements.forEach(e => e.y -= min_y);

          // Origin (center) point of the group.
          const old_Ox = old_x + old_width/2;
          const old_Oy = old_y + old_height/2;
          const new_Ox = old_x + new_width/2;
          const new_Oy = old_y + new_height/2;
          // How much has the top left (TL) corner of the group moved
          // due to width/height changes, according to the page's coordinate system?
          const angle = -this.absolute_rotation;
          const old_TL = Px.Util.rotatePoint(-old_width/2, -old_height/2, angle);
          const new_TL = Px.Util.rotatePoint(-new_width/2, -new_height/2, angle);
          const dTLx = (new_Ox + new_TL[0]) - (old_Ox + old_TL[0]);
          const dTLy = (new_Oy + new_TL[1]) - (old_Oy + old_TL[1]);
          // Convert the shift we applied to the elements to the page's coordinate system.
          const element_shift = Px.Util.rotatePoint(-min_x, -min_y, angle);
          // The position of the group needs to be adjusted so that the elements stay
          // at the same place from the user's/page's perspective.
          const new_x = old_x - (element_shift[0] + dTLx);
          const new_y = old_y - (element_shift[1] + dTLy);

          this.update({x: new_x, y: new_y});
        }
      }

    });
  }

  clone(params) {
    return super.clone(Object.assign({image_store: this.image_store}, params));
  }

  // Takes a `point` in absolute (page) coordinates,
  // and returns the point converted to group coordinates.
  inGroupCoords(point) {
    const M = this.absolute_transform_matrix;
    const I = Px.Util.invertMatrix(M);
    const coords = Px.Util.applyMatrix(I, [point.x, point.y]);
    return {x: coords[0], y: coords[1]};
  }

  // Takes a `point` represented as an object {x, y} in group's own
  // coordinate system, and returns the point transposed to the page
  // coordinate system.
  inPageCoords(point) {
    const M = this.absolute_transform_matrix;
    const coords = Px.Util.applyMatrix(M, [point.x, point.y]);
    return {x: coords[0], y: coords[1]};
  }

  // ---------------
  // Getters/setters
  // ---------------

  get width() {
    return this.calculatedWidth;
  }

  set width(width) {
    const dims = this.dimensions_before_resize || this.getResizeDimensions();
    const orig_width = dims.group.width;
    const scale = width / orig_width;
    mobx.runInAction(() => {
      this.elements.forEach((element, idx) => {
        const element_dims = dims.elements[idx];
        element.width = scale * element_dims.width;
        element.x = scale * element_dims.x;
      });
    });
  }

  get height() {
    return this.calculatedHeight;
  }

  set height(height) {
    const dims = this.dimensions_before_resize || this.getResizeDimensions();
    const orig_height = dims.group.height;
    const scale = height / orig_height;
    mobx.runInAction(() => {
      this.elements.forEach((element, idx) => {
        const element_dims = dims.elements[idx];
        element.height = scale * element_dims.height;
        element.y = scale * element_dims.y;
      });
    });
  }

  // -------
  // Private
  // -------

  getResizeDimensions() {
    const getSize = function(ele) {
      return {
        width: ele.width,
        height: ele.height,
        x: ele.x,
        y: ele.y
      };
    };

    const result = {
      group: getSize(this),
      elements: this.elements.map(getSize)
    };

    return result;
  }

  serializableAttributes() {
    const attrs = super.serializableAttributes();
    attrs.unit = true;
    return attrs;
  }

};

Px.Editor.GroupElementModel.ELEMENT_TYPE = 'group';
