Px.Editor.PdfElementModel = class PdfElementModel extends Px.Editor.BaseElementModel {

  static fromXMLNode(node, params) {
    const props = {
      id:              node.getAttribute('src'),
      page_number:     parseInt(node.getAttribute('page')) || 1,
      edit:            node.getAttribute('edit') === 'true',
      x:               parseFloat(node.getAttribute('x')) || 0,
      y:               parseFloat(node.getAttribute('y')) || 0,
      z:               parseInt(node.getAttribute('z'), 10) || 0,
      width:           parseFloat(node.getAttribute('width')) || 0,
      height:          parseFloat(node.getAttribute('height')) || 0,
      rotation:        parseFloat(node.getAttribute('rotate')) || 0,
      erotation:       node.getAttribute('erotation') !== 'false',
      elayer:          node.getAttribute('elayer') !== 'false',
      opacity:         parseFloat(node.getAttribute('opacity')) || 1,
      eopacity:        node.getAttribute('eopacity') !== 'false',
      name:            node.getAttribute('name') || null,
      move:            node.getAttribute('move') !== 'false',
      resize:          node.getAttribute('resize') !== 'false',
      'delete':        node.getAttribute('delete') !== 'false',
      placeholder:     node.getAttribute('placeholder') === 'true',
      show_on_preview: node.getAttribute('show_on_preview') === 'true',
      tags:            node.getAttribute('tags') || null,
      clone_id:        node.getAttribute('clone_id') || null,
      layout:          node.getAttribute('layout') === 'true',
      pdf_layer:       node.getAttribute('pdf_layer') || null,
      group:           params.group || null,
      page:            params.page,
      pdf_store:       params.pdf_store
    };
    return Px.Editor.PdfElementModel.make(props);
  }

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null, serialize: false},
      page_number: {std: 1, serialize: false},
      elayer: {std: true},
      eopacity: {std: true},
      placeholder: {std: false},
      show_on_preview: {std: false},
      // Reference to the PdfStore.
      pdf_store: {std: null, two_page_spread: false, serialize: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      pdf: function() {
        if (this.id) {
          return this.pdf_store.get(this.id);
        } else {
          throw new Error('PDF element id attribute is required')
        }
      }
    });
  }

  clone(params) {
    return super.clone(Object.assign({pdf_store: this.pdf_store}, params));
  }

  serializableAttributes() {
    const attrs = super.serializableAttributes();
    attrs.src = this.id;
    attrs.page = this.page_number;
    return attrs;
  }

};

Px.Editor.PdfElementModel.ELEMENT_TYPE = 'pdf';
