Px.Editor.UploadMethodModal = class UploadMethodModal extends Px.Components.BaseModal {

  get content() {
    return Px.template`
      <div class="px-standard-uploader">
        ${this.data.upload_button_template({
            show_plus_icon: false,
            button_label: Px.t('Select images')
         })}
      </div>
      ${Px.if(Px.config.qr_uploader, () => {
        return Px.template`
          <div class="px-qr-code">
            <div class="px-separator">
              ${Px.t('or upload from mobile')}
            </div>
            <div class="px-image-wrapper">
              ${Px.if(this.showSpinner, () => {
                return this.renderChild(Px.Components.Spinner, 'spinner');
              }).else(() => {
                return Px.template`<img src="${this.state.qr_code_src}" />`;
              })}
            </div>
          </div>
        `;
      })}
    `;
  }

  get css_class() {
    return `${super.css_class} px-upload-method-modal`;
  }

  constructor(data) {
    super(data);

    if (Px.config.qr_uploader) {
      const store = this.data.store;
      const project_id = store.project_id;
      const gallery_id = this.data.gallery_id;
      const url = `/upload/qr_code.json?book=${project_id}&gallery_id=${gallery_id}`;

      fetch(url, {method: 'POST'}).then(r => r.json()).then(json => {
        mobx.runInAction(() => {
          this.state.qr_code_src = json.code_src;
          this.state.upload_token = json.upload_token;
        });

        store.galleries.trackUpload(this.state.upload_token, gallery_id);
      }).catch(err => {
        console.error(err);
      });
    }

    this.registerReaction(() => this.uploadStatus, upload_status => {
      if (upload_status === 'uploading' || upload_status === 'uploaded' || upload_status === 'complete') {
        this.destroy();
      }
    });
  }

  get dataProperties() {
    return {
      store: {required: true},
      gallery_id: {required: true},
      upload_button_template: {required: true},
      onFilesSelected: {required: true}
    };
  }

  static get properties() {
    return {
      qr_code_src: {std: null},
      upload_token: {std: null}
    };
  }

  static get computedProperties() {
    return {
      uploadStatus: function() {
        return this.data.store.galleries.getUploadStatus(this.state.upload_token);
      },
      showSpinner: function() {
        return (this.state.qr_code_src === null) || this.uploadStatus === 'initialized';
      }
    };
  }

  destroy() {
    if (this.state.upload_token) {
      this.data.store.galleries.scheduleUploadCleanup(this.state.upload_token);
    }
    super.destroy();
  }

  // --------------
  // Event handlers
  // --------------

  onFilesSelected(evt) {
    this.data.onFilesSelected(evt);
    this.destroy();
  }

};
