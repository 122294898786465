Px.Editor.SaveProjectModal = class SaveProjectModal extends Px.Components.BaseModal {

  get title() {
    return Px.t('Save Project');
  }

  get footer() {
    return Px.template`
      <button class="px-cancel"
              data-onclick="saveAndExit"
              ${this.state.disabled ? 'disabled' : ''}>
        ${Px.t('Save & Exit')}
      </button>
      <button class="px-ok px-save"
              data-onclick="saveAndContinue"
              ${this.state.disabled ? 'disabled' : ''}>
        ${Px.t('Save & Continue')}
      </button>
    `;
  }

  get css_class() {
    return `${super.css_class} px-confirmation-modal`;
  }

  constructor(props) {
    super(props);
    this.close_on_background_click = false;
  }

  get dataProperties() {
    return {
      saveAndContinue: {required: true},
      saveAndExit: {required: true}
    }
  }

  static get properties() {
    return {
      disabled: {type: 'bool', std: false}
    }
  }

  // --------------
  // Event handlers
  // --------------

  saveAndContinue(evt) {
    this.state.disabled = true;
    this.data.saveAndContinue();
    this.destroy();
  }

  saveAndExit(evt) {
    this.state.disabled = true;
    this.data.saveAndExit();
    this.destroy();
  }

};
