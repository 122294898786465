Px.Editor.TextElementModel = class TextElementModel extends Px.Editor.BaseElementModel {

  static fromXMLNode(node, params) {
    const props = {
      text:            node.textContent || '',
      edit:            node.getAttribute('edit') === 'true',
      x:               parseFloat(node.getAttribute('x')) || 0,
      y:               parseFloat(node.getAttribute('y')) || 0,
      z:               parseInt(node.getAttribute('z'), 10) || 33,
      width:           parseFloat(node.getAttribute('width')) || 0,
      height:          parseFloat(node.getAttribute('height')) || 0,
      rotation:        parseFloat(node.getAttribute('rotate')) || 0,
      erotation:       node.getAttribute('erotation') !== 'false',
      font:            node.getAttribute('font') || '1',
      pointsize:       Math.round(Px.Util.mm2pt(parseFloat(node.getAttribute('fontsize') || 0))),
      efontsize:       node.getAttribute('efontsize') !== 'false',
      color:           node.getAttribute('color') || '#000000',
      ecolor:          node.getAttribute('ecolor') !== 'false',
      dir:             node.getAttribute('dir') || 'ltr',
      name:            node.getAttribute('name') || null,
      palette:         node.getAttribute('palette') || null,
      fontpalette:     node.getAttribute('fontpalette') || null,
      align:           node.getAttribute('align') || 'left',
      valign:          node.getAttribute('valign') || 'top',
      kerning:         parseFloat(node.getAttribute('kerning')) || 0,
      leading:         parseFloat(node.getAttribute('leading')) || 0,
      tp:              parseFloat(node.getAttribute('tp')) || 0,
      bp:              parseFloat(node.getAttribute('bp')) || 0,
      lp:              parseFloat(node.getAttribute('lp')) || 0,
      rp:              parseFloat(node.getAttribute('rp')) || 0,
      opacity:         parseFloat(node.getAttribute('opacity')) || 1,
      eopacity:        node.getAttribute('eopacity') !== 'false',
      move:            node.getAttribute('move') !== 'false',
      resize:          node.getAttribute('resize') !== 'false',
      'delete':        node.getAttribute('delete') !== 'false',
      path:            node.getAttribute('path') || null,
      shrink:          node.getAttribute('shrink') === 'true',
      placeholder:     node.getAttribute('placeholder') === 'true',
      show_on_preview: node.getAttribute('show_on_preview') === 'true',
      tags:            node.getAttribute('tags') || null,
      clone_id:        node.getAttribute('clone_id') || null,
      layout:          node.getAttribute('layout') === 'true',
      pdf_layer:       node.getAttribute('pdf_layer') || null,
      group:           params.group || null,
      page:            params.page
    };
    return Px.Editor.TextElementModel.make(props);
  }

  static get properties() {
    return Object.assign(super.properties, {
      _text: {std: '', serialize: false},
      z: {std: 33},
      font: {std: '1'},
      pointsize: {std: 0, serialize: false},
      efontsize: {std: true},
      eopacity: {std: true},
      color: {std: '#000000'},
      ecolor: {std: true},
      dir: {std: 'ltr'},
      palette: {std: null},
      fontpalette: {std: null},
      align: {std: 'left'},
      valign: {std: 'top'},
      kerning: {std: 0},
      leading: {std: 0},
      tp: {std: 0},
      bp: {std: 0},
      lp: {std: 0},
      rp: {std: 0},
      path: {std: null},
      shrink: {std: false},
      placeholder: {std: false},
      show_on_preview: {std: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      raw_text: function() {
        return this._text;
      },
      fontsize: function() {
        return Px.Util.pt2mm(this.pointsize);
      },
      url_params: function() {
        var params = {
          text: this.text,
          width: this.width,
          height: this.height,
          pointsize: this.pointsize,
          align: this.align,
          valign: this.valign,
          font: this.font
        };
        ['tp', 'bp', 'lp', 'rp', 'kerning', 'leading'].forEach((prop) => {
          if (this[prop]) {
            params[prop] = this[prop];
          }
        });
        if (this.dir === 'rtl') {
          params.dir = 'rtl';
        }
        if (this.shrink) {
          params.overflow = 'shrink';
        }
        if (this.path) {
          var path = this.path;
          if (path[0] === 'C') {
            params.closed = 't;';
            path = path.replace('C', '');
          }
          params.path = path;
        }
        return params;
      },
      json_query: function() {
        var params = this.url_params;
        var keys = _.keys(params);
        // sort the keys to make the query string stable (and cacheable).
        keys.sort();
        var json = [];
        keys.forEach(function(key) {
          json.push(JSON.stringify(key) + ':' + JSON.stringify(params[key]));
        });
        return '{' + json.join(',') + '}';
      },
      is_refresh_suppressed: function() {
        if (this.is_resizing) {
          return true;
        }
        let group = this.group;
        while (group) {
          if (group.is_resizing) {
            return true;
          }
          group = group.group;
        }
        return false;
      },
      xml: function() {
        var content = this.raw_text.replace(/\&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return `<text ${this.xmlizeAttributes()}>${content}</text>`;
      }
    });
  }

  static get default_text_content() {
    return Px.t('default text content', 'Text');
  }

  // ---------------
  // Getters/setters
  // ---------------

  get text() {
    let text = this._text;
    const page_number = this.page && this.page.page_number;
    if (page_number !== null) {
      text = text.replace(/%pn%/g, page_number);
    }
    return text;
  }

  set text(text) {
    // Remove invalid XML chars from the string, see:
    // http://stackoverflow.com/a/28152666/51397
    // Cannot use a regex here because JS regular expressions don't work
    // with surrogate pairs ('\u10000' is not a valid JS character).
    var len = text.length;
    var cleaned = '';
    var i = 0;
    var c;
    while (i < len) {
      c = Px.Util.codePointAt(text, i);
      if (c > 65535) { // surrogate pair
        // Characters between 0x10000 and 0x10FFF are valid XML characters in theory,
        // but MySQL's utf8 encoding does not support them. We would have to convert
        // the print_pages table to utf8mb4 for them to work, but running migrations
        // on the huge print_pages table is too risky, so just skip these characters.
        i += 2;
      } else {
        if ((c === 0x9 || c === 0xA || c === 0xD) ||
            (c >= 0x20 && c <= 0xD7FF) ||
            (c >= 0xE000 && c <= 0xFFFD)) {
          cleaned += text[i];
        }
        i += 1;
      }
    }
    this._text = cleaned;
  }

  // -------
  // Private
  // -------

  serializableAttributes() {
    var attrs = super.serializableAttributes();
    attrs.fontsize = this.fontsize;
    return attrs;
  }

};

Px.Editor.TextElementModel.ELEMENT_TYPE = 'text';
