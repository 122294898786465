Px.Editor.ElementRotateIcon = class ElementRotateIcon extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    const path_radius = this.iconRadius * 0.4;
    const arrowhead_size = this.strokeWidth * 1.5;

    return Px.template`
      <g class="px-control-component"
         opacity="${this.isEnabled ? 1 : 0}"
         pointer-events="${this.pointerEventsAttribute}"
         cursor="${this.state.drag_origin ? 'grabbing' : 'grab'}"
         data-onclick="onClick"
         data-onmousedown="grabHandle"
         data-ontouchstart="grabHandle"
         transform="translate(${this.data.element.width / 2} ${this.data.element.height + this.iconOffset})"
         data-px-tooltip="${Px.t('Rotate')}">
        <line x1="0"
              y1="0"
              x2="0"
              y2="-${this.iconOffset}"
              stroke-width="${this.inSvgUnits(2)}"
              stroke="var(--editor-selection-color)"
              cursor="auto"
        />
        <circle class="px-control-handle"
                r="${this.iconRadius}"
                stroke-width="${this.strokeWidth}"
                stroke="#fff"
                fill="var(--editor-selection-color)"
        />
        <path d="M 0 -${path_radius}
                 A ${path_radius} ${path_radius} 0 1 1 -${path_radius} 0"
              fill="none"
              stroke-width="${this.strokeWidth}"
              stroke-linecap="round"
              stroke="#fff"
        />
        <g transform="translate(-${path_radius}, ${arrowhead_size/8})">
          <polygon points="${-arrowhead_size/2},0 0,-${arrowhead_size} ${arrowhead_size/2},0"
                   fill="#fff"
                   stroke-width="${this.strokeWidth}"
                   stroke="#fff"
          />
        </g>
      </g>
    `;
  }

  constructor(props) {
    super(props);
    this._drag_raf = null;

    this.dragHandle = this.dragHandle.bind(this);
    this.releaseHandle = this.releaseHandle.bind(this);
  }

  get dataProperties() {
    return {
      element: {required: true},
      scale: {required: true},
      store: {required: true},
      icon_radius: {std: 12},
      icon_offset: {std: 30}
    }
  }

  static get properties() {
    return {
      drag_origin: {type: 'obj', std: null}
    };
  }

  static get computedProperties() {
    return {
      isEnabled: function() {
        const element = this.data.element;
        const selected_element = this.data.store.selected_element;
        if (!(selected_element && selected_element.edit && selected_element.erotation)) {
          return false;
        }
        return element === selected_element || element.two_page_spread_clone === selected_element;
      },
      pointerEventsAttribute: function() {
        return this.isEnabled ? 'auto' : 'none';
      },
      iconRadius: function() {
        return this.inSvgUnits(this.data.icon_radius);
      },
      iconOffset: function() {
        return this.inSvgUnits(this.data.icon_offset);
      },
      strokeWidth: function() {
        return this.inSvgUnits(1.5);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  // Prevent click from propagating to the page.
  onClick(evt) {
    evt.stopPropagation();
  }

  grabHandle(evt) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }
    document.body.style.cursor = 'grabbing';
    evt.stopPropagation();
    evt.preventDefault();

    const element = this.data.element;
    const page_offset = $j(this.dom_node).closest('svg.px-page').offset();
    const element_origin = {
      x: page_offset.left + this.inPixels(element.x + (element.width / 2)),
      y: page_offset.top + this.inPixels(element.y + (element.height / 2))
    };
    const pageX = 'pageX' in evt ? evt.pageX : evt.targetTouches[0].pageX;
    const pageY = 'pageY' in evt ? evt.pageY : evt.targetTouches[0].pageY;

    this.state.drag_origin = {
      rotation: element.rotation,
      angle: this.calculateDragAngle(pageX, pageY, element_origin),
      element_origin: element_origin
    };

    this._end_with_undo = this.data.store.undo_redo.beginWithUndo({
      label: 'rotate ' + element.type,
      set_id: this.data.store.selected_set.id
    });

    this.data.store.grabElement(element);

    const $doc = $j(document);
    $doc.on('mousemove touchmove', this.dragHandle);
    $doc.on('mouseup touchend touchcancel', this.releaseHandle);
  }

  dragHandle(evt) {
    if (this._drag_raf) {
      cancelAnimationFrame(this._drag_raf);
    }
    this._drag_raf = requestAnimationFrame(() => {
      const pageX = 'pageX' in evt ? evt.pageX : evt.targetTouches[0].pageX;
      const pageY = 'pageY' in evt ? evt.pageY : evt.targetTouches[0].pageY;

      const element = this.data.element;

      const angle = this.calculateDragAngle(pageX, pageY, this.state.drag_origin.element_origin);
      const degrees_diff = Px.Util.toDegrees(angle - this.state.drag_origin.angle);
      const original_rotation = this.state.drag_origin.rotation;

      let new_rotation = original_rotation + degrees_diff;
      if (evt.shiftKey) {
        new_rotation = Math.round(new_rotation / ElementRotateIcon.DEGREES_STEP) * ElementRotateIcon.DEGREES_STEP;
      }

      element.update({rotation: new_rotation});
    });
  }

  releaseHandle(evt) {
    if (this._drag_raf) {
      cancelAnimationFrame(this._drag_raf);
      this._drag_raf = null;
    }
    document.body.style.cursor = null;
    this.data.store.releaseElement(this.data.element);
    const $doc = $j(document);
    $doc.off('mousemove touchmove', this.dragHandle);
    $doc.off('mouseup touchend touchcancel', this.releaseHandle);
    this.state.drag_origin = null;
    this._end_with_undo();
  }

  // -------
  // Private
  // -------

  calculateDragAngle(pageX, pageY, element_origin) {
    const dx = pageX - element_origin.x;
    const dy = pageY - element_origin.y;
    return -Math.atan2(dx, dy);
  }

};

Px.Editor.ElementRotateIcon.DEGREES_STEP = 15;
