Px.Editor.GroupGalleryStore = class GroupGalleryStore extends Px.BaseStore {

  constructor(project_store) {
    super();
    this.project_store = project_store;
  }

  static get properties() {
    return {
      groups: {std: mobx.observable.map()},
      groups_loading: {std: false}
    };
  }

  get actions() {
    return {
      init: function() {
        if (this.groups.size === 0) {
          this.loadGroups();
        }
      },

      loadGroups: function() {
        this.groups_loading = true;
        const group_id = this.project_store.group_id ;
        const url = group_id ? `/v1/groups/${group_id}.json` : '/v1/groups/_mine.json';
        fetch(url).then(r => r.json()).then(json => {
          const groups = group_id ? [json] : json;
          mobx.runInAction(() => {
            this.groups.clear();
            groups.forEach(group => {
              this.groups.set(String(group.id), this.buildGroupItem(group));
            });
            this.groups_loading = false;
          });
        });
      },

      loadGroupGalleries: function(group_id) {
        const group = this.groups.get(String(group_id));
        group.loading = true;
        fetch(`/v1/groups/${group_id}/galleries.json`).then(r => r.json()).then(json => {
          mobx.runInAction(() => {
            group.galleries.clear();
            json.forEach(gallery => {
              group.galleries.set(String(gallery.id), this.buildGalleryItem(gallery));
            });
            group.loading = false;
          });
        });
      },

      loadGalleryImages: function(group_id, gallery_id) {
        const gallery = this.getGallery(group_id, gallery_id);
        gallery.loading = true;
        fetch(`/v1/galleries/${gallery_id}/images.json`).then(r => r.json()).then(json => {
          mobx.runInAction(() => {
            const items = [];
            json.forEach(image => {
              items.push(this.buildImageItem(image));
            });
            gallery.images.replace(items);
            gallery.loading = false;
          });
        });
      }

    };
  }

  getGroup(group_id) {
    // Make sure group_id is always a string.
    return this.groups.get(String(group_id));
  }

  getGallery(group_id, gallery_id) {
    return this.groups.get(String(group_id)).galleries.get(String(gallery_id));
  }

  groupGalleries(group_id) {
    return Array.from(this.getGroup(group_id).galleries.values());
  }

  galleryImages(group_id, gallery_id) {
    return this.getGallery(group_id, gallery_id).images;
  }

  buildGroupItem(group) {
    return {
      type: 'gallery',
      id: group.id,
      loading: true,
      caption: group.name,
      galleries: mobx.observable.map()
    };
  }

  buildGalleryItem(item) {
    return {
      type: 'gallery',
      id: item.id,
      loading: true,
      caption: item.name,
      images: mobx.observable.array()
    };
  }

  buildImageItem(item) {
    return {
      type: 'image',
      id: `db:${item.id}`,
      thumb_url: item.preview,
      caption: item.filename,
      data: item
    };
  }

};
