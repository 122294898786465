Px.Editor.DropboxImageModel = class DropboxImageModel extends Px.Editor.BaseTaggedImageModel {

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      dimensions: function(callback) {
        // TODO: super() is currently not supported in overridden computed properties,
        //       so we cannot call the supermethod from BaseTaggedImageModel here.
        const tags = this.tags;
        if (tags.width && tags.height) {
          const width = parseInt(tags.width, 10) || 0;
          const height = parseInt(tags.height, 10) || 0;
          return [width, height];
        } else {
          // Not all dropbox images may have dimensions in the API.
          // Just ignore resolutions warnings on them for now by returning large numbers.
          return [10000, 10000];
        }
      }
    });
  }

};
