Px.Editor.ImageUploadProgressIndicator = class ImageUploadProgressIndicator extends Px.Editor.BaseComponent {

  template() {
    const image = this.data.image;

    return Px.template`
      <div class="px-image-upload-progress-indicator">
        ${Px.if(image && image.type === 'local', () => {
          const local_file = image.data.local_file;
          if (local_file) {
            if (local_file.upload_failure) {
              return Px.template`
                <div class="px-upload-failure-notice">
                  ${Px.t('Upload failed')}
                </div>
              `;
            } else if (!local_file.upload_success) {
              return Px.template`
                <div class="px-upload-progress">
                  <div class="px-upload-progress-bar" style="transform:scaleX(${local_file.upload_progress_percent/100})">
                  </div>
                </div>
              `;
            }
          }
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true},
      image: {std: null}
    };
  }

};
