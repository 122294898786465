Px.Editor.ImageEditPanel = class ImageEditPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const store = this.data.store;
    const image = this.data.image;

    return Px.template`
      <div class="px-image-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">
            ${Px.if(image.crop, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Zoom')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'zoom-slider', this.zoomSliderProps)}
                </div>
              `;
            })}

            ${Px.if(Px.config.image_borders && image.eborder, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Border Width')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'border-slider', this.borderSliderProps)}
                </div>
              `;
            })}

            ${Px.if(Px.config.image_borders && image.eborder && image.ebordercolor && image.border > 0, () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Border Color')}</h2>
                  ${r(Px.Components.ColorButton, 'border-color', this.borderColorButtonProps)}
                </div>
              `;
            })}

            ${Px.if(Px.config.border_radius && image.eborderradius, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Border Radius')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'radius-slider', this.radiusSliderProps)}
                </div>
              `;
            })}

            ${Px.if(image.eopacity && !store.cut_print_mode, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Opacity')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'opacity-slider', this.opacitySliderProps)}
                </div>
              `;
            })}

            ${Px.if(Px.config.image_rotation && image.erotation, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Rotation')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'rotation-slider', this.rotationSliderProps)}
                </div>
              `;
            })}

            ${Px.if(image.emask && (store.masks.images.length || image.mask), () => {
              return Px.template`
                <div class="px-edit-control px-mask-control">
                  <h2>${Px.t('Mask')}</h2>
                  <div class="px-controls">
                    ${Px.if(store.masks.images.length, () => {
                      return Px.template`
                        <button class="px-select-mask" data-onclick="goToMaskSelection">
                          ${Px.t('Select...')}
                        </button>
                      `;
                    })}
                    ${Px.if(image.mask, () => {
                      return Px.template`
                        <button class="px-remove-mask" data-onclick="clearMask">
                          ${Px.t('Remove')}
                        </button>
                      `;
                    })}
                  </div>
                </div>
              `;
            })}

            ${Px.if(Px.config.image_effects, () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('Effects')}</h2>
                  <div class="px-image-effects">
                    ${r(Px.Components.ToggleButton, 'sepia-button', this.sepiaButtonProps)}
                    ${r(Px.Components.ToggleButton, 'grayscale-button', this.grayscaleButtonProps)}
                    ${r(Px.Components.ToggleButton, 'equalize-button', this.equalizeButtonProps)}
                    ${r(Px.Components.ToggleButton, 'sharpen-button', this.sharpenButtonProps)}
                    ${r(Px.Components.ToggleButton, 'flip-button', this.flipButtonProps)}
                    ${r(Px.Components.ToggleButton, 'blur-button', this.blurButtonProps)}
                  </div>
                </div>
              `;
            })}
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setOpacity = this.setOpacity.bind(this);
    this.setZoom = this.setZoom.bind(this);
    this.setBorder = this.setBorder.bind(this);
    this.setRadius = this.setRadius.bind(this);
    this.setRotation = this.setRotation.bind(this);
    this.setSepia = this.setSepia.bind(this);
    this.setGrayscale = this.setGrayscale.bind(this);
    this.setEqualize = this.setEqualize.bind(this);
    this.setSharpen = this.setSharpen.bind(this);
    this.setFlip = this.setFlip.bind(this);
    this.setBlur = this.setBlur.bind(this);
    this.selectBorderColor = this.selectBorderColor.bind(this);
    this.onBeforeOpacitySliderDrag = this.onBeforeOpacitySliderDrag.bind(this);
    this.onAfterOpacitySliderDrag = this.onAfterOpacitySliderDrag.bind(this);
    this.onBeforeZoomSliderDrag = this.onBeforeZoomSliderDrag.bind(this);
    this.onAfterZoomSliderDrag = this.onAfterZoomSliderDrag.bind(this);
    this.onBeforeBorderSliderDrag = this.onBeforeBorderSliderDrag.bind(this);
    this.onAfterBorderSliderDrag = this.onAfterBorderSliderDrag.bind(this);
    this.onBeforeRadiusSliderDrag = this.onBeforeRadiusSliderDrag.bind(this);
    this.onAfterRadiusSliderDrag = this.onAfterRadiusSliderDrag.bind(this);
    this.onBeforeRotationSliderDrag = this.onBeforeRotationSliderDrag.bind(this);
    this.onAfterRotationSliderDrag = this.onAfterRotationSliderDrag.bind(this);
  }

  get dataProperties() {
    return {
      image: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      zoomSliderProps: function() {
        return {
          min: 0,
          max: Px.Editor.ImageElementModel.MAX_ZOOM,
          step: 0.25,
          value: this.data.image.zoom,
          onNewValue: this.setZoom,
          onBeforeDrag: this.onBeforeZoomSliderDrag,
          onAfterDrag: this.onAfterZoomSliderDrag
        };
      },
      opacitySliderProps: function() {
        return {
          min: 20,
          max: 100,
          value: this.data.image.opacity * 100,
          onNewValue: this.setOpacity,
          onBeforeDrag: this.onBeforeOpacitySliderDrag,
          onAfterDrag: this.onAfterOpacitySliderDrag
        };
      },
      borderSliderProps: function() {
        return {
          min: 0,
          max: 10,
          step: 0.25,
          value: this.data.image.border,
          onNewValue: this.setBorder,
          onBeforeDrag: this.onBeforeBorderSliderDrag,
          onAfterDrag: this.onAfterBorderSliderDrag
        };
      },
      borderColorButtonProps: function() {
        return {
          color: this.data.image.bordercolor,
          title: Px.t('Choose color'),
          onClick: this.selectBorderColor
        }
      },
      radiusSliderProps: function() {
        return {
          min: 0,
          max: 300,
          value: this.data.image.radius,
          onNewValue: this.setRadius,
          onBeforeDrag: this.onBeforeRadiusSliderDrag,
          onAfterDrag: this.onAfterRadiusSliderDrag
        };
      },
      rotationSliderProps: function() {
        return {
          min: -180,
          max: 180,
          step: 0.25,
          value: this.data.image.rotation,
          onNewValue: this.setRotation,
          onBeforeDrag: this.onBeforeRotationSliderDrag,
          onAfterDrag: this.onAfterRotationSliderDrag
        };
      },
      sepiaButtonProps: function() {
        return {
          content: Px.t('Sepia'),
          pressed: this.data.image.sepia,
          onToggle: this.setSepia
        };
      },
      grayscaleButtonProps: function() {
        return {
          content: Px.t('Grayscale'),
          pressed: this.data.image.grayscale,
          onToggle: this.setGrayscale
        };
      },
      equalizeButtonProps: function() {
        return {
          content: Px.t('Equalize'),
          pressed: this.data.image.equalize,
          onToggle: this.setEqualize
        };
      },
      sharpenButtonProps: function() {
        return {
          content: Px.t('Sharpen'),
          pressed: this.data.image.sharpen,
          onToggle: this.setSharpen
        };
      },
      flipButtonProps: function() {
        return {
          content: Px.t('Flip'),
          pressed: this.data.image.flip,
          onToggle: this.setFlip
        };
      },
      blurButtonProps: function() {
        return {
          content: Px.t('Blur'),
          pressed: this.data.image.blur,
          onToggle: this.setBlur
        };
      }
    };
  }

  setZoom(zoom) {
    this.data.image.update({zoom: zoom});
  }
  setOpacity(opacity) {
    this.data.image.update({opacity: opacity / 100});
  }
  setBorder(border) {
    this.data.image.update({border: border});
  }
  setRadius(radius) {
    this.withUndo('set border radius', () => {
      this.data.image.update({radius: radius});
    });
  }
  setRotation(rotation) {
    this.withUndo('rotate image', () => {
      this.data.image.update({rotation: rotation});
    });
  }
  setSepia(sepia) {
    this.withUndo('sepia filter', function() {
      this.data.image.update({sepia: sepia});
    });
  }
  setGrayscale(grayscale) {
    this.withUndo('grayscale filter', function() {
      this.data.image.update({grayscale: grayscale});
    });
  }
  setEqualize(equalize) {
    this.withUndo('equalize filter', function() {
      this.data.image.update({equalize: equalize});
    });
  }
  setSharpen(sharpen) {
    this.withUndo('sharpen filter', function() {
      this.data.image.update({sharpen: sharpen});
    });
  }
  setFlip(flip) {
    this.withUndo('flip image', function() {
      this.data.image.update({flip: flip});
    });
  }
  setBlur(blur) {
    this.withUndo('blur filter', function() {
      this.data.image.update({blur: blur});
    });
  }

  onBeforeZoomSliderDrag() {
    this.beginWithUndo('change image zoom');
  }
  onAfterZoomSliderDrag() {
    this.endWithUndo('change image zoom');
  }
  onBeforeOpacitySliderDrag() {
    this.beginWithUndo('change image opacity');
  }
  onAfterOpacitySliderDrag() {
    this.endWithUndo('change image opacity');
  }
  onBeforeBorderSliderDrag() {
    this.beginWithUndo('change image border');
  }
  onAfterBorderSliderDrag() {
    this.endWithUndo('change image border');
  }
  onBeforeRadiusSliderDrag() {
    this.beginWithUndo('change image radius');
  }
  onAfterRadiusSliderDrag() {
    this.endWithUndo('change image radius');
  }
  onBeforeRotationSliderDrag() {
    this.beginWithUndo('change image rotation');
  }
  onAfterRotationSliderDrag() {
    this.endWithUndo('change image rotation');
  }

  // --------------
  // Event handlers
  // --------------

  selectBorderColor(evt) {
    const image = this.data.image;
    const store = this.data.store;
    this.makeModal(Px.Editor.ColorPickerModal, {
      store: store,
      initial_color: image.bordercolor,
      palette_id: image.palette || Px.config.default_image_border_color_palette_id,
      usage_context: 'image_borders',
      color_type_switch: store.color_picker_type_switch_enabled,
      onColorSelected: color => {
        this.withUndo('set border color', () => {
          image.update({bordercolor: color});
        });
      }
    });
  }

  clearMask(evt) {
    this.withUndo('remove image mask', () => {
      this.data.image.update({mask: null});
    });
  }

  goToMaskSelection(evt) {
    this.data.store.ui.setImageTabSection('image-mask');
  }

};
