Px.Editor.PageModel = class PageModel extends Px.Util.mixin(Px.BaseModel, Px.Editor.ElementContainerMixin) {

  static fromJSON(json, params) {
    var getChildNodes = function(parent) {
      var standard_nodes = [];
      var layout_nodes = [];
      var node = parent.firstChild;
      while (node) {
        if (node.nodeType === 1) {
          if (node.tagName.toLowerCase() === 'g' && node.getAttribute('unit') !== 'true') {
            // Support for deprecated layout groups.
            _.each(getChildNodes(node), function(n) {
              n.setAttribute('layout', 'true');
              layout_nodes.push(n);
            });
          } else {
            standard_nodes.push(node);
          }
        }
        node = node.nextSibling;
      }
      return standard_nodes.concat(layout_nodes);
    };

    var page_node = Px.Util.parseXML(json.data).firstChild;

    var props = {
      id: json.id,
      name: json.name,
      snap_points: json.snap_points || [],
      width: parseFloat(page_node.getAttribute('width')) || 0,
      height: parseFloat(page_node.getAttribute('height')) || 0,
      bleed: parseFloat(page_node.getAttribute('bleed')) || 0,
      margin: parseFloat(page_node.getAttribute('margin')) || 0,
      gutter: parseFloat(page_node.getAttribute('gutter')) || 0,
      edit: page_node.getAttribute('edit') !== 'false',
      bgfill: page_node.getAttribute('bgfill') === 'true',
      layout_id: parseInt(page_node.getAttribute('layout_id'), 10) || null,
      bgcolor: page_node.getAttribute('bgcolor') || null,
      srccolor: page_node.getAttribute('srccolor') || null,
      mask: page_node.getAttribute('mask') || null,
      src: page_node.getAttribute('src') || null,
      crop: page_node.getAttribute('crop') || null,
      quantity: parseInt(page_node.getAttribute('quantity'), 10) || 1,
      pages: (page_node.getAttribute('pages') || '').split(',').map(name => name.trim()),
      version: parseInt(page_node.getAttribute('version'), 10) || 1
    };

    if (json.hasOwnProperty('_virtual_bleed')) {
      props._virtual_bleed = json._virtual_bleed;
    }
    if (json.hasOwnProperty('_virtual_margin')) {
      props._virtual_margin = json._virtual_margin;
    }

    const page = this.make(props);

    page.elements = _.map(getChildNodes(page_node), node => {
      const extra_params = _.extend({page: page}, params);
      return Px.Editor.BaseElementModel.fromXMLNode(node, extra_params);
    });

    // Walk through all child elements and register any image, which hasn't already been registered
    // by the load handler above. All 'db' type images should already be registered, but others need
    // to be registered now.
    const registerImages = element => {
      if (element.type === 'image' && element.id && !params.image_store.get(element.id)) {
        params.image_store.register(element.id, {});
      }
      if (element.elements) {
        element.elements.forEach(registerImages);
      }
    };
    // Do the same for any PDF elements.
    const registerPdfs = element => {
      if (element.type === 'pdf' && element.id && !params.pdf_store.get(element.id)) {
        params.pdf_store.register(element.id, {});
      }
      if (element.elements) {
        element.elements.forEach(registerPdfs);
      }
    };
    page.elements.forEach(element => {
      registerImages(element);
      registerPdfs(element);
    });

    return page;
  }

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null, serialize: false},
      edit: {std: true},
      name: {std: null},
      width: {std: 0},
      height: {std: 0},
      _bleed: {std: 0, serialize: false},
      _margin: {std: 0, serialize: false},
      _virtual_bleed: {std: null, serialize: false},
      _virtual_margin: {std: null, serialize: false},
      gutter: {std: 0},
      bgcolor: {std: null},
      bgfill: {std: false},
      src: {std: null},
      srccolor: {std: null},
      mask: {std: null},
      crop: {std: null},
      layout_id: {std: null},
      quantity: {std: 1},
      version: {std: 1},
      pages: {std: mobx.observable.array(), serialize: false},
      snap_points: {std: mobx.observable.array(), serialize: false},
      // Circular dependency to the parent set. Can be null if page represents a layout.
      set: {std: null, serialize: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      page_number: function() {
        const project_store = this.set && this.set.project_store;
        if (!(project_store instanceof Px.Editor.BookProjectStore)) {
          return null;
        }
        let page_number = 0;
        outer_loop:
        for (const set of project_store.page_sets) {
          for (const page of set.pages) {
            if (set.count) {
              page_number++;
            }
            if (page === this) {
              break outer_loop;
            }
          }
        }
        return page_number;
      },
      position: function() {
        var pos = 0;
        if (this.set) {
          for (var i = 0; i < this.set.pages.length; i++) {
            if (this.set.pages[i] === this) {
              break;
            }
            pos++;
          }
        }
        return pos;
      },
      in_leftmost_position: function() {
        if (Px.config.rtl) {
          return this.set ? this.position === this.set.pages.length - 1 : true;
        } else {
          return this.position === 0;
        }
      },
      in_rightmost_position: function() {
        if (Px.config.rtl) {
          return this.position === 0;
        } else {
          return this.set ? this.position === this.set.pages.length - 1 : true;
        }
      },
      caption: function() {
        if (this.in_leftmost_position && this.set.left_caption) {
          return this.set.left_caption;
        } else if (this.in_rightmost_position && this.set.right_caption) {
          return this.set.right_caption;
        } else {
          return this.set.center_caption || this.name;
        }
      },
      image_elements: function() {
        return this.getElementsByType('image');
      },
      pdf_elements: function() {
        return this.getElementsByType('pdf');
      },
      text_elements: function() {
        return this.getElementsByType('text');
      },
      score_elements: function() {
        return this.getElementsByType('score');
      },
      inline_page_elements: function() {
        return this.getElementsByType('ipage');
      },
      layout_elements: function() {
        var elements = [];
        this.elements.forEach(function(element) {
          if (element.layout) {
            elements.push(element);
          }
        });
        return elements;
      },
      unedited_elements: function() {
        var unedited = [];
        this.forEachElement(element => {
          if (element.is_unedited) {
            unedited.push(element);
          }
        });
        return unedited;
      },
      fillable_placeholders: function() {
        return this.image_elements.filter(element => {
          return element.is_editable_master_element && element.replace && (element.placeholder || element.id === null);
        });
      },
      can_receive_layout: function() {
        var has_existing_layout = this.layout_elements.length > 0 || this.layout_id;
        var can_receive = this.edit || has_existing_layout;
        return Boolean(can_receive);
      },
      binding_score: function() {
        var binding = _.find(this.elements, function(element) {
          return element.type === 'score' && element.name === 'binding';
        });
        return binding || null;
      },
      binding_width: function() {
        return this.binding_score ? this.binding_score.width : 0;
      },
      is_new_page: function() {
        return String(this.id).match(/^new:/);
      },
      // We assume a cut print page only contains a single editable image.
      cut_print_image: function() {
        return this.image_elements.find(image => {
          return image.id && !image.placeholder;
        });
      },
      xml: function() {
        var xml = '<?xml version="1.0" encoding="UTF-8"?>';
        xml += '<page ' + this.xmlizeAttributes() + '>';
        xml += this.xmlizeElements();
        xml += '</page>';
        return xml;
      },
      // Fast hash code algorithm to generate an integer hash from page xml.
      // Adapted from http://stackoverflow.com/a/7616484/51397
      xml_hash: function() {
        var hash = 0;
        var i, chr, len;
        for (i = 0, len = this.xml.length; i < len; i++) {
          chr = this.xml.charCodeAt(i);
          hash = ((hash << 5) - hash) + chr;
          hash |= 0; // Convert to 32bit integer
        }
        return hash;
      }
    });
  }

  get actions() {
    return Object.assign(super.actions, {
      addElement: function(element) {
        this._elements.push(element);
        element.page = this;
      },

      removeElement: function(element) {
        this._elements.remove(element);
        element.page = null;
      },

      // NOTE: This is only for laflat binding marks, because we assume that regular bindings and hinges
      //       are only present on the cover page which is always generated on the server, so we don't need
      //       to bother with it on the frontend. However if we ever want to move project generation entirely
      //       to the frontend, we'll have to take care of all types of scores.
      generateLayflatBindingScore: function() {
        const width = 1;  // the layflat binding score is 1 mm wide
        const score = Px.Editor.ScoreElementModel.make({
          name: 'binding',
          x: (this.width - width) / 2,
          y: 0,
          width: width,
          height: this.height
        });
        this.addElement(score);
      },

      generateBleedScores: function() {
        this.score_elements.forEach(score => {
          if (score.name === 'bleed') {
            this.removeElement(score);
          }
        });

        if (this.bleed) {
          const rects = [
            [0, 0, this.width, this.bleed],
            [0, this.height - this.bleed, this.width, this.bleed]
          ];
          if (!this.gutter || this.in_leftmost_position) {
            rects.push([0, this.bleed, this.bleed, this.height - 2*this.bleed]);
          }
          if (!this.gutter || this.in_rightmost_position) {
            rects.push([this.width - this.bleed, this.bleed, this.bleed, this.height - 2*this.bleed]);
          }

          rects.forEach(rect => {
            const score = Px.Editor.ScoreElementModel.make({
              name: 'bleed',
              x: rect[0],
              y: rect[1],
              width: rect[2],
              height: rect[3]
            });
            this.addElement(score);
          });
        }
      },

      // Takes care of shifting elements properly when the page's width changes.
      // The code should match the server-side logic in product_definition/page_filter.rb.
      adjustBinding: function(old_binding, new_binding, layout_only) {
        var self = this;
        var expansion = new_binding - old_binding;
        if (!layout_only) {
          this.width += expansion;
        }
        var original_width = this.width - expansion;
        var tolerance = 3; // 3 mm tolerance
        // Gets the shift amount the element should be shifted for.
        // Elements from the left side of the page shouldn't be shifted at all,
        // elements from the spine area should be shifted expansion/2,
        // while the elements from the right side of the page should be shifted full expansion.
        var get_shift = function(element) {
          var shift;
          var x = element.center_point.x;
          if (x < (original_width - old_binding - tolerance)/2) {
            shift = 0;
          } else if (x <= (original_width + old_binding + tolerance)/2) {
            shift = expansion/2;
          } else {
            shift = expansion;
          }
          return shift;
        };

        var shift_element = function(element) {
          var shift = get_shift(element);
          if (shift !== 0) {
            element.x = element.x + shift;
          }
        };

        var elements = layout_only ? this.layout_elements : this.elements;
        elements.forEach(element => {
          if (element.type === 'score') {
            if (element.name === 'binding') {
              element.width = new_binding;
              element.x = (self.width - new_binding)/2;
            } else if (element.name === 'bleed') {
              element.destroy();
            } else if (element.name === 'hinge') {
              // TODO: For now just shift since hinge is a static value in template definition,
              //       however it would probably make sense to grow it according to the binding?
              shift_element(element);
            }
          } else {
            shift_element(element);
          }
        });

        this.generateBleedScores();
      },

      setLayout: function(layout) {
        var self = this;

        var deleted_images = [];
        var deleted_texts = {};
        this.layout_elements.forEach(function(element) {
          if (element.is_in_viewport && element.is_master_element) {
            if (element.type === 'image' && element.id && element.edit && element.replace) {
              deleted_images.push(element);
            } else if (element.type === 'text' && element.text && element.name && element.edit && !element.placeholder) {
              deleted_texts[element.name] = element;
            }
            element.destroy();
          }
        });

        layout.elements.forEach(function(layout_element) {
          var reflow = null;
          var element = layout_element.clone({page: self});
          element.layout = true;
          element.page = self;
          if (element.type === 'image' && element.replace && (element.placeholder || !element.id)) {
            reflow = deleted_images.shift();
            if (reflow) {
              element.id = reflow.id;
              element.placeholder = reflow.placeholder;
            }
          } else if (element.type === 'text' && element.name && element.placeholder) {
            reflow = deleted_texts[element.name];
            if (reflow) {
              element.text = reflow.text;
              element.placeholder = reflow.placeholder;
            }
          }
          self.addElement(element);
        });

        this.adjustBinding(0, this.binding_width, true);
        this.layout_id = layout.id;
      },

      // Tries to fill an image into an available placeholder.
      // Returns true if the image is sucessfully filled, otherwise returns false.
      fillImage: function(image_id) {
        const placeholder = this.fillable_placeholders[0];
        if (placeholder) {
          placeholder.fillPlaceholder(image_id);
          return true;
        } else {
          return false;
        }
      }
    });
  }

  // Not a very efficient way to clone a Page (goes via XML).
  clone(opts) {
    opts = opts || {};
    var json = {
      id: 'new:' + Px.Util.guid(),
      data: this.xml,
      name: this.name,
      snap_points: this.snap_points.toJS()
    };
    return Px.Editor.PageModel.fromJSON(json, opts);
  }

  // ---------------
  // Getters/setters
  // ---------------

  get bleed() {
    // In the admin the same template can map to multiple pages in a generate project,
    // with possibly different bleed values.
    // In the admin we therefore use a virtual bleed value, which is for display purposes only
    // and never gets serialized.
    if (this._virtual_bleed !== null) {
      return this._virtual_bleed;
    }
    return this._bleed;
  }

  set bleed(val) {
    this._bleed = val;
  }

  get margin() {
    if (this._virtual_margin !== null) {
      return this._virtual_margin;
    }
    return this._margin;
  }

  set margin(val) {
    this._margin = val;
  }

  // ------
  // Public
  // ------

  optimalScale(available_width, available_height, crop_bleed) {
    var viewable_width;
    var viewable_height;
    if (crop_bleed) {
      viewable_width = this.width - (this.bleed + (this.gutter || this.bleed));
      viewable_height = this.height - 2*this.bleed;
    } else {
      viewable_width = this.width - this.gutter;
      viewable_height = this.height;
    }
    // try height
    var scale = available_height / viewable_height;
    var new_width = viewable_width * scale;
    if (new_width > available_width) {
      // try width
      scale = available_width / viewable_width;
    }
    return scale;
  }

  pageNameMatches(page_names) {
    let valid = false;
    page_names = page_names.filter(name => name);
    if (page_names.length) {
      let name;
      for (let i = 0; i < page_names.length; i++) {
        name = page_names[i];
        if (name[0] === '!') {
          if (this.name === name.substr(1)) {
            // This page is explicitly excluded,
            // no need to look further.
            valid = false;
            break;
          } else {
            valid = true;
          }
        } else if (this.name === name) {
          // This page is listed, all good.
          valid = true;
          break;
        }
      }
    } else {
      valid = true;
    }
    return valid;
  }

  viewBoxWidth(crop_bleed, debug_gutter) {
    const width = this.width;
    const bleed = this.bleed;
    const has_two_pages = this.set && this.set.pages.length === 2;
    const gutter = (debug_gutter && has_two_pages) ? 0 : this.gutter;
    if (crop_bleed) {
      return width - (bleed + (gutter || bleed));
    } else {
      return width - (gutter || 0);
    }
  }

  viewBoxHeight(crop_bleed) {
    const height = this.height;
    const bleed = this.bleed;
    if (crop_bleed) {
      return height - 2*bleed;
    } else {
      return height;
    }
  }

  gridLines(vblocks, hblocks) {
    const lines = [];
    const vblock_width = (this.width - 2 * this.bleed) / vblocks;
    const hblock_width = (this.height - 2 * this.bleed) / hblocks;
    for (let v = 1; v < vblocks; v++) {
      lines.push({x: this.bleed + v * vblock_width});
    }
    for (let h = 1; h < hblocks; h++) {
      lines.push({y: this.bleed + h * hblock_width});
    }
    return lines;
  }

  // -------
  // Private
  // -------

  serializableAttributes() {
    var self = this;
    var attrs = {};
    _.each(this.constructor.properties, function(spec, key) {
      if (spec.serialize !== false) {
        attrs[key] = self[key];
      }
    });
    if (this._bleed) {
      attrs.bleed = this._bleed;
    }
    if (this._margin) {
      attrs.margin = this._margin;
    }
    if (this.pages.length > 0) {
      attrs.pages = this.pages.join(',');
    }
    return attrs;
  }

  xmlizeAttributes() {
    var attributes = this.serializableAttributes();
    var keys = Object.keys(attributes).sort();
    var output = [];
    keys.forEach(key => {
      var value = attributes[key];
      var spec = this.constructor.properties[key] || {std: null};
      if (value !== spec.std) {
        output.push(`${key}="${_.escape(value.toString())}"`);
      }
    });
    return output.join(' ');
  }

};
