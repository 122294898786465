Px.Editor.PdfEditPanel = class PdfEditPanel extends Px.Editor.BaseComponent {

  template() {
    const pdf = this.data.pdf;
    const r = this.renderChild;

    return Px.template`
      <div class="px-pdf-edit-panel px-edit-panel">
        <div class="px-edit-section">
          <div class="px-edit-controls">

            ${Px.if(pdf.erotation, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Rotation')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'rotation-slider', this.rotationSliderProps)}
                </div>
              `;
            })}

            ${Px.if(pdf.eopacity, () => {
              return Px.template`
                <div class="px-edit-control px-slider-control">
                  <h2>${Px.t('Opacity')}</h2>
                  ${r(Px.Components.SliderWithNumericInput, 'opacity-slider', this.opacitySliderProps)}
                </div>
              `;
            })}

          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setRotation = this.setRotation.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.onBeforeRotationSliderDrag = this.onBeforeRotationSliderDrag.bind(this);
    this.onAfterRotationSliderDrag = this.onAfterRotationSliderDrag.bind(this);
    this.onBeforeOpacitySliderDrag = this.onBeforeOpacitySliderDrag.bind(this);
    this.onAfterOpacitySliderDrag = this.onAfterOpacitySliderDrag.bind(this);
  }

  get dataProperties() {
    return {
      pdf: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      rotationSliderProps: function() {
        return {
          min: -180,
          max: 180,
          step: 0.25,
          value: this.data.pdf.rotation,
          onNewValue: this.setRotation,
          onBeforeDrag: this.onBeforeRotationSliderDrag,
          onAfterDrag: this.onAfterRotationSliderDrag
        };
      },
      opacitySliderProps: function() {
        return {
          min: 20,
          max: 100,
          value: this.data.pdf.opacity * 100,
          onNewValue: this.setOpacity,
          onBeforeDrag: this.onBeforeOpacitySliderDrag,
          onAfterDrag: this.onAfterOpacitySliderDrag
        };
      },
    };
  }

  setRotation(rotation) {
    this.withUndo('rotate pdf', () => {
      this.data.pdf.update({rotation: rotation});
    });
  }
  setOpacity(opacity) {
    this.withUndo('set pdf opacity', () => {
      this.data.pdf.update({opacity: opacity / 100});
    });
  }
  onBeforeRotationSliderDrag() {
    this.beginWithUndo('change pdf rotation');
  }
  onAfterRotationSliderDrag() {
    this.endWithUndo('change pdf rotation');
  }
  onBeforeOpacitySliderDrag() {
    this.beginWithUndo('change pdf opacity');
  }
  onAfterOpacitySliderDrag() {
    this.endWithUndo('change pdf opacity');
  }

};
