Px.Editor.InlinePageElementModel = class InlinePageElementModel extends Px.Editor.BaseElementModel {

  // --------------------
  // Static/class methods
  // --------------------

  static fromXMLNode(node, params) {
    return this.make({
      id:              parseInt(node.getAttribute('id'), 10) || null,
      template:        node.getAttribute('template') || null,
      edit:            node.getAttribute('edit') === 'true',
      x:               parseFloat(node.getAttribute('x')) || 0,
      y:               parseFloat(node.getAttribute('y')) || 0,
      z:               parseInt(node.getAttribute('z'), 10) || 0,
      width:           parseFloat(node.getAttribute('width')) || 0,
      height:          parseFloat(node.getAttribute('height')) || 0,
      rotation:        parseFloat(node.getAttribute('rotate')) || 0,
      resize:          node.getAttribute('resize') !== 'false',
      move:            node.getAttribute('move') !== 'false',
      erotation:       node.getAttribute('erotation') !== 'false',
      elayer:          node.getAttribute('elayer') !== 'false',
      'delete':        node.getAttribute('delete') !== 'false',
      opacity:         parseFloat(node.getAttribute('opacity')) || 1,
      eopacity:        node.getAttribute('eopacity') !== 'false',
      zoom:            parseFloat(node.getAttribute('zoom')) || 0,
      left:            parseFloat(node.getAttribute('left')) || 0,
      top:             parseFloat(node.getAttribute('top')) || 0,
      crop:            node.getAttribute('crop') === 'true',
      stretch:         node.getAttribute('stretch') === 'true',
      radius:          parseFloat(node.getAttribute('radius')) || 0,
      mask:            node.getAttribute('mask') || null,
      shadow_opacity:  parseFloat(node.getAttribute('shadow_opacity'), 10) || 0,
      shadow_stdev:    parseFloat(node.getAttribute('shadow_stdev'), 10) || 0,
      shadow_ox:       parseFloat(node.getAttribute('shadow_ox'), 10) || 0,
      shadow_oy:       parseFloat(node.getAttribute('shadow_oy'), 10) || 0,
      shadow_color:    node.getAttribute('shadow_color') || '#000000',
      name:            node.getAttribute('name') || null,
      placeholder:     node.getAttribute('placeholder') === 'true',
      show_on_preview: node.getAttribute('show_on_preview') === 'true',
      tags:            node.getAttribute('tags') || null,
      clone_id:        node.getAttribute('clone_id') || null,
      layout:          node.getAttribute('layout') === 'true',
      pdf_layer:       node.getAttribute('pdf_layer') || null,
      group:           params.group || null,
      page:            params.page,
      image_store:     params.image_store
    });
  }

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null},
      template: {std: null},
      zoom: {std: 0},
      _left: {std: 0, serialize: false},
      _top: {std: 0, serialize: false},
      eopacity: {std: true},
      elayer: {std: true},
      crop: {std: false},
      stretch: {std: false},
      radius: {std: 0},
      mask: {std: null},
      shadow_opacity: {std: 0},
      shadow_stdev: {std: 0},
      shadow_ox: {std: 0},
      shadow_oy: {std: 0},
      shadow_color: {std: '#000000'},
      placeholder: {std: false},
      show_on_preview: {std: false},
      // Reference to the ImageStore.
      image_store: {std: null, two_page_spread: false, serialize: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      embedded_page: function() {
        let page;
        const project_store = this.page && this.page.set && this.page.set.project_store;
        if (project_store) {
          if (this.id) {
            page = project_store.pages.find(page => page.id === this.id);
          } else if (this.template && !(project_store instanceof Px.Editor.BookProjectStore)) {
            page = project_store.pages.find(page => page.name === this.template);
          }
        }
        return page || null;
      },
      embedded_page_dimensions: function() {
        if (this.embedded_page === null) {
          return [0, 0];
        } else {
          return this.calculatePageDimensions();
        }
      },
      mask_image: function() {
        if (this.mask) {
          return this.image_store.get(this.mask);
        } else {
          return null;
        }
      },
      _left_limit: function() {
        const page_width = this.embedded_page_dimensions[0];
        const minw = Math.abs(this._min_dimensions[0]);
        let limit;
        if (page_width > minw) {
          const x = (page_width - minw)/2;
          limit = x * 100/page_width;
        } else {
          limit = 0;
        }
        return limit;
      },
      _top_limit: function() {
        const page_height = this.embedded_page_dimensions[1];
        const minh = Math.abs(this._min_dimensions[1]);
        let limit;
        if (page_height > minh) {
          const y = (page_height - minh)/2;
          limit = y * 100/page_height;
        } else {
          limit = 0;
        }
        return limit;
      },
      // Returns the minimum allowed width and height for the embedded page,
      // so that there's no blank space between the embeded page element and the border.
      _min_dimensions: function() {
        const dimensions = Px.Util.circumscribedRectangleDimensions(this.width, this.height, 0);
        return [dimensions.width, dimensions.height];
      }
    });
  }

  // ---------------
  // Getters/setters
  // ---------------

  get left() {
    return Math.max(-this._left_limit, Math.min(this._left_limit, this._left));
  }

  set left(val) {
    this._left = val;
  }

  get top() {
    return Math.max(-this._top_limit, Math.min(this._top_limit, this._top));
  }

  set top(val) {
    this._top = val;
  }

  // -------
  // Private
  // -------

  calculatePageDimensions() {
    const zoom_factor = 1 + this.zoom/100;
    const width = this.width * zoom_factor;
    const height = this.height * zoom_factor;
    const aspect_ratio = this.embedded_page.width / this.embedded_page.height;

    let w, h;
    if (this.crop) {
      const dimensions = Px.Util.circumscribedRectangleDimensions(width, height, 0);
      w = dimensions.width;
      h = dimensions.height;
      if (!this.stretch) {
        if (aspect_ratio >= w/h) {
          w = h * aspect_ratio;
        } else {
          h = w / aspect_ratio;
        }
      }
    } else {
      let inscribed_page_ar = aspect_ratio;
      if (this.stretch) {
        const circumscribed_dims = Px.Util.circumscribedRectangleDimensions(width, height, 0);
        inscribed_page_ar = circumscribed_dims.width / circumscribed_dims.height;
      }
      const dimensions = Px.Util.inscribedRectangleDimensions(width, height, 0, inscribed_page_ar);
      w = dimensions.width;
      h = dimensions.height;
    }
    return [w, h];
  }

  serializableAttributes() {
    const attrs = super.serializableAttributes();
    attrs.left = this.left;
    attrs.top = this.top;
    return attrs;
  }

};

Px.Editor.InlinePageElementModel.ELEMENT_TYPE = 'ipage';
