Px.Editor.FontPaletteStore = class FontPaletteStore extends Px.BaseStore {

  constructor(theme_id) {
    super();
    this.theme_id = theme_id;
  }

  static get properties() {
    return {
      default_palette: {std: null},
      palettes: {std: mobx.observable.map()}
    };
  }

  get actions() {
    return {
      load: function(palette_id) {
        if (this.get(palette_id)) {
          return;
        }
        var model = Px.Editor.FontPaletteModel.make({id: palette_id, theme_id: this.theme_id});
        model.load();
        if (palette_id) {
          this.palettes.set(palette_id, model);
        } else {
          this.default_palette = model;
        }
      },

      loadAll: function() {
        fetch('/v1/font_palettes.json').then(response => response.json()).then(json => {
          json.forEach(palette_props => {
            const model = Px.Editor.FontPaletteModel.make(Object.assign(palette_props, {status: 'loaded'}));
            this.palettes.set(palette_props.id, model);
          });
        });
      }
    };
  }

  get(palette_id) {
    if (!palette_id) {
      return this.default_palette;
    }
    return this.palettes.get(palette_id) || null;
  }

};
