import * as mobx from '../../lib/mobx.js';
import Util from './util.js';
import Base from './base.js';
import EventEmitterMixin from './mixins/event_emitter_mixin';
import DisposerRegistryMixin from './mixins/disposer_registry_mixin';
import ObservablePropertiesMixin from './mixins/observable_properties_mixin';

Px.Component = class Component extends Util.mixin(
  Base,
  EventEmitterMixin,
  DisposerRegistryMixin,
  ObservablePropertiesMixin({propertiesScope: 'state'})
) {

  constructor(params) {
    super(params);

    this.component_type = this.constructor.name || 'AnonymousComponent';
    this._component_id = this._generateComponentId();
    // Handle instantiation and rendering of child components in the template
    this.subcomponents = {};
    this.dom_node = null;

    this._html = null;
    this._depth = 0;
    this._new_subcomponents = null;
    this._parent_key = null;
    this._autopatch_reaction = null;
    this._is_destroyed = false;

    this.renderChild = this.renderChild.bind(this);

    Px.Component.InstanceRegistry[this._component_id] = this;

    if (this.dataProperties) {
      this._setupData(params);
    }
  }

  // Default render function renders the `template` template.
  // May be overridden in subclasses.
  // Has to return a string of HTML with a single top-level element.
  render() {
    const html = this.template();
    if (html === null) {
      return '';
    }
    if (typeof html === 'string' || html instanceof String) {
      return html.trim();
    }
    throw new Error(`Expected 'this.template()' to return string or null, got: ${html}`);
  }

  // Renders internal representation of a child component as a div with special properties.
  // The type and key (both of type string) parameters are required, while the params (object)
  // parameter of custom key-value pairs is optional.
  renderChild(type, key, params) {
    if (!(Component.isPrototypeOf(type))) {
      throw new Error(`First parameter to renderChild should be a Component class, got: ${type}`);
    }
    if (typeof key !== 'string') {
      throw new Error(`Second parameter to renderChild should be a string key, got: ${key}`);
    }
    if (this._new_subcomponents) {
      if (this._new_subcomponents[key]) {
        throw new Error(`${this}: Child key not unique: ${key}`);
      }
      this._new_subcomponents[key] = true;
    }
    let child = this.subcomponents[key];
    if (child) {
      mobx._allowStateChanges(true, () => {
        mobx.untracked(() => {
          child.updateData(params);
        });
      });
    } else {
      mobx._allowStateChanges(true, () => {
        mobx.untracked(() => {
          child = type.make(params);
        });
      });
      child._parent_key = key;
      child._depth = this._depth + 1;
      this.subcomponents[key] = child;
    }
    // If child is already rendered, make sure to render the same type of node as its root node,
    // so that morphdom doesn't try to destroy it when doing its thing.
    // If child is not rendered yet, use a <script> element. The advantage of <script> over a <div>
    // is that a <script> works as a child of <table> while <div> does not.
    let start_tag, end_tag;
    if (child.dom_node) {
      start_tag = end_tag = child.dom_node.tagName;
    } else {
      start_tag = 'script type="text/node-tpl"';
      end_tag = 'script';
    }
    return Px.raw(`<${start_tag} data-px-id="${child._component_id}"></${end_tag}>`);
  }

  // Renders and mounts the component into target_node.
  // The component's dom node is appended to the target_node.
  mount(target_node) {
    const element = $j(target_node);

    if (this.dom_node) {
      throw new Error(`Component already mounted: ${this}`);
    }
    if (!element.length) {
      throw new Error(`Cannot mount ${this}; node not found: ${target_node}`);
    }
    if (element.length > 1) {
      throw new Error(`Cannot mount ${this}; got multiple matching nodes: ${target_node}`);
    }

    this._initialRender();
    element.append(this.dom_node);
    this._emitMount();
  }

  // Cleanup HTML and child components.
  // Not sure about what order it should be done in. Should children be destroyed first,
  // or does removing the parent HTML first avoid extra steps to remove their HTML?
  destroy() {
    super.destroy();

    _.each(this.subcomponents, child => child.destroy());
    this.subcomponents = {};

    if (this._autopatch_reaction) {
      this._autopatch_reaction.dispose();
    }

    if (this.dom_node) {
      this.dom_node.__px_component = null;
      this.dom_node.parentNode.removeChild(this.dom_node);
      this.dom_node = null;
    }

    this._is_destroyed = true;
    delete Px.Component.InstanceRegistry[this._component_id];
    this.emit('destroy');
  }

  updateData(params) {
    params = params || {};

    const bindings = this._dataBindings;
    const missing = [];

    mobx.transaction(() => {
      _.each(this.dataProperties, (val, key) => {
        if (typeof(params[key]) === 'undefined') {
          if (val.required) {
            missing.push(key);
          } else {
            bindings[key] = val.std;
          }
        } else {
          bindings[key] = params[key];
        }
      });
      if (missing.length !== 0) {
        throw new Error(`${this}: missing required data: '${missing.join(', ')}' in '${_.keys(params).join(', ')}'`);
      }
    });
  }

  toString() {
    return `${this.component_type}[${this._component_id}]`;
  }

  // Recursively emits 'mount' event (deepest children first).
  _emitMount() {
    _.each(this.subcomponents, child => child._emitMount());
    this.emit('mount');
  }

  // Only for use for bridging between old event diven code and components.
  _rerender() {
    console.warn('Using _rerender is deprecated');
    if (this.dom_node) {
      this._morphdom(this._renderInNewContext());
      _.each(this.subcomponents, child => child._rerender());
    }
  }

  _renderInNewContext() {
    this._new_subcomponents = {};
    let html;
    mobx._allowStateChanges(false,() => {
      html = this.render();
    });
    if (typeof html !== 'string') {
      throw new Error(`${this}: Expected render to return a string, got: ${html}`);
    }
    this._new_subcomponents = null;
    return html;
  }

  _mountAsChild(target_node) {
    this._initialRender();
    target_node.parentNode.replaceChild(this.dom_node, target_node);
  }

  _initialRender() {
    // The callback is invoked every time dependencies change.
    const reaction = new mobx.Reaction(`${this}.autopatch()`, () => {
      // When we detect that component's dependencies have changed, we don't immediatelly
      // re-render the component; we schedule a render of all stale components at the end of
      // this mobx reaction/transaction cycle instead.
      Px.Component._renderTransaction.stale.push(this);
      Px.Component._scheduleRerender();
    });

    // Initial render.
    reaction.track(() => {
      this._html = this._renderInNewContext();
    });

    this._buildDOMNode(this._html);
    this._autopatch_reaction = reaction;
  }

  _renderAndMorph() {
    let new_html;
    this._autopatch_reaction.track(() => {
      new_html = this._renderInNewContext();
    });
    if (new_html !== this._html) {
      this._html = new_html;
      this._morphdom(this._html);
    }
  }

  _getComponentForNode(node) {
    // We cache reference to the component on the node because this code may be invoked
    // several times during morphing for the same node.
    let component = node.__px_component;
    if (!component) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const id = node.getAttribute('data-px-id');
        if (id) {
          component = Px.Component.InstanceRegistry[id];
          node.__px_component = component;
        }
      }
    }
    return component;
  }

  _morphdom(html) {
    const self = this;
    const new_node = this._htmlToNode(html);
    const components_to_mount = {};

    // Prefill all subcomponents into components_to_destroy, we will delete each one
    // we encounter while walking the new DOM tree.
    const components_to_destroy = {};
    _.each(this.subcomponents, (child, key) => {
      components_to_destroy[child._component_id] = child;
    });


    const pxNoRerenderValue = (node) => {
      if ('pxNoRerender' in node) {
        return node.pxNoRerender;
      } else if (node.dataset && 'pxNoRerender' in node.dataset) {
        return node.dataset.pxNoRerender;
      } else if (node.classList && node.classList.contains('px-no-rerender')) {
        return 'true';
      }
    };

    morphdom(this.dom_node, new_node, {
      getNodeKey: function(node) {
        const component = self._getComponentForNode(node);
        if (component) {
          return component._component_id;
        }
      },
      onNodeAdded: function(node) {
        // If the new node is a subcomponent slot, mount the subcomponent in its place.
        const component = self._getComponentForNode(node);
        if (component) {
          components_to_mount[component._component_id] = {component: component, node: node};
          delete components_to_destroy[component._component_id];
        }
      },
      onBeforeNodeDiscarded: function(node) {
        // Do NOT remove nodes marked with the px-no-rerender class.
        if (pxNoRerenderValue(node) === 'true') {
          return false;
        }
        // If the discarded node is a subcomponent, don't let morphdom remove it from the DOM.
        // It will be automatically removed when we destroy discarded subcomponents below.
        // This avoids morphdom walking through the discarded subtree and invoking onNodeDiscarded
        // callbacks on every node.
        const component = self._getComponentForNode(node);
        if (component) {
          return false;
        }
      },
      onBeforeElChildrenUpdated: function(from_node, to_node) {
        if (pxNoRerenderValue(from_node) === 'children') {
          return false;
        }
      },
      // TODO: This assumes morphdom will never try to morph one subcomponent into another (because subcomponents
      // are keyed). This needs verification and tests! It is not clear how morphdom does node reordering!
      onBeforeElUpdated: function(from_node, to_node) {
        // Always patch the top-level node and its children.
        if (from_node === self.dom_node) {
          return true;
        }
        // Do NOT patch nodes marked with the px-no-rerender class.
        if (pxNoRerenderValue(from_node) === 'true') {
          return false;
        }
        // Is the new node a subcomponent slot?
        const new_component = self._getComponentForNode(to_node);
        if (new_component) {
          delete components_to_destroy[new_component._component_id];
          // If the particular component is already mounted in that slot, there is nothing to do.
          if (from_node.__px_component === new_component) {
            // This component appears to be already mounted, so remove it from components_to_mount.
            // This sometimes happens because morphdom invokes onNodeAdded added on the wrapper node,
            // but then automatically remounts the subcomponent (based on the key, I guess).
            delete components_to_mount[new_component._component_id];
            return false;
          } else {
            if (from_node.__px_component) {
              throw new Error('Did not expect to be morphing subcomponent trees from ' +
                              from_node.__px_component + ' to ' + new_component);
            } else {
              throw new Error('Did not expect to be morphing subcomponent into a non-component node: ' +
                              new_component + ' ' + from_node);
            }
          }
        }
        if (from_node.__px_component) {
          throw new Error('Did not expect to be morphing non-component node into subcomponent tree ' +
                          from_node.__px_component);
        }
      }
    });

    // Destroy any previous subcomponents we did not encounter during this DOM update.
    _.each(components_to_destroy, (child, id) => {
      child.destroy();
      delete self.subcomponents[child._parent_key];
    });

    // Mount all new subcomponents we encountered.
    _.each(components_to_mount, (spec, id) => {
      const subcomponent = spec.component;
      if (!subcomponent.dom_node) {
        subcomponent._mountAsChild(spec.node);
        subcomponent._emitMount();
      }
    });
  }

  // Takes a string of HTML and converts it to a DOM node tree.
  // Inspired by http://stackoverflow.com/a/22621001/51397
  _htmlToNode(html) {
    let fragment = null;
    let tag_name = null;
    const tag_match = html.match(/^\s*<([^>\s]+)/);
    if (tag_match) {
      tag_name = tag_match[1].toLowerCase();
    }
    const is_svg_child = tag_name && Px.Component.__svg_tags[tag_name];
    if (is_svg_child) {
      // If the top-level node is one of the SVG child node types, we need to wrap the entire
      // html into <svg> tags in order for the browser to interpret the elements correctly.
      html = `<svg>${html}</svg>`;
    }

    const template_element = document.createElement('template');
    template_element.innerHTML = html;
    fragment = document.importNode(template_element.content, true);

    const children = is_svg_child ? fragment.childNodes[0].childNodes : fragment.childNodes;
    if (children.length === 0) {
      throw new Error(`${this} did not render a valid DOM node`);
    } else if (children.length > 1) {
      throw new Error(`${this} rendered more than one top-level DOM node. Components must render a single top-level node.`);
    }
    const node = children[0];
    node.__px_component = this;
    return node;
  }

  // Invoked the first time component is rendered.
  // This function walks child nodes and mounts subcomponents when it encounters subcomponent slots.
  _buildDOMNode(html) {
    const root_node = this._htmlToNode(html);
    const components_to_mount = [];

    Px.Util.walkDOM(root_node, node => {
      if (node !== root_node) {
        const component = this._getComponentForNode(node);
        if (component) {
          components_to_mount.push({component: component, node: node});
          return false;
        }
      }
    });

    components_to_mount.forEach(spec => spec.component._mountAsChild(spec.node));

    this.dom_node = root_node;
  }

  _generateComponentId() {
    let component_id = '';
    for (let i = 0; i < 4; i++) {
      component_id += (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    }
    return component_id;
  }

  // Assign data verifying it corresponds to what is defined in dataProperties
  _setupData(params) {
    params = params || {};

    // Check no required fields are missing
    const bindings = {};
    const decorators = {};
    const missing = [];
    _.each(this.dataProperties, (val, key) => {
      if (typeof(params[key]) === 'undefined') {
        if (val.required) {
          missing.push(key);
        } else {
          bindings[key] = val.std;
          decorators[key] = mobx.observable.ref;
        }
      } else {
        bindings[key] = params[key];
        decorators[key] = mobx.observable.ref;
      }
    });
    if (missing.length !== 0) {
      const stringified_params = `[${Object.keys(params)}]`;
      try {
        stringified_params = JSON.stringify(params);
      } catch (e) {}
      throw new Error(`${this}: missing required data: '${missing.join(', ')}' in '${stringified_params}'`);
    }

    this._dataBindings = mobx.observable(bindings);

    this.data = new Proxy(this._dataBindings, {
      get: (target, key) => {
        if (!bindings.hasOwnProperty(key)) {
          throw new Error(`${this}: Trying to access undeclared data property: '${key}'`);
        }
        return target[key];
      },
      set: (target, key, value) => {
        throw new Error(`${this}: Setting data properties is not allowed: '${key}'`);
      }
    });
  }

};

Px.Component._renderTransaction = {
  stale: [],
  updated: [],
  scheduled: false
};

Px.Component._scheduleRerender = function() {
  const transaction = Px.Component._renderTransaction;
  if (!transaction.scheduled) {
    transaction.scheduled = true;
    // Create a new auto-disposing reaction to schedule re-render which runs
    // after all other currently scheduled reactions.
    mobx.when(
      () => true,
      () => {
        transaction.scheduled = false;
        Px.Component._rerenderComponents();
      }, {
        name: 'Px.Component::RerenderReaction'
      }
    );
  }
};

Px.Component._rerenderComponents = function() {
  const transaction = Px.Component._renderTransaction;

  if (transaction.stale.length) {  // There are stale components - rerender them.
    const stale_components = transaction.stale.splice(0);
    stale_components.sort(function(a, b) {
      return a._depth - b._depth;
    });

    stale_components.forEach(function(component) {
      if (!component._is_destroyed) {
        component._renderAndMorph();
        transaction.updated.push(component);
      }
    });
    // While rendering stale components, some other components might have become stale
    // and have to be rerendered before this render cycle is completely done, so schedule
    // another render loop.
    Px.Component._scheduleRerender();
  } else {  // There are no stale components left, so emit 'updated' events to complete the render transaction.
    const updated_components = transaction.updated.splice(0);
    for (let i = updated_components.length - 1; i >= 0; i--) {
      updated_components[i].emit('update');
    }
  }
};

// When rendering a component where the top-level node is one of the SVG child node types,
// some special handling is required in order for browsers to interpret the component node
// as SVG element and not plain XHTML element.
Px.Component.__svg_tags = {
  circle: true,
  clipPath: true,
  defs: true,
  ellipse: true,
  feBlend: true,
  feColorMatrix: true,
  feComponentTransfer: true,
  feComposite: true,
  feConvolveMatrix: true,
  feDiffuseLighting: true,
  feDisplacementMap: true,
  feDistantLight: true,
  feDropShadow: true,
  feFlood: true,
  feFuncA: true,
  feFuncB: true,
  feFuncG: true,
  feFuncR: true,
  feGaussianBlur: true,
  feImage: true,
  feMerge: true,
  feMergeNode: true,
  feMorphology: true,
  feOffset: true,
  fePointLight: true,
  feSpecularLighting: true,
  feSpotLight: true,
  feTile: true,
  feTurbulence: true,
  filter: true,
  g: true,
  image: true,
  line: true,
  linearGradient: true,
  mask: true,
  path: true,
  polygon: true,
  polyline: true,
  radialGradient: true,
  rect: true,
  text: true,
  textPath: true,
  tspan: true,
  use: true
};

Px.Component.InstanceRegistry = {};

/* Event Handling: Find a Home for me!*/

// Approach similar to React where you listen for all events on the document body.
// If an event is triggered, search up the DOM starting from the target element to find an event handler
// and the closest component.
// Events listener's are setup by assinging a data attribute like data-onclick.
// Components automatically store a reference to the associated component instance on the DOM node
// (node.__px_component) when rendering, and register themselves in the Px.Component.InstanceRegistry lookup hash.
(function() {
  function PxSetupEventHanding() {
    const eventTypes = {
      // Events that bubble.
      change: false,
      click: false,
      contextmenu: true,
      dblclick: false,
      dragend: false,
      dragenter: false,
      dragleave: false,
      dragover: false,
      dragstart: false,
      drop: false,
      focusin: false,
      focusout: false,
      input: false,
      keydown: false,
      keypress: false,
      keyup: false,
      mousedown: false,
      mouseup: false,
      touchcancel: false,
      touchend: false,
      touchstart: false,
      wheel: false,
      // Events that do not bubble. We bind them in capturing phase.
      cancel: true,
      mouseenter: true,
      mouseleave: true,
      scroll: true
    };

    const $body = $j('body');
    _.each(eventTypes, function(capturingPhase, eventType) {
      const dataAttr = `data-on${eventType}`;

      const processEvent = function(evt) {
        let currentElement = evt.target;
        let eventHandler = null;
        let component = null;

        while (currentElement && !(eventHandler && component)) {
          if (!eventHandler) {
            evt.currentTarget = currentElement;
            eventHandler = currentElement.getAttribute(dataAttr);
          }
          if (eventHandler) {
            component = currentElement.__px_component;
          }
          currentElement = currentElement.parentElement;
        }

        if (eventHandler && component) {
          if (component[eventHandler]) {
            try {
              if (typeof Sentry !== 'undefined') {
                Sentry.addBreadcrumb({
                  level: 'info',
                  category: 'px.ui.' + eventType,
                  message: component.component_type + ': ' + eventHandler
                });
              }
            } catch (e) {}
            component[eventHandler].call(component, evt);
          } else {
            const errormsg = `Event handler ${eventHandler} not found on ${component}.`;
            if (eventHandler.match(/^self\./)) {
              errormsg += "\nMaybe you should try removing the leading 'self.'?";
            }
            throw new Error(errormsg);
          }
        }
      };

      if (capturingPhase) {
        document.body.addEventListener(eventType, processEvent, true);
      } else {
        $body.on(eventType, processEvent);
      }

    });
  }
  jQuery(document).ready(PxSetupEventHanding);
})();
/* End of Event handling */

var Component = Px.Component;
export default Component;
