Px.Components.RadioButtons = class RadioButtons extends Px.Component {

  template() {
    return Px.template`
      <div class="px-radio-buttons">
        ${this.data.buttons.map(button => {
          return this.renderChild(Px.Components.ToggleButton, this.buttonId(button), this.buttonProps(button));
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      value: {required: false},
      // The buttons property should be an array of objects with 'id' and 'content' properties.
      // The 'id' should be unique among the set of buttons. The 'content' can be plain text
      // or html/svg content.
      buttons: {required: true},
      onNewValue: {std: function(value) {
        this.state.internal_value = value;
      }}
    };
  }

  static get properties() {
    return {
      internal_value: {type: 'str', std: null}
    };
  }

  static get computedProperties() {
    return {
      selectedValue: function() {
        if (this.state.internal_value !== null) {
          return this.state.internal_value;
        }
        return this.data.value;
      }
    };
  }

  buttonId(button) {
    return `button-${this._component_id}-${button.id}`;
  }

  buttonProps(button) {
    return {
      content: button.content,
      pressed: (button.id === this.selectedValue),
      onToggle: is_pressed => {
        if (is_pressed && button.id !== this.selectedValue) {
          this.data.onNewValue.call(this, button.id);
        }
      }
    };
  }
};
