Px.Components.Accordion = class Accordion extends Px.Component {

  template() {
    const r = this.renderChild;

    return Px.template`
      <section class="px-accordion">
        ${this.data.items.map((item, idx) => {
          return Px.template`
            <section class="px-accordion-item" data-expanded="${this.isExpanded(item)}">
              <h1 class="px-accordion-title" data-idx="${idx}" data-onclick="onClick">
                ${item.title}
              </h1>
              <div class="px-accordion-content-wrapper"
                  data-idx="${idx}"
                  style="max-height:${this.maxHeight(item)}">
                <div class="px-accordion-content">
                  ${r(item.component, `item-${item.id}`, item.data)}
                </div>
              </div>
            </section>
          `;
        })}
      </section>
    `;
  }

  get dataProperties() {
    return {
      // `items` should be a list of objects defining each accordion panel.
      // Each object should include: `id`, `title`, `component`, and `data` properties.
      items: {required: true},
      expanded_item_id: {std: null},
      onItemExpanded: {std: function(item_id) {
        this.state.internal_expanded_item_id = item_id;
      }}
    };
  }

  static get properties() {
    return {
      internal_expanded_item_id: {type: 'str', std: undefined}
    };
  }

  static get computedProperties() {
    return {
      expandedItemId: function() {
        if (this.state.internal_expanded_item_id !== undefined) {
          return this.state.internal_expanded_item_id;
        }
        return this.data.expanded_item_id;
      }
    };
  }

  isExpanded(item) {
    return item.id === this.expandedItemId;
  }

  maxHeight(item) {
    if (this.isExpanded(item)) {
      return '100vh';
    } else {
      return '0px';
    }
  }

  // --------------
  // Event handlers
  // --------------

  onClick(evt) {
    const idx = evt.currentTarget.getAttribute('data-idx');
    const item = this.data.items[idx];
    if (this.isExpanded(item)) {
      this.data.onItemExpanded.call(this, null);
    } else {
      this.data.onItemExpanded.call(this, item.id);
    }
  }
};
