Px.Editor.QrUploader = class QrUploader extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-qr-uploader">
        <header>
          <img class="px-logo" src="${this.logoImagePath}" alt="" />
        </header>
        <main>
          ${Px.if(this.data.upload_token, () => {
            return Px.template`
              <label class="px-file-upload-button px-large px-primary-color px-strong"
                    ${this.uploadButtonDisabled ? 'disabled' : ''}>
                ${Px.t('Upload images')}
                <input style="display:none"
                       type="file"
                       name="files[]"
                       accept="${Px.LocalFiles.Validation.VALID_FILE_TYPES.join(', ')}"
                       ${this.data.max_files === 1 ? '' : 'multiple'}
                       data-onchange="onFilesSelected"
                       ${this.uploadButtonDisabled ? 'disabled' : ''} />
              </label>
              <div class="px-upload-progress">
                ${Px.if(this.state.local_files.length, () => {
                  if (this.uploadInProgress) {
                    return Px.template`
                      <progress max="100" value="${this.uploadPercent}" />
                    `;
                  } else {
                    return Px.template`
                      <p class="px-notice">
                        ${Px.t('Upload complete.')}
                      </p>
                    `;
                  }
                })}
              </div>
              <div class="px-uploads">
                ${this.filesToList.map(file => {
                  return Px.template`
                    <div class="px-upload">
                      <div class="px-filename">${file.file.name}</div>
                      ${Px.if(file.upload_failure, () => {
                        return Px.template`
                          <div class="px-upload-error">
                            ${file.upload_error}
                          </div>
                        `;
                      }).else(() => {
                        return Px.template`
                        <div class="px-upload-progress-percent">
                          ${file.upload_progress_percent.toFixed(0)}%
                        </div>
                        `;
                      })}
                    </div>
                  `;
                })}
              </div>
            `;
          }).else(() => {
            return Px.template`
              <p class="px-token-error">
                ${Px.t('token error message', 'Upload token missing or invalid.')}
              </p>
            `;
          })}
        </main>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.registerReaction(() => this.state.local_files.length > 0 && !this.uploadInProgress, complete => {
      if (complete) {
        this.touchUploadToken(true);
      }
    }, {
      name: 'Px.Editor.QrUploader::uploadsCompleteReaction'
    });

    this._upload_touch_interval = setInterval(() => {
      this.touchUploadToken();
    }, 20000);
  }

  destroy() {
    clearInterval(this._upload_touch_interval);
    super.destroy();
  }

  get dataProperties() {
    return {
      gallery_id: {std: null},
      max_files: {std: 0},
      max_file_size: {std: 0},
      upload_token: {std: null}
    };
  }

  static get properties() {
    return {
      local_files: {std: mobx.observable.array()}
    };
  }

  static get computedProperties() {
    return {
      logoImagePath: function() {
        const default_logo_path = '/site_images/pixfizz-logo.svg';
        return Px.config.logo_image_path || default_logo_path;
      },
      uploadInProgress: function() {
        return this.state.local_files.some(file => file.upload_in_progress);
      },
      uploadButtonDisabled: function() {
        if (this.uploadInProgress) {
          return true;
        }
        if (this.data.max_files > 0) {
          const successful_uploads = this.state.local_files.filter(file => file.upload_success);
          return successful_uploads.length >= this.data.max_files;
        }
        return false;
      },
      uploadPercent: function() {
        let percent_sum = 0;
        this.state.local_files.forEach(file => {
          percent_sum += (file.upload_failure ? 100 : file.upload_progress_percent);
        });
        return (percent_sum / this.state.local_files.length);
      },
      filesToList: function() {
        return this.state.local_files.reverse().filter(file => !file.upload_queued);
      }
    };
  }

  touchUploadToken(markAllUploaded) {
    let url = `/upload/upload_status.json?upload_token=${this.data.upload_token}&touch=true`;
    if (markAllUploaded) {
      url += '&record_all_uploaded=true';
    }
    return fetch(url, {method: 'POST'});
  }

  // --------------
  // Event handlers
  // --------------

  onFilesSelected(evt) {
    const custom_validations = {};
    if (this.data.max_files) {
      custom_validations.max_files_to_import = this.data.max_files;
    }
    if (this.data.max_file_size) {
      custom_validations.max_file_size = this.data.max_file_size;
    }

    let files = Px.LocalFiles.Validation.filterAndValidateFiles(evt.target.files, custom_validations);

    if (files) {
      const upload_url = `/upload/image?gallery_id=${this.data.gallery_id}&upload_token=${this.data.upload_token}`;
      const uploader = Px.LocalFiles.Uploader.make(upload_url);
      files.forEach(file => {
        const local_file = Px.LocalFiles.LocalFileModel.make(file);
        this.state.local_files.push(local_file);
        uploader.uploadFile(local_file);
      });
    }
  }

};
