Px.Editor.CutPrintStore = class CutPrintStore extends Px.BaseStore {

  static get properties() {
    return {
      selected_sets: {std: mobx.observable.array()}
    };
  }

  get actions() {
    return {
      select: function(set) {
        if (!this.isSelected(set)) {
          this.selected_sets.push(set);
        }
      },
      deselect: function(set) {
        this.selected_sets.remove(set);
      },
      deselectAll: function() {
        this.selected_sets.clear();
      }
    };
  }

  isSelected(set) {
    for (const selected_set of this.selected_sets) {
      if (set === selected_set) {
        return true;
      }
    }
    return false;
  }

};
