Px.Editor.ImageImportPanel = class ImageImportPanel extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class= "px-image-import-panel px-tab-content-panel">
        <div class="px-gallery-tabs-wrapper" data-tab-count="${this.availableTabs.length}">
          <div class="px-gallery-tabs">
            ${this.availableTabs.map(tab => {
              return Px.template`
                <button data-tab-id="${tab.id}"
                        data-onclick="selectTab"
                        data-selected="${this.selectedTab && this.selectedTab.id === tab.id}">
                  ${tab.title}
                </button>
              `;
            })}
          </div>
        </div>
        <div class="px-gallery-content-panel">
          ${Px.if(this.selectedTab, () => {
            return this.renderChild(
              this.selectedTab.component,
              `tab-${this.selectedTab.id}`,
              this.selectedTab.data
            );
          })}
        </div>
      </div>
    `;
  }

  constructor(props) {
    super(props);

    this.registerReaction(() => this.selectedTab, tab => {
      if (tab && tab.init) {
        tab.init();
      }
    }, {
      name: 'Px.Editor.ImageImportPanel::initSelectedTab',
      fireImmediately: true
    });
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      availableTabs: function() {
        const store = this.data.store;
        const tab_definitions = {
          device: {
            id: 'device',
            title: Px.t('Upload'),
            component: Px.Editor.ProjectGalleryPanel,
            data: {store: store}
          },
          albums: {
            id: 'albums',
            title: Px.t('Galleries'),
            component: Px.Editor.AlbumGalleryPanel,
            data: {store: store},
            init: function() {
              if (store.image_sources.includes('galleries')) {
                store.galleries.user.init();
              }
            }
          }
        };
        if (Px.config.clipart_tab) {
          tab_definitions.clipart = {
            id: 'clipart',
            title: Px.t('Clipart'),
            component: Px.Editor.ClipartPanel,
            data: {store: store}
          };
        }
        if (store.resource_type !== 'book') {
          tab_definitions.theme_resources = {
            id: 'theme_resources',
            title: Px.t('Resources'),
            component: Px.Editor.ThemeResourceGalleryPanel,
            data: {store: store}
          }
        }
        const tab_ids = new Set();
        store.image_sources.forEach(id => {
          if (tab_definitions[id]) {
            tab_ids.add(id);
          } else if (['galleries', 'facebook', 'instagram', 'dropbox'].includes(id) ||
                     (id === 'groups' && Px.urlQuery().groups)) {
            tab_ids.add('albums');
          }
        });
        return Array.from(tab_ids).map(id => tab_definitions[id]);
      },
      selectedTab: function() {
        let tab_id = this.data.store.ui.active_image_source;
        if (['galleries', 'facebook', 'instagram', 'dropbox', 'groups'].includes(tab_id)) {
          tab_id = 'albums';
        }
        return this.getTabById(tab_id);
      }
    };
  }

  getTabById(tab_id) {
    let tab = null;
    if (tab_id) {
      tab = this.availableTabs.find(tab => {
        return tab.id === tab_id;
      });
    }
    return tab || null;
  }

  // --------------
  // Event handlers
  // --------------

  selectTab(evt) {
    const tab_id = evt.currentTarget.getAttribute('data-tab-id');
    this.data.store.ui.active_image_source = tab_id;
  }

};
