Px.Editor.DropboxGalleryStore = class DropboxGalleryStore extends Px.BaseStore {

  static get properties() {
    return {
      access_token: {std: null},
      _galleries: {std: mobx.observable.map()}
    };
  }

  get actions() {
    return {
      init: function() {
        if (!this.galleryForPath('')) {
          this.load();
        }
      },

      load: function(path) {
        path = path || '';
        var self = this;
        this._ensureAccessToken(function() {
          var data = {path: path};
          if (!self.galleryForPath(path)) {
            var gallery = self.createGalleryForPath(path);
            self.dropboxAPI('/files/list_folder', data, function(err, data) {
              mobx.runInAction(function() {
                gallery.loading = false;
                if (!err) {
                  _.each(data.entries, function(item) {
                    if (self.isFolder(item)) {
                      gallery.items.push(self.buildFolderItem(item));
                    } else if (self.isValidImage(item)) {
                      gallery.items.push(self.buildImageItem(item));
                    }
                  });
                } else {
                  alert("Failed fetching Dropbox images.");
                }
              });
            });
          }
        });
      },

      _ensureAccessToken: function(callback) {
        var self = this;
        if (this.access_token === null) {
          this.getAccessToken(function(token) {
            if (token) {
              self.access_token = token;
              callback();
            } else {
              alert("Failed to obtain Dropbox access token.");
            }
          });
        } else {
          callback();
        }
      }
    };
  }

  items(path) {
    path = path || '';
    var gallery = this.galleryForPath(path);
    if (gallery) {
      return _.filter(gallery.items, function(item) {
        return item.id;
      });
    } else {
      return [];
    }
  }

  isGalleryLoaded(path) {
    path = path || '';
    var gallery = this.galleryForPath(path);
    if (gallery) {
      return !gallery.loading && _.all(gallery.items, function(item) {
        return !item.loading;
      });
    } else {
      return false;
    }
  }

  // -------
  // Private
  // -------

  dropboxAPI(endpoint, data, callback) {
    var params = {authorization: 'Bearer ' + this.access_token};
    var url = 'https://api.dropbox.com/2' + endpoint + '?' + $j.param(params);
    var xhr = $j.ajax({
      method: 'POST',
      url: url,
      data: JSON.stringify(data),
      contentType: 'application/json; charset=utf-8',
      success: function(response) {
        callback(null, response);
      },
      error: function(xhr, text, err) {
        callback(err || text);
      }
    });
    return xhr;
  }

  isValidImage(item) {
    var is_image = item.name.match(/.*\.(png|jpg|jpeg)$/i);
    var file_size_limit = 20 * 1024 * 1024;  // 20 MB
    return is_image && item.size < file_size_limit;
  }

  isFolder(item) {
    return item['.tag'] === 'folder';
  }

  galleryForPath(path) {
    return this._galleries.get(path);
  }

  createGalleryForPath(path) {
    var gallery = mobx.observable({
      items: [],
      loading: true
    });
    this._galleries.set(path, gallery);
    return gallery;
  }

  requestSharedLink(item, callback) {
    var self = this;
    var data = {
      path: item.path_display,
      direct_only: true
    };
    this.dropboxAPI('/sharing/list_shared_links', data, function(err, data) {
      if (err) {
        callback(err);
      } else {
        var matching_link = _.find(data.links, function(link) {
          return link.link_permissions.requested_visibility['.tag'] === 'public';
        });
        if (matching_link) {
          callback(null, self.makeDownloadLink(matching_link.url));
        } else {
          self.createSharedLink(item, callback);
        }
      }
    });
  }

  createSharedLink(item, callback) {
    var self = this;
    var data = {
      path: item.path_display,
      settings: {
        requested_visibility: 'public'
      }
    };
    this.dropboxAPI('/sharing/create_shared_link_with_settings', data, function(err, data) {
      if (err) {
        callback(err);
      } else {
        callback(null, self.makeDownloadLink(data.url));
      }
    });
  }

  requestImageDimensions(item, callback) {
    var data = {
      path: item.path_display,
      include_media_info: true
    };
    this.dropboxAPI('/files/get_metadata', data, function(err, data) {
      if (err) {
        callback(err);
      } else {
        var dimensions = data.media_info && data.media_info.metadata && data.media_info.metadata.dimensions;
        if (dimensions && dimensions.width && dimensions.height) {
          callback(null, dimensions);
        } else {
          callback(null, null)
        }
      }
    });
  }

  // Bypass the dropbox preview page and force image to be displayed inline; see: https://www.dropbox.com/help/201/en
  makeDownloadLink(url) {
    // Remove legacy dl=0, if present.
    url = url.replace(/dl=0/, '');
    var separator = url.indexOf('?') > -1 ? '&' : '?';
    return url + separator + 'raw=1';
  }

  thumbnailURL(path) {
    const THUMB_SIZE = 'w640h480';
    var url =  'https://content.dropboxapi.com/2/files/get_thumbnail';
    var params = {
      authorization: 'Bearer ' + this.access_token,
      arg: JSON.stringify({path: path, size: THUMB_SIZE})
    };
    return url + '?' + $j.param(params);
  }

  buildFolderItem(item) {
    return {
      type: 'gallery',
      id: item.path_display,
      caption: item.name
    };
  }

  buildImageItem(item) {
    const thumb_url = this.thumbnailURL(item.path_display);
    item.pixfizz_thumb_url = thumb_url;
    var image = mobx.observable({
      type: 'image',
      id: null,
      loading: true,
      thumb_url: thumb_url,
      caption: item.name,
      data: item
    });
    this.buildImageId(item, function(err, image_id) {
      mobx.runInAction(function() {
        image.loading = false;
        if (err) {
          console.error('Failed fetching share url for dropbox image', err);
        } else {
          image.id = image_id;
        }
      });
    });
    return image;
  }

  buildImageId(item, callback) {
    var share_link_promise = new Promise((resolve, reject) => {
      this.requestSharedLink(item, function(err, share_link) {
        if (err) {
          reject(err);
        } else {
          resolve(share_link);
        }
      });
    });
    var metadata_promise = new Promise((resolve, reject) => {
      this.requestImageDimensions(item, function(err, dimensions) {
        if (err) {
          reject(err);
        } else {
          resolve(dimensions);
        }
      });
    });
    Promise.all([share_link_promise, metadata_promise]).then(values => {
      var share_url = values[0];
      var dimensions = values[1];
      var tags = '';
      if (dimensions) {
        tags = '#width=' + dimensions.width + '&height=' + dimensions.height;
      }
      callback(null, 'dropbox:' + share_url + tags)
    }).catch(err => callback(err));
  }

  getAccessToken(callback) {
    var client_id = Px.config.dropbox_app_key;
    var redirect_path = '/v1/social_app_tokens/dropbox';
    var scheme = window.location.protocol.replace(':', '');
    var host = window.location.host;
    var params = {
      redirect_uri: Px.config.dropbox_redirect_base_url + redirect_path,
      response_type: 'code',
      client_id: client_id,
      state: JSON.stringify({host: host, scheme: scheme})
    };
    var url = 'https://www.dropbox.com/oauth2/authorize?' + $j.param(params);
    var popup = Px.Util.openPopup(url, {width: 627, height: 450});
    var popup_interval = null;
    var stopPopupInterval = function() {
      clearInterval(popup_interval);
      popup_interval = null;
    };
    // The only reliable way to exchange message with popup on a different domain, see:
    // http://stackoverflow.com/questions/18625733
    popup_interval = setInterval(function() {
      if (popup_interval && (!popup || popup.closed)) {
        stopPopupInterval();
        callback(null);
      } else {
        try {
          if (popup.window.location.href.match('/social_app_tokens/dropbox_complete')) {  // will throw if cross-domain
            if (popup.document.readyState === 'complete') {
              stopPopupInterval();
              var token = popup.window.location.hash.substring(1);
              popup.close();
              callback(token);
            }
          }
        } catch (e) {
          // ignore
        }
      }
    }, 250);
  }

};
