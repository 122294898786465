Px.Components.BaseModal = class BaseModal extends Px.Component {

  static open(props) {
    const modal = this.make(props);
    modal.mount('body');
    return modal;
  }

  template() {
    return Px.template`
      <div class="px-modal ${this.css_class}"
           data-onclick="onBackgroundClick">
        <div class="px-modal-panel">
          <div class="px-modal-header">${this.header}</div>
          ${/* Avoiding whitespace on purpose to be able to use :empty CSS selector */ ''}
          <div class="px-modal-content">${this.content}</div>
          <div class="px-modal-footer">${this.footer}</div>
        </div>
      </div>
    `;
  }

  constructor(props) {
    props = props || {};
    super(props);

    this._content_for = {};

    ['title', 'content', 'footer', 'css_class'].forEach(part => {
      this._content_for[part] = props.hasOwnProperty(part) ? props[part] : '';
    });

    const defaults = {
      close_on_background_click: true,
      render_close_button: true
    };

    ['close_on_background_click', 'render_close_button'].forEach(prop => {
      this[prop] = props.hasOwnProperty(prop) ? props[prop] : defaults[prop];
    });

    if (props.event_handlers) {
      Object.keys(props.event_handlers).forEach(name => {
        this[name] = props.event_handlers[name];
      });
    }

    this.onKeyup = this.onKeyup.bind(this);
    $j(document).on('keyup', this.onKeyup);
  }

  destroy() {
    $j(document).off('keyup', this.onKeyup);
    super.destroy();
  }

  close() {
    this.destroy();
  }

  // Subclasses may define extra css classes for the top level node.
  get css_class() {
    return this.contentFor('css_class');
  }

  // Subclasses may define header, title, content, and footer templates.
  get header() {
    return Px.template`
      ${Px.if(this.render_close_button, () => {
        return Px.template`<span class="px-close-button" data-onclick="close">&#x2715;</span>`;
      })}
      ${Px.if(this.title, () => {
        return Px.template`<h1>${this.title}</h1>`;
      })}
    `;
  }

  get title() {
    return this.contentFor('title');
  }

  get content() {
    return this.contentFor('content');
  }

  get footer() {
    return this.contentFor('footer');
  }

  // -------
  // Private
  // -------

  contentFor(name) {
    const fragment = this._content_for[name];
    if (typeof fragment === 'function') {
      return fragment.call(this);
    } else {
      return fragment;
    }
  }

  // --------------
  // Event handlers
  // --------------

  onKeyup(evt) {
    if (this.close_on_background_click && evt.key === 'Escape') {
      this.close();
    }
  }

  onBackgroundClick(evt) {
    // Only close the modal if user clicked the background directly (ignore propagated events).
    if (this.close_on_background_click && evt.target === this.dom_node) {
      this.close();
    }
  }
};


var BaseModal = Px.Components.BaseModal;
export default BaseModal;
