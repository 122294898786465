Px.Editor.NotificationModel =  class extends Px.BaseModel {

  constructor(props) {
    super(props);
    this.id = Px.Util.guid();
  }

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null},
      message: {std: ''},
      is_disposing: {std: false},
      _notification_type: {std: 'info'}
    });
  }

  // ---------------
  // Getters/setters
  // ---------------

  get notification_type() {
    return this._notification_type;
  }

  set notification_type(type) {
    if (!_.include(this.constructor.VALID_TYPES, type)) {
      throw new Error(`Invalid notification type: ${type}`);
    }
    this._notification_type = type;
  }

};

Px.Editor.NotificationModel.VALID_TYPES = ['info', 'success', 'warning', 'error'];
