Px.Components.SliderWithNumericInput = class SliderWithNumericInput extends Px.Component {

  template() {
    return Px.template`
      <div class="px-slider-with-numeric-input">
        <input type="number"
               step="${this.data.step}"
               min="${this.data.min}"
               max="${this.data.max}"
               value="${this.selectedValue}"
               data-oninput="onNewInputValue" />
        ${this.renderChild(Px.Components.Slider, 'slider', this.sliderProps)}
      </div>
    `;
  }

  constructor(data) {
    super(data);
    this.onNewSliderValue = this.onNewSliderValue.bind(this);
  }

  get dataProperties() {
    return {
      value: {std: 0},
      min: {std: 0},
      max: {std: 100},
      step: {std: 1},
      onNewValue: {std: function(new_value) {
        this.state.internal_value = new_value;
      }},
      onBeforeDrag: {std: function() {}},
      onAfterDrag: {std: function() {}}
    };
  }

  static get properties() {
    return {
      internal_value: {type: 'float', std: null}
    };
  }

  static get computedProperties() {
    return {
      selectedValue: function() {
        if (this.state.internal_value !== null) {
          return this.state.internal_value;
        }
        return this.data.value;
      },
      sliderProps: function() {
        return {
          value: this.selectedValue,
          min: this.data.min,
          max: this.data.max,
          step: this.data.step,
          onNewValue: this.onNewSliderValue,
          onBeforeDrag: this.data.onBeforeDrag,
          onAfterDrag: this.data.onAfterDrag
        };
      }
    };
  }

  onNewSliderValue(val) {
    this.data.onNewValue.call(this, val);
  }

  onNewInputValue(evt) {
    const val = parseFloat(evt.currentTarget.value);
    if (typeof val === 'number' && isFinite(val)) {
      // This is a bit of a hack, but in order to avoid replicating the logic to
      // clip the value and make sure it's a valid step from Slider, we just delegate
      // to the Slider subcomponent.
      this.subcomponents.slider.setValue(val);
    }
  }
};
