Px.Editor.ThemeResourceGalleryPanel = class ThemeResourceGalleryPanel extends Px.Editor.BaseGalleryPanel {

  get css_class() {
    return 'px-theme-resource-gallery-panel';
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      galleryStore: function() {
        return this.data.store.galleries.theme_resources;
      },
      items: function() {
        return this.galleryStore.images;
      },
      isLoading: function() {
        return !this.galleryStore.loaded;
      },
      showAutofillButton: function() {
        return false;
      },
      showUploadButton: function() {
        return true;
      },
      uploadButtonLabel: function() {
        if (this.galleryStore.import_in_progress) {
          return Px.t('Importing {{count}} images...').replace('{{count}}', this.galleryStore.pending_import_count);
        } else {
          return Px.t('Upload images');
        }
      },
      uploadButtonDisabled: function() {
        return this.galleryStore.import_in_progress;
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  onFilesSelected(evt) {
    const files = Px.LocalFiles.Validation.filterAndValidateFiles(evt.target.files);
    if (files) {
      this.importAndSelectImages(files);
    }
  }

  // -------
  // Private
  // -------

  importAndSelectImages(files) {
    const image_store = this.data.store.images;
    let scrolled = false;
    this.galleryStore.importImages(files, image => {
      if (image) {
        // Register the image to immediately trigger the upload.
        if (!image_store.get(image.id)) {
          image_store.register(image.id, image.data);
        }
        if (!scrolled) {
          setTimeout(() => {
            const element = document.querySelector(`.px-image[data-image-id="${image.id}"]`);
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
          }, 100);
        }
        scrolled = true;
      }
    });
  }

};
