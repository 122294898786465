Px.Editor.PageSetModel = class PageSetModel extends Px.BaseModel {

  constructor(params) {
    const props = Object.assign({}, params);
    delete props.project_store;
    super(props);
    this.project_store = params.project_store;
    // Reaction which creates two page spread clone elements, if the page set contains a two page spread.
    // It doesn't do anything if clone elements already exists.
    this.registerReaction(() => {
      let elements = [];
      if (Px.config.two_page_spread && this.pages.length === 2) {
        this.pages.forEach(page => {
          const two_page_spread_enabled_elements = page.elements.filter(e => e.constructor.TWO_PAGE_SPREAD_ENABLED);
          elements = elements.concat(two_page_spread_enabled_elements);
        });
      }
      return elements;
    }, elements => {
      if (elements) {
        elements.forEach(function(element) {
          if (!element.two_page_spread_clone) {
            element.createTwoPageSpreadClone();
          }
        });
      }
    }, {
      fireImmediately: true,
      name: 'Px.Editor.PageSetModel::CreateTwoPageSpreadClonesReaction'
    });
  }

  static get properties() {
    return Object.assign(super.properties, {
      double_page: {std: false},
      grow: {std: false},
      count: {std: true},
      editor: {std: true},
      fulfillment: {std: true},
      _left_caption_template: {std: null},
      _center_caption_template: {std: null},
      _right_caption_template: {std: null},
      pages: {std: mobx.observable.array()}
    });
  }

  static get computedProperties() {
    return {
      id: function() {
        const page_ids = _.map(this.pages, function(page) {
          return page.id;
        });
        return page_ids.join('-');
      },
      position: function() {
        const page_sets = this.project_store.page_sets;
        for (let i = 0; i < page_sets.length; i++) {
          if (page_sets[i] === this) {
            return i;
          }
        }
        return -1;
      },
      editor_position: function() {
        const page_sets = this.project_store.editor_page_sets;
        for (let i = 0; i < page_sets.length; i++) {
          if (page_sets[i] === this) {
            return i;
          }
        }
        return -1;
      },
      image_elements: function() {
        return this.getElementsByType('image');
      },
      text_elements: function() {
        return this.getElementsByType('text');
      },
      score_elements: function() {
        return this.getElementsByType('score');
      },
      in_layout: function() {
        return this.position !== -1;
      },
      left_caption_template: function() {
        return this._left_caption_template || '';
      },
      center_caption_template: function() {
        return this._center_caption_template || '';
      },
      right_caption_template: function() {
        if (this._right_caption_template && (this.pages.length > 1 || this.double_page)) {
          return this._right_caption_template;
        } else {
          return '';
        }
      },
      left_caption: function() {
        var caption = this.left_caption_template;
        if (caption.match('{{n}}')) {
          caption = caption.replace('{{n}}', this.countPlaceholdersBefore('left') + 1);
        }
        return caption;
      },
      center_caption: function() {
        var caption = this.center_caption_template;
        var match = caption.match(/\{\{n\}\}/g);
        if (match) {
          var count = this.countPlaceholdersBefore('center');
          _.times(match.length, function(i) {
            caption = caption.replace('{{n}}', count + 1 + i);
          });
        }
        return caption;
      },
      right_caption: function() {
        var caption = this.right_caption_template;
        if (caption.match('{{n}}')) {
          caption = caption.replace('{{n}}', this.countPlaceholdersBefore('right') + 1);
        }
        return caption;
      },
      cut_print_image: function() {
        // We assume a cut print set only has a single page and that page contains a single editable image.
        return this.pages[0].cut_print_image;
      }
    };
  }

  get actions() {
    return Object.assign(super.actions, {
      addPage: function(page) {
        this.pages.push(page);
        page.set = this;
      }
    });
  }

  countPlaceholdersBefore(caption_position) {
    var count = 0;
    for (var i = 0; i < this.position; i++) {
      var set = this.project_store.page_sets[i];
      if (set.left_caption_template.match('{{n}}')) {
        count += 1;
      }
      // Center caption can contain more than one template.
      var center_match = set.center_caption_template.match(/\{\{n\}\}/g);
      if (center_match) {
        count += center_match.length;
      }
      if (set.right_caption_template.match('{{n}}')) {
        count += 1;
      }
    }
    // Add any placeholders positioned before this caption in *current* set.
    if (caption_position === 'left') {
      return count;
    }
    if (this.left_caption_template.match('{{n}}')) {
      count += 1;
    }
    if (caption_position === 'center') {
      return count;
    }
    var center_match_current = this.center_caption_template.match(/\{\{n\}\}/g);
    if (center_match_current) {
      count += center_match_current.length;
    }
    return count;
  }

  getElementsByType(type) {
    var matching_elements = [];
    this.pages.forEach(page => {
      matching_elements = matching_elements.concat(page.getElementsByType(type));
    });
    return matching_elements;
  }

  clone(opts) {
    var clone = Px.Editor.PageSetModel.make({
      double_page: this.double_page,
      grow: this.grow,
      count: this.count,
      editor: this.editor,
      fulfillment: this.fulfillment,
      _left_caption_template: this._left_caption_template,
      _center_caption_template: this._center_caption_template,
      _right_caption_template: this._right_caption_template,
      project_store: this.project_store
    });

    if (opts.pages) {
      opts.pages.forEach(page => {
        this.addPage(page);
      });
    } else {
      this.pages.forEach(page => {
        clone.addPage(page.clone(opts));
      });
    }

    return clone;
  }


};
