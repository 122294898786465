// Derived from MicroEvent.js: https://raw.github.com/jeromeetienne/microevent.js/master/microevent.js
var EventEmitterMixin = Base => class extends Base {

  constructor() {
    super(...arguments);
    this._events = {};
  }

  on(event, fct) {
    if (!this._events[event]) {
      this._events[event] = [];
    }
    this._events[event].push(fct);
  }

  once(event, fct) {
    fct.once = true;
    if (!this._events[event]) {
      this._events[event] = [];
    }
    this._events[event].push(fct);
  }

  removeListener(event, fct) {
    const events = this._events[event];
    if (!events) {
      return;
    }
    events.splice(events.indexOf(fct), 1);
  }

  emit(event /* , args... */) {
    const events = this._events[event];
    if (!events) {
      return;
    }
    for (let i = 0; i < events.length; i++) {
      const fn = events[i];
      fn.apply(this, Array.prototype.slice.call(arguments, 1));
      // Remove function if applied with .once
      if (fn.once) {
        this.removeListener(event, fn);
        i--;
      }
    }
  }

};

Px.EventEmitterMixin = EventEmitterMixin;
export default EventEmitterMixin;