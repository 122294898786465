Px.Editor.PageDisplay = class PageDisplay extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const store = this.data.store;
    return Px.template`
      <div class="px-page-display">
        ${r(Px.Components.ResizeDetector, 'resize-detector', {onResize: this.resize})}
        <div class="px-page-prev" data-enabled="${this.canGoToPreviousSet}">
          <button data-px-tooltip="${Px.t('Previous')}" data-onclick="goToPreviousSet">
            ${Px.raw(PageDisplay.icons.navigation_arrow)}
          </button>
        </div>
        <div class="px-page-toolbar">
          ${Px.if(!store.ui.preview_mode, () => {
            return Px.template`
              <div class="px-left">
                <button data-px-tooltip="${Px.t('Undo')}"
                        data-px-tooltip-offset="0"
                        data-tool-id="undo"
                        data-onclick="performUndo"
                        ${store.undo_redo.can_undo ? '' : 'disabled' }>
                  ${Px.raw(PageDisplay.icons.undo)}
                </button>
                <button data-px-tooltip="${Px.t('Redo')}"
                        data-px-tooltip-offset="0"
                        data-tool-id="redo"
                        data-onclick="performRedo"
                        ${store.undo_redo.can_redo ? '' : 'disabled' }>
                  ${Px.raw(PageDisplay.icons.redo)}
                </button>
                <div class="px-spacer"></div>
                ${Px.if(!this.hideAddTextBoxButton, () => {
                  return Px.template`
                    <button data-px-tooltip="${Px.t('Add text')}"
                            data-px-tooltip-offset="0"
                            data-tool-id="add-text"
                            data-onclick="addTextBox"
                            ${this.canAddTextBox ? '' : 'disabled'}>
                      ${Px.raw(PageDisplay.icons.add_text)}
                    </button>
                  `;
                })}
                ${Px.if(!this.hideAddImageBoxButton, () => {
                  return Px.template`
                    <button data-px-tooltip="${this.addImageBoxTooltip}"
                            data-px-tooltip-offset="0"
                            data-tool-id="add-image"
                            data-onclick="addImageBox"
                            ${this.canAddImageBox ? '' : 'disabled'}>
                      ${Px.raw(PageDisplay.icons.add_image)}
                    </button>
                  `;
                })}
              </div>
              <div class="px-right">
                <div class="px-zoom-slider">
                  ${Px.raw(PageDisplay.icons.zoom_out)}
                  ${r(Px.Components.Slider, 'zoom-slider', this.zoomSliderProps)}
                  ${Px.raw(PageDisplay.icons.zoom_in)}
                </div>
              </div>
            `;
          })}
        </div>
        <div class="px-current-set">
          <div class="px-pages" data-highlight-selected-page="${this.highlightSelectedPage}">
            ${Px.if(store.selected_set, () => {
              return Px.template`
                <div class="px-pages-scrollable-area">
                  ${store.selected_set.pages.map(page => {
                    return Px.template`
                      <div class="px-page-display-page"
                           data-selected="${store.selected_page === page ? 'true' : 'false'}"
                           style="${this.pageWrapperStyle(page, false)}">
                        ${r(Px.Editor.Page, `page-${page.id}`, {
                              page: page,
                              available_width: this.availablePageWidth * this.zoom,
                              available_height: this.availablePageHeight * this.zoom,
                              preview_mode: store.ui.preview_mode,
                              hide_placeholders: store.ui.preview_mode,
                              render_content: true,
                              render_controls: !(this.renderControlsInOverlay || store.ui.preview_mode)
                        })}
                      </div>
                    `;
                  })}
                </div>
              `;
            })}
          </div>
          <div class="px-page-titles">
            ${Px.if(this.leftCaption, () => {
              return Px.template`
                <div class="px-caption px-left-caption">${this.leftCaption}</div>
              `;
            })}
            ${Px.if(this.centerCaption, () => {
              return Px.template`
                <div class="px-caption px-center-caption">${this.centerCaption}</div>
              `;
            })}
            ${Px.if(this.rightCaption, () => {
              return Px.template`
                <div class="px-caption px-right-caption">${this.rightCaption}</div>
              `;
            })}
          </div>
        </div>
        ${Px.if(this.renderControlsInOverlay, () => {
          return Px.template`
            <div class="px-current-set px-current-set-controls">
              <div class="px-pages">
                ${Px.if(store.selected_set && !store.ui.preview_mode && this.zoom === 1, () => {
                  return Px.template`
                    <div class="px-pages-scrollable-area">
                      ${store.selected_set.pages.map(page => {
                        return Px.template`
                          <div class="px-page-display-page"
                               data-selected="${store.selected_page === page ? 'true' : 'false'}"
                               style="${this.pageWrapperStyle(page, true)}">
                            ${r(Px.Editor.Page, `page-controls-${page.id}`, {
                                  page: page,
                                  available_width: this.availablePageWidth * this.zoom,
                                  available_height: this.availablePageHeight * this.zoom,
                                  render_content: false,
                                  render_controls: true
                            })}
                          </div>
                        `;
                      })}
                    </div>
                  `;
                })}
              </div>
            </div>
          `;
        })}
        <div class="px-page-next" data-enabled="${this.canGoToNextSet}">
          <button data-px-tooltip="${Px.t('Next')}" data-onclick="goToNextSet">
            ${Px.raw(PageDisplay.icons.navigation_arrow)}
          </button>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this._resize_scheduled = false;
    this._zoom_timeout = null;
    this._zoom_origin = null;

    // Gap/margin between pages in pixels.
    // Note that this is only used to account for the gap in width calculations,
    // but you still need to add appropriate CSS styles to the page element in order
    // for there to actually be a gap.
    this.gap_between_pages = 4;
    // Margin between the edges of the display. This margin is only required so that we don't chop off
    // the drop shadow filter on the pages.
    // This one doesn't actually have to be set in CSS because pages are always centered.
    this.page_margin = 6;

    this.showMoveMessageDebounced = null;
    this.showRotateMessageDebounced = null;

    this.resize = this.resize.bind(this);
    this.startZooming = this.startZooming.bind(this);
    this.stopZooming = this.stopZooming.bind(this);
    this.updateScroll = this.updateScroll.bind(this);
    this.onBeforeZoomSliderDrag = this.onBeforeZoomSliderDrag.bind(this);
    this.onAfterZoomSliderDrag = this.onAfterZoomSliderDrag.bind(this);
    this.keyboardShortcutHandler = this.keyboardShortcutHandler.bind(this);
    this.keyReleaseHandler = this.keyReleaseHandler.bind(this);
    this.wheelHandler = this.wheelHandler.bind(this);

    $j(document).on('keydown', this.keyboardShortcutHandler);
    $j(document).on('keyup', this.keyReleaseHandler);
    document.addEventListener('wheel', this.wheelHandler, {passive: false});

    const debounce_interval = 5000;
    this.showMoveMessageDebounced = _.debounce(this.showMoveMessage.bind(this), debounce_interval, true);
    this.showRotateMessageDebounced = _.debounce(this.showRotateMessage.bind(this), debounce_interval, true);

    this.registerReaction(() => this.data.store.selected_set, this.resetZoom.bind(this), {
      name: 'Px.Editor.PageDisplay::ResetZoomReaction'
    });

    this.registerReaction(() => this.data.store.selected_set, this.selectCutPrintImage.bind(this), {
      name: 'Px.Editor.PageDisplay::SelectCutPrintImage'
    });

    this.on('update', this.updateScroll);
  }

  destroy() {
    $j(document).off('keydown', this.keyboardShortcutHandler);
    $j(document).off('keyup', this.keyReleaseHandler);
    document.removeEventListener('wheel', this.wheelHandler);
    super.destroy();
  }

  get dataProperties() {
    return {
      store: {required: true},
    }
  }

  static get properties() {
    return {
      width: {type: 'float', std: 0},
      height: {type: 'float', std: 0}
    };
  }

  static get computedProperties() {
    return {
      zoom: function() {
        return this.data.store.ui.page_display_zoom;
      },
      zoomSliderProps: function() {
        const ui_store = this.data.store.ui;
        return {
          min: Px.Editor.UIStore.ZOOM.MIN,
          max: Px.Editor.UIStore.ZOOM.MAX,
          step: 0.01,
          handle_width: 4,
          value: ui_store.page_display_zoom,
          onNewValue: ui_store.setZoom,
          onBeforeDrag: this.onBeforeZoomSliderDrag,
          onAfterDrag: this.onAfterZoomSliderDrag
        };
      },
      pageCount: function() {
        const selected_set = this.data.store.selected_set;
        return selected_set ? selected_set.pages.length : 1;
      },
      availablePageWidth: function() {
        const margins_and_gap = (2 * this.page_margin) + (this.gap_between_pages * (this.pageCount - 1));
        const available_width = (this.state.width - margins_and_gap) / this.pageCount;
        return Math.max(0, available_width);
      },
      availablePageHeight: function() {
        const available_height = this.state.height - (2 * this.page_margin);
        return Math.max(0, available_height);
      },
      pageScales: function() {
        const page_scales = [];
        const set = this.data.store.selected_set;
        if (set) {
          const width = this.availablePageWidth * this.zoom;
          const height = this.availablePageHeight * this.zoom;
          const crop_bleed = this.data.store.crop_bleed;
          set.pages.forEach(page => {
            page_scales.push(page.optimalScale(width, height, crop_bleed));
          });
        }
        return page_scales;
      },
      canGoToPreviousSet: function() {
        const store = this.data.store;
        return store.selected_set && store.selected_set.editor_position > 0;
      },
      canGoToNextSet: function() {
        const store = this.data.store;
        if (store.selected_set) {
          return store.selected_set.editor_position < store.project.editor_page_sets.length - 1;
        } else {
          return store.project.editor_page_sets.length > 0;
        }
      },
      leftCaption: function() {
        const selected_set = this.data.store.selected_set;
        return selected_set && selected_set.left_caption;
      },
      centerCaption: function() {
        const store = this.data.store;
        const selected_set = store.selected_set;
        if (selected_set && store.cut_print_mode &&
            !selected_set.left_caption & !selected_set.center_caption && !selected_set.right_caption) {
          const image_element = selected_set.pages[0].image_elements.find(e => e.is_editable_master_element && e.id);
          if (image_element) {
            const image = store.images.get(image_element.id);
            if (image && image.filename) {
              return image.filename;
            }
          }
        }
        return selected_set && selected_set.center_caption;
      },
      rightCaption: function() {
        const selected_set = this.data.store.selected_set;
        return selected_set && selected_set.right_caption;
      },
      renderControlsInOverlay: function() {
        // When zoomed in, we fall back to rendering controls inline in the main page,
        // otherwise getting scrolling to work would be a huge pain.
        return !this.data.store.ui.preview_mode && this.zoom === 1;
      },
      canAddImageBox: function() {
        const store = this.data.store;
        return store.selected_page && store.selected_page.edit;
      },
      hideAddImageBoxButton: function() {
        return (this.data.store.theme.templates.every(template => template.edit === false) ||
                (this.data.store.cut_print_mode && !Px.config.advanced_edit_mode));
      },
      addImageBoxTooltip: function() {
        return this.canAddImageBox ? Px.t('Add image box') : Px.t('Adding images is not allowed');
      },
      canAddTextBox: function() {
        const store = this.data.store;
        return store.selected_page && store.selected_page.edit;
      },
      hideAddTextBoxButton: function() {
        return (this.data.store.theme.templates.every(template => template.edit === false) ||
                (this.data.store.cut_print_mode && !Px.config.advanced_edit_mode));
      },
      addTextBoxTooltip: function() {
        return this.canAddTextBox ? '' : Px.t('Cannot add text boxes to this page');
      },
      highlightSelectedPage: function() {
        const store = this.data.store;
        const has_multipage_sets = store.project.editor_page_sets.some(set => set.pages.length > 1);
        return has_multipage_sets && ['background', 'layouts'].includes(store.ui.expanded_tab_id);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  resize() {
    if (this._resize_scheduled) {
      // Resize handler already scheduled, abort.
      return;
    } else {
      this._resize_scheduled = true;
    }
    requestAnimationFrame(() => {
      const node = this.dom_node.querySelector('.px-pages');
      const width = node.offsetWidth;
      const height = node.offsetHeight;
      mobx.transaction(() => {
        this.state.width = width;
        this.state.height = height;
      });
      this._resize_scheduled = false;
    });
  }

  performUndo(evt) {
    this.data.store.undo_redo.undo();
  }

  performRedo(evt) {
    this.data.store.undo_redo.redo();
  }

  addTextBox(evt) {
    const store = this.data.store;
    if (this.canAddTextBox) {
      mobx.runInAction(() => {
        const text = store.addText();
        store.selectElement(text);
      });
    }
  }

  addImageBox(evt) {
    const store = this.data.store;
    if (this.canAddImageBox) {
      mobx.runInAction(() => {
        const image = store.addImage();
        store.selectElement(image);
      });
    }
  }

  goToPreviousSet(evt) {
    this.data.store.selectPreviousSet();
  }

  goToNextSet(evt) {
    this.data.store.selectNextSet();
  }

  keyboardShortcutHandler(evt) {
    if (!(document.activeElement === null || document.activeElement === document.body)) {
      // Keyboard shortcuts should be ignored if an element other than the body is currently focused,
      // so that for example we don't delete the text element when pressing backspace in the text edit dialog.
      return;
    }

    const store = this.data.store;
    const page = store.selected_page;
    const element = store.selected_element;
    const ctrlKey = evt.ctrlKey || (navigator.platform.match(/Mac/) && evt.metaKey);

    if (element && (element.edit || Px.config.advanced_edit_mode)) {
      if (element['delete'] && !store.cut_print_mode &&
                                ((ctrlKey && evt.which === Px.Util.keyCodeFromChar('d')) ||
                                evt.which === Px.Util.keyCodes['delete'] ||
                                evt.which === Px.Util.keyCodes.backspace)) {
        evt.preventDefault();
        this.deleteSelectedElement();
        return;
      }
      if (ctrlKey && evt.which === Px.Util.keyCodeFromChar('c')) {
        evt.preventDefault();
        this.copySelectedElement();
        return;
      }
      const arrowKeys = {};
      arrowKeys[Px.Util.keyCodes.arrow_left] = 'left';
      arrowKeys[Px.Util.keyCodes.arrow_up] = 'up';
      arrowKeys[Px.Util.keyCodes.arrow_right] = 'right';
      arrowKeys[Px.Util.keyCodes.arrow_down] = 'down';

      if (element.move && !evt.altKey && arrowKeys[evt.which]) {
        this.nudgeSelectedElement(arrowKeys[evt.which], evt.shiftKey);
        return;
      }

      if (element.erotation && evt.altKey && (evt.which === Px.Util.keyCodes.arrow_left ||
                                              evt.which === Px.Util.keyCodes.arrow_right)) {
        this.rotateSelectedElement(arrowKeys[evt.which], evt.shiftKey);
        return;
      }
    }

    if (page && page.edit) {
      if (ctrlKey && evt.which === Px.Util.keyCodeFromChar('v') && !store.clipboard.empty) {
        evt.preventDefault();
        this.pasteCopiedElement();
        return;
      }
    }

    if (evt.which === Px.Util.keyCodes.escape) {
      mobx.runInAction(() => {
        store.selectElement(null);
        store.ui.image_swap_source = null;
      });
      return;
    }

    if (ctrlKey && evt.which === Px.Util.keyCodeFromChar('a')) {
      this.selectAllElementsOnSelectedPage();
      return;
    }

    if (ctrlKey && evt.which === Px.Util.keyCodeFromChar('z')) {
      store.undo_redo.undo();
      return;
    }
    if (ctrlKey && evt.which === Px.Util.keyCodeFromChar('y')) {
      store.undo_redo.redo();
      return;
    }

    if ((evt.which === Px.Util.keyCodes.arrow_right && !Px.config.rtl) ||
        (evt.which === Px.Util.keyCodes.arrow_left && Px.config.rtl)) {
      store.selectNextSet();
      return;
    }
    if ((evt.which === Px.Util.keyCodes.arrow_left && !Px.config.rtl) ||
        (evt.which === Px.Util.keyCodes.arrow_right && Px.config.rtl)) {
      store.selectPreviousSet();
      return;
    }
  }

  keyReleaseHandler(evt) {
    const is_ctrl = evt.key === 'Control' || (navigator.platform.match(/Mac/) && evt.key === 'Meta');
    if (is_ctrl) {
      // Ctrl key was just released, so release the zoom origin.
      this.stopZooming();
    }
  }

  wheelHandler(evt) {
    if (!this.dom_node) {
      return;
    }

    const ctrl_key = evt.ctrlKey || (navigator.platform.match(/Mac/) && evt.metaKey);
    if (ctrl_key) {
      evt.preventDefault();

      this.startZooming(evt.clientX, evt.clientY);

      clearTimeout(this._zoom_timeout);
      this._zoom_timeout = setTimeout(this.stopZooming, 100);

      this.data.store.ui.zoomIn(-0.01 * evt.deltaY);
    }
  }

  onBeforeZoomSliderDrag() {
    if (!this.dom_node) {
      return;
    }
    // Zoom into the central part of page display.
    const node = this.dom_node.querySelector('.px-pages');
    const rect = node.getBoundingClientRect();
    const x = rect.left + (rect.width / 2);
    const y = rect.top + (rect.height / 2);
    this.startZooming(x, y);
  }

  onAfterZoomSliderDrag() {
    this.stopZooming();
  }

  // -------
  // Private
  // -------

  startZooming(clientX, clientY) {
    if (!this._zoom_origin) {
      const node = this.dom_node.querySelector('.px-pages');
      const scroll_node = node.firstElementChild;
      const rect = node.getBoundingClientRect();
      const scroll_rect = scroll_node.getBoundingClientRect();
      const offset_left = Math.max(rect.left, scroll_rect.left);
      const offset_top = Math.max(rect.top, scroll_rect.top);

      this._zoom_origin = {
        x: clientX,
        y: clientY,
        offset_left: offset_left,
        offset_top: offset_top,
        scroll_left: node.scrollLeft,
        scroll_top: node.scrollTop,
        scroll_width: scroll_node.scrollWidth,
        scroll_height: scroll_node.scrollHeight
      };
    }
  }

  stopZooming() {
    clearTimeout(this._zoom_timeout);
    this._zoom_origin = null;
  }

  // This is really only required for autorotated cut prints, but it shouldn't make any difference
  // for other types of pages, so we're using it everywhere.
  pageWrapperStyle(page, is_control_layer) {
    const selected_element = this.data.store.selected_element;
    const size_calculator = this.sizeCalculator(page);
    let width = size_calculator.width();
    let height = size_calculator.height();
    if (size_calculator.isAutorotatedCutPrint()) {
      [height, width] = [width, height];
    }

    let style = `width:${width}px; height:${height}px;`;

    // When no element is selected, clip the control layer to not overflow the page are,
    // so that when clicking outside the page, the user cannot accidentally select any elements
    // which partially lie outside of the page area.
    // But once an element is selected, we do want the control layer to overflow, since that's basically
    // the main purpose of the separate control layer -- ability to interact with selected element's controls
    // even if they stick outside of the page.
    if (is_control_layer && !selected_element) {
      style += 'overflow:hidden;';
    }

    // If we are on a two page spread, we want to prevent controls from one page to be visible over the other page.
    // The reason for that is that we have a few pixels of gap between the pages, which means that if controls from
    // both pages are drawn, and are allowed to bleed over to the other page, there's a few pixel mismatch between
    // their position which makes it look broken.
    if (is_control_layer && Px.config.two_page_spread && page.set.pages.length === 2 && selected_element &&
        selected_element.two_page_spread_clone && selected_element.two_page_spread_clone.is_in_viewport) {
      const half_gap = this.gap_between_pages / 2;
      let points;
      if (page.in_leftmost_position) {
        points = [
          ['-100%', '-100%'],
          [`calc(100% + ${half_gap}px)`, '-100%'],
          [`calc(100% + ${half_gap}px)`, '200%'],
          ['-100%', '200%']
        ];
      } else {
        points = [
          [`-${half_gap}px`, '-100%'],
          ['200%', '-100%'],
          ['200%', '200%'],
          [`-${half_gap}px`, '200%']
        ];
      }
      style += `clip-path:polygon(${points.map(ps => ps.join(' ')).join(', ')});`;
    }

    return style;
  }

  sizeCalculator(page) {
    return Px.Editor.Page.SizeCalculator.make(this.data.store, page, {
      available_width: this.availablePageWidth * this.zoom,
      available_height: this.availablePageHeight * this.zoom
    });
  }

  resetZoom() {
    this.data.store.ui.resetZoom();
  }

  // Keep the point under the mouse pointer at the same position after zooming.
  updateScroll() {
    if (!this.dom_node) {
      return;
    }

    const origin = this._zoom_origin;
    if (!origin) {
      return
    }

    const node = this.dom_node.querySelector('.px-pages');
    const scroll_node = node.firstElementChild;

    const rect = node.getBoundingClientRect();
    const scroll_rect = scroll_node.getBoundingClientRect();

    const offset_left = Math.max(rect.left, scroll_rect.left);
    const offset_top = Math.max(rect.top, scroll_rect.top);
    const scroll_width = scroll_node.scrollWidth;
    const scroll_height = scroll_node.scrollHeight;

    const x1 = origin.x - origin.offset_left;
    const y1 = origin.y - origin.offset_top;
    const x2 = origin.x - offset_left;
    const y2 = origin.y - offset_top;

    node.scrollLeft =  ((scroll_width / origin.scroll_width) * (x1 + origin.scroll_left)) - x2;
    node.scrollTop = ((scroll_height / origin.scroll_height) * (y1 + origin.scroll_top)) - y2;
  }

  selectCutPrintImage() {
    const store = this.data.store;
    if (store.cut_print_mode && store.selected_page && store.ui.editor_mode !== 'mobile') {
      const image = store.selected_page.image_elements.find(image => image.edit);
      if (image) {
        store.selectElement(image);
      }
    }
  }

  deleteSelectedElement() {
    this.withUndo('delete element', function() {
      this.data.store.selected_element.destroy();
    });
  }

  copySelectedElement() {
    const store = this.data.store;
    store.clipboard.copy(store.selected_element);
    store.showNotification(Px.t('Saved to clipboard.'), 'success');
  }

  pasteCopiedElement() {
    const store = this.data.store;
    mobx.runInAction(() => {
      let element;
      this.withUndo('paste element', function() {
        element = store.clipboard.paste(store.selected_page);
      });
      if (element) {
        store.selectElement(element);
        store.showNotification(Px.t('Pasted from clipboard.'), 'success');
      }
    });
  }

  showMoveMessage() {
    const message = Px.t('Press "Shift" while using arrows to move element in smaller increments.');
    this.data.store.showNotification(message, 'info');
  }

  showRotateMessage() {
    const message = Px.t('Press "Shift" while using arrows to rotate element in smaller increments.');
    this.data.store.showNotification(message, 'info');
  }

  selectAllElementsOnSelectedPage() {
    const store = this.data.store;
    if (!(store.selected_page)) {
      return;
    }
    mobx.runInAction(() => {
      store.selectElement(null);
      store.selected_page.elements.forEach(element => {
        if (element.edit || Px.config.advanced_edit_mode) {
          if (element.is_in_viewport) {
            store.addOrRemoveFromElementSelection(element);
          }
        }
      });
    });
  }

  nudgeSelectedElement(direction, finetuning) {
    if (!finetuning) {
      this.showMoveMessageDebounced();
    }
    const element = this.data.store.selected_element;
    const page = element.page;
    const scale = this.pageScales[page.position];
    const pixels = finetuning ? 1 : 25;
    const increment = scale > 0 ? pixels/scale : 0;
    this.withUndo('nudge element', () => {
      switch (direction) {
      case 'left':
        element.x -= increment;
        break;
      case 'up':
        element.y -= increment;
        break;
      case 'right':
        element.x += increment;
        break;
      case 'down':
        element.y += increment;
        break;
      }
    });
  }

  rotateSelectedElement(direction, finetuning) {
    if (!finetuning) {
      this.showRotateMessageDebounced();
    }
    const element = this.data.store.selected_element;
    const increment = finetuning ? 0.25 : 15;
    this.withUndo('rotate element', () => {
      switch (direction) {
      case 'left':
        element.rotation -= increment;
        break;
      case 'right':
        element.rotation += increment;
        break;
      }
    });
  }

};

Px.Editor.PageDisplay.icons = {
  undo: '<svg width="31" height="31" viewBox="0 0 31 31"><path d="M9.68744 21.7968C9.68744 22.5989 10.3384 23.2499 11.1406 23.2499H16.9531C19.2432 23.2499 21.0858 22.4516 22.3548 21.1438C23.5597 19.8867 24.2282 18.21 24.2187 16.4686C24.2282 14.7273 23.5597 13.0506 22.3548 11.7934C21.0858 10.4856 19.2432 9.68739 16.9531 9.68739H10.7724L12.1674 8.29239C12.4241 8.01692 12.5639 7.65258 12.5572 7.27612C12.5506 6.89966 12.3981 6.54048 12.1318 6.27424C11.8656 6.00801 11.5064 5.8555 11.13 5.84886C10.7535 5.84222 10.3892 5.98196 10.1137 6.23864L6.23869 10.1136C5.96657 10.3861 5.81372 10.7554 5.81372 11.1405C5.81372 11.5256 5.96657 11.8949 6.23869 12.1674L10.1137 16.0424C10.3892 16.2991 10.7535 16.4388 11.13 16.4322C11.5064 16.4255 11.8656 16.273 12.1318 16.0068C12.3981 15.7405 12.5506 15.3814 12.5572 15.0049C12.5639 14.6284 12.4241 14.2641 12.1674 13.9886L10.7724 12.5936H16.9531C18.5379 12.5936 19.6016 13.1284 20.2701 13.8162C20.954 14.5234 21.3124 15.4824 21.3124 16.4686C21.3225 17.4546 20.9487 18.4058 20.2701 19.1211C19.6016 19.8089 18.5379 20.3436 16.9531 20.3436H11.1406C10.7552 20.3436 10.3856 20.4967 10.1131 20.7692C9.84054 21.0418 9.68744 21.4114 9.68744 21.7968Z" fill="currentColor"/></svg>',
  redo: '<svg width="32" height="31" viewBox="0 0 32 31"><path d="M9.22091 21.1441C10.49 22.4519 12.3325 23.2501 14.6227 23.2501H20.4352C20.8206 23.2501 21.1902 23.097 21.4627 22.8245C21.7352 22.552 21.8883 22.1824 21.8883 21.797C21.8883 21.4116 21.7352 21.042 21.4627 20.7695C21.1902 20.497 20.8206 20.3439 20.4352 20.3439H14.6227C13.0378 20.3439 11.9741 19.8091 11.3057 19.1213C10.627 18.406 10.2532 17.4548 10.2633 16.4689C10.2633 15.4807 10.6217 14.5236 11.3057 13.8164C11.9741 13.1286 13.0378 12.5939 14.6227 12.5939H20.8033L19.4083 13.9889C19.1516 14.2643 19.0119 14.6287 19.0185 15.0051C19.0251 15.3816 19.1777 15.7408 19.4439 16.007C19.7101 16.2732 20.0693 16.4257 20.4458 16.4324C20.8222 16.439 21.1866 16.2993 21.462 16.0426L25.337 12.1676C25.6092 11.8952 25.762 11.5258 25.762 11.1407C25.762 10.7557 25.6092 10.3863 25.337 10.1139L21.462 6.23886C21.329 6.0961 21.1686 5.98159 20.9903 5.90216C20.8121 5.82274 20.6197 5.78004 20.4245 5.77659C20.2294 5.77315 20.0356 5.80904 19.8547 5.88213C19.6737 5.95521 19.5094 6.06399 19.3714 6.20198C19.2334 6.33996 19.1246 6.50433 19.0515 6.68527C18.9785 6.86621 18.9426 7.06002 18.946 7.25513C18.9495 7.45024 18.9922 7.64266 19.0716 7.82091C19.151 7.99916 19.2655 8.15958 19.4083 8.29261L20.8033 9.68761H14.6227C12.3325 9.68761 10.49 10.4859 9.22091 11.7937C8.01599 13.0508 7.34754 14.7275 7.35703 16.4689C7.35703 18.1467 7.96735 19.8517 9.22091 21.1441Z" fill="currentColor"/></svg>',
  add_image: '<svg width="27" height="27" viewBox="0 0 27 27"><path d="M20.3636 7.875V11.2387C20.3636 11.2387 18.1249 11.25 18.1136 11.2387V7.875H14.7386C14.7386 7.875 14.7499 5.63625 14.7386 5.625H18.1136V2.25H20.3636V5.625H23.7386V7.875H20.3636ZM16.9886 12.375V9H13.6136V5.625H4.61365C3.37615 5.625 2.36365 6.6375 2.36365 7.875V21.375C2.36365 22.6125 3.37615 23.625 4.61365 23.625H18.1136C19.3511 23.625 20.3636 22.6125 20.3636 21.375V12.375H16.9886ZM4.61365 21.375L7.98865 16.875L10.2386 20.25L13.6136 15.75L18.1136 21.375H4.61365Z" fill="currentColor"/></svg>',
  add_text: '<svg width="27" height="27" viewBox="0 0 27 27"><path d="M20.3636 7.875V11.2387C20.3636 11.2387 18.1249 11.25 18.1136 11.2387V7.875H14.7386C14.7386 7.875 14.7499 5.63625 14.7386 5.625H18.1136V2.25H20.3636V5.625H23.7386V7.875H20.3636ZM16.9886 12.375V9H13.6136V5.625H4.61365C3.37615 5.625 2.36365 6.6375 2.36365 7.875V21.375C2.36365 22.6125 3.37615 23.625 4.61365 23.625H18.1136C19.3511 23.625 20.3636 22.6125 20.3636 21.375V12.375H16.9886ZM4.61365 21.375L7.98865 16.875L10.2386 20.25L13.6136 15.75L18.1136 21.375H4.61365Z" fill="currentColor"/><rect x="4" y="15" width="15" height="7" fill="currentColor"/><path d="M16.3218 20.0702H13.7756L13.109 17.9962H9.29198L8.63229 20.0702H6.09998L9.87065 9.7002H12.6368L16.3218 20.0702ZM12.4863 15.9221L11.3984 12.4848C11.3135 12.224 11.2549 11.9131 11.2225 11.552H11.1646C11.1461 11.856 11.0851 12.1569 10.9817 12.4547L9.87298 15.9221H12.4863Z" fill="white"/></svg>',
  navigation_arrow: '<svg width="16px" height="28px" viewBox="0 0 16 28" xmlns="http://www.w3.org/2000/svg"><polyline stroke-width="2" stroke-linecap="round" fill="none" stroke="#8492A6" points="1,1 14,14 1,27" /></svg>',
  zoom_in: '<svg width="8" height="8" viewBox="0 0 12 12"><line x1="0" y1="6" x2="12" y2="6" stroke-width="2" stroke="currentColor"/><line x1="6" y1="0" x2="6" y2="12" stroke-width="2" stroke="currentColor"/></svg>',
  zoom_out: '<svg width="8" height="8" viewBox="0 0 12 12"><line x1="0" y1="6" x2="12" y2="6" stroke-width="2" stroke="currentColor"/></svg>'
};
