Px.Editor.BaseComponent = class BaseComponent extends Px.Component {

  constructor(props) {
    super(props);
    this._undo_callbacks = {};
  }

  renderChild(type, key, params) {
    const store = this.store || this.data && this.data.store;
    return super.renderChild(type, key, _.extend({store: store}, params));
  }

  makeModal(component_type, data) {
    const store = this.store || this.data && this.data.store;
    const css_class = function() {
      let custom_class = '';
      if (data.css_class) {
        if (typeof data.css_class === 'function') {
          custom_class += data.css_class.call(this);
        } else if (typeof data.css_class === 'string') {
          custom_class += data.css_class;
        }
      }
      if (store && store.ui) {
        custom_class += `px-${store.ui.editor_mode}-mode`;
      }
      return custom_class;
    }
    const data_with_css_class = _.extend({css_class: css_class}, data);
    const modal = component_type.make(data_with_css_class);
    modal.mount('body');
    return modal;
  }

  withUndo(name, fn, opts) {
    const store = this.store || this.data.store;
    const defaults = {
      label: name,
      set_id: store.selected_set && store.selected_set.id
    };
    store.undo_redo.withUndo(fn.bind(this), _.extend(defaults, opts));
  }

  beginWithUndo(name, opts) {
    const store = this.store || this.data.store;
    const defaults = {
      label: name,
      set_id: store.selected_set && store.selected_set.id
    };
    this._undo_callbacks[name] = store.undo_redo.beginWithUndo(_.extend(defaults, opts));
  }

  endWithUndo(name) {
    this._undo_callbacks[name]();
    this._undo_callbacks[name] = null;
  }

};
