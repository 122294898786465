Px.Editor.TextStore = class TextStore extends Px.BaseStore {

  constructor(project_id) {
    super();
    this.project_id = project_id;
  }

  static get properties() {
    return {
      texts: {std: mobx.observable.map()}
    };
  }

  static get computedProperties() {
    return {
      queued_texts: function() {
        return this.getAllByStatus('queued');
      }
    };
  }

  get actions() {
    return {
      register: function(key) {
        if (this.get(key)) {
          return;
        }
        var self = this;
        var model = Px.Editor.TextModel.make({key: key});
        this.texts.set(key, model);
        // Start loading the text in a timeout, so that if multiple texts are requested
        // during execution of a single render, they all load in a single request.
        this.scheduleLoad();
        return model;
      }
    };
  }

  get(key) {
    return this.texts.get(key) || null;
  }

  // -------
  // Private
  // -------

  getAllByStatus(status) {
    var matching = [];
    for (const model of this.texts.values()) {
      if (model.status === status) {
        matching.push(model);
      }
    }
    return matching;
  }

  scheduleLoad() {
    if (this._raf_id) {
      return;
    }
    this._raf_id = requestAnimationFrame(() => {
      this._loadQueuedSlice();
    });
  }

  _loadQueuedSlice() {
    var self = this;
    // Load at most 20 texts at once to avoid blocking the editor for too long.
    var queued_texts = this.queued_texts.slice(0, 20);
    if (!queued_texts.length) {
      this._raf_id = null;
      return;
    }

    var json_data = [];
    queued_texts.forEach(function(model) {
      json_data.push(JSON.stringify(model.key) + ':' + model.key);
    });
    json_data = '{' + json_data.join(',') + '}';
    mobx.runInAction(function() {
      queued_texts.forEach(function(model) {
        model.update({status: 'loading'});
      });
    });
    fetch(`/v1/books/${this.project_id}/texts.json`, {
      method: 'PUT',
      body: new URLSearchParams({texts: json_data, v3: 't'})
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(`HTTP Error; Status: ${response.status}`);
      }
    }).then(json => {
      mobx.runInAction(function() {
        _.each(json.data, function(val, key) {
          self.get(key).update({
            status: 'loaded',
            data: val
          });
        });
        self._raf_id = null;
        self.scheduleLoad();  // Schedule loading of the next slice, if it exists.
      });
    }).catch((err) => {
      console.warn('Text failed to load', err);
      mobx.runInAction(() => {
        _.each(queued_texts, function(model) {
          model.update({status: 'failed'});
        });
        self._raf_id = null;
      });
    });
  }

};
