Px.Editor.MobileUserGalleryPanel = class MobileUserGalleryPanel extends Px.Util.mixin(
  Px.Editor.UserGalleryPanel,
  Px.Editor.MobileGalleryPanelMixin
) {
  no_items_template() {
    return Px.template`
      <div class="px-gallery-items">
        ${this.extra_actions_template()}
      </div>
    `;
  }

  extra_actions_template() {
    let action_button = '';
    if (this.canGoUpLevel) {
      action_button = Px.template`
        <label class="px-file-upload-button px-small px-secondary-color">
          ${Px.raw(Px.Editor.MobileUserGalleryPanel.icons.upload)}
          <input style="display:none"
                 multiple
                 type="file"
                 name="files[]"
                 accept="${Px.LocalFiles.Validation.VALID_FILE_TYPES.join(', ')}"
                 data-onchange="onFilesSelected"
           />
        </label>
        <div class="caption">${Px.t('Upload')}</div>
      `;
    } else {
      action_button = Px.template`
        <button class="px-small px-secondary-color" data-onclick="addGalleryModal">
          ${Px.raw(Px.Editor.MobileUserGalleryPanel.icons.add_gallery)}
        </button>
        <div class="caption">${Px.t('Add Gallery')}</div>
      `;
    }

    return Px.template`
      <div class="px-gallery-item px-action-item">
        ${action_button}
      </div>
    `;
  }

  get css_class() {
    return 'px-user-gallery-panel';
  }

};

Px.Editor.MobileUserGalleryPanel.icons = {
  upload: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><line x1="12" y1="16" x2="12" y2="2" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="6" y1="7" x2="12" y2="2" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="18" y1="7" x2="12" y2="2" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="2" y1="15" x2="2" y2="21.67" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9"/><line x1="22" y1="21.67" x2="2" y2="21.67" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9"/><line x1="22" y1="15" x2="22" y2="21.67" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9"/></svg>',
  add_gallery: '<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><path d="M20,20.47H2.51A.51.51,0,0,1,2,20L2,2.54A.51.51,0,0,1,2.53,2L20,2a.52.52,0,0,1,.52.52V20A.51.51,0,0,1,20,20.47Z" fill="none" stroke="currentColor" stroke-width="1.39"/><line x1="20.31" y1="5.63" x2="24" y2="5.63" fill="none" stroke="currentColor" stroke-width="1.39"/><line x1="24" y1="24.1" x2="24" y2="5.63" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.39"/><line x1="5.53" y1="24.1" x2="24" y2="24.1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.39"/><line x1="5.53" y1="20.87" x2="5.53" y2="24.1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.39"/><line x1="12.59" y1="15" x2="17.59" y2="15" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><line x1="15.09" y1="12.5" x2="15.09" y2="17.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></svg>'
}
