Px.Editor.GridElement = class GridElement extends Px.Editor.BaseElementComponent {

  template() {
    const element = this.data.element;
    return Px.template`
      <g class="px-grid-element"
        data-element-id="${element.unique_id}"
        data-selected="${this.isSelected}"
        transform="${this.transformAttribute}"
        pointer-events="${this.pointerEventsAttribute}">
        ${Px.if(this.data.render_content, () => {
          return Px.template`
            <rect class="px-grid-header"
                  x="${this.sidebarWidth}"
                  y="0"
                  width="${this.headerWidth}"
                  height="${this.headerHeight}"
                  fill="${element.headercolor}"
            />
            <rect class="px-grid-sidebar"
                  x="0"
                  y="${this.headerHeight}"
                  width="${this.sidebarWidth}"
                  height="${this.sidebarHeight}"
                  fill="${element.sidebarcolor}"
            />
            ${this.verticalLines.map(line => {
              return Px.template`
                <line class="px-grid-vertical-line"
                      x1="${line.x1}"
                      y1="${line.y1}"
                      x2="${line.x2}"
                      y2="${line.y2}"
                      stroke="${line.stroke}"
                      stroke-width="${line.strokeWidth}"
                />
              `;
            })}
            ${this.horizontalLines.map(line => {
              return Px.template`
                <line class="px-grid-horizontal-line"
                      x1="${line.x1}"
                      y1="${line.y1}"
                      x2="${line.x2}"
                      y2="${line.y2}"
                      stroke="${line.stroke}"
                      stroke-width="${line.strokeWidth}"
                />
              `;
            })}
            <rect class="px-grid-frame"
                  x="0"
                  y="0"
                  width="${element.width}"
                  height="${element.height}"
                  stroke="${element.color}"
                  stroke-width="${element.stroke}"
                  fill="none"
            />
          `;
        })}
        ${Px.if(this.data.render_controls, () => {
          return this.selection_outline_template();
        })}
      </g>
    `;
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      headerWidth: function() {
        return Math.max(0, this.data.element.width - this.sidebarWidth);
      },
      headerHeight: function() {
        const element = this.data.element;
        return element.height * element.header;
      },
      sidebarWidth: function() {
        const element = this.data.element;
        return element.width * element.sidebar;
      },
      sidebarHeight: function() {
        return Math.max(0, this.data.element.height - this.headerHeight);
      },
      verticalLines: function() {
        const element = this.data.element;
        const lines = [];
        _.times(7, i => {
          const line_x = this.sidebarWidth + (element.width - this.sidebarWidth)/7.0 * i;
          lines.push({
            x1: line_x,
            y1: i === 0 ? 0 : this.headerHeight,
            x2: line_x,
            y2: element.height,
            stroke: element.color,
            strokeWidth: element.stroke
          });
        });
        return lines;
      },
      horizontalLines: function() {
        const element = this.data.element;
        const lines = [];
        _.times(5, i => {
          const line_y = this.headerHeight + (element.height - this.headerHeight)/5.0 * i;
          lines.push({
            x1: i === 0 ? 0 : this.sidebarWidth,
            y1: line_y,
            x2: element.width,
            y2: line_y,
            stroke: element.color,
            strokeWidth: element.stroke
          });
        });
        return lines;
      }
    });
  }

};
