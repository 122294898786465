// Mixin which turns properties defined in `properties` into mobx observable fields,
// and functions defined in `computedProperties` into mobx computed values.
// Unlike most other mixins, this one accepts parameters and needs to be used as a function.
// The accepted parameters are propertiesScope and computedPropertiesScope, which both
// default to null. You can set the to the name of the property you would like to have
// the observable properties/computed values available on. For example. we use
// propertiesScope: 'state' in Px.Component.
var ObservablePropertiesMixin = function(opts) {

  const options = _.extend({propertiesScope: null, computedPropertiesScope: null}, opts);

  class ComputedFunctionDefinition {
    constructor(fn, options) {
      this.fn = fn;
      this.options = options || {};
    }
  }

  const getPropertyDescriptors = function(cls) {
    const definitions = cls.properties;
    if (definitions) {
      const descriptors = {};
      _.each(definitions, (spec, key) => {
        if (!(_.isObject(spec) && spec.hasOwnProperty('std'))) {
          throw new Error(`Property '${key}' does not specify the default value in std!`);
        }
        if (options.propertiesScope === null && cls.prototype.hasOwnProperty(key)) {
          throw new Error(`Cannot set up observable property '${key}' - property already defined!`);
        }
        const std = spec.std;
        // Primitive value, nothing to do here.
        if (Object(std) !== std) {
          descriptors[key] = std;
        // When mobx sees an object which is already observable, it simply reuses it, which is bad when the
        // object is defined on a prototype and shared among all instances. Make sure to create a copy.
        } else if (mobx.isObservableArray(std)) {
          descriptors[key] = Array.from(std);
        // Same thing with obserfable maps.
        } else if (mobx.isObservableMap(std)) {
          descriptors[key] = new Map(std);
        // mobx knows how to handle native Arrays and Maps properly, so nothing to do here.
        } else if (std instanceof Array || std instanceof Map) {
          descriptors[key] = std;
        // We treat plain objects as maps, so convert it to a Map for mobx to do its magic.
        } else if (typeof std === 'object' && std.constructor === Object) {
          const map = new Map();
          Object.keys(std).forEach(key => {
            map.set(key, std[key]);
          });
          descriptors[key] = map;
        } else {
          console.error(`Unsupported default value type for property '${key}':`, std);
          throw new Error(`Unsupported default value type for property '${key}'`);
        }
      });
      return descriptors;
    }
    return null;
  };

  const getComputedPropertyDescriptors = function(cls) {
    const definitions = cls.computedProperties;
    if (definitions) {
      // Add computed props.
      _.each(definitions, (fn, prop) => {
        if (options.computedPropertiesScope === null && cls.prototype.hasOwnProperty(prop)) {
          throw new Error(`Cannot set up computed property '${prop}' - property already defined!`);
        }
        if (typeof fn !== 'function' && !(fn instanceof ComputedFunctionDefinition)) {
          throw new Error(`Expected computedProperties to contain only functions, but under '${prop} got: '${fn}`);
        }
      });
      return definitions;
    }
    return null;
  };

  const decorateWithObservableProperties = function(cls) {
    if (cls.hasOwnProperty('__observable_properties')) {
      return;
    }

    if (options.propertiesScope && cls.prototype.hasOwnProperty(options.propertiesScope)) {
      throw new Error(
        `Cannot set up observable properties in scope '${options.propertiesScope}': ` +
          'property with that name is already defined!'
      );
    }
    if (options.computedPropertiesScope && cls.prototype.hasOwnProperty(options.computedPropertiesScope)) {
      throw new Error(
        `Cannot set up computed properties in scope '${options.computedPropertiesScope}': ` +
          'property with that name is already defined!'
      );
    }

    const config = {
      properties: {
        scope_class: null,
        descriptors: null
      },
      computed_properties: {
        scope_class: null,
        descriptors: null
      }
    };

    const property_descriptors = getPropertyDescriptors(cls);
    if (property_descriptors) {
      config.properties.descriptors = property_descriptors;
      let target;
      if (options.propertiesScope) {
        const scope_class = class PropertiesScope extends Px.Base {};
        config.properties.scope_class = scope_class;
        target = scope_class;
      } else {
        target = cls;
      }
      const decorations = {};
      const target_proto = target.prototype;
      _.each(property_descriptors, (val, name) => {
        Object.defineProperty(target_proto, name, {
          value: val,
          configurable: true
        });
        decorations[name] = mobx.observable;
      });
      mobx.decorate(target, decorations);
    }

    const computed_property_descriptors = getComputedPropertyDescriptors(cls);
    if (computed_property_descriptors) {
      config.computed_properties.descriptors = computed_property_descriptors;
      let target;
      if (options.computedPropertiesScope) {
        const scope_class = class ComputedPropertiesScope extends Px.Base {};
        config.computed_properties.scope_class = scope_class;
        target = scope_class;
      } else {
        target = cls;
      }
      const decorations = {};
      const target_proto = target.prototype;
      _.each(computed_property_descriptors, (fn, name) => {
        let computed_opts = {};
        if (fn instanceof ComputedFunctionDefinition) {
          computed_opts = fn.options;
          fn = fn.fn;
        }
        Object.defineProperty(target_proto, name, {
          get: fn,
          configurable: true
        });
        decorations[name] = mobx.computed(computed_opts);
      });
      mobx.decorate(target, decorations);
    }

    cls.__observable_properties = config;
  };

  const initializeObservableProperties = function(instance) {
    const config = instance.constructor.__observable_properties;
    const properties_scope_class = config.properties.scope_class;
    if (properties_scope_class) {
      const properties_target = properties_scope_class.make();
      const descriptors = config.properties.descriptors;
      instance[options.propertiesScope] = new Proxy(properties_target, {
        get: (target, key) => {
          if (!descriptors.hasOwnProperty(key)) {
            throw new Error(`${instance}: Trying to access uknown property '${key}'`);
          }
          return target[key];
        },
        set: (target, key, value) => {
          if (!descriptors.hasOwnProperty(key)) {
            throw new Error(`${instance}: Trying to set uknown property '${key}'`);
          }
          target[key] = value;
          return true;
        }
      });
    }
    const computed_properties_scope_class = config.computed_properties.scope_class;
    if (computed_properties_scope_class) {
      instance[options.computedPropertiesScope] = computed_properties_scope_class.make();
    }
  };

  const mixin = Base => class ObservablePropertiesMixin extends Base {
    static computed(fn, opts) {
      return new ComputedFunctionDefinition(fn, opts);
    }

    constructor() {
      super(...arguments);
      decorateWithObservableProperties(this.constructor);
      initializeObservableProperties(this);
    }
  };

  return mixin;

};

Px.ObservablePropertiesMixin = ObservablePropertiesMixin;
export default ObservablePropertiesMixin;
