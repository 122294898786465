Px.Editor.ElementCropIcon = class ElementCropIcon extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    const line_len = this.iconRadius / 1.75;
    const arrowhead_length = this.iconRadius / 4;
    const offset = Math.sin(Math.PI / 4) * arrowhead_length;
    const arrow_points = [
      [
        [-offset, -(line_len - offset)],
        [0, -line_len],
        [offset, -(line_len - offset)]
      ],
      [
        [-(line_len - offset), -offset],
        [-line_len, 0],
        [-(line_len - offset), offset]
      ],
      [
        [-offset, (line_len - offset)],
        [0, line_len],
        [offset, (line_len - offset)]
      ],
      [
        [(line_len - offset), -offset],
        [line_len, 0],
        [(line_len - offset), offset]
      ]
    ];

    return Px.template`
      <g class="px-control-component"
         opacity="${this.isEnabled ? 1 : 0}"
         pointer-events="${this.pointerEventsAttribute}"
         cursor="move"
         data-onclick="onClick"
         data-onmousedown="grabHandle"
         data-ontouchstart="grabHandle"
         transform="translate(${this.data.element.width / 2} ${this.data.element.height / 2})"
         data-px-tooltip="${Px.t('Drag to adjust cropping')}">
        <circle class="px-control-handle"
                r="${this.iconRadius}"
                stroke-width="${this.strokeWidth / 2}"
                stroke="#fff"
                stroke-opacity="0.6"
                fill="var(--editor-selection-color)"
                fill-opacity="0.6"
        />
        <line x1="-${line_len}"
              y1="0"
              x2="${line_len}"
              y2="0"
              stroke-width="${this.strokeWidth}"
              stroke="#fff"
              stroke-opacity="0.6"
        />
        <line x1="0"
              y1="-${line_len}"
              x2="0"
              y2="${line_len}"
              stroke-width="${this.strokeWidth}"
              stroke="#fff"
              stroke-opacity="0.6"
        />
        ${arrow_points.map(points => {
          return Px.template`
            <polyline points="${points.map(pair => pair.join(',')).join(' ')}"
                      fill="none"
                      stroke-width="${this.strokeWidth}"
                      stroke="#fff"
                      stroke-opacity="0.6"
            />
          `;
        })}
      </g>
    `;
  }

  constructor(props) {
    super(props);
    this._drag_raf = null;
    this._drag_origin = null;
    this.releaseHandle = this.releaseHandle.bind(this);
  }

  get dataProperties() {
    return {
      element: {required: true},
      scale: {required: true},
      store: {required: true},
      grabElement: {required: true},
      icon_radius: {std: 23}
    }
  }

  static get computedProperties() {
    return {
      isEnabled: function() {
        const element = this.data.element;
        const selected_element = this.data.store.selected_element;
        if (!(selected_element && selected_element.edit && selected_element.crop)) {
          return false;
        }
        if (selected_element.placeholder) {
          return false;
        }
        return element === selected_element || element.two_page_spread_clone === selected_element;
      },
      pointerEventsAttribute: function() {
        return this.isEnabled ? 'auto' : 'none';
      },
      iconRadius: function() {
        return this.inSvgUnits(this.data.icon_radius);
      },
      strokeWidth: function() {
        return this.inSvgUnits(1.5);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  // Prevent click from propagating to the page.
  onClick(evt) {
    evt.stopPropagation();
  }

  grabHandle(evt) {
    if (evt.type === 'mousedown' && evt.which !== 1) {
      return;
    }
    this.data.store.ui.toggleElementCroppingMode(true);
    this.data.grabElement(evt);
    const $doc = $j(document);
    $doc.on('mouseup touchend touchcancel', this.releaseHandle);
  }

  releaseHandle(evt) {
    this.data.store.ui.toggleElementCroppingMode(false);
    const $doc = $j(document);
    $doc.off('mouseup touchend touchcancel', this.releaseHandle);
  }

};
