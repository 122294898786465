Px.Editor.FontPaletteModel = class FontPaletteModel extends Px.BaseModel {

  constructor(params) {
    const props = Object.assign({}, params);
    delete props.theme_id;
    super(props);
    this.theme_id = params.theme_id;
  }

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null},
      name: {std: null},
      fonts: {std: mobx.observable.array()},
      _status: {std: 'queued'}
    });
  }

  static get computedProperties() {
    return {
      is_default: function() {
        return this.id === null;
      }
    };
  }

  get actions() {
    return Object.assign(super.actions, {
      load: function() {
        this.status = 'loading';
        const url = this.is_default ? `/v1/fonts.json?theme_id=${this.theme_id}` : `/v1/font_palettes/${this.id}.json`;
        fetch(url).then(response => {
          if (response.ok) {
            response.json().then(json => {
              let fonts = this.is_default ? json : json.fonts;
              fonts = _.sortBy(fonts, f => f.name);
              this.update({fonts: fonts, name: json.name, status: 'loaded'});
            });
          } else {
            throw new Error(`HTTP error status: ${response.status}`);
          }
        }).catch(() => {
          this.status = 'failed';
        });
      }
    });
  }

  // ---------------
  // Getters/setters
  // ---------------

  get status() {
    return this._status;
  }

  set status(status) {
    if (!_.include(FontPaletteModel.VALID_STATUSES, status)) {
      throw new Error(`Invalid font palette status: ${status}`);
    }
    this._status = status;
  }

};

Px.Editor.FontPaletteModel.VALID_STATUSES = ['queued', 'loading', 'loaded', 'failed'];
