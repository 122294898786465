Px.Editor.ElementEditIconsContainer = class ElementEditIconsContainer extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    return Px.template`
      <g transform="translate(0, ${this.inSvgUnits(-ElementEditIconsContainer.CONTAINER_MARGIN_Y)})">
        ${Px.if(this.enabledIcons.length, () => {
          return Px.template`
            <rect x="${this.containerPosition[0]}"
                  y="${this.containerPosition[1]}"
                  width="${this.containerSize[0]}"
                  height="${this.containerSize[1]}"
                  rx="${this.containerSize[1] / 2}"
                  fill="#fff"
                  stroke="var(--neutral-grey-4)"
                  stroke-width="${this.inSvgUnits(1)}"
                  data-onmousedown="preventMousedown"
                  data-ontouchstart="preventMousedown"
            />
            ${this.renderIcons()}
          `;
        })}
      </g>
    `;
  }

  get dataProperties() {
    return {
      element: {required: true},
      scale: {required: true},
      store: {required: true}
    }
  }

  static get computedProperties() {
    return {
      enabledIcons: function() {
        const store = this.data.store;
        const selected_element = store.selected_element;
        const element = this.data.element;
        const is_selected = selected_element &&
              (element === selected_element || element.two_page_spread_clone === selected_element);
        const icons = [];
        if (is_selected && element.edit) {
          // Regular users can only re-arrange order of elements in layer 0.
          // Admin users can re-arrange inner layer order on every layer.
          if ((element.elayer && element.z === 0) || Px.config.advanced_edit_mode) {
            icons.push([Px.Editor.ElementLayerIcons, 'layer-icons']);
          }
          if (element.type === 'image') {
            if (!(element.erotation || element.placeholder)) {
              icons.push([Px.Editor.ImageTurnIcons, 'image-turn-icons']);
            }
            if (!element.placeholder && !element.stretch && !store.cut_print_mode) {
              icons.push([Px.Editor.ImageSwapIcon, 'image-swap-icon']);
            }
          }
          if (element['delete']) {
            icons.push([Px.Editor.ElementDeleteIcon, 'delete-icon']);
          } else if (element.type === 'image' && element.replace && element.id && !store.cut_print_mode) {
            icons.push([Px.Editor.ImageClearIcon, 'image-clear-icon']);
          }
        }
        return icons;
      },
      iconPositions: function() {
        let current_x_offset = 0;
        const offsets = [];
        this.enabledIcons.forEach(icon_props => {
          const icon_size = icon_props[0].SIZE;
          const width = icon_size[0];
          const height = icon_size[1];
          if (current_x_offset !== 0) {
            current_x_offset += ElementEditIconsContainer.ICON_MARGIN;
          }
          offsets.push([current_x_offset, -height]);
          current_x_offset += width;
        });
        // We want to align the icons to the center of the element instead of left,
        // so calculate the starting position from element width & icon container width;
        const start_x = (this.data.element.width - this.inSvgUnits(current_x_offset)) / 2;
        return offsets.map(offset => {
          return [
            start_x + this.inSvgUnits(offset[0]),
            this.inSvgUnits(offset[1] - ElementEditIconsContainer.CONTAINER_PADDING_Y)
          ]
        });
      },
      containerSize: function() {
        let width = 0;
        let height = 0;
        this.enabledIcons.forEach(icon_props => {
          const icon_size = icon_props[0].SIZE;
          width += icon_size[0];
          height = Math.max(height, icon_size[1]);
        });
        width += (this.enabledIcons.length - 1) * ElementEditIconsContainer.ICON_MARGIN;
        width += 2 * ElementEditIconsContainer.CONTAINER_PADDING_X;
        height += 2 * ElementEditIconsContainer.CONTAINER_PADDING_Y;
        return [this.inSvgUnits(width), this.inSvgUnits(height)];
      },
      containerPosition: function() {
        return [
          this.iconPositions[0][0] - this.inSvgUnits(ElementEditIconsContainer.CONTAINER_PADDING_X),
          -this.containerSize[1]
        ];
      }
    };
  }

  renderIcons() {
    return this.enabledIcons.map((icon_props, idx) => {
      const position = this.iconPositions[idx];
      return this.renderChild(icon_props[0], icon_props[1], {
        element: this.data.element,
        scale: this.data.scale,
        x: position[0],
        y: position[1]
      });
    });
  }

  // --------------
  // Event handlers
  // --------------

  preventMousedown(evt) {
    // This handler ensures that click on the icons container doesn't propagate
    // to the page, causing the element to get deselected.
  }

};

Px.Editor.ElementEditIconsContainer.ICON_MARGIN = 16;
Px.Editor.ElementEditIconsContainer.CONTAINER_PADDING_X = 16;
Px.Editor.ElementEditIconsContainer.CONTAINER_PADDING_Y = 2;
Px.Editor.ElementEditIconsContainer.CONTAINER_MARGIN_Y = 12;
