Px.Editor.MobileCutPrintQuantityPanel = class MobileCutPrintQuantityPanel extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-mobile-cut-print-quantity-panel">
        <div class="px-quantity-buttons">
          <button data-onclick="decrementQuantity" title="${Px.t('Decrement auantity')}"
                  ${this.decrementQuantityButtonEnabled ? '' : 'disabled'}>
            ${Px.raw(MobileCutPrintQuantityPanel.icons.decrement_quantity)}
          </button>
          <input type="number" min="1" step="1" data-onchange="setQuantity"
                  value="${this.quantityInputValue}" ${this.quantityInputEnabled ? '' : 'disabled'} />
          <button data-onclick="incrementQuantity" title="${Px.t('Increment quantity')}"
                  ${this.incrementQuantityButtonEnabled ? '' : 'disabled'}>
            ${Px.raw(MobileCutPrintQuantityPanel.icons.increment_quantity)}
          </button>
        </div>
        <div class="px-selection-button">
          ${Px.if(this.areAllSetsSelected, () => {
            return Px.template`
              <button data-onclick="deselectAll">
                ${Px.t('Deselect All')}
              </button>
            `;
          }).else(() => {
            return Px.template`
              <button data-onclick="selectAll">
                ${Px.t('Select All')}
              </button>
            `;
          })}
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true},
    };
  }

  static get computedProperties() {
    const CutPrints = Px.Editor.CutPrints;
    return {
      projectStore: function() {
        return this.data.store.project;
      },
      cutPrintStore: function() {
        return this.data.store.cut_prints;
      },
      areAllSetsSelected: CutPrints.computedProperties.areAllSetsSelected,
      incrementQuantityButtonEnabled: CutPrints.computedProperties.incrementQuantityButtonEnabled,
      decrementQuantityButtonEnabled: CutPrints.computedProperties.decrementQuantityButtonEnabled,
      printSetsWithDefinitions: CutPrints.computedProperties.printSetsWithDefinitions,
      quantityInputEnabled: CutPrints.computedProperties.quantityInputEnabled,
      quantityInputValue: CutPrints.computedProperties.quantityInputValue
    };
  }

  // --------------
  // Event handlers
  // --------------

  setQuantity(evt) {
    Px.Editor.CutPrints.prototype.setQuantity.call(this, evt);
  }

  incrementQuantity(evt) {
    Px.Editor.CutPrints.prototype.incrementQuantity.call(this, evt);
  }

  decrementQuantity(evt) {
    Px.Editor.CutPrints.prototype.decrementQuantity.call(this, evt);
  }

  selectAll(evt) {
    Px.Editor.CutPrints.prototype.selectAll.call(this, evt);
  }

  deselectAll(evt) {
    Px.Editor.CutPrints.prototype.deselectAll.call(this, evt);
  }

};

Px.Editor.MobileCutPrintQuantityPanel.icons = {
  decrement_quantity: '<svg width="22px" height="22px" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="12" fill="none" stroke-width="2" stroke="currentColor" /><line x1="6" y1="13" x2="20" y2="13" fill="none" stroke-width="2" stroke="currentColor" /></svg>',
  increment_quantity: '<svg width="22px" height="22px" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13" r="12" fill="none" stroke-width="2" stroke="currentColor" /><line fill="none" stroke-width="2" stroke="currentColor" x1="6" y1="13" x2="20" y2="13" /><line fill="none" stroke-width="2" stroke="currentColor" x1="13" y1="6" x2="13" y2="20" /></svg>'
};
