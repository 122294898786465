Px.Editor.UploadProjectImagesModal = class UploadProjectImagesModal extends Px.Components.BaseModal {

  get title() {
    return Px.t('Upload Images');
  }

  get content() {
    return Px.template`
      <p>${Px.t('Do you want to upload project images?')}</p>
      ${Px.if(this.state.upload_in_progress, () => {
        return Px.template`
          <div class="px-upload-progress">
            <div class="px-upload-progress-bar" style="width:${this.uploadProgress}%"></div>
          </div>
          <p class="px-upload-progress-details">
            ${this.uploadProgress}%
          </p>
        `;
      })}
    `;
  }

  get footer() {
    if (this.state.upload_in_progress) {
      return Px.template`
        <button class="px-cancel"
                data-onclick="cancelUpload"
                ${this.state.disabled ? 'disabled' : ''}>
          ${Px.t('Cancel Upload')}
        </button>
      `;
    } else {
      return Px.template`
        <button data-onclick="exitEditor"
                ${this.state.disabled ? 'disabled' : ''}>
          ${Px.t('Exit without Uploading')}
        </button>
        <button class="px-ok"
                data-onclick="uploadImages"
                ${this.state.disabled ? 'disabled' : ''}>
          ${Px.t('Upload Images')}
        </button>
      `;
    }
  }

  get css_class() {
    return `${super.css_class} px-confirmation-modal px-upload-project-images-modal`;
  }

  constructor(props) {
    super(props);
    this.close_on_background_click = false;
    this.registerReaction(
      () => this.localImages.length === 0,
      (complete) => {
        if (complete) {
          this.exitEditor();
        }
      },
      {name: 'Px.Editor.UploadsProjectImagesModal::exitOnUploadComplete'}
    );
  }

  get dataProperties() {
    return {
      store: {required: true},
      exitEditor: {required: true}
    }
  }

  static get properties() {
    return {
      upload_in_progress: {type: 'bool', std: false},
      total_images: {type: 'int', std: 0},
      disabled: {type: 'bool', std: false}
    }
  }

  static get computedProperties() {
    return {
      localImages: function() {
        return this.data.store.galleries.project.local_images;
      },
      uploadProgress: function() {
        const total_count = this.state.total_images;
        const uploaded_count = total_count - this.localImages.length;
        let progress = uploaded_count;
        this.localImages.forEach(image => {
          const file = image.data.local_file;
          if (!file.upload_failure) {
            progress += (file.upload_progress_percent / 100);
          }
        });
        return Math.round((progress / total_count) * 100);
      }
    }
  }

  // --------------
  // Event handlers
  // --------------

  uploadImages(evt) {
    const image_store = this.data.store.images;
    mobx.runInAction(() => {
      this.state.upload_in_progress = true;
      this.state.total_images = this.localImages.length;
      this.localImages.forEach(image => {
        if (!image_store.get(image.id)) {
          // Registering the image triggers upload.
          image_store.register(image.id, image.data);
        }
      });
    });
  }

  cancelUpload(evt) {
    // This doesn't actually stop the uploads, but that doesn't matter much.
    this.state.upload_in_progress = false;
  }

  exitEditor(evt) {
    this.state.disabled = true;
    this.data.exitEditor();
    this.destroy();
  }

};
