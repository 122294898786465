Px.Editor.BaseImageModel = class BaseImageModel extends Px.BaseModel {

  static factory(id, attrs) {
    const type = this.REGEX.exec(id)[1];

    switch (type) {
    case 'db':
      return Px.Editor.DbImageModel.make({id: id, data: attrs});
    case 'fb':
      return Px.Editor.FbImageModel.make({id: id, data: attrs});
    case 'instagram':
      return Px.Editor.InstagramImageModel.make({id: id, data: attrs});
    case 'dropbox':
      return Px.Editor.DropboxImageModel.make({id: id, data: attrs});
    case 'barcode':
      return Px.Editor.BarcodeImageModel.make({id: id, data: attrs});
    case 'tag':
      return Px.Editor.TagImageModel.make({id: id, data: attrs});
    case 'local':
      return Px.Editor.LocalImageModel.make({id: id, data: attrs});
    default:
      console.error(`Unsupported image type: ${type}`);
      return null;
    }
  }

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null},
      // Position of the image in the image store.
      order: {std: 0},
      // Custom data, specific to each image type.
      data: {std: null}
    });
  }

  static get computedProperties() {
    return {
      type: function() {
        return this._regexMatch[1];
      },
      value: function() {
        return this._regexMatch[2];
      },
      dimensions: function() {
        throw new Error('Not implemented');
      },
      width: function() {
        return this.dimensions[0];
      },
      height: function() {
        return this.dimensions[1];
      },
      aspect_ratio: function() {
        if (this.width === 0 || this.height === 0) {
          // TODO: Load image and measure natural dimensions?
          return 1;
        } else {
          return this.width / this.height;
        }
      },
      preview: function() {
        return this.data.pixfizz_thumb_url;
      },
      filename: function() {
        const filename = this.data && this.data.filename;
        return filename || '';
      },
      _regexMatch: function() {
        return this.id.match(BaseImageModel.REGEX);
      }
    };
  }

  src(params) {
    throw new Error('Not implemented');
  }

  hasEffects(params) {
    return Boolean(params && (params.sepia || params.flip || params.grayscale ||
                              params.sharpen || params.normalize || params.blur ||
                              params.equalize || _.isNumber(params.brightness) ||
                              (_.isNumber(params.contrast) || params.contrast === true)));
  }

};

Px.Editor.BaseImageModel.REGEX = /(^[\w\.]*):(.*)/;
// It's a 10x10 px gray (color #bbb) image.
Px.Editor.BaseImageModel.DEFAULT_IMAGE = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" fill="%23DCDBDA" /></svg>';
