Px.Editor.ScoreElement = class ScoreElement extends Px.Editor.BaseElementComponent {

  template() {
    if (!this.data.render_content) {
      return Px.template`
        <g></g>
      `;
    }

    const element = this.data.element;
    return Px.template`
      <rect class="px-score-element px-${element.name}"
            width="${element.width}"
            height="${element.height}"
            x="${element.x}"
            y="${element.y}"
            pointer-events="none"
      />
    `;
  }

}
