Px.Editor.OptionStore = class OptionStore extends Px.BaseStore {

  constructor(project_id, image_store) {
    super();
    this.project_id = project_id;
    this.image_store = image_store;
  }

  static get properties() {
    return {
      loaded: {std: false},
      available_options: {std: mobx.observable.array()},
      available_template_options: {std: mobx.observable.array()},
    }
  }

  static get computedProperties() {
    return {
      combined_available_options: function() {
        return this.available_options.concat(this.available_template_options);
      },
      images: function() {
        let images = [];
        this.combined_available_options.forEach(option => {
          if (option.type === 'multiple_choice') {
            option.values.forEach(value => {
              images = images.concat(mobx.toJS(value.element_substitution_images));
            });
          }
        });
        return images;
      }
    };
  }

  get actions() {
    return {
      load: function() {
        var self = this;
        var xhr = $j.getJSON(`/v1/books/${this.project_id}/available_options.json`);
        xhr.done(data => {
          mobx.runInAction(() => {
            this.available_options.replace(data.options.filter(option => option.published));
            this.available_template_options.replace(data.template_options.filter(option => option.published));
            this.images.forEach(image => {
              const img_id = `db:${image.id}`;
              if (!this.image_store.get(img_id)) {
                this.image_store.register(img_id, image);
              }
            });
            this.loaded = true;
          });
        });
      }
    }
  }

  isSubstitutionImage(image_id) {
    return this.images.some(image => `db:${image.id}` === image_id);
  }

  getOption(id) {
    return this.available_options.find(opt => opt.id === id);
  }

  getOptionByCode(code) {
    return this.available_options.find(opt => opt.code === code);
  }

  getTemplateOption(id) {
    return this.available_template_options.find(opt => opt.id === id);
  }

  getTemplateOptionByCode(code) {
    return this.available_template_options.find(opt => opt.code === code);
  }

  getChildOptions(option) {
    return this.available_options.filter(opt => opt.parent_id === option.id);
  }

  getChildTemplateOptions(option) {
    return this.available_template_options.filter(opt => opt.parent_id === option.id);
  }

};
