Px.Editor.BarcodeElementModel = class BarcodeElementModel extends Px.Editor.BaseElementModel {

  static fromXMLNode(node, params) {
    const props = {
      x:         parseFloat(node.getAttribute('x')),
      y:         parseFloat(node.getAttribute('y')),
      width:     parseFloat(node.getAttribute('width')) || 0,
      height:    parseFloat(node.getAttribute('height')) || 0,
      rotation:  parseFloat(node.getAttribute('rotate')) || 0,
      tags:      node.getAttribute('tags') || null,
      layout:    node.getAttribute('layout') === 'true',
      pdf_layer: node.getAttribute('pdf_layer') || null,
      group:     params.group || null,
      page:      params.page
    };
    return Px.Editor.BarcodeElementModel.make(props);
  }

};

Px.Editor.BarcodeElementModel.ELEMENT_TYPE = 'barcode';
