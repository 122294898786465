Px.Editor.MobileImageSourcePanel = class MobileImageSourcePanel extends Px.Editor.BaseComponent {
  template() {
    const image_element = this.data.store.selected_element;
    return Px.template`
      <div class="px-mobile-image-source-editor">
        ${this.renderChild(Px.Editor.MobileImageSelectorPanel, 'galleries-panel')}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

};


Px.Editor.MobileImageCropPanel = class CropPanel extends Px.Editor.BaseComponent {
  template() {
    return Px.template`
      <div class="px-mobile-image-crop-editor">
        <div class="px-controls">
          <p class="px-crop-instructions">
            ${Px.t('Drag image to adjust cropping. Pinch to zoom.')}
          </p>
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

};


Px.Editor.MobileImageMaskPanel = class CropPanel extends Px.Editor.BaseComponent {
  template() {
    const selected_element = this.data.store.selected_element;
    return Px.template`
      <div class="px-mobile-image-mask-editor">
        <div class="px-mask-gallery">
          ${this.masks.map(image => {
            return Px.template`
              <div class="px-image"
                  data-image-id="${image.id}"
                  data-selected="${selected_element.mask === image.id}"
                  data-onclick="selectMask">
                <span>
                  <img src="${image.src({size: 150})}" />
                </span>
              </div>
            `;
          })}
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      masks: function() {
        const store = this.data.store;
        return store.masks.images.map(image_id => store.images.get(image_id));
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  selectMask(evt) {
    const image = this.data.store.selected_element;
    const mask_id = evt.currentTarget.getAttribute('data-image-id');
    if (mask_id === image.mask) {
      this.withUndo('remove image mask', () => {
        image.update({mask: null});
      });
    } else {
      this.withUndo('set image mask', () => {
        image.update({mask: mask_id});
      });
    }
  }

};
