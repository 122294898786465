Px.Editor.MappedPreviewPanel = class MappedPreviewPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const store = this.data.store;
    return Px.template`
      <div class="px-mapped-preview-panel">
        <div class="px-prev">
          ${Px.if(this.hasMultiplePreviews, () => {
            return Px.template`
              <button title="${Px.t('Previous')}" data-onclick="goToPrevious">
                ${Px.raw(MappedPreviewPanel.icons.navigation_arrow)}
              </button>
            `;
          })}
        </div>
        <div class="px-canvas-container">
          ${r(Px.Components.ResizeDetector, `resize-detector`, {onResize: this.resize})}
          ${Px.if(store.project.loaded && store.theme.loaded, () => {
            return r(Px.Components.MappedPreview, 'mapped-preview', this.mappedPreviewProps);
          })}
        </div>
        <div class="px-next">
          ${Px.if(this.hasMultiplePreviews, () => {
            return Px.template`
              <button title="${Px.t('Next')}" data-onclick="goToNext">
                ${Px.raw(MappedPreviewPanel.icons.navigation_arrow)}
              </button>
            `;
          })}
        </div>
        <div class="px-dots">
          ${Px.if(this.hasMultiplePreviews, () => {
            return this.mappedPreviews.map((_, idx) => {
              return Px.template`
                <span data-current="${idx === this.state.preview_index}" data-idx="${idx}" data-onclick="selectPreview">
                </span>
              `;
            });
          })}
        </div>
      </div>
    `;
  }

  constructor(props) {
    super(props);

    this.resize = this.resize.bind(this);

    this.on('mount', this.resize);
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get properties() {
    return {
      available_width: {type: 'float', std: 0},
      available_height: {type: 'float', std: 0},
      preview_index: {type: 'int', std: 0}
    }
  }

  static get computedProperties() {
    return {
      mappedPreviews: function() {
        return this.data.store.theme.mapped_previews;
      },
      hasMultiplePreviews: function() {
        return this.mappedPreviews.length > 1;
      },
      bgImageSrc: function() {
        return this.mappedPreviews[this.state.preview_index].asset.url;
      },
      glbUrl: function() {
        return this.mappedPreviews[this.state.preview_index].glb_url;
      },
      mappedPreviewProps: function() {
        const preview_sections = {};
        this.data.store.theme.preview_sections.forEach(section => {
          const page = this.data.store.project.pages[section.page];
          const coords = section.coordinates;
          const x = coords[0] / page.width;
          const y = coords[1] / page.height;
          const width = (coords[6] - coords[0]) / page.width;
          const height = (coords[3] - coords[1]) / page.height;
          const opts = {
            draft_xml: page.xml,
            crop: [x, y, width, height].join(','),
            width: 1200,
            hide_placeholders: true
          };
          const url = `/v1/pages/${page.id}.png?${$j.param(opts)}`;
          preview_sections[section.name] = url;
        });
        return {
          bg_url: this.bgImageSrc,
          glb_url: this.glbUrl,
          preview_sections: preview_sections,
          width: this.state.available_width,
          height: this.state.available_height
        };
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  resize(evt) {
    const container = this.dom_node.querySelector('.px-canvas-container');
    mobx.runInAction(() => {
      this.state.available_width = $j(container).width();
      this.state.available_height = $j(container).height();
    });
  }

  goToPrevious(evt) {
    let prev_idx = this.state.preview_index - 1;
    if (prev_idx < 0) {
      prev_idx = this.mappedPreviews.length - 1;
    }
    this.state.preview_index = prev_idx;
  }

  goToNext(evt) {
    let next_idx = this.state.preview_index + 1;
    if (next_idx >= this.mappedPreviews.length) {
      next_idx = 0;
    }
    this.state.preview_index = next_idx;
  }

  selectPreview(evt) {
    const idx = parseInt(evt.target.getAttribute('data-idx'), 10);
    this.state.preview_index = idx;
  }

};

Px.Editor.MappedPreviewPanel.icons = {
  navigation_arrow: '<svg width="16px" height="28px" viewBox="0 0 16 28" xmlns="http://www.w3.org/2000/svg"><polyline stroke-width="2" stroke-linecap="round" fill="none" stroke="#8492A6" points="1,1 14,14 1,27" /></svg>',
};
