Px.Component.DragAndDrop = {
  current_drag: mobx.observable({
    type: null,
    item: null,
    origin: {
      coords: {x: null, y: null},
      position: {top: null, left: null}
    },
    x: null,
    y: null
  }, {
    item: mobx.observable.ref
  }),

  _drag_internal: {
    target: null,
    cancelled: false
  },

  // Some helpers to uniformly determine event coordinates.
  _pageX: function(evt) {
    const oevt = evt.originalEvent;
    return ('targetTouches' in oevt) ? oevt.targetTouches[0].pageX : evt.pageX;
  },

  _pageY: function(evt) {
    const oevt = evt.originalEvent;
    return ('targetTouches' in oevt) ? oevt.targetTouches[0].pageY : evt.pageY;
  },

  _clientX: function(evt) {
    const oevt = evt.originalEvent;
    return ('targetTouches' in oevt) ? oevt.targetTouches[0].clientX : evt.clientX;
  },

  _clientY: function(evt) {
    const oevt = evt.originalEvent;
    return ('targetTouches' in oevt) ? oevt.targetTouches[0].clientY : evt.clientY;
  },

  _revertDrag: function(opts, callback) {
    const DnD = Px.Component.DragAndDrop;
    const current_drag = DnD.current_drag;
    const ox = current_drag.origin.coords.x;
    const oy = current_drag.origin.coords.y;
    const dx = current_drag.x - ox;
    const dy = current_drag.y - oy;
    const duration = opts.revert_duration || 300;
    let should_animate = duration > 0;

    // If the item was moved less then 5 pixels, it was probably a click rather than
    // an intentional drag, so end the drag immediately rather than animate.
    const MIN_DIST = 5;
    if (Math.sqrt(Math.abs(dx*dx + dy*dy)) < MIN_DIST) {
      should_animate = false;
    }

    if (should_animate) {
      let t0;
      let progress = 0;
      const animate = function(t) {
        if (!t0) {
          t0 = t;
        }
        progress = (t - t0);
        if (progress < duration) {
          // same as 'swing' (jQuery's default easing function).
          const easing = 0.5 + Math.cos(progress/duration * Math.PI)/2;
          mobx.transaction(function() {
            current_drag.x = ox + (dx * easing);
            current_drag.y = oy + (dy * easing);
          });
          requestAnimationFrame(animate);
        } else {
          callback();
        }
      };
      requestAnimationFrame(animate);
    } else {
      callback();
    }
  },

  _resetState: function() {
    const DnD = Px.Component.DragAndDrop;
    DnD._drag_internal = {
      target: null,
      cancelled: false
    };
    mobx.transaction(function() {
      DnD.current_drag.type = null;
      DnD.current_drag.item = null;
      DnD.current_drag.origin = {
        coords: {x: null, y: null},
        position: {top: null, left: null}
      };
      DnD.current_drag.x = null;
      DnD.current_drag.y = null;
    });
  },

  DragSource: {
    init: function(component, opts) {
      opts = opts || {};
      opts.handle = opts.handle || null;
      opts.dragged_copy = opts.dragged_copy || {};
      if (!opts.beginDrag) {
        throw new Error("DragSource must provide required option 'beginDrag'!");
      }
      const $doc = $j(document);
      const body_style = document.body.style;
      const body_css_width = body_style.width;
      const body_css_height = body_style.height;
      const body_css_overflow = body_style.overflow;
      const DnD = Px.Component.DragAndDrop;
      let dragged_copy;
      let onmove_raf;

      const onDragStart = function(evt) {
        if (evt.type === 'mousedown' && evt.which !== 1) {
          return;
        }
        const current_drag = DnD.current_drag;
        if (current_drag.type) {
          // Drag already in progress, ignore.
          return;
        }
        evt.preventDefault();
        if (!opts.canDrag || opts.canDrag.call(component)) {
          // Fix body width & height and set overflow to hidden to prevent dragged
          // copy from enlarging the body while dragged to the right or bottom
          // past the current bounds of the document.
          const $body = $j('body');
          body_style.width = $body.width() + 'px';
          body_style.height = $body.height() + 'px';
          body_style.overflow = 'hidden';

          $doc.bind('mousemove touchmove', onDragMove);
          $doc.bind('mouseup touchend', onDragEnd);
          $doc.bind('touchcancel', onDragCancel);

          const x = DnD._pageX(evt);
          const y = DnD._pageY(evt);
          mobx.transaction(function() {
            current_drag.type = component.component_type;
            current_drag.item = opts.beginDrag.call(component);
            current_drag.origin = {
              coords: {x: x, y: y},
              position: $j(component.dom_node).position()
            };
            current_drag.x = x;
            current_drag.y = y;
          });
          const $component_node = $j(component.dom_node);
          dragged_copy = DnD.DraggedCopy.make({
            component_class: component.constructor,
            component_key: 'dragged-copy-' + component._component_id,
            component_data: _.extend({is_dragged_copy: true}, component.data),
            width: $component_node.outerWidth(true),
            height: $component_node.outerHeight(true),
            opacity: opts.dragged_copy.opacity,
            z_index: opts.dragged_copy.z_index,
            class_name: opts.dragged_copy.class_name
          });
          const $dragged_copy_container = $j(opts.dragged_copy.append_to || component.dom_node.parentNode);
          dragged_copy.mount($dragged_copy_container);
        }
      };

      const isValidDropTarget = function(element) {
        const drop_target = element && element.__px_component && element.__px_component._drop_target;
        if (!drop_target) {
          return false;
        }
        for (const type of drop_target.types.values()) {
          if (component instanceof type) {
            return true;
          }
        }
        return false;
      };

      const onDragMove = function(evt) {
        if (!onmove_raf) {
          onmove_raf = requestAnimationFrame(function() {
            onmove_raf = null;
            // elementFromPoint requires clientX/Y rather than pageX/Y.
            const x = DnD._clientX(evt);
            const y = DnD._clientY(evt);
            let element = document.elementFromPoint(x, y);
            const current_drag = DnD.current_drag;
            const drag_internal = DnD._drag_internal;
            const previous_target = drag_internal.target;
            let hovered_component = null;
            if (isValidDropTarget(element)) {
              hovered_component = element.__px_component;
            }
            while (element && !hovered_component) {
              element = element.parentElement;
              if (isValidDropTarget(element)) {
                hovered_component = element.__px_component;
              }
            }

            const hovered_target = hovered_component && hovered_component._drop_target;

            if (hovered_target) {
              if (previous_target !== hovered_target) {
                if (previous_target && previous_target.spec.dragLeave) {
                  previous_target.spec.dragLeave.call(previous_target.component, current_drag.item);
                }
                if (hovered_target.spec.dragEnter) {
                  hovered_target.spec.dragEnter.call(hovered_component, current_drag.item);
                }
                drag_internal.target = {
                  spec: hovered_target.spec,
                  types: hovered_target.types,
                  component: hovered_component
                };
              }
            } else {
              if (previous_target) {
                if (previous_target.spec.dragLeave) {
                  previous_target.spec.dragLeave.call(previous_target.component, current_drag.item);
                }
                drag_internal.target = null;
              }
            }

            mobx.transaction(function() {
              current_drag.x = DnD._pageX(evt);
              current_drag.y = DnD._pageY(evt);
            });
          });
        }
      };

      const onDragEnd = function(evt) {
        const current_drag = DnD.current_drag;
        const drag_internal = DnD._drag_internal;
        const target = drag_internal.target;
        let drop_result;

        cancelAnimationFrame(onmove_raf);
        onmove_raf = null;
        $doc.unbind('mousemove touchmove', onDragMove);
        $doc.unbind('touchcancel', onDragCancel);
        $doc.unbind('mouseup touchend', onDragEnd);

        // Revert body styles back to original values.
        body_style.width = body_css_width;
        body_style.height = body_css_height;
        body_style.overflow = body_css_overflow;

        if (target) {
          if (target.spec.dragLeave) {
            target.spec.dragLeave.call(target.component, current_drag.item);
          }
          // This drag ended with a drop on a target. Can we drop?
          if (!drag_internal.cancelled &&
              (!target.spec.canDrop || target.spec.canDrop.call(target.component, current_drag.item))) {
            drop_result = target.spec.onDrop.call(target.component, current_drag.item);
            if (!drop_result) {
              drop_result = {};
            }
          }
        }

        const endDrag = function() {
          if (opts.endDrag) {
            opts.endDrag.call(component, drop_result);
          }
          dragged_copy.destroy();
          DnD._resetState();
        };

        if (drop_result) {
          endDrag();
        } else {
          DnD._revertDrag(opts, endDrag);
        }
      };

      const onDragCancel = function(evt) {
        DnD._drag_internal.cancelled = true;
        onDragEnd(evt);
      };

      component.on('mount', function() {
        $j(component.dom_node).on('mousedown touchstart', opts.handle || null, onDragStart);
      });
    }
  },

  DropTarget: {
    init: function(component, opts) {
      if (!opts.types) {
        throw new Error("DropTarget must provide required option 'types'!");
      }
      component._drop_target = {
        spec: opts,
        types: opts.types
      };
    }
  },

  // TODO: This should probably not be a component.
  AutoscrollHotspots: class AutoscrollHotspots extends Px.Component {
    // TODO: The div is redundant, ideally components' render method was allowed
    //       to return null or a comment node;
    template() {
      return '<div class="autoscroll-hotspots"></div>';
    }

    constructor(data) {
      super(data);
      this.hotspots = [];
      const createOrDestroyHotspots = active => {
        if (active) {
          this.createHotspots();
        } else {
          this.destroyHotspots();
        }
      };
      this.registerReaction(this.hotspotsActive.bind(this), createOrDestroyHotspots, {
        name: 'Px.Component.DragAndDrop::createOrDestroyHotspotsReaction'
      });
    }

    destroy() {
      this.destroyHotspots();
      super.destroy();
    }

    hotspotsActive() {
      return Px.Component.DragAndDrop.current_drag.type && this.dom_node;
    }

    createHotspots() {
      const scroll_panel = this.dom_node.parentNode;
      const Hotspot = Px.Component.DragAndDrop._AutoscrollHotspot;
      // TODO: In theory, we could also support horizontal scroll.
      const directions = ['up', 'down'];
      directions.forEach(direction => {
        const component = Hotspot.make({direction: direction, scroll_panel: scroll_panel});
        this.hotspots.push(component);
        component.mount('body');
      });
    }

    destroyHotspots() {
      while (this.hotspots.length) {
        this.hotspots.pop().destroy();
      }
    }
  },

  _AutoscrollHotspot: class AutoscrollHotspot extends Px.Component {
    template() {
      return Px.template`
        <div style="${this.styles()}" data-onmouseenter="startScroll" data-onmouseleave="endScroll"></div>
      `;
    }

    constructor(data) {
      super(data);
      this.scroll_animation = null;
    }

    destroy() {
      cancelAnimationFrame(this.scroll_animation);
      super.destroy();
    }

    get dataProperties() {
      return {
        direction: {required: true},
        scroll_panel: {required: true}
      };
    }

    styles() {
      const styles = {
        position: 'fixed',
        left: 0,
        right: 0,
        'z-index': 100000
      };
      const $scroll_panel = $j(this.data.scroll_panel);
      const offset = $scroll_panel.offset();
      const height = $scroll_panel.outerHeight();
      // Have at most 50px high area of the hotspot overlapping the scroll panel.
      const overlap = Math.min(50, height/4.0);

      if (this.data.direction === 'up') {
        styles.top = 0;
        styles.height = (offset.top + overlap) + 'px';
      } else if (this.data.direction === 'down') {
        styles.bottom = 0;
        styles.top = (offset.top + height - overlap) + 'px';
      }
      return _.map(styles, (val, name) => `${name}:${val}`).join('; ');
    }

    // event handlers

    startScroll(evt) {
      cancelAnimationFrame(this.scroll_animation);
      const scrollable_panel = this.data.scroll_panel;
      const sign = this.data.direction === 'down' ? +1 : -1;

      let ts;
      const loop = new_ts => {
        if (!ts) {
          ts = new_ts;
        }
        const diff = sign * (new_ts - ts);
        ts = new_ts;
        scrollable_panel.scrollTop = scrollable_panel.scrollTop + diff;
        this.scroll_animation = requestAnimationFrame(loop);
      };

      this.scroll_animation = requestAnimationFrame(loop);
    }

    endScroll(evt) {
      cancelAnimationFrame(this.scroll_animation);
    }
  },

  DraggedCopy: class DraggedCopy extends Px.Component {
    template() {
      return Px.template`
        <div class="px-no-rerender ${this.data.class_name}" style="${this.styles()}">
          ${ this.renderChild(this.data.component_class, this.data.component_key, this.data.component_data) }
        </div>
      `;
    }

    get dataProperties() {
      return {
        component_class: {required: true},
        component_key: {required: true},
        component_data: {required: true},
        width: {required: true},
        height: {required: true},
        opacity: {std: 1},
        z_index: {std: 999999},
        class_name: {std: ''}
      };
    }

    styles() {
      const current_drag = Px.Component.DragAndDrop.current_drag;
      const origin = current_drag.origin;
      const x = current_drag.x - (origin.coords.x - origin.position.left);
      const y = current_drag.y - (origin.coords.y - origin.position.top);
      const styles = {
        'pointer-events': 'none',
        'z-index': this.data.z_index,
        position: 'absolute',
        width: this.data.width + 'px',
        height: this.data.height + 'px',
        left: x + 'px',
        top: y + 'px'
      };
      if (this.data.opacity < 1) {
        styles.opacity = this.data.opacity;
      }
      return _.map(styles, (val, name) => `${name}:${val}`).join('; ');
    }
  }
};
