Px.LocalFiles.Validation = {

  // Windows does not recognize the mime type of HEIC files, so we need to include the .heic extension
  // in the valid file types form explicitly.
  VALID_FILE_TYPES: [
    'image/jpeg', 'image/jpg', 'image/png', 'image/webp',
    'image/heic', '.heic', 'application/pdf'
  ],
  MAX_FILES_TO_IMPORT: 1000,

  get MAX_FILE_SIZE_MB() {
    return Px.config.large_format ? 150 : 50;
  },

  get MAX_FILE_SIZE() {
    return Px.LocalFiles.Validation.MAX_FILE_SIZE_MB * 1024 * 1024;
  },

  // Filters and validates selected files to only select images,
  // and check that file size is not too large.
  // Returns an array of selected files, or null if validation fails.
  filterAndValidateFiles: function(files, custom_validations) {
    const validation_defaults = {
      valid_file_types: Px.LocalFiles.Validation.VALID_FILE_TYPES,
      max_files_size: Px.LocalFiles.Validation.MAX_FILE_SIZE,
      max_files_to_import: Px.LocalFiles.Validation.MAX_FILES_TO_IMPORT
    };

    validations = Object.assign(validation_defaults, custom_validations || {});

    files = Array.from(files).filter(file => {
      let matches = false;
      if (validations.valid_file_types.includes(file.type)) {
        matches = true;
      }
      // HEIC files have a blank file.type on Windows. Recognise them based on the extension instead.
      if (!file.type && file.name && file.name.match(/\.heic$/i)) {
        matches = true;
      }
      return matches;
    });

    if (files.length === 0) {
      return null;
    }

    const too_large = _.find(files, function(file) {
      return file.size > validations.max_file_size;
    });

    let msg;

    if (too_large) {
      const max_file_size_mb = Math.round(validations.max_file_size / (1024 * 1024));
      msg = Px.t('Some images are too large. Maximum allowed file size is {{size}} MB.');
      alert(msg.replace(/\{\{size\}\}/g, max_file_size_mb));
      return null;
    }

    if (files.length > validations.max_files_to_import) {
      msg = Px.t('Too many images to import. Maximum allowed is {{maximum}}.');
      alert(msg.replace(/\{\{maximum\}\}/g, validations.max_files_to_import));
      return null;
    }

    return files;
  }

};
