Px.Editor.GroupGalleryPanel = class GroupGalleryPanel extends Px.Editor.BaseGalleryPanel {

  get css_class() {
    return 'px-group-gallery-panel';
  }

  static get properties() {
    return Object.assign(super.properties, {
      selected_group_id: {type: 'str', std: null}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      galleryStore: function() {
        return this.data.store.galleries.groups;
      },
      items: function() {
        const state = this.state;
        if (state.selected_gallery_id) {
          return this.galleryStore.galleryImages(state.selected_group_id, state.selected_gallery_id);
        } else if (this.state.selected_group_id) {
          return this.galleryStore.groupGalleries(state.selected_group_id);
        } else {
          return Array.from(this.galleryStore.groups.values());
        }
      },
      isLoading: function() {
        if (this.state.selected_gallery_id) {
          return this.galleryStore.getGallery(this.state.selected_group_id, this.state.selected_gallery_id).loading;
        } else if (this.state.selected_group_id) {
          return this.galleryStore.getGroup(this.state.selected_group_id).loading;
        } else {
          return this.galleryStore.groups_loading;
        }
      },
      canLoadMoreItems: function() {
        return false;
      },
      canGoUpLevel: function() {
        return this.state.selected_group_id !== null;
      }
    });
  }

  // --------------
  // Event handlers
  // --------------

  onGalleryClick(evt) {
    const state = this.state;
    const item_id = evt.currentTarget.getAttribute('data-gallery-id');
    if (state.selected_group_id) {
      state.selected_gallery_id = item_id;
      this.galleryStore.loadGalleryImages(state.selected_group_id, state.selected_gallery_id);
    } else {
      state.selected_group_id = item_id;
      this.galleryStore.loadGroupGalleries(state.selected_group_id);
    }
  }

  upLevel(evt) {
    if (this.state.selected_gallery_id !== null) {
      this.state.selected_gallery_id = null;
    } else if (this.state.selected_group_id) {
      this.state.selected_group_id = null;
    } else if (this.data.onExit) {
      this.data.onExit();
    }
  }

};
