Px.Editor.OptionsPanel = class OptionsPanel extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-tab-content-panel px-options-panel">
        <div class="px-panel-top-section">
          <h1>${Px.t('Product Options:')}</h1>
        </div>

        ${Px.if(this.areOptionsAvailable, () => {
          return Px.template`
            <div class="px-options px-panel-scrollable-section">
              ${this.availableTemplateOptions.map(([option, level]) => this.optionControlTemplate(option, level, 'template_option'))}
              ${this.availableOptions.map(([option, level]) => this.optionControlTemplate(option, level, 'option'))}
            </div>
          `;
        }).else(() => {
          return Px.template`
            <p class="px-no-items-text">
              ${Px.t('No options available for this page.')}
            </p>
          `;
        })}
      </div>
    `;
  }

  optionControlTemplate(option, level, type) {
    const store = this.data.store;
    const r = this.renderChild;
    const unique_id = `${type}-${option.id}`;

    return Px.template`
      <div class="px-option" data-option-code="${option.code}" data-option-level="${level}">
        <h2>${option.name}</h2>
        ${Px.if(option.type === 'multiple_choice', () => {
          return r(Px.Components.Dropdown, `option-select-${unique_id}`, this.dropdownProps(option, type));
        }).elseIf(option.type === 'text', () => {
          return Px.template`
            <input value="${this.optionValue(option, type)}"
                   ${Number.isInteger(option.min_length) ? Px.raw(`minlength="${option.min_length}"`) : ''}
                   ${Number.isInteger(option.max_length) ? Px.raw(`maxlength="${option.max_length}"`) : ''}
                   ${option.pattern ? Px.template`pattern="${option.pattern}"` : ''}
                   ${option.description ? Px.template`title="${option.description}"` : ''}
                   ${option.required ? 'required' : ''}
                   data-option-type="${type}"
                   data-option-id="${option.id}"
                   data-onchange="onTextOptionChange"
                   data-oninput="onTextOptionInput"
            />
          `;
        }).elseIf(option.type === 'color', () => {
          if (option.palette_id) {
            return r(Px.Editor.PaletteColorPicker, `color-pick-${unique_id}`, this.paletteColorPickerProps(option, type));
          } else {
            if (store.ui.editor_mode === 'mobile') {
              return Px.template`
                <input type="color"
                       value="${this.optionValue(option, type)}"
                       data-option-type="${type}"
                       data-option-id="${option.id}"
                       data-onchange="onColorInputChange"
                />
              `;
            } else {
              return r(Px.Components.ColorButton, `color-pick-${unique_id}`, this.colorButtonProps(option, type));
            }
          }
        }).elseIf(option.type === 'font', () => {
          return r(Px.Components.Dropdown, `font-select-${unique_id}`, this.fontDropdownProps(option, type));
        })}
      </div>
    `;
  }

  font_preview_template(font) {
    return Px.template`
      <img class="px-font-dropdown-option"
          alt="${font.name}"
          title="${font.name}"
          src="/print_fonts/${font.id}/preview.svg?size=22&width=200&height=45"
      />
    `;
  }

  constructor(props) {
    super(props);

    this.text_option_save_timeout = null;

    this.registerReaction(() => this.colorPaletteIds(), palette_ids => {
      palette_ids.forEach(palette_id => {
        this.data.store.color_palettes.load(palette_id);
      });
    }, {
      fireImmediately: true,
      name: 'Px.Editor.OptionsPanel::LoadColorPaletteReaction'
    });

    this.registerReaction(() => this.fontPaletteIds(), palette_ids => {
      palette_ids.forEach(palette_id => {
        this.data.store.font_palettes.load(palette_id);
      });
    }, {
      fireImmediately: true,
      name: 'Px.Editor.OptionsPanel::LoadFontPaletteReaction'
    });
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  destroy() {
    clearTimeout(this.text_option_save_timeout);
    super.destroy();
  }

  static get computedProperties() {
    return {
      availableTemplateOptions: function() {
        return this.optionsForDisplay(this.data.store.options.available_template_options, 'template_option');
      },
      availableOptions: function() {
        return this.optionsForDisplay(this.data.store.options.available_options, 'option');
      },
      areOptionsAvailable: function() {
        return this.availableTemplateOptions.length > 0 || this.availableOptions.length > 0;
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  onOptionSelected(option, type, value) {
    this.withUndo('select option', () => {
      this.setOptionValue(option, type, value);
    });
  }

  onTextOptionChange(evt) {
    clearTimeout(this.text_option_save_timeout);

    const store = this.data.store;
    const input = evt.target;
    const is_valid = input.checkValidity();
    if (is_valid) {
      const type = input.getAttribute('data-option-type');
      const id = parseInt(input.getAttribute('data-option-id'), 10);
      const value = input.value;
      let option;
      if (type === 'template_option') {
        option = store.options.getTemplateOption(id);
      } else {
        option = store.options.getOption(id);
      }
      this.withUndo('set text option', () => {
        this.setOptionValue(option, type, value);
      });
    } else {
      // reportValidity() needs to be invoked from a timeout, otherwise the error message doesn't get displayed.
      setTimeout(() => input.reportValidity(), 0);
    }
  }

  onTextOptionInput(evt) {
    clearTimeout(this.text_option_save_timeout);
    this.text_option_save_timeout = setTimeout(() => this.onTextOptionChange(evt), 2500);
  }

  onPaletteColorSelected(option, type, color) {
    this.withUndo('set color option', () => {
      this.setOptionValue(option, type, color);
    });
  }

  openColorPicker(option, type, evt) {
    this.makeModal(Px.Editor.ColorPickerModal, {
      store: this.data.store,
      initial_color: this.optionValue(option),
      onColorSelected: color => {
        this.withUndo('set color option', () => {
          this.setOptionValue(option, type, color);
        });
      }
    });
  }

  onColorInputChange(evt) {
    const store = this.data.store;
    const input = evt.currentTarget;
    const type = input.getAttribute('data-option-type');
    const id = parseInt(input.getAttribute('data-option-id'), 10);
    const value = input.value;
    let option;
    if (type === 'template_option') {
      option = store.options.getTemplateOption(id);
    } else {
      option = store.options.getOption(id);
    }
    this.withUndo('set color option', () => {
      this.setOptionValue(option, type, value);
    });
  }

  onFontSelected(option, type, font_id) {
    this.withUndo('set font option', () => {
      this.setOptionValue(option, type, font_id);
    });
  }

  // -------
  // Private
  // -------

  optionValue(option, type) {
    const store = this.data.store;
    const selections = type === 'template_option' ? store.project.template_options : store.project.options;
    let value = selections.get(option.code, '');
    if (!value) {
      if (option.type === 'color' || option.type === 'font') {
        value = option.placeholder;
      }
    }
    return value;
  }

  setOptionValue(option, type, value) {
    const store = this.data.store;
    if (type === 'template_option') {
      store.setTemplateOption(option, value);
    } else {
      store.setOption(option, value);
    }
  }

  paletteColors(option) {
    let colors = [];
    if (option.palette_id) {
      const palette = this.data.store.color_palettes.get(option.palette_id);
      if (palette) {
        colors = palette.colors.map(function(color) {
          return {
            value: color.value,
            name: color.name || ''
          };
        });
      }
    }
    return colors;
  }

  fontOptions(option) {
    const palette = this.data.store.font_palettes.get(option.palette_id);
    if (!palette) {
      return [];
    }
    return palette.fonts.map(font => {
      return {
        value: font.id,
        name: font.name,
        panel_name: this.font_preview_template(font)
      };
    });
  }

  dropdownProps(option, type) {
    const store = this.data.store;
    const selections = type === 'template_option' ? store.project.template_options : store.project.options;
    const selected_value = selections.get(option.code, '');
    const values = option.values.filter(value => value.published).map(value => {
      return {
        value: value.code,
        name: value.name,
        panel_name: value.image ? Px.template`<img src="${value.image}"/> ${value.name}` : value.name
      };
    });
    if (!option.required || !selected_value) {
      values.unshift({value: '', name: ''});
    }

    return {
      value: selected_value,
      options: values,
      onNewValue: value => this.onOptionSelected(option, type, value)
    }
  }

  paletteColorPickerProps(option, type) {
    return {
      color: this.optionValue(option, type),
      colors: this.paletteColors(option),
      onNewValue: this.onPaletteColorSelected.bind(this, option, type)
    }
  }

  colorButtonProps(option, type) {
    return {
      color: this.optionValue(option, type),
      title: Px.t('Choose color'),
      onClick: this.openColorPicker.bind(this, option, type)
    };
  }

  fontDropdownProps(option, type) {
    const font_options = this.fontOptions(option);
    let value = this.optionValue(option, type);
    const matching_option = _.find(font_options, font_opt => {
      return String(font_opt.value) === String(value);
    });
    // If currently assigned font does not exist in current font palette, set the value to an empty string,
    // so that the font dropdown doesn't display confusing font IDs, but is empty instead.
    if (matching_option) {
      value = matching_option.value;
    } else {
      value = '';
    }
    return {
      value: value,
      options: font_options,
      onNewValue: this.onFontSelected.bind(this, option, type)
    };
  }

  // Filters and reorders available options for display.
  // Child options are only displayed if the trigger value is selected on the parent.
  // Child options should always be rendered immediately after the parent.
  optionsForDisplay(available_options, type) {
    const store = this.data.store;

    // We currently only support multiple_choice and text, color, and font options.
    const supported_option_types = ['multiple_choice', 'text', 'color', 'font'];
    available_options = available_options.filter(option => supported_option_types.includes(option.type));
    available_options = available_options.filter(option => {
      if (store.ui.editor_mode === 'mobile') {
        // In mobile mode, always display all options because options are only available on project view,
        // not on individual pages, unless the options is disabled on all pages of the project.
        return store.project.editor_pages.some(page => page.pageNameMatches(option.page_names));
      }
      if (store.cut_print_mode && !store.selected_set) {
        // On main cut print page, only show options that don't declare explicit page names.
        return option.page_names.length === 0;
      }
      return store.selected_set && store.selected_set.pages.some(page => page.pageNameMatches(option.page_names));
    });

    const selections = type === 'template_option' ? store.project.template_options : store.project.options;

    const gatherOptions = (parent, level) => {
      let selected_value = null;
      const selected_code = parent && selections.get(parent.code);
      if (selected_code && parent.type === 'multiple_choice') {
        selected_value = parent.values.find(val => val.code === selected_code);
      }

      let this_level_options = available_options.filter(option => {
        if (parent === null) {
          // All top level options are elegible.
          return !option.parent_id;
        } else if (option.parent_id === parent.id) {
          // Only triggered child options are elegible.
          return selected_value && selected_value.id === option.trigger_value_id;
        } else {
          return false;
        }
      });

      let options_with_levels = [];
      this_level_options.forEach(option => {
        options_with_levels.push([option, level]);
        options_with_levels = options_with_levels.concat(gatherOptions(option, level + 1));
      });
      return options_with_levels;
    };

    return gatherOptions(null, 0);
  }

  colorPaletteIds() {
    const palette_ids = [];

    this.data.store.options.available_template_options.forEach(option => {
      if (option.type === 'color' && option.palette_id) {
        palette_ids.push(option.palette_id);
      }
    });
    this.data.store.options.available_options.forEach(option => {
      if (option.type === 'color' && option.palette_id) {
        palette_ids.push(option.palette_id);
      }
    });

    return _.uniq(palette_ids);
  }

  fontPaletteIds() {
    const palette_ids = [];

    this.data.store.options.available_template_options.forEach(option => {
      if (option.type === 'font') {
        palette_ids.push(option.palette_id || null);
      }
    });
    this.data.store.options.available_options.forEach(option => {
      if (option.type === 'font') {
        palette_ids.push(option.palette_id || null);
      }
    });

    return _.uniq(palette_ids);
  }

};
