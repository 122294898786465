// Mixin which automatically converts functions declared inside `actions` property
// into propert mobx actions.
Px.DeclarativeActionsMixin = Base => class extends Base {

  constructor() {
    super(...arguments);

    if (this.actions) {
      _.each(this.actions, (fn, name) => {
        if (typeof fn !== 'function') {
          throw new Error(`Action '${name}' is not a function!`);
        }
        this[name] = mobx.action(name, fn.bind(this));
      });
    }
  }

};
