Px.Editor.ThemeLayoutsProjectStore = class ThemeLayoutsProjectStore extends Px.Editor.BaseProjectStore {

  constructor(theme_store, image_store, pdf_store) {
    super();
    this.theme_store = theme_store;
    this.image_store = image_store;
    this.pdf_store = pdf_store;
    this.theme_id = theme_store.theme_id;
    this.name = 'Layouts';

    mobx.reaction(
      () => this.theme_store.unit,
      unit => this.unit = unit,
      {name: 'Px.Editor.ThemeLayoutsProjectStore::themeUnitReaction'}
    )

    mobx.reaction(
      () => this.theme_store.minimum_dpi,
      minimum_dpi => this.minimum_dpi = minimum_dpi,
      {name: 'Px.Editor.ThemeLayoutsProjectStore::themeMinDpiReaction'}
    )
  }

  get actions() {
    return Object.assign(super.actions, {

      load: function() {
        const xhr = $j.getJSON(this.apiURL());
        xhr.done(data => {
          mobx.runInAction(() => {
            data.forEach(layout => {
              layout.images.forEach(image => {
                const img_id = `db:${image.id}`;
                if (!this.image_store.get(img_id)) {
                  this.image_store.register(img_id, image);
                }
              });
              layout.pdfs.forEach(pdf => {
                const pdf_id = `db:${pdf.id}`;
                if (!this.pdf_store.get(pdf_id)) {
                  this.pdf_store.register(pdf_id, pdf);
                }
              });
            });
            mobx.when(() => this.theme_store.loaded, () => {
              this.loaded = true;
              this.setLayoutAndStoreSavedSnapshot(this.makeLayoutJSON(data));
            });
          });
        });
        return new Promise((resolve, reject) => {
          xhr.done(resolve).fail(jxhr => {
            this.loaded = true;
            if (jxhr.status === 403) {
              let default_text = "You don't have permission to open this project.\n";
              default_text += "Perhaps you forgot to log in?";
              reject(Px.t('project permission error', default_text));
            } else {
              let default_text = "An error occured while loading the project.\n";
              default_text += "You can try reloading the page.\n";
              default_text += "If the problem persists, please contact support.";
              reject(Px.t('project load error', default_text));
            }
          });
        });
      },

      save: function() {
        // Refuse to save if the project contains any local images that haven't finished uploading yet.
        if (this.local_images.length) {
          alert(Px.t('Cannot save: some images are still uploading. Try again later.'));
          return Promise.reject(new Error('Images still uploading'));
        }
        this.saving = true;
        const pages = {};
        const promise = fetch(this.apiURL(), {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: $j.param({book: this.data_for_save_endpoint})
        }).then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`HTTP Error; Status: ${response.status}`);
          }
        }).then(json => {
          // TODO: Be more inteligent; don't replace the entire thing.
          mobx.runInAction(() => {
            this.setLayoutAndStoreSavedSnapshot(this.makeLayoutJSON(json));
            this.saving = false;
          });
        }).catch(() => {
          this.saving = false;
        });
        return promise;
      }

    });
  }

  apiURL() {
    return `/v1/themes/${this.theme_id}/layouts.json?include_inherited=false`;
  }

  availableBleedValuesForPage(page) {
    const available_bleeds = [];
    this.theme_store.set_definitions.forEach(set_def => {
      set_def.pages.forEach(page_def => {
        if (this.pageDefinitionApplicableToLayoutPage(page_def, page)) {
          available_bleeds.push(page_def.bleed);
        }
      });
    });
    return _.uniq(available_bleeds).sort();
  }

  availableMarginValuesForPage(page) {
    const available_margins = [];
    this.theme_store.set_definitions.forEach(set_def => {
      set_def.pages.forEach(page_def => {
        if (this.pageDefinitionApplicableToLayoutPage(page_def, page)) {
          available_margins.push(page_def.margin);
        }
      });
    });
    return _.uniq(available_margins).sort();
  }

  // -------
  // Private
  // -------

  pageDefinitionApplicableToLayoutPage(page_def, page) {
    if (!this.pageNameMatches(page_def.template_names, page)) {
      return false;
    }
    const tolerance = Px.Editor.LayoutModel.SIZE_TOLERANCE;
    const width_percent = page.width * tolerance;
    const height_percent = page.height * tolerance;
    const in_width_tolerance = (page_def.width >= page.width - width_percent) &&
                               (page_def.width <= page.width + width_percent);
    const in_height_tolerance = (page_def.height >= page.height - height_percent) &&
                                (page_def.height <= page.height + height_percent);
    return in_width_tolerance && in_height_tolerance;
  }

  // The `page` parameter is not always a proper PageModel object.
  // When invoked from makeLayoutJSON below, it's just a JSON dict,
  // which is why we cannot call `page.pageNameMatches(...)` directly.
  pageNameMatches(template_names, page) {
    return Px.Editor.PageModel.prototype.pageNameMatches.call({name: page.name}, template_names);
  }

  makeLayoutJSON(response_data) {
    return response_data.map(layout => {
      const page_node = Px.Util.parseXML(layout.data).firstChild;
      const stub_page = {
        name: layout.name,
        width: parseFloat(page_node.getAttribute('width')) || 0,
        height: parseFloat(page_node.getAttribute('height')) || 0
      };
      const bleed_values = this.availableBleedValuesForPage(stub_page);
      const margin_values = this.availableMarginValuesForPage(stub_page);
      layout._virtual_bleed = bleed_values[0] || 0;
      layout._virtual_margin = margin_values[0] || 0;
      return {
        print_page: [layout],
        center_caption: layout.name,
        editor: true
      };
    });
  }

};
