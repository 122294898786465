Px.Editor.BaseThemeGalleryStore = class BaseThemeGalleryStore extends Px.BaseStore {

  constructor(theme_id, image_store) {
    super();
    this.theme_id = theme_id;
    this.image_store = image_store;
  }

  static get properties() {
    return {
      loaded: {std: false},
      tagged_images: {std: mobx.observable.array()}
    };
  }

  static get computedProperties() {
    return {
      images: function() {
        return this.tagged_images.map(image => image.id);
      }
    };
  }

  get actions() {
    return {
      load: function() {
        const xhr = $j.getJSON(`/v1/themes/${this.theme_id}/${this.constructor.GALLERY_ID}.json`);
        return xhr.done(data => {
          mobx.runInAction(() => {
            data.forEach(image => {
              const img_id = `db:${image.id}`;
              if (!this.image_store.get(img_id)) {
                this.image_store.register(img_id, image);
              }
              this.tagged_images.push({
                id: img_id,
                tags: image.design_tags || []
              });
            });
            this.loaded = true;
          });
        });
      }
    };
  }

};

// This needs to be set in the subclass!
Px.Editor.BaseThemeGalleryStore.GALLERY_ID = null;
