Px.Editor.GroupElement = class GroupElement extends Px.Editor.BaseElementComponent {

  template() {
    return Px.template`
      <g class="px-group-element" transform="${this.transformAttribute}" data-selected="${this.isSelected}">
        ${this.data.element.elements.map(element => {
          return this.renderChild(this.componentType(element), element.unique_id, this.elementProps(element));
        })}
        ${Px.if(this.data.render_controls, () => {
          return Px.template`
            ${this.selection_outline_template()}
            ${this.data.preview_mode ? '' : this.renderEditControls()}
          `;
        })}
      </g>
    `;
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      pointerEventsAttribute: function() {
        return 'none';
      }
    });
  }

  componentType(element) {
    return Px.Editor.BaseElementComponent.getClass(element.type);
  }

  elementProps(element) {
    return {
      element: element,
      scale: this.data.scale,
      preview_mode: this.data.preview_mode,
      mobile_mode: this.data.mobile_mode,
      render_content: this.data.render_content,
      render_controls: this.data.render_controls,
      page_stack: this.data.page_stack
    };
  }

};
