Px.Editor.ColorPickerModal = class ColorPickerModal extends Px.Components.BaseModal {

  get content() {
    if (this.data.palette_id) {
      return this.renderChild(Px.Editor.PaletteColorPicker, 'palette-color-picker', this.paletteColorPickerProps);
    } else {
      return Px.template`
        ${this.renderChild(Px.Components.ColorPicker, 'color-picker', this.colorPickerProps)}
        ${Px.if(this.suggestedColors.length > 0, () => {
          return Px.template`
            <h3>${Px.t('Suggested Colors')}</h3>
            <div class="px-suggested-colors">
              ${this.suggestedColors.map(color => {
                const display_name = this.colorDisplayName(color);
                return Px.template`
                  <div class="px-color"
                       data-onclick="selectSuggestedColor"
                       data-color="${color}"
                       data-selected="${color === this.state.color}"
                       data-px-tooltip="${display_name}"
                       data-color-scheme="${Px.Util.isColorDark(color) ? 'dark': 'light'}">
                    <div class="px-thumbnail">
                      <img class="px-background-img"
                           src="${this.backgroundColorDataUri(color)}"
                      />
                    </div>
                    <div class="px-label">
                      ${display_name}
                    </div>
                  </div>
                `;
              })}
            </div>
          `;
        })}
      `;
    }
  }

  get footer() {
    return Px.template`
      <button class="px-cancel" data-onclick="destroy">
        ${Px.t('Cancel')}
      </button>
      <button class="px-ok" data-onclick="saveColor">
        ${this.data.button_text}
      </button>
    `;
  }

  get css_class() {
    return `${super.css_class} px-color-picker-modal`;
  }

  constructor(props) {
    super(props);
    this.close_on_background_click = false;

    this.onNewValue = this.onNewValue.bind(this);
    if (this.data.palette_id) {
      this.data.store.color_palettes.load(this.data.palette_id);
    }
    if (this.data.initial_color) {
      this.state.color = this.data.initial_color;
    }
  }

  get dataProperties() {
    return {
      store: {required: true},
      initial_color: {std: null},
      button_text: {std: Px.t('OK')},
      palette_id: {std: null},
      usage_context: {std: null},
      color_type_switch: {std: false},
      onColorSelected: {required: true}
    };
  }

  static get properties() {
    return {
      color: {type: 'str', std: '#888888'}
    };
  }

  static get computedProperties() {
    return {
      colorPickerProps: function() {
        return {
          color: this.state.color,
          color_type_switch: this.data.color_type_switch,
          onNewValue: this.onNewValue
        };
      },
      paletteColorPickerProps: function() {
        let colors = [];
        const palette = this.data.store.color_palettes.get(this.data.palette_id);
        if (palette) {
          colors = palette.colors.map(function(color) {
            return {
              value: color.value,
              name: color.name || ''
            };
          });
        }
        return {
          color: this.state.color,
          colors: colors,
          onNewValue: this.onNewValue
        };
      },
      suggestedColors: function() {
        // Suggests colors that are already used in the project.
        const store = this.data.store;
        const pages = store.project.pages;
        const background_colors = {};
        const text_colors = {};
        const image_border_colors = {};
        const ipage_shadow_colors = {};
        // Collect all colors used in project pages.
        const page_weight = 2;
        store.project.pages.forEach(page => {
          if (page.bgcolor) {
            if (!background_colors[page.bgcolor]) {
              background_colors[page.bgcolor] = 0;
            }
            background_colors[page.bgcolor] += page_weight;
          }
          page.text_elements.forEach(text => {
            if (!text_colors[text.color]) {
              text_colors[text.color] = 0;
            }
            text_colors[text.color] += page_weight;
          });
          page.image_elements.forEach(image => {
            if (image.border) {
              if (!image_border_colors[image.bordercolor]) {
                image_border_colors[image.bordercolor] = 0;
              }
              image_border_colors[image.bordercolor] += page_weight;
            }
          });
          page.inline_page_elements.forEach(ipage => {
            if (!ipage_shadow_colors[ipage.shadow_color]) {
              ipage_shadow_colors[ipage.shadow_color] = 0;
            }
            ipage_shadow_colors[ipage.shadow_color] += page_weight;
          });
        });
        // Collect all colors used in layouts.
        const layout_weight = 1;
        store.layouts.layouts.forEach(layout => {
          layout.text_elements.forEach(text => {
            if (!text_colors[text.color]) {
              text_colors[text.color] = 0;
            }
            text_colors[text.color] += layout_weight;
          });
          layout.image_elements.forEach(image => {
            if (image.border) {
              if (!image_border_colors[image.bordercolor]) {
                image_border_colors[image.bordercolor] = 0;
              }
              image_border_colors[image.bordercolor] += layout_weight;
            }
          });
          layout.inline_page_elements.forEach(ipage => {
            if (!ipage_shadow_colors[ipage.shadow_color]) {
              ipage_shadow_colors[ipage.shadow_color] = 0;
            }
            ipage_shadow_colors[ipage.shadow_color] += layout_weight;
          });
        });
        const sortByWeight = (colors) => {
          return _.sortBy(Object.entries(colors), entry => -parseInt(entry[1], 10)).map(entry => entry[0]);
        };
        const colors = {
          texts: sortByWeight(text_colors),
          image_borders: sortByWeight(image_border_colors),
          backgrounds: sortByWeight(background_colors),
          inline_page_shadows: sortByWeight(ipage_shadow_colors)
        };
        const default_order = ['texts', 'image_borders', 'backgrounds', 'inline_page_shadows'];
        let order = default_order;
        const usage_context = this.data.usage_context;
        if (usage_context) {
          order = [usage_context];
          for (const ctx of default_order) {
            if (ctx !== usage_context) {
              order.push(ctx);
            }
          }
        }
        let suggested_colors = [];
        for (const key of order) {
          suggested_colors = suggested_colors.concat(colors[key]);
        }
        const selected_color_type = this.colorType(this.state.color);
        const matching_color_types = suggested_colors.filter(color => this.colorType(color) === selected_color_type);
        return _.uniq(matching_color_types).filter(color => color !== this.data.initial_color);
      }
    };
  }

  colorType(color) {
    if (color.slice(0, 4) === 'cmyk') {
      return 'CMYK';
    } else {
      return 'RGB';
    }
  }

  onNewValue(color) {
    this.state.color = color;
  }

  // --------------
  // Event handlers
  // --------------

  saveColor(evt) {
    this.data.onColorSelected(this.state.color);
    this.destroy();
  }

  selectSuggestedColor(evt) {
    const color = evt.currentTarget.getAttribute('data-color');
    this.state.color = color;
  }

  // -------
  // Private
  // -------

  colorDisplayName(color_str) {
    if (color_str.slice(0, 4) === 'cmyk') {
      const cmyk = Px.Util.parseCmykString(color_str);
      return `CMYK(${cmyk.c}, ${cmyk.m}, ${cmyk.y}, cmyk.k)`;
    } else {
      return color_str;
    }
  }

  backgroundColorDataUri(color) {
    const fill = Px.Util.colorForDisplay(color).replace('#', '%23');
    const rect = `<rect x="0" y="0" width="100%" height="100%" fill="${fill}"></rect>`;
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 4 4">${rect}</svg>`;
    return `data:image/svg+xml,${svg}`;
  }

};
