Px.LocalFiles.Uploader = class Uploader extends Px.Base {

  constructor(upload_path, opts) {
    opts = opts || {};
    super();
    this.files = mobx.observable.array();
    this.upload_path = upload_path;
    this.upload_method = opts.upload_method || 'post';
    this.upload_param = opts.upload_param || 'data';
    this.custom_headers = opts.custom_headers || {};

    this.logger = {
      debug: function(item, msg) {
        console.debug(`[${item.file.name}] ${msg}`);
      },
      warn: function(item, msg) {
        console.warn(`[${item.file.name}] ${msg}`);
      }
    };
  }

  // `file` should be a Px.LocalFiles.LocalFile instances.
  uploadFile(local_file) {
    this.files.push(local_file);
    this.uploadNext();
  }

  // -------
  // Private
  // -------

  uploadQueue() {
    const queued = this.files.filter(file => file.upload_queued);
    const sorted = _.sortBy(queued, file => {
      // Order by priority first; order files with same priority by nr of retries.
      return -(file.upload_priority + (file.upload_tries/1000.0));
    });
    return sorted;
  }

  currentUploads() {
    return this.files.filter(file => file.upload_in_progress && file.upload_progress_percent < 100);
  }

  // If there are less than CONCURRENCY upload loops running, starts a new upload loop.
  // Otherwise doesn't do anything.
  uploadNext() {
    if (this.currentUploads().length >= Uploader.CONCURRENCY) {
      return;
    }

    const item = this.uploadQueue()[0];
    if (item) {
      item.onUploadStarted();
      this.readFile(item, (err, blob) => {
        if (!err) {
          this.ajaxUpload(item, blob);
        } else {
          this.onUploadError(item, Px.t('Failed reading image from disk'));
        }
      });
    }
  }

  readFile(item, callback) {
    if (item.is_resizable_image) {
      const opts = {
        size: Px.config.upload_size,
        imageRendering: 'optimizeQuality'
      };
      Px.LocalFiles.reader.readImage(item.file, opts, blob => {
        if (blob) {
          callback(null, blob);
        } else  {
          callback(new Error(`Failed to read image ${item.file.name}`));
        }
      });
    } else {
      // Use the original file.
      callback(null, item.file);
    }
  }

  ajaxUpload(item, blob) {
    // NOTE: Cannot use fetch() because it doesn't support upload progress events yet.
    const xhr = new XMLHttpRequest();
    const file = item.file;
    let stopped = false;
    let timeout;

    this.logger.debug(item, 'File upload starting');

    const scheduleTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (!stopped) {
          stopped = true;
          try { xhr.abort(); } catch (e) {}
          this.onUploadError(item, Px.t('Upload request timed out'));
        }
      }, Uploader.TIMEOUT);
    };

    // Start the timeout.
    scheduleTimeout();

    xhr.upload.onprogress = evt => {
      this.onUploadProgress(item, evt);
      scheduleTimeout(); // reschedule the timeout.
    };

    xhr.onreadystatechange = () => {
      let status;

      if (xhr.readyState === 4 && !stopped) {
        stopped = true;
        clearTimeout(timeout);

        // Getting the HTTP status might fail on some Gecko versions.
        try {
          status = xhr.status;
        } catch (e) {
          status = 0;
        }

        let json;
        try {
          json = JSON.parse(xhr.responseText);
        } catch (e) {}


        if (status >= 400) {
          this.onUploadError(item, this.userFriendlyUploadError(status, xhr.responseText, json));
        } else {
          if (json) {
            this.onUploadSuccess(item, json);
          } else {
            let errmsg = (xhr.responseText || '');
            if (errmsg.length > 200) {
              errmsg = errmsg.substring(0, 200) + '...';
            }
            this.onUploadError(item, Px.t('Invalid JSON') + '\n' + errmsg);
          }
        }
      }
    };

    xhr.onabort = () => {
      if (!stopped) {
        stopped = true;
        clearTimeout(timeout);
        this.onUploadError(item, Px.t('Upload request aborted'));
      }
    };

    xhr.open(this.upload_method, this.upload_path, true);

    _.each(this.custom_headers, function(val, name) {
      xhr.setRequestHeader(name, val);
    });

    const code = Px.Util.guid();
    const form_data = new FormData();
    form_data.append('code', code);
    form_data.append(this.upload_param, blob);

    xhr.send(form_data);
  }

  onUploadSuccess(item, response) {
    item.onUploadSuccess(response);
    this.logger.debug(item, 'Upload finished successfully');
    this.uploadNext();
  }

  userFriendlyUploadError(http_status, response_text, json) {
    if (response_text && response_text.length > 200) {
      response_text = response_text.substring(0, 200) + '...';
    }

    let errmsg;
    if (json && json.error) {
      switch (json.error) {
      case 'Image too large':
        errmsg = Px.t('Image is too large.');
        break;
      case 'Zero-length blob not permitted':
      case 'Image is entirely black':
        errmsg = Px.t('Unable to source image. Please save the file to a gallery in order to add it to your project.');
        break;
      default:
        errmsg = json.error;
      }
    }

    if (!errmsg) {
      errmsg = `HTTP Error ${http_status}.`;
      if (response_text) {
        errmsg += '\n' + response_text;
      }
    }

    return errmsg;
  }

  onUploadError(item, errmsg) {
    // Should the upload be rescheduled?
    const reschedule = item.upload_tries < Uploader.MAX_TRIES;
    if (reschedule) {
      item.queueForUpload();
    } else {
      item.onUploadError(errmsg);
    }

    this.logger.warn(item, `Upload Failed! (try ${item.upload_tries} of ${Uploader.MAX_TRIES}) ${errmsg}`);
    this.uploadNext();
  }

  onUploadProgress(item, evt) {
    if (evt.lengthComputable) {
      const percent = Math.round((evt.loaded / evt.total) * 100);
      this.logger.debug(item, `Uploaded ${percent}%`);
      item.onUploadProgress(percent);
      // If upload is at 100%, we can start the next upload without waiting for the answer from the server,
      // because that usually takes at least a couple hundred miliseconds.
      if (percent === 100) {
        this.uploadNext();
      }
    }
  }

};
// Max nr of files being uploaded concurrently.
Px.LocalFiles.Uploader.CONCURRENCY = 3;
// Number of times a failed uplaod may be retried.
Px.LocalFiles.Uploader.MAX_TRIES = 3;
// Max nr of ms between two upload progress events before assuming the request is dead.
Px.LocalFiles.Uploader.TIMEOUT = 180000;
