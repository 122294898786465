// TODO: Support text on a path..
Px.Editor.TextElement = class TextElement extends Px.Editor.BaseElementComponent {

  template() {
    const element = this.data.element;
    const r = this.renderChild;

    return Px.template`
      <g class="px-text-element"
         pointer-events="${this.pointerEventsAttribute}"
         data-element-id="${element.unique_id}"
         data-selected="${this.isSelected}">

        ${Px.if(this.textModelData && this.data.render_content, () => {
          return this.textModelData.text_chunks.map(chunk => {
            return Px.template`
              <text x="${element.x + chunk.x}"
                    y="${element.y + chunk.y}"
                    font-size="${chunk.font_size}"
                    opacity="${this.opacityAttribute}"
                    fill="${Px.Util.colorForDisplay(element.color)}"
                    transform="${this.textTransformAttribute}"
                    font-family="${this.textModelData.font.name},'Adobe NotDef'"
                    pointer-events="none"
                    style="white-space:pre;">${
                /* omitting whitespace between tags and text content is important because we use white-space: pre.*/
                Array.from(this.replaceMissingGlyphs(chunk.text)).map((character, idx) => {
                  const x = element.x + chunk.x + chunk.offsets[idx];
                  const y = element.y + chunk.y;
                  return Px.template`<tspan x="${x}" y="${y}">${character}</tspan>`;
                })
              }</text>
            `;
          });
        })}

        <g transform="${this.transformAttribute}">
          ${Px.if(this.data.render_content && this.isLoading && (this.data.mobile_mode || !this.data.preview_mode), () => {
            return r(Px.Editor.ElementIcon, 'loading-indicator', this.loadingIndicatorProps);
          })}
          ${Px.if(this.data.render_controls, () => {
            return Px.template`
              ${this.selection_outline_template()}
              ${this.data.preview_mode ? '' : this.renderEditControls()}
            `;
          })}

          ${Px.if(this.data.render_content || (this.data.render_controls && this.isSelected), () => {
            return Px.template`
              ${Px.if(this.isOverflown, () => {
                return r(Px.Editor.ElementIcon, 'overflow-warning', this.overflowIconProps);
              })}
              ${Px.if(this.areGlyphsMissing, () => {
                return r(Px.Editor.ElementIcon, 'glyphs-missing-warning', this.glyphsMissingIconProps);
              })}
            `;
          })}
        </g>

        ${Px.if(this.data.render_content && !this.data.preview_mode, () => {;
          return this.renderBleedWarning();
        })}
      </g>
    `;
  }

  constructor(props) {
    super(props);

    this._cached_model_data = null;
    this._dbltap_start = 0;

    this.grabElementWithTouch = this.grabElementWithTouch.bind(this);

    // Invoke preventDefault on touchstart to prevent double-tap-top-zoom behaviour on iOS, since we implement
    // custom double tap handler for text elements.
    // We do invoke preventDefault in the data-ontouchstart handler, but that uses event bubbling and at the point
    // that handler runs it's already too late to prevent double-tap-to-zoom.
    if (!this.data.mobile_mode) {
      this.on('mount', () => {
        $j(this.dom_node).bind('touchstart', evt => evt.preventDefault());
      });
    }

    // Whenever the query string changes (and refresh is not suppressed),
    // register the new query with the text store.
    this.registerReaction(() => {
      return !this.data.element.is_refresh_suppressed && !this.textModel && this.jsonQuery;
    }, qstring => {
      if (qstring) {
        this._cached_model_data = null;
        this.data.store.texts.register(qstring);
      }
    }, {
      fireImmediately: true,
      name: 'Px.Editor.TextElement::RegisterTextReaction'
    });

    this.registerReaction(() => {
      return this.isSelected && this.isOverflown && !this.data.preview_mode;
    }, show_warning => {
      if (show_warning) {
        const msg = Px.t("Not all your text is visible in the text box, " +
                         "try increasing the size of the text box or reducing the text size.");
        this.data.store.showNotification(msg, 'warning');
      }
    }, {
      name: 'Px.Editor.TextElement::overflowWarningReaction'
    });

    this.registerReaction(() => {
      return this.isSelected && this.areGlyphsMissing && !this.data.preview_mode;
    }, show_warning => {
      if (show_warning) {
        this.data.store.showNotification(this.glyphsMissingWarningMessage, 'warning');
      }
    }, {
      name: 'Px.Editor.TextElement::missingGlyphsWarningReaction'
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      jsonQuery: function() {
        return this.data.element.json_query;
      },
      textModel: function() {
        return this.data.store.texts.get(this.jsonQuery);
      },
      textModelData: function() {
        const model = this.textModel;
        if (model && model.loaded) {
          // Cache the current model data, so we can use the cached value while resizing.
          this._cached_model_data = mobx.toJS(model.data);
          return model.data;
        } else if (this._cached_model_data !== null) {
          return this._cached_model_data;
        } else {
          return null;
        }
      },
      isLoading: function() {
        return this.textModelData === null;
      },
      isOverflown: function() {
        const model = this.textModel;
        return model && model.loaded && model.data.overflown;
      },
      missingGlyphs: function() {
        const model = this.textModel;
        return model && model.loaded && model.data.font.missing_glyphs || [];
      },
      areGlyphsMissing: function() {
        return this.missingGlyphs.length > 0;
      },
      glyphsMissingWarningMessage: function() {
        const msg = Px.t(
          'glyphs missing warning',
          'Some characters could not be displayed using the selected font, ' +
            'try using a different font: "{{characters}}".'
        );
        const characters = this.missingGlyphs.join(', ');
        return msg.replace('{{characters}}', characters);
      },
      opacityAttribute: function() {
        let opacity = this.data.element.opacity;
        if (this.data.element.is_refresh_suppressed) {
          opacity *= 0.35;
        }
        return opacity;
      },
      textTransformAttribute: function() {
        const element = this.data.element;
        const x = element.x;
        const y = element.y;
        const w = element.width;
        const h = element.height;
        const rot = element.rotation;
        return `rotate(${rot},${x + w/2},${y + h/2})`;
      },
      pointerEventsAttribute: function() {
        const store = this.data.store;
        const element = this.data.element;
        if (this.data.mobile_mode && element.edit && element.is_selected && store.mobile.view_mode === 'edit') {
          return 'auto';
        }
        if (this.data.preview_mode) {
          return 'none';
        }
        if (Px.config.advanced_edit_mode) {
          return 'auto';
        }
        return this.data.element.edit ? 'auto' : 'none';
      },
      pointerEventHandlers: function() {
        let handlers = '';
        if (this.data.mobile_mode) {
          const store = this.data.store;
          const element = this.data.element;
          if (element.edit && element.page && element.page.set === store.selected_set) {
            handlers = 'data-onclick="selectElement"';
          }
        } else {
          handlers = [
            'data-onmousedown="grabElement"',
            'data-ontouchstart="grabElementWithTouch"',
            'data-ondblclick="onDoubleClick"',
            'data-onmouseenter="hoverElement"',
            'data-onmouseleave="unhoverElement"'
          ].join(' ');
        }
        return Px.raw(handlers);
      },
      overflowIconProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: TextElement.icons.overflow_warning,
          title: Px.t('text cropping warning', 'Text is too large to fit in the box.'),
          position: 'top left',
          width: 30 * this.iconScale,
          height: 30 * this.iconScale
        };
      },
      glyphsMissingIconProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: TextElement.icons.glyphs_missing_warning,
          title: this.glyphsMissingWarningMessage,
          position: 'top left',
          width: 30 * this.iconScale,
          height: 30 * this.iconScale
        };
      },
      loadingIndicatorProps: function() {
        return {
          store: this.data.store,
          element: this.data.element,
          scale: this.data.scale,
          icon: TextElement.icons.loading_indicator,
          title: Px.t('Loading...'),
          position: 'center',
          width: 40 * this.iconScale,
          height: 20 * this.iconScale
        };
      }
    });
  }

  // -------
  // Private
  // -------

  replaceMissingGlyphs(text) {
    this.missingGlyphs.forEach(character => {
      // Replace missing glyphs with the unicode replacement (question mark in a box) character.
      // This is because some characters such as tabs will not fall back to the Adobe NotDef font
      // because browsers try to some kind of a "best guess" render (each browser seems to do
      // something different).
      text = text.replace(character, '\uFFFD');
    });
    return text;
  }

  // --------------
  // Event handlers
  // --------------

  grabElementWithTouch(evt) {
    this.grabElement(evt);
    const now = Date.now();
    if (now - this._dbltap_start < 500) {
      this.onDoubleClick(evt);
      this._dbltap_start = 0;
    } else {
      this._dbltap_start = now;
    }
  }

  onDoubleClick(evt) {
    this.makeModal(Px.Editor.TextEditModal, {
      store: this.data.store,
      text_model: this.data.element
    });
  }

};

Px.Editor.TextElement.icons = {
  overflow_warning: '<svg width="20" height="20" viewBox="0 0 20 20"><polygon points="10,1 19,19 1,19" fill="#ffffff" stroke-width="1.5" stroke="#ffc82c" stroke-linejoin="round" /><line x1="10" y1="7" x2="10" y2="13" stroke-width="1.5" stroke="#ffc82c" stroke-linecap="round" /><line x1="10" y1="16" x2="10" y2="16" stroke-width="1.5" stroke="#ffc82c" stroke-linecap="round" /></svg>',
  glyphs_missing_warning: '<svg width="20" height="20" viewBox="0 0 20 20"><polygon points="10,1 19,19 1,19" fill="#ffffff" stroke-width="1.5" stroke="#ffc82c" stroke-linejoin="round" /><line x1="10" y1="7" x2="10" y2="13" stroke-width="1.5" stroke="#ffc82c" stroke-linecap="round" /><line x1="10" y1="16" x2="10" y2="16" stroke-width="1.5" stroke="#ffc82c" stroke-linecap="round" /></svg>',
  loading_indicator: '<svg width="6" height="2" viewBox="0 0 6 2"><circle cx="3" cy="1" r="0.5" fill="%23000000" opacity="0.5"></circle><circle cx="1" cy="1" r="0.5" fill="%23000000" opacity="0.5"></circle><circle cx="5" cy="1" r="0.5" fill="%23000000" opacity="0.5"></circle></svg>'
};
