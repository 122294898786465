import * as THREE from '../../lib/three.module-r142.js';
import {GLTFLoader} from '../../lib/GLTFLoader.js';

Px.Components.MappedPreview = class MappedPreview extends Px.Component {
  template() {
    return Px.template`
      <div class="px-mapped-preview" style="width:${this.availableWidth}px; height:${this.availableHeight}px">
        <canvas></canvas>
      </div>
    `;
  }

  get dataProperties() {
    return {
      bg_url: {required: true},
      glb_url: {std: null},
      preview_sections: {std: {}},  // dict of section_name => image_url key-value pairs
      width: {std: null},
      height: {std: null},
      size: {std: 100},
      onSetUp: {std: null},
      onBeforeRender: {std: null},
      onRender: {std: null}
    };
  }

  static get properties() {
    return {
      bg_image_width: {std: 0, type: 'float'},
      bg_image_height: {std: 0, type: 'float'},
      glb_loaded: {std: false, type: 'bool'},
      // -1 means loading hasn't started yet and we don't know how many sections we have.
      textures_loading: {std: -1, type: 'int'},
      rendered_with_assets_loaded: {std: false, type: 'bool'}
    };
  }

  constructor(props) {
    super(props);
    this._rerender_scheduled = false;
    this._to_blob_queue = [];
    this.renderer = null;
    this.camera = null;
    this.scene = null;
    this.background_sprite = null;

    this.render3d = this.render3d.bind(this);

    // Preload preview section images so that we don't have to wait for the GLB to get loaded first.
    // If the GLB doesn't actually use all of the preview sections this is a bit wasteful, but since
    // in most cases the majority of defined preview sections does get used, it is worth doing this.
    this.registerReaction(() => Object.values(this.data.preview_sections), urls => {
      urls.forEach(url => {
        const image = new Image();
        image.src = url;
      });
    }, {
      fireImmediately: true,
      name: 'Px.Component.MappedPreview::preloadPreviewSections'
    });

    // If the background image or the GLB path change, we have to redraw everything.
    this.registerReaction(() => this.data.glb_url, glb_url => {
      this.state.glb_loaded = false;
      this.state.rendered_with_assets_loaded = false;
      if (this.renderer) {
        this.renderer.dispose();
        this.setUp3d();
      }
    }, {
      name: 'Px.Components.MappedPreview::redrawOnGLBChange'
    });

    this.registerReaction(() => this.data.bg_url, () => {
      if (this.renderer) {
        this.renderer.dispose();
        this.setUp3d();
      }
      this.state.bg_image_width = 0;
      this.state.bg_image_height = 0;
      this.state.rendered_with_assets_loaded = false;
      const image = new Image();
      image.onload = () => {
        mobx.runInAction(() => {
          this.state.bg_image_width = image.naturalWidth;
          this.state.bg_image_height = image.naturalHeight;
        });
      };
      image.src = this.data.bg_url;
    }, {
      fireImmediately: true,
      name: 'Px.Components.MappedPreview::redrawOnBackground'
    });

    this.registerReaction(() => [this.canvasWidth, this.canvasHeight], this.render3d, {
      name: 'Px.Components.MappedPreview::redrawOnCanvasResize'
    });

    this.on('mount', () => this.setUp3d());
    this.on('update', this.render3d);
  }

  static get computedProperties() {
    return {
      loaded: function() {
        return this.assetsLoaded && this.state.rendered_with_assets_loaded;
      },
      assetsLoaded: function() {
        return this.state.glb_loaded && this.bgImageLoaded && this.texturesLoaded;
      },
      texturesLoaded: function() {
        return this.state.textures_loading === 0;
      },
      bgImageLoaded: function() {
        return this.state.bg_image_width !== 0 && this.state.bg_image_height !== 0;
      },
      availableWidth: function() {
        if (this.data.width) {
          return this.data.width;
        } else {
          return this.availableHeight * this.aspectRatio;
        }
      },
      availableHeight: function() {
        if (this.data.height) {
          return this.data.height;
        } else if (this.data.width) {
          return this.data.width / this.aspectRatio;
        } else {
          if (this.aspectRatio < 1) {
            return this.data.size;
          } else {
            return this.data.size / this.aspectRatio;
          }
        }
      },
      canvasScale: function() {
        if (!this.bgImageLoaded) {
          return 0;
        }
        let scale = this.availableWidth / this.state.bg_image_width;
        if (scale * this.state.bg_image_height > this.availableHeight) {
          scale = this.availableHeight / this.state.bg_image_height;
        }
        return scale;
      },
      canvasWidth: function() {
        return this.canvasScale * this.state.bg_image_width;
      },
      canvasHeight: function() {
        return this.canvasScale * this.state.bg_image_height;
      },
      aspectRatio: function() {
        if (!this.bgImageLoaded) {
          return 1;
        }
        return this.state.bg_image_width / this.state.bg_image_height;
      }
    };
  }

  destroy() {
    clearTimeout(this._render3d_timeout);
    super.destroy();
  }

  render3d() {
    if (!this.renderer || !this.camera) {
      return;
    }

    if (this._rerender_scheduled) {
      return;
    }

    this._rerender_scheduled = true;

    this._render3d_timeout = setTimeout(() => {
      if (this.data.onBeforeRender) {
        this.data.onBeforeRender();
      }

      this.camera.aspect = this.aspectRatio;
      this.camera.updateProjectionMatrix();

      const y_scale = this.viewportHeightAt(this.camera.far);
      const x_scale = y_scale * this.aspectRatio;
      this.background_sprite.scale.set(x_scale, y_scale, 1);
      const vec3 = new THREE.Vector3();
      const camera_vector = this.camera.getWorldDirection(vec3);
      const distance_from_camera = this.camera.far;
      const sprite_position = camera_vector.multiplyScalar(distance_from_camera);
      this.background_sprite.position.x = sprite_position.x;
      this.background_sprite.position.y = sprite_position.y;
      this.background_sprite.position.z = sprite_position.z;

      this.renderer.setSize(this.canvasWidth, this.canvasHeight);
      this.renderer.render(this.scene, this.camera);
      if (this.assetsLoaded) {
        this.state.rendered_with_assets_loaded = true;
      }
      this._rerender_scheduled = false;

      if (this.data.onRender) {
        this.data.onRender();
      }
    });
  }

  setUp3d() {
    // Set up the renderer, camera, and the scene.
    const canvas = this.dom_node.querySelector('canvas');
    this.renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
      premultipliedAlpha: false,
      antialias: true,
      logarithmicDepthBuffer: true,
      powerPreference: 'high-performance'
    });
    this.renderer.autoClear = true;
    // Set devicePixelRatio on the renderer, but only on desktop, because webgl with a large amount
    // of pixels can get very resouce intensive. Safari iOS is very sensitive about resource usage
    // and will preemptively kill a page with "A problem Occurred with this Webpage, so it was Reloaded".
    if (!Px.Util.matchMedia('mobile').matches) {
      this.renderer.setPixelRatio(window.devicePixelRatio);
    }

    this.camera = null;
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('white');

    // If a GLB file is already loading for whatever reason, cancel the pending callback
    // before scheduling a new one so that the obsolete callback doesn't mess with our scene.
    // TODO: any loading textures will still mess with our textures_loaded count when loading :/
    if (this._gltf_load_callback) {
      this._gltf_load_callback.cancel();
      this.resetTexturesLoading();
    }

    this._gltf_load_callback = Px.Util.cancellableFunction(gltf => {
      gltf.scene.traverse(child => {
        const preview_section = child.userData['px:preview_section'];
        if (preview_section) {
          const src = this.data.preview_sections[preview_section];
          if (src) {
            this.incrementTexturesLoading();
            const loader = new THREE.TextureLoader();
            // The default `crossOrigin` setting is "anonymous", which disables cookies in Safari
            // even when requests are same-origin. Since preview sections will never be cross-origin,
            // we can safely set this to `null`.
            loader.setCrossOrigin(null);
            const texture = loader.load(src, () => {
              this.decrementTexturesLoading();
              this.render3d();
            });
            this.setTextureParams(texture);
            child.material.map = texture;
            child.material.transparent = true;
          }
        }
        if (child.userData['px:material_blending'] === 'multiply') {
          child.material.blending = THREE.MultiplyBlending;
        }
      });
      gltf.scene.children.slice().forEach(object3d => {
        this.scene.add(object3d);
      });
      if (gltf.cameras.length) {
        this.camera = gltf.cameras[0];
      }
      this.state.glb_loaded = true;
      this.firstRender3d();
    });

    // Load GLB file and import it into the scene.
    if (this.data.glb_url) {
      const loader = new GLTFLoader();
      loader.load(this.data.glb_url, this._gltf_load_callback.bind(this), null, e => {
        console.error(e);
        // Sometimes the GLB file doesn't exist (ie if you start setting up a mapped preview),
        // but so far didn't adjust any 3d properties.
        // In that case we should still display the background image, so set the `glb_loaded`
        // flag to `true` to be able to proceed.
        this.state.glb_loaded = true;
        this.firstRender3d();
      });
    } else {
      this.firstRender3d();
    }
  }

  toBlob(callback, type, quality) {
    this.scheduleToBlob(callback, type, quality);
  }

  // -------
  // Private
  // -------

  scheduleToBlob(callback, type, quality) {
    this._to_blob_queue.push({callback: callback, type: type, quality: quality});

    if (this._to_blob_queue.length === 1) {
      if (this.dom_node) {
        this.workToBlob();
      } else {
        this.on('mount', () => this.workToBlob());
      }
    }
  }

  workToBlob() {
    const job = this._to_blob_queue[0];
    const internal_canvas = this.dom_node && this.dom_node.querySelector('canvas');
    if (internal_canvas && internal_canvas.width && internal_canvas.height) {
      // We cannot simply call toBlob on the internal canvas because when using a pixel ratio other than 1,
      // the internal canvas size is multiplied by the pixel ratio and would produce images of too large size.
      const resized_canvas = document.createElement('canvas');
      resized_canvas.width = this.canvasWidth;
      resized_canvas.height = this.canvasHeight;
      const ctx = resized_canvas.getContext('2d', {alpha: true});
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(internal_canvas, 0, 0, this.canvasWidth, this.canvasHeight);
      resized_canvas.toBlob(blob => {
        job.callback(blob);
        this._to_blob_queue.shift();
        if (this._to_blob_queue.length > 0) {
          this.workToBlob();
        }
      }, job.type, job.quality);
    } else {
      job.callback(null);
      this._to_blob_queue.shift();
      if (this._to_blob_queue.length > 0) {
        this.workToBlob();
      }
    }
  }

  makeDefaultCamera() {
    return new THREE.PerspectiveCamera(5, this.aspectRatio, 0.001, 1000);
  }

  incrementTexturesLoading() {
    if (this.state.textures_loading === -1) {
      this.state.textures_loading = 1;
    } else {
      this.state.textures_loading += 1;
    }
  }

  decrementTexturesLoading() {
    this.state.textures_loading -= 1;
  }

  resetTexturesLoading() {
    this.state.textures_loading = -1;
  }

  viewportHeightAt(z) {
    const fov_radians = this.camera.fov * (Math.PI / 180);
    const half_height = z * Math.tan(fov_radians / 2);
    return half_height * 2;
  }

  firstRender3d() {
    if (!this.camera) {
      this.camera = this.makeDefaultCamera();
      this.scene.add(this.camera);
    }

    // Add background image as sprite.
    this.incrementTexturesLoading();
    const bg_texture = new THREE.TextureLoader().load(this.data.bg_url, () => {
      this.decrementTexturesLoading();
      this.render3d();
    });
    this.setTextureParams(bg_texture);
    const bg_material = new THREE.SpriteMaterial({map: bg_texture});
    this.background_sprite = new THREE.Sprite(bg_material);
    this.background_sprite.userData = {
      'px:background_sprite': true,
      'px:export': false
    };
    this.scene.add(this.background_sprite);

    if (this.data.onSetUp) {
      this.data.onSetUp(this.renderer, this.scene, this.camera, this.render3d);
    }
    this.render3d();
  }

  setTextureParams(texture) {
    texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
  }

};
