Px.Editor.ColorPaletteStore = class ColorPaletteStore extends Px.BaseStore {

  static get properties() {
    return {
      default_palette: {std: null},
      palettes: {std: mobx.observable.map()}
    };
  }

  get actions() {
    return {
      load: function(palette_id) {
        if (this.get(palette_id)) {
          return;
        }
        const model = Px.Editor.ColorPaletteModel.make({id: palette_id});
        model.load();
        this.palettes.set(palette_id, model);
      },

      loadAll: function() {
        fetch('/v1/color_palettes.json').then(response => response.json()).then(json => {
          json.forEach(palette_props => {
            const model = Px.Editor.ColorPaletteModel.make(Object.assign(palette_props, {status: 'loaded'}));
            this.palettes.set(palette_props.id, model);
          });
        });
      }
    };
  }

  get(palette_id) {
    return this.palettes.get(palette_id) || null;
  }

};
