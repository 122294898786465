Px.Editor.ImageStore = class ImageStore extends Px.BaseStore {

  constructor(resource_type, project_id, options) {
    super();

    this.onImageUploaded = options.onImageUploaded;

    let upload_url = '/upload/image';
    if (resource_type === 'book') {
      if (project_id) {
        upload_url = `/upload/image?book=${project_id}`;
      }
    } else {
      if (Px.config.resource_gallery_tag_id) {
        upload_url = `/upload/to_subgallery?tag=${Px.config.resource_gallery_tag_id}`;
      }
    }
    this.uploader = Px.LocalFiles.Uploader.make(upload_url);
  }

  static get properties() {
    return {
      _images: {std: mobx.observable.map()},
      _replaced_images: {std: mobx.observable.map()}
    };
  }

  static get computedProperties() {
    return {
      images: function() {
        return _.sortBy(Array.from(this._images.values()), 'order');
      },
      local_images: function() {
        return this.images.filter(function(image) {
          return image.type === 'local';
        });
      }
    };
  }

  get actions() {
    return {
      register: function(image_id, attrs) {
        if (this.get(image_id)) {
          console.error(`Cannot register image ${image_id}; image already exists`);
          return;
        }
        const model = Px.Editor.BaseImageModel.factory(image_id, attrs);
        if (!model) {
          throw new Error(`Unsupported image type: ${image_id}`);
        }

        model.order = this._images.size;
        this._images.set(image_id, model);

        if (model.type === 'local' && model.data.file) {
          const local_file = Px.LocalFiles.LocalFileModel.make(model.data.file);
          model.data.local_file = local_file;
          mobx.when(() => local_file.upload_success, () => this.onFileUploaded(model, local_file.upload_response));
          this.uploader.uploadFile(local_file);
        }

        return this.get(image_id);
      },

      onFileUploaded: function(local_image, data) {
        const database_image = this.register(`db:${data.id}`, data);
        database_image.order = local_image.order;
        this._images.delete(local_image.id);
        // Store reference to the new database image under the old local key into _replaced_images.
        // We need this so that we can properly resolve any 'local:*' images that have been stored
        // in page snapshots, for example.
        this._replaced_images.set(local_image.id, database_image);
        local_image.destroy();
        if (this.onImageUploaded) {
          this.onImageUploaded.call(null, local_image.id, database_image.id);
        }
      }
    };
  }

  uploadedAsLocalFileThisSession(db_image) {
    const entry = Array.from(this._replaced_images.entries()).find(entry => entry[1] === db_image);
    return Boolean(entry && entry[0].match(/^local:/));
  }

  // Returns image by id.
  get(image_id) {
    return this._images.get(image_id) || this._replaced_images.get(image_id) || null;
  }

};
