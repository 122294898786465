Px.Editor.FacebookGalleryStore = class FacebookGalleryStore extends Px.BaseStore {

  static get properties() {
    return {
      is_logged_in: {std: false},
      galleries: {std: mobx.observable.map()},
      albums_loading: {std: false}
    };
  }

  get actions() {
    return {
      init: function() {
        if (this.galleries.size === 0) {
          this.loadGalleries();
        }
      },

      loadGalleries: function() {
        var self = this;
        this.albums_loading = true;
        this._ensureLoggedIn(function() {
          FB.api('/me/albums', {'limit': self.constructor.GALLERY_LIMIT}, function(response) {
            mobx.runInAction(function() {
              _.each(response.data, function(gallery) {
                // Instagram Photos are hidden due to Facebook bug:
                // http://developers.facebook.com/bugs/202119973248747/
                if (gallery.name !== 'Instagram Photos') {
                  self.galleries.set(gallery.id, self.buildGalleryItem(gallery));
                }
              });
              self.albums_loading = false;
            });
          });
        });
      },

      loadGalleryImages: function(gallery_id) {
        var self = this;
        var query_opts = {
          limit: 500,
          fields: 'id,name,width,height,source,images'
        };
        var gallery = self.galleries.get(gallery_id);
        gallery.loading = true;
        FB.api('/' + gallery_id + '/photos', query_opts, function(response) {
          mobx.runInAction(function() {
            var items = [];
            _.each(response.data, function(image) {
              items.push(self.buildImageItem(image));
            });
            gallery.images.replace(items);
            gallery.loading = false;
          });
        });
      },

      _ensureLoggedIn: function(callback) {
        var self = this;
        if (this.logged_in) {
          callback();
        } else {
          FB.login(function(response) {
            if (response.authResponse) {
              $j.post('/v1/social_app_tokens/facebook.json', {
                signed_request: response.authResponse.signedRequest,
                access_token: response.authResponse.accessToken
              });
              self.logged_in = true;
              callback();
            } else {
              alert("Failed to log into Facebook.");
            }
          }, {scope: 'email,user_photos'});
        }
      }
    };
  }

  galleryImages(gallery_id) {
    return this.galleries.get(gallery_id).images;
  }

  isGalleryLoaded(gallery_id) {
    if (gallery_id === null) {
      return !this.albums_loading;
    } else {
      var gallery = this.galleries.get(gallery_id);
      return gallery && !gallery.loading;
    }
  }

  // -------
  // Private
  // -------

  _getAccessToken() {
    return FB.getAuthResponse().accessToken;
  }

  _getThumbImage(images, width) {
    var result = null;
    if (images) {
      result = _.min(images, function(image) {
        return image.width > width ? image.width - width : 1000;
      });
    }
    return result;
  }

  _getOptimalImage(images, size) {
    var optimal = null;
    if (images) {
      var area = size * size;
      optimal = _.min(images, function(image) {
        return Math.abs(area - (image.width * image.height));
      });
    }
    return optimal;
  }

  buildGalleryItem(item) {
    return {
      type: 'gallery',
      id: item.id,
      loading: true,
      caption: item.name,
      images: mobx.observable.array()
    };
  }

  buildImageItem(item) {
    var thumb_image = this._getThumbImage(item.images, 237);
    var hires_image = this._getOptimalImage(item.images, 5000);
    var lowres_url = item.source;
    var thumb_url = thumb_image ? thumb_image.source : lowres_url;
    var hires_url;
    var width;
    var height;
    if (hires_image) {
      hires_url = hires_image.source;
      width = hires_image.width;
      height = hires_image.height;
    } else {
      width = item.width;
      height = item.height;
    }
    var image_id = lowres_url + '#width=' + width + '&height=' + height;
    if (hires_url) {
      image_id += '&hires=' + encodeURIComponent(hires_url);
    }
    item.pixfizz_thumb_url = thumb_url;
    return {
      type: 'image',
      id: 'fb:' + image_id,
      thumb_url: thumb_url,
      caption: item.name,
      data: item
    };
  }

};

Px.Editor.FacebookGalleryStore.GALLERY_LIMIT = 200;
