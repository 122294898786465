$j = jQuery.noConflict();

Px = {};

Px.urlQuery = function(qs) {
  if (typeof qs === 'undefined') {
    qs = window.location.search.replace('?', '');
  }
  var parsed = {};
  var pairs = qs.split('&');
  var pair;
  for (var i = 0; i < pairs.length; i++) {
    if (pairs[i]) {
      pair = pairs[i].split('=');
      parsed[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
  }
  return parsed;
};

Px.config = mobx.observable({});
Px.configure = function(settings) {
  mobx.extendObservable(Px.config, settings);
};

Px.translations = mobx.observable.map();
Px.setTranslations = function(translations) {
  _.each(translations, function(text, key) {
    Px.translations.set(key.toLowerCase(), text);
  });
};
Px.t = function(key, fallback) {
  return Px.translations.get(key.toLowerCase()) || (fallback || key);
};

(function() {
  const SafeString = class SafeString extends String {};

  class IfElseResult {
    constructor(triggered, body) {
      this.triggered = triggered;
      this.result = triggered ? body() : '';
    }
    elseIf(condition, altbody) {
      if (!this.triggered) {
        return new IfElseResult(condition, altbody);
      }
      return this;
    }
    else(altbody) {
      if (!this.triggered) {
        this.result = altbody();
      }
      return this;
    }
  }

  Px.raw = function(string) {
    return new SafeString(string);
  };


  Px.if = function(condition, body) {
    return new IfElseResult(condition, body);
  };

  const escape = function escape(obj) {
    if (obj instanceof IfElseResult) {
      obj = obj.result;
    }
    if (obj instanceof Array) {
      return obj.map(o => escape(o)).join('');
    } else if (obj instanceof SafeString) {
      return obj;
    } else {
      return _.escape(obj);
    }
  };

  Px.template = function(strings, ...values) {
    const values_length = values.length;
    let result = '';
    for (let i = 0; i < values_length; i++) {
      result += strings[i] + escape(values[i]);
    }
    result += strings[values_length];
    return new SafeString(result);
  };

})();
