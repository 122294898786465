Px.Editor.ImageClearIcon = class ImageClearIcon extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    // The px-control-handle <rect> is there only to catch any click events when an active
    // element is partially covered by another element.
    // See comment about `grabHandle` below.

    return Px.template`
      <g class="px-control-component"
         cursor="pointer"
         data-onclick="onClick"
         data-onmousedown="grabHandle"
         data-ontouchstart="grabHandle"
         transform="translate(${this.data.x}, ${this.data.y})"
         data-px-tooltip="${Px.t('Clear')}">
        <rect width="${this.iconWidth}"
              height="${this.iconHeight}"
              fill="none"
              stroke="none"
              class="px-control-handle"
              pointer-events="all"
        />
        <svg width="${this.iconWidth - 2*this.iconPadding}"
             height="${this.iconHeight - 2*this.iconPadding}"
             viewBox="0 0 23 23">
          <path d="M6.23102 8.17547H17.1206L16.4696 18.2605C16.4398 18.7208 16.2299 19.1529 15.8825 19.4687C15.5351 19.7844 15.0764 19.9602 14.5999 19.9603H8.75272C8.27613 19.9602 7.81746 19.7844 7.47009 19.4687C7.12273 19.1529 6.91278 18.7208 6.88299 18.2605L6.23102 8.17547ZM18.2335 5.4559V7.26894H5.11911V5.4559H7.92933V4.54937C7.92933 4.06852 8.12671 3.60737 8.47806 3.26736C8.82941 2.92734 9.30593 2.73633 9.80281 2.73633H13.5498C14.0466 2.73633 14.5232 2.92734 14.8745 3.26736C15.2259 3.60737 15.4233 4.06852 15.4233 4.54937V5.4559H18.2335ZM9.80281 5.4559H13.5498V4.54937H9.80281V5.4559Z" fill="var(--danger-color)"/>
        </svg>
      </g>
    `;
  }

  get dataProperties() {
    return {
      element: {required: true},
      x: {required: true},
      y: {required: true},
      scale: {required: true},
      store: {required: true},
    }
  }

  static get computedProperties() {
    return {
      iconWidth: function() {
        return this.inSvgUnits(ImageClearIcon.SIZE[0]);
      },
      iconHeight: function() {
        return this.inSvgUnits(ImageClearIcon.SIZE[1]);
      },
      iconPadding: function() {
        return this.inSvgUnits(1);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  // Prevent click from propagating to the element.
  onClick(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.stopPropagation();
  }

  // Note: We're using mousedown rather than a more straightforward click event,
  // because it makes it easier to handle proxying the event when the active element
  // is partially covered by another element. See `grabHandle` in `Px.Editor.BaseElementComponent`.
  grabHandle(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();

    this.withUndo('clear image element', () => {
      this.data.element.update({
        id: null,
        placeholder: true,
        zoom: 0,
        left: 0,
        top: 0
      });
    });
  }

};

Px.Editor.ImageClearIcon.SIZE = [25, 25];
