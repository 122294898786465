Px.Components.ColorButton = class ColorButton extends Px.Component {

  template() {
    return Px.template`
      <button class="px-color-button"
              title="${this.data.title}"
              style="background-color:${Px.Util.colorForDisplay(this.data.color)};"
              data-color-scheme="${this.colorScheme}"
              data-onclick="onClick">
        ${this.data.color}
      </button>
    `;
  }

  get dataProperties() {
    return {
      color: {required: true},
      title: {std: ''},
      onClick: {required: true}
    };
  }

  static get computedProperties() {
    return {
      colorScheme: function() {
        return Px.Util.isColorDark(this.data.color) ? 'dark' : 'light';
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  onClick(evt) {
    this.data.onClick.call(this);
  }

};
