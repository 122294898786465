Px.Editor.MobileStore = class MobileStore extends Px.BaseStore {

  constructor(main_store) {
    super();
    this.main_store = main_store;
    this.project_store = main_store.project;
    this.undo_redo_store = main_store.undo_redo;
    this.image_store = main_store.images;

    mobx.reaction(() => this.main_store.selected_element, element => {
      if (element && this.main_store.ui.editor_mode === 'mobile') {
        this.startEditMode();
        if (element.type === 'image') {
          if (this.main_store.cut_print_mode) {
            this.setActiveTool('image-crop-editor');
          } else if (element.replace) {
            this.setActiveTool('image-source-editor');
          }
        } else if (element.type === 'text') {
          this.setActiveTool('text-content-editor');
        }
      }
    }, {
      name: 'Px.Editor.MobileStore::StartEditModeReaction'
    });

    mobx.reaction(() => this.main_store.selected_set, set => {
      if (set && this.main_store.ui.editor_mode === 'mobile') {
        this.setActiveTool(null);
      }
    }, {
      name: 'Px.Editor.MobileStore::ClearActiveToolReaction'
    });

    mobx.when(() => this.project_store.loaded, () => {
      if (Px.config.autofill_button && Px.config.autofill_autolaunch && !this.main_store.ui.share_view) {
        if (this.project_store.fillable_placeholders.length > 0) {
          this.setActiveTool('autofill-panel');
        }
      }
    });
  }

  static get properties() {
    return {
      sidebar_active: {std: false},
      animation_in_progress: {std: false},
      // This only stores XML of pages from current set, because we only edit elements on one set at a time.
      edit_mode_snapshot: {std: null},
      selected_sets: {std: mobx.observable.array()},
      _autofill_image_ids: {std: mobx.observable.array()},
      _selected_autofill_image_ids: {std: mobx.observable.array()},
      _active_tool: {std: null},
    };
  }

  static get computedProperties() {
    return {
      view_mode: function() {
        if (this.main_store.selected_element) {
          return 'edit';
        } else if (this.active_tool === 'autofill-panel') {
          return 'autofill';
        } else if (this.selected_sets.length) {
          return 'set-selection';
        } else if (this.main_store.selected_set) {
          if (this.active_tool === 'page-layout-selector') {
            return 'layout-selection';
          } else {
            return 'page';
          }
        } else if (this.main_store.cut_print_mode && this.active_tool === 'cut-prints-quantity-editor') {
          return 'cut-prints-quantity-selection';
        } else {
          return 'project';
        }
      },
      active_tool: function() {
        if (this.main_store.cut_print_mode) {
          if (this.main_store.project.cut_print_sets_with_definitions.length === 0) {
            return 'image-source-editor';
          } else {
            return this._active_tool;
          }
        } else if (this._active_tool === 'page-layout-selector') {
          if (!this.main_store.selected_page) {
            return null;
          }
        } else if (this._active_tool === 'autofill-panel') {
          return this._active_tool
        } else if (!this.main_store.selected_element) {
          return null;
        }
        return this._active_tool;
      },
      selected_set_snapshot: function() {
        return this.main_store.selected_set.pages.map(page => page.xml);
      },
      has_changes_to_save: function() {
        const page_count = this.edit_mode_snapshot.length;
        if (page_count !== this.selected_set_snapshot.length) {
          return true;
        }
        for (let i = 0; i < page_count; i++) {
          if (this.edit_mode_snapshot[i] !== this.selected_set_snapshot[i]) {
            return true;
          }
        }
        return false;
      },
      selected_page_count: function() {
        let count = 0;
        this.selected_sets.forEach(set => {
          count += set.pages.length;
        });
        return count;
      },
      autofill_images: function() {
        return this._autofill_image_ids.map(img_id => {
          return this.image_store.get(img_id);
        });
      },
      selected_autofill_images: function() {
        return this._selected_autofill_image_ids.map(img_id => {
          return this.image_store.get(img_id);
        });
      }
    };
  }

  get actions() {
    return {
      animate: function(callback) {
        this.animation_in_progress = true;
        setTimeout(() => {
          mobx.runInAction(() => {
            this.animation_in_progress = false;
            callback();
          });
        }, 100);
      },

      setActiveTool: function(tool_id) {
        if (tool_id !== null && !_.include(MobileStore.TOOL_IDS, tool_id)) {
          throw new Error('Unrecognized tool id: ' + tool_id);
        }
        this._active_tool = tool_id;
      },

      startEditMode: function() {
        this.edit_mode_snapshot = this.selected_set_snapshot;
        this._end_with_undo = this.undo_redo_store.beginWithUndo({
          name: 'edit element',
          set_id: this.main_store.selected_set.id
        });
      },

      endEditMode: function() {
        const has_changes = this.has_changes_to_save;
        this._active_tool = null;
        this.edit_mode_snapshot = null;;
        this._end_with_undo();
        this.main_store.selectElement(null);
        return has_changes;
      },

      cancelEditMode: function() {
        const has_changes = this.endEditMode();
        if (has_changes) {
          this.undo_redo_store.undo();
        }
      },

      addAutofillImageId: function(image_id) {
        if (!this.getAutofillImageId(image_id)) {
          this._autofill_image_ids.push(image_id);
        }
      },

      removeAutofillImageId: function(image_id) {
        const existing_image_id = this.getAutofillImageId(image_id);
        if (existing_image_id) {
          this._autofill_image_ids.remove(existing_image_id);
        }
        this.deselectAutofillImageId(image_id);
      },

      selectAutofillImageId: function(image_id) {
        if (!this.isAutofillImageIdSelected(image_id)) {
          this._selected_autofill_image_ids.push(image_id);
        }
      },

      deselectAutofillImageId: function(image_id) {
        const selected_image_id = this.getSelectedAutofillImageId(image_id);
        this._selected_autofill_image_ids.remove(selected_image_id);
      },

      toggleSelectedAutofillImageId: function(image_id) {
        if (this.isAutofillImageIdSelected(image_id)) {
          this.deselectAutofillImageId(image_id);
        } else {
          this.selectAutofillImageId(image_id);
        }
      },

      clearAutofillImages: function() {
        this._autofill_image_ids.clear();
        this._selected_autofill_image_ids.clear();
      }

    };
  }

  getAutofillImageId(image_id) {
    // When dealing with image IDs we must keep in mind that IDs can change,
    // for example when a local image gets uploaded and "converted" to a database image.
    const resolved_image = this.image_store.get(image_id);
    return this._autofill_image_ids.find(img_id => this.image_store.get(img_id) === resolved_image);
  }

  getSelectedAutofillImageId(image_id) {
    // When dealing with image IDs we must keep in mind that IDs can change,
    // for example when a local image gets uploaded and "converted" to a database image.
    const resolved_image = this.image_store.get(image_id);
    return this._selected_autofill_image_ids.find(img_id => this.image_store.get(img_id) === resolved_image);
  }

  isAutofillImageIdSelected(image_id) {
    return Boolean(this.getSelectedAutofillImageId(image_id));
  }


};

Px.Editor.MobileStore.TOOL_IDS = [
  'page-layout-selector',
  'image-source-editor', 'image-crop-editor', 'image-mask-editor',
  'text-content-editor', 'text-alignment-editor', 'text-font-editor', 'text-size-editor',
  'autofill-panel',
  'cut-prints-quantity-editor'
];
