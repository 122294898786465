Px.Editor.MobileLayoutsPanel = class MobileLayoutsPanel extends Px.Editor.BaseComponent {

  template() {
    return Px.template`
      <div class="px-mobile-layouts-panel">
        <div class="px-tool-wrapper">
          ${this.layouts.map(layout => {
            return Px.template`
              <div class="px-layout"
                  title="${layout.name}"
                  data-layout-id="${layout.id}"
                  data-current="${this.isCurrent(layout)}"
                  data-onclick="selectLayout"
              >
                ${this.renderChild(Px.Editor.Page, `layout-${layout.id}`, this.pageProps(layout))}
              </div>
            `;
          })}
        </div>
      </div>
    `;
  }

  constructor(props) {
    super(props);

    const get_page_properties = page => {
      return {
        elements_xml: page.elements.map(element => element.xml),
        layout_id: page.layout_id,
        xml: page.xml
      }
    };

    // Fire immediatelly option does not work because the render reaction is invoked first,
    // so we have to do this manually at first.
    this.state.initial_page_properties = get_page_properties(this.data.store.selected_page);

    this.registerReaction(() => this.data.store.selected_page, page => {
      this.state.initial_page_properties = page ? get_page_properties(page) : null;
    }, {
      name: 'Px.Editor.MobileBaseElementEditPanel::recordInitialPageXMLReaction'
    });
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get properties() {
    return {
      initial_page_properties: {type: 'obj', std: null},
    };
  }

  static get computedProperties() {
    return {
      layouts: function() {
        const store = this.data.store;
        const page = store.selected_page;
        if (!(page && page.can_receive_layout)) {
          return [];
        }
        return store.layouts.layouts.filter(layout => layout.applicableToPage(page, store.options));
      },
      pageLayoutChanged: function() {
        const selected_page = this.data.store.selected_page;
        return selected_page && selected_page.xml !== this.state.initial_page_properties.xml;
      }
    };
  }

  pageProps(layout) {
    // The div containing the layout has a 1px border, so we have to subtract that from the available width/height;
    // Keep these values in sync with the CSS width/height/border values.
    const layout_width = 64;
    const border_width = 2;
    const size = layout_width - (2 * border_width);
    return {
      page: layout,
      available_width: size,
      available_height: size,
      preview_mode: true
    };
  }

  isCurrent(layout) {
    return String(layout.id) === String(this.data.store.selected_page.layout_id);
  }

  // --------------
  // Event handlers
  // --------------

  selectLayout(evt) {
    const layout_id = evt.target.getAttribute('data-layout-id');
    const layout = this.data.store.layouts.get(layout_id);
    this.data.store.selected_page.setLayout(layout);
  }

  cancel(evt) {
    this.revertChanges();
  }

  save(evt) {
    const selected_page = this.data.store.selected_page;
    const layout = this.data.store.layouts.get(selected_page.layout_id);
    if (layout) {
      // Revert changes first, then re-apply them for undo/redo to work correctly.
      this.revertChanges();
      this.data.store.undo_redo.withUndo(() => {
        selected_page.setLayout(layout);
      }, {
        label: 'set page layout',
        set_id: selected_page.set.id
      });
    }
  }

  // -------
  // Private
  // -------

  revertChanges() {
    const selected_page = this.data.store.selected_page;
    const initial_properties = this.state.initial_page_properties;
    mobx.runInAction(() => {
      selected_page.layout_id = initial_properties.layout_id;
      selected_page.elements.forEach(element => element.destroy());
      selected_page.elements = initial_properties.elements_xml.map(xml => {
        return Px.Editor.BaseElementModel.fromXML(xml, {
          page: selected_page,
          image_store: this.data.store.images
        });
      });
    });
  }

};
