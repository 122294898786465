Px.Components.ColorPicker.ColorPickerCMYK = class ColorPickerCMYK extends Px.Component {

  template() {
    const r = this.renderChild;
    const width = this.data.width;

    return Px.template`
      <div class="px-color-picker-cmyk" style="width:${width}px">
        <div class="px-color-component px-cyan">
          <canvas width="${width}" height="${this.data.slider_height}"></canvas>
          ${r(Px.Components.ColorPicker.ColorSlider, 'cyan-slider', this.cyanSliderProps)}
        </div>
        <div class="px-color-component px-magenta">
          <canvas width="${width}" height="${this.data.slider_height}"></canvas>
          ${r(Px.Components.ColorPicker.ColorSlider, 'magenta-slider', this.magentaSliderProps)}
        </div>
        <div class="px-color-component px-yellow">
          <canvas width="${width}" height="${this.data.slider_height}"></canvas>
          ${r(Px.Components.ColorPicker.ColorSlider, 'yellow-slider', this.yellowSliderProps)}
        </div>
        <div class="px-color-component px-key">
          <canvas width="${width}" height="${this.data.slider_height}"></canvas>
          ${r(Px.Components.ColorPicker.ColorSlider, 'key-slider', this.keySliderProps)}
        </div>
        <div class="px-bottom-controls">
          <input class="px-color-component-input px-cyan"
                 type="text"
                 value="${Math.round(this.state.selected_cyan * 100)}"
                 onfocus="this.select()"
                 data-onchange="onCyanInputChange"
          />
          <input class="px-color-component-input px-magenta"
                 type="text"
                 value="${Math.round(this.state.selected_magenta * 100)}"
                 onfocus="this.select()"
                 data-onchange="onMagentaInputChange"
          />
          <input class="px-color-component-input px-yellow"
                 type="text"
                 value="${Math.round(this.state.selected_yellow * 100)}"
                 onfocus="this.select()"
                 data-onchange="onYellowInputChange"
          />
          <input class="px-color-component-input px-key"
                 type="text"
                 value="${Math.round(this.state.selected_key * 100)}"
                 onfocus="this.select()"
                 data-onchange="onKeyInputChange"
          />
          <div class="px-color-preview"
               style="background-color:${this.selectedColorRgb};"
               data-color-scheme="${Px.Util.isColorDark(this.selectedColorRgb) ? 'dark': 'light'}">
            ${Px.raw(Px.Components.ColorPicker.icons.tick)}
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.onCyanChange = this.onCyanChange.bind(this);
    this.onMagentaChange = this.onMagentaChange.bind(this);
    this.onYellowChange = this.onYellowChange.bind(this);
    this.onKeyChange = this.onKeyChange.bind(this);

    this.registerReaction(() => {
      return this.isValidCmykColor(this.data.color) ? this.data.color : null;
    }, color => {
      this.setColorValue(color);
    }, {
      fireImmediately: true,
      name: 'Px.Components.ColorPicker::SetColorReaction'
    });

    this.on('mount', () => {
      this.registerAutorun(() => {
        this.drawCanvasElements();
      }, {
        name: 'Px.Components.ColorPicker::drawCanvasElements'
      });
    });
  }

  get dataProperties() {
    return {
      color: {std: 'cmyk(0,0,0,100)'},
      width: {std: 220},
      slider_height: {std: 8},
      onNewValue: {std: (new_color) => {
        this.setColorValue(new_color);
      }},
      onBeforeDrag: {std: function() {}},
      onAfterDrag: {std: function() {}}
    };
  }

  static get properties() {
    return {
      selected_cyan: {type: 'int', std: 0},
      selected_magenta: {type: 'int', std: 0},
      selected_yellow: {type: 'int', std: 0},
      selected_key: {type: 'int', std: 0}
    };
  }

  static get computedProperties() {
    return {
      selectedColorRgb: function() {
        const rgb = Px.Util.cmykToRgb(this.cmykValue);
        return Px.Util.generateRgbString(rgb);
      },
      cmykValue: function() {
        return {
          c: this.state.selected_cyan,
          m: this.state.selected_magenta,
          y: this.state.selected_yellow,
          k: this.state.selected_key
        };
      },
      cyanSliderProps: function() {
        return {
          value: Math.round(this.cmykValue.c * 100),
          step: 1,
          min: 0,
          max: 100,
          onNewValue: this.onCyanChange,
          onBeforeDrag: this.data.onBeforeDrag,
          onAfterDrag: this.data.onAfterDrag
        };
      },
      magentaSliderProps: function() {
        return {
          value: Math.round(this.cmykValue.m * 100),
          step: 1,
          min: 0,
          max: 100,
          onNewValue: this.onMagentaChange,
          onBeforeDrag: this.data.onBeforeDrag,
          onAfterDrag: this.data.onAfterDrag
        };
      },
      yellowSliderProps: function() {
        return {
          value: Math.round(this.cmykValue.y * 100),
          step: 1,
          min: 0,
          max: 100,
          onNewValue: this.onYellowChange,
          onBeforeDrag: this.data.onBeforeDrag,
          onAfterDrag: this.data.onAfterDrag
        };
      },
      keySliderProps: function() {
        return {
          value: Math.round(this.cmykValue.k * 100),
          step: 1,
          min: 0,
          max: 100,
          onNewValue: this.onKeyChange,
          onBeforeDrag: this.data.onBeforeDrag,
          onAfterDrag: this.data.onAfterDrag
        };
      }
    };
  }

  parseComponentValue(str) {
    const n = parseInt(str, 10) || 0;
    return Math.max(0, Math.min(100, n));
  }

  drawCanvasElements() {
    this.drawSliderStrip('.px-cyan canvas', 'cyan');
    this.drawSliderStrip('.px-magenta canvas', 'magenta');
    this.drawSliderStrip('.px-yellow canvas', 'yellow');
    this.drawSliderStrip('.px-key canvas', 'black');
  }

  drawSliderStrip(selector, color) {
    const canvas = this.dom_node && this.dom_node.querySelector(selector, color);
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const grad = ctx.createLinearGradient(0, 0, this.data.width, 0);
      grad.addColorStop(0, 'white');
      grad.addColorStop(1, color);
      ctx.fillStyle = grad;
      ctx.fillRect(0, 0, this.data.width, this.data.slider_height);
    }
  }

  isValidCmykColor(color) {
    if (color) {
      return color.match(/^cmyk\(\d{1,3},\d{1,3},\d{1,3},\d{1,3}\)$/);
    } else {
      return false;
    }
  }

  setColorValue(value) {
    const cmyk = Px.Util.parseCmykString(value);
    mobx.runInAction(() => {
      this.state.selected_cyan = cmyk.c;
      this.state.selected_magenta = cmyk.m;
      this.state.selected_yellow = cmyk.y;
      this.state.selected_key = cmyk.k;
    });
  }

  triggerColorChange(updates) {
    const cmyk = Object.assign({}, this.cmykValue, updates);
    this.data.onNewValue(Px.Util.generateCmykString(cmyk));
  }

  handleInputChange(evt, component_key) {
    const value = this.parseComponentValue(evt.target.value);
    const updates = {};
    updates[component_key] = value / 100;
    this.triggerColorChange(updates);
    evt.target.value = value;  // make sure values are in sync
  }

  // --------------
  // Event handlers
  // --------------

  onCyanInputChange(evt) {
    this.handleInputChange(evt, 'c');
  }
  onMagentaInputChange(evt) {
    this.handleInputChange(evt, 'm');
  }
  onYellowInputChange(evt) {
    this.handleInputChange(evt, 'y');
  }
  onKeyInputChange(evt) {
    this.handleInputChange(evt, 'k');
  }

  onCyanChange(cyan) {
    this.triggerColorChange({c: cyan / 100});
  }
  onMagentaChange(magenta) {
    this.triggerColorChange({m: magenta / 100});
  }
  onYellowChange(yellow) {
    this.triggerColorChange({y: yellow / 100});
  }
  onKeyChange(key) {
    this.triggerColorChange({k: key / 100});
  }

};
