Px.Editor.ImageSwapIcon = class ImageSwapIcon extends Px.Util.mixin(
  Px.Editor.BaseComponent,
  Px.Editor.SVGElementMixin
) {

  template() {
    // The px-control-handle <rect> is there only to catch any click events when an active
    // element is partially covered by another element.
    // See comment about `grabHandle` below.

    return Px.template`
      <g class="px-control-component"
         cursor="pointer"
         data-onclick="onClick"
         data-onmousedown="grabHandle"
         data-ontouchstart="grabHandle"
         transform="translate(${this.data.x}, ${this.data.y})"
         data-px-tooltip="${Px.t('Swap')}">
        <rect width="${this.iconWidth}"
              height="${this.iconHeight}"
              fill="none"
              stroke="none"
              class="px-control-handle"
              pointer-events="all"
        />

        <svg width="${this.iconWidth - 2*this.iconPadding}"
             height="${this.iconHeight - 2*this.iconPadding}"
            viewBox="0 0 17 16">
          <path d="M10.3044 1.72003C10.2358 1.64634 10.153 1.58724 10.061 1.54625C9.96895 1.50526 9.86964 1.48322 9.76894 1.48144C9.66823 1.47966 9.5682 1.49819 9.47482 1.53591C9.38143 1.57363 9.2966 1.62977 9.22538 1.70099C9.15416 1.77221 9.09801 1.85705 9.06029 1.95043C9.02257 2.04382 9.00405 2.14385 9.00582 2.24455C9.0076 2.34526 9.02964 2.44457 9.07063 2.53657C9.11162 2.62857 9.17073 2.71137 9.24441 2.78003L10.4644 4.00003H3.77441C3.5755 4.00003 3.38474 4.07905 3.24408 4.2197C3.10343 4.36035 3.02441 4.55112 3.02441 4.75003C3.02441 4.94894 3.10343 5.13971 3.24408 5.28036C3.38474 5.42101 3.5755 5.50003 3.77441 5.50003H10.4644L9.24441 6.72003C9.11193 6.86221 9.03981 7.05025 9.04324 7.24455C9.04667 7.43886 9.12538 7.62424 9.26279 7.76165C9.40021 7.89907 9.58559 7.97778 9.77989 7.98121C9.97419 7.98463 10.1622 7.91251 10.3044 7.78003L12.8044 5.28003C12.9449 5.13941 13.0238 4.94878 13.0238 4.75003C13.0238 4.55128 12.9449 4.36066 12.8044 4.22003L10.3044 1.72003ZM6.80441 9.28003C6.93689 9.13786 7.00902 8.94981 7.00559 8.75551C7.00216 8.56121 6.92345 8.37582 6.78604 8.23841C6.64862 8.101 6.46324 8.02228 6.26894 8.01886C6.07464 8.01543 5.88659 8.08755 5.74441 8.22003L3.24441 10.72C3.10396 10.8607 3.02507 11.0513 3.02507 11.25C3.02507 11.4488 3.10396 11.6394 3.24441 11.78L5.74441 14.28C5.88659 14.4125 6.07464 14.4846 6.26894 14.4812C6.46324 14.4778 6.64862 14.3991 6.78604 14.2617C6.92345 14.1242 7.00216 13.9389 7.00559 13.7446C7.00902 13.5503 6.93689 13.3622 6.80441 13.22L5.58441 12H12.2744C12.4733 12 12.6641 11.921 12.8047 11.7804C12.9454 11.6397 13.0244 11.4489 13.0244 11.25C13.0244 11.0511 12.9454 10.8604 12.8047 10.7197C12.6641 10.579 12.4733 10.5 12.2744 10.5H5.58441L6.80441 9.28003Z" fill="var(--icon-color)"/>
        </svg>
      </g>
    `;
  }

  get dataProperties() {
    return {
      element: {required: true},
      x: {required: true},
      y: {required: true},
      scale: {required: true},
      store: {required: true},
    }
  }

  static get computedProperties() {
    return {
      iconWidth: function() {
        return this.inSvgUnits(ImageSwapIcon.SIZE[0]);
      },
      iconHeight: function() {
        return this.inSvgUnits(ImageSwapIcon.SIZE[1]);
      },
      iconPadding: function() {
        return this.inSvgUnits(1.5);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  // Prevent click from propagating to the element.
  onClick(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.stopPropagation();
  }

  // Note: We're using mousedown rather than a more straightforward click event,
  // because it makes it easier to handle proxying the event when the active element
  // is partially covered by another element. See `grabHandle` in `Px.Editor.BaseElementComponent`.
  grabHandle(evt) {
    if (evt.which !== 1) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();
    this.data.store.ui.enableImageSwapMode(this.data.element);
  }

};

Px.Editor.ImageSwapIcon.SIZE = [24.4375, 23];
