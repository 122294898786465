Px.Editor.MobileSidebarPanel = class MobileSidebarPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const store = this.data.store;
    return Px.template`
      <div class="px-mobile-sidebar-panel-wrapper"
           data-active="${this.data.store.mobile.sidebar_active}"
           data-onclick="closeOnBackgroundClick"
           data-ontouchstart="closeOnTouchMove"
           style="${this.styleAttribute}"">
        <div class="px-mobile-sidebar-panel" data-ontouchstart="startDrag">
          <div class="px-mobile-sidebar-header">
            ${Px.if(this.activePanelComponent, () => {
              return Px.template`
                <button data-onclick="closePanel">
                  ${Px.raw(Px.Editor.Main.icons.caret_left)}
                </button>
                <div class="px-title">
                  ${Px.t(this.activePanelTitle)}
                </div>
              `;
            }).elseIf(Px.config.logo_image_path, () => {
              return Px.template`
                <img class="px-logo" src="${Px.config.logo_image_path}" alt=""/>
              `;
            })}
          </div>
          <div class="px-mobile-sidebar-body">
            ${Px.if(this.activePanelComponent, () => {
              return r(this.activePanelComponent, `panel-${this.state.active_panel_id}`);
            }).else(() => {
              return Px.template`
                <div class="px-product-info">
                  <div class="px-product-name">${store.project.product_name}</div>
                  <div class="px-page-count">
                    <span class="px-page-count-text">
                      ${this.pageCountText}
                    </span>
                    ${Px.if(this.priceText, () => {
                      return Px.template`
                        ${Px.if(this.pageCountText, () => {
                          return Px.template`
                            <span class="px-separator"></span>
                          `;
                        })}
                        <span class="px-price">
                          ${Px.raw(this.priceText)}
                        </span>
                      `;
                    })}
                  </div>
                </div>
                <ul>
                  ${Px.if(this.showOptionsButton, () => {
                    return Px.template`
                      <li>
                        <button data-onclick="openOptionsPanel">
                          <div class="px-icon">${Px.raw(this.data.options_icon)}</div>
                          <div class="px-text">${Px.t('Product Options')}</div>
                        </button>
                      </li>
                    `;
                  })}
                  ${Px.if(this.showCartButton, () => {
                    const label = Px.urlQuery().cart === 't' ? Px.t('Save to cart') : Px.t('Add to Cart');
                    return Px.template`
                      <li>
                        <button data-onclick="addToCart" ${this.isCartButtonDisabled ? 'disabled' : ''}>
                          <div class="px-icon">${Px.raw(this.data.cart_icon)}</div>
                          <div class="px-text">${label}</div>
                        </button>
                      </li>
                    `;
                  })}
                  ${Px.if(this.showSaveButton, () => {
                    return Px.template`
                      <li>
                        <button data-onclick="saveOrExit">
                          <div class="px-icon">${Px.raw(this.data.save_icon)}</div>
                          <div class="px-text">${Px.t('Save & Exit')}</div>
                        </button>
                      </li>
                    `;
                  })}
                  ${Px.if((Px.config.help_url || '').trim(), () => {
                    return Px.template`
                      <li>
                        <button data-onclick="showHelp">
                          <div class="px-icon">${Px.raw(this.data.help_icon)}</div>
                          <div class="px-text">${Px.t('Help')}</div>
                        </button>
                      </li>
                    `;
                  })}
                  ${Px.if(this.showExitButton, () => {
                    return Px.template`
                      <li>
                        <button data-onclick="exitEditor">
                          <div class="px-icon">${Px.raw(this.data.exit_icon)}</div>
                          <div class="px-text">${Px.t('Exit')}</div>
                        </button>
                      </li>
                    `;
                  })}
                </ul>
                <p class="px-mobile-notice">
                  ${Px.t('mobile_instructions', 'More advanced design features are available on the desktop version. Save your project to your account and access it on any device.')}
                </p>
              `;
            })}
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.registerReaction(() => this.data.store.mobile.sidebar_active, is_active => {
      if (!is_active) {
        this.state.active_panel_id = null;
      }
    }, {
      name: 'Px.Editor.MobileSidebarPanel::ActivePanelIdReaction'
    });
  }

  get dataProperties() {
    return {
      store: {required: true},
      cart_icon: {required: true},
      cart_callback: {required: true},
      save_icon: {required: true},
      save_callback: {required: true},
      exit_icon: {required: true},
      exit_callback: {required: true},
      help_icon: {required: true},
      help_callback: {required: true},
      options_icon: {required: true}
    };
  }

  static get properties() {
    return {
      offset_x: {type: 'float', std: 0},
      active_panel_id: {type: 'str', std: null}
    };
  }

  static get computedProperties() {
    return {
      styleAttribute: function() {
        if (!this.data.store.mobile.sidebar_active) {
          return '';
        } else {
          const offset_x = Math.min(this.state.offset_x, 0);
          return `left:${offset_x}px`;
        }
      },
      pageCountText: function() {
        const store = this.data.store;
        if (store.cut_print_mode) {
          const photo_count = Px.t('{{count}} photos').replace('{{count}}', store.counted_page_count);
          const total_print_count = Px.t('{{count}} prints total').replace('{{count}}', store.total_cut_print_quantity);
          return Px.template`${photo_count} <span class="px-separator"></span> ${total_print_count}`;
        } else {
          return '';
        }
      },
      priceText: function() {
        const store = this.data.store;
        if (!Px.config.display_price || store.price === null) {
          return '';
        }
        const formatted_price = Px.Util.formatCurrency(store.price, Px.config.currency_format);
        const quantity = store.quantity;
        if (quantity > 1 && !store.cut_print_mode) {
          return Px.t('{{price}} each').replace('{{price}}', formatted_price);
        } else {
          return formatted_price;
        }
      },
      showCartButton: function() {
        const store = this.data.store;
        return store.resource_type === 'book' && !store.ui.share_view;
      },
      isCartButtonDisabled: function() {
        const store = this.data.store;
        return store.cut_print_mode && store.total_cut_print_quantity === 0;
      },
      showSaveButton: function() {
        const store = this.data.store;
        if (store.external_integration.showSaveButton) {
          return store.external_integration.showSaveButton(store);
        }
        const url_query = Px.urlQuery();
        return !(url_query.cart === 't' || url_query.parent_orderline);
      },
      showOptionsButton: function() {
        const store = this.data.store;
        return (
          Px.config.project_options &&
            store.options.combined_available_options.length > 0 &&
            store.resource_type === 'book'
        );
      },
      showExitButton: function() {
        const store = this.data.store;
        if (store.external_integration.showExitButton) {
          return store.external_integration.showExitButton(store);
        }
        return true;
      },
      activePanelComponent: function() {
        const components = {
          'options': Px.Editor.OptionsPanel
        };
        return components[this.state.active_panel_id] || null;
      },
      activePanelTitle: function() {
        const titles = {
          'options': 'Product Options'
        };
        return titles[this.state.active_panel_id] || null;
      }
    }
  }

  // --------------
  // Event handlers
  // --------------

  closePanel(evt) {
    this.state.active_panel_id = null;
  }

  openOptionsPanel(evt) {
    this.state.active_panel_id = 'options';
  }

  addToCart(evt) {
    this.data.cart_callback();
  }

  saveOrExit(evt) {
    this.data.save_callback();
  }

  exitEditor(evt) {
    this.data.exit_callback();
  }

  showHelp(evt) {
    this.data.help_callback();
  }

  closeOnBackgroundClick(evt) {
    const target = document.elementFromPoint(evt.clientX, evt.clientY);
    if (target === this.dom_node) {
      mobx.runInAction(() => {
        this.data.store.mobile.sidebar_active = false;
      });
    }
  }

  closeOnTouchMove(evt) {
    const doc = $j(document);
    const touch = evt.originalEvent.targetTouches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);
    if (target !== this.dom_node) {
      return;
    }

    const removeListeners = () => {
      doc.off('touchmove', onTouchMove);
      doc.off('touchend touchcancel', removeListeners);
    };

    const onTouchMove = evt => {
      removeListeners();
      mobx.runInAction(() => {
        this.data.store.mobile.sidebar_active = false;
      });
    };

    doc.on('touchmove', onTouchMove);
    doc.on('touchend touchcancel', removeListeners);
  }

  startDrag(evt) {
    const doc = $j(document);
    const touches = evt.originalEvent.targetTouches;

    if (touches.length !== 1) {
      return;
    }

    evt.preventDefault();
    const drag_origin_x = touches[0].pageX;
    this.state.offset_x = 0;

    let raf_id;
    const onDragMove = evt => {
      if (raf_id) {
        cancelAnimationFrame(raf_id);
      }
      const new_offset_x = evt.originalEvent.targetTouches[0].pageX - drag_origin_x;
      raf_id = requestAnimationFrame(() => {
        this.state.offset_x = new_offset_x;
        raf_id = null;
      });
    };

    const onDragEnd = evt => {
      doc.off('touchmove', onDragMove);
      doc.off('touchend', onDragEnd);
      doc.off('touchcancel', onDragCancel);
      cancelAnimationFrame(raf_id);
      if (this.state.offset_x <= -Px.Util.minSwipeDistance) {
        mobx.runInAction(() => {
          this.state.offset_x = 0;
          this.data.store.mobile.sidebar_active = false;
        });
      } else {
        this.state.offset_x = 0;
      }
    };

    const onDragCancel = evt => {
      doc.off('touchmove', onDragMove);
      doc.off('touchend', onDragEnd);
      doc.off('touchcancel', onDragCancel);
      cancelAnimationFrame(raf_id);
      this.state.offset_x = 0;
    }

    doc.on('touchmove', onDragMove);
    doc.on('touchend', onDragEnd);
    doc.on('touchcancel', onDragCancel);
  }

};
