Px.Editor.PageSettingsPanel = class PageSettingsPanel extends Px.Editor.BaseComponent {

  template() {
    const store = this.data.store;
    const page = this.data.page;
    const r = this.renderChild;
    const ToggleButton = Px.Components.ToggleButton;

    return Px.template`
      <div class="px-page-settings-panel px-tab-content-panel px-edit-panel">
        <div class="px-panel-top-section">
          <h1>${Px.t('Page Settings:')}</h1>
        </div>
        <div class="px-edit-section">
          <div class="px-edit-controls">

            <div class="px-edit-control">
              ${Px.if(store.resource_type === 'theme_templates', () => {
                return Px.template`
                  <h2>${Px.t('Page Flags')}</h2>
                  <div class="px-page-flags">
                    ${r(ToggleButton, 'page-edit-button-${page.id}', this.editButtonProps)}
                    ${r(ToggleButton, 'full-page-background-button-${page.id}', this.fullPageBackgroundButtonProps)}
                  </div>
                `;
              }).elseIf(store.resource_type === 'theme_layouts', () => {
                return Px.template`
                  <h2>${Px.t('Page Names')}</h2>
                  <input class="px-page-names" type="text" data-onchange="setPages" value="${page.pages.join(', ')}" />
                `;
              })}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);

    this.setEdit = this.setEdit.bind(this);
    this.setBgfill = this.setBgfill.bind(this);
  }

  get dataProperties() {
    return {
      page: {required: true},
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      editButtonProps: function() {
        return {
          content: Px.t('Add Elements'),
          pressed: this.data.page.edit,
          onToggle: this.setEdit
        };
      },
      fullPageBackgroundButtonProps: function() {
        return {
          content: Px.t('Full-Page Background'),
          pressed: this.data.page.bgfill,
          onToggle: this.setBgfill
        };
      },
    };
  }

  setEdit(edit) {
    this.withUndo('set page edit flag', () => {
      this.data.page.update({edit: edit});
    });
  }
  setBgfill(bgfill) {
    this.withUndo('set page bgfill flag', () => {
      this.data.page.update({bgfill: bgfill});
    });
  }

  // --------------
  // Event handlers
  // --------------

  setPages(evt) {
    const val = (evt.target.value || '').split(',').map(s => s.trim());
    this.withUndo('set pages field', () => {
      this.data.page.pages = val;
    });
  }
};
