import Base from './base.js';

class BaseModel extends Px.Util.mixin(
  Base,
  Px.ObservablePropertiesMixin(),
  Px.DeclarativeActionsMixin,
  Px.DisposerRegistryMixin
) {

  constructor(props) {
    super(props);
    const declared_properties = this.constructor.properties;
    _.each(props, (val, key) => {
      if (key in this || declared_properties.hasOwnProperty(key)) {
        this[key] = val;
      } else {
        console.warn(`Unrecognized property: ${key}`);
      }
    });
  }

  // TODO: Validations.
  static get properties() {
    return {
      _destroyed: {type: 'bool', std: false}
    };
  }

  get actions() {
    return {
      update: function(props) {
        var self = this;
        _.each(props, function(val, name) {
          self[name] = val;
        });
      },
      destroy: function() {
        this._destroyed = true;
      }
    };
  }

  // ---------------
  // Getters/setters
  // ---------------

  get destroyed() {
    return this._destroyed;
  }

};

Px.BaseModel = BaseModel;
export default BaseModel;