// Mixin which adds the `registerDisposer` function to the class.
var DisposerRegistryMixin = Base => class extends Base {

  constructor() {
    super(...arguments);
    // An array of disposer functions that will be automatically called when component is destroyed.
    // User this.registerDisposer(...) to add a disposer to this array.
    this._disposers = [];
  }

  destroy() {
    this._disposers.forEach(function(disposer) {
      disposer();
    });

    this._disposers = [];

    if (super.destroy) {
      super.destroy(...arguments);
    }
  }

  registerDisposer(disposer) {
    this._disposers.push(disposer);
  }

  registerReaction(expression, action, options) {
    this.registerDisposer(mobx.reaction(expression, action, options));
  }

  registerAutorun(view, options) {
    this.registerDisposer(mobx.autorun(view, options));
  }

};

Px.DisposerRegistryMixin = DisposerRegistryMixin;
export default DisposerRegistryMixin;
