Px.Editor.LayoutsPanel = class LayoutsPanel extends Px.Editor.BaseComponent {
  template() {
    return Px.template`
      <div class="px-tab-content-panel px-layouts-panel">
        <div class="px-panel-top-section">
          <h1>${Px.t('Layouts:')}</h1>
        </div>

        ${Px.if(this.applicableLayouts.length > 0, () => {
          return this.renderChild(Px.Editor.TaggedItemsPanel, 'tagged-items', this.taggedItemsPanelProps);
        }).else(() => {
          return Px.template`
            <p class="px-no-items-text">
              ${Px.t('No layouts available for this page.')}
            </p>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      taggedItemsPanelProps: function() {
        return {
          list_component: Px.Editor.LayoutsPanel.LayoutList,
          items: this.applicableLayouts
        };
      },
      applicableLayouts: function() {
        const store = this.data.store;
        const page = store.selected_page;
        if (!(page && page.can_receive_layout)) {
          return [];
        }
        return store.layouts.layouts.filter(layout => layout.applicableToPage(page, store.options));
      }
    };
  }

};

Px.Editor.LayoutsPanel.LayoutList = class LayoutsPanel extends Px.Editor.BaseComponent {
  template() {
    return Px.template`
      <div class="px-layouts">
        ${this.data.items.map(layout => {
          const is_selected = layout.id === this.data.store.selected_page.layout_id;
          return Px.template`
            <div class="px-layout"
                 data-selected="${is_selected}"
                 data-onclick="selectLayout"
                 data-layout-id="${layout.id}">
              ${this.renderChild(Px.Editor.Page, `layout-${layout.id}`, this.pageProps(layout))}
              ${is_selected ? Px.raw(Px.Editor.BaseGalleryPanel.icons.tick_mark) : ''}
            </div>
          `;
        })}
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true},
      items: {required: true}
    };
  }

  pageProps(layout) {
    const thumb_width = 150;
    const scale = layout.width ? thumb_width / layout.width : 0;
    return {
      page: layout,
      preview_mode: true,
      render_controls: false,
      scale: scale,
      absolute_size: false
    };
  }

  // --------------
  // Event handlers
  // --------------

  selectLayout(evt) {
    const store = this.data.store;
    const layout_id = parseInt(evt.currentTarget.getAttribute('data-layout-id'), 10);
    const layout = store.layouts.get(layout_id);
    const page = store.selected_page;

    if (layout.id === page.layout_id) {
      return;
    }

    mobx.runInAction(() => {
      this.withUndo('set layout', () => {
        page.setLayout(layout);
      });
      store.selectPage(page);
      store.selectElement(null);
    });
  }

};
