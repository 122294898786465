Px.Editor.TextModel = class TextModel extends Px.BaseModel {

  static get properties() {
    return Object.assign(super.properties, {
      key: {std: null},
      // Set to data returned from the API after loading succesfully.
      data: {std: null},
      json: {std: null},
      _status: {std: 'queued'}
    });
  }

  static get computedProperties() {
    return {
      queued: function() {
        return htis.status === 'queued';
      },
      loading: function() {
        return htis.status === 'loading';
      },
      loaded: function() {
        return this.status === 'loaded';
      },
      failed: function() {
        return this.status === 'failed';
      }
    };
  }

  // ---------------
  // Getters/setters
  // ---------------

  get status() {
    return this._status;
  }

  set status(status) {
    if (!_.include(TextModel.VALID_STATUSES, status)) {
      throw new Error(`Invalid text status: ${status}`);
    }
    this._status = status;
  }

};

Px.Editor.TextModel.VALID_STATUSES = ['queued', 'loading', 'loaded', 'failed'];
