Px.LocalFiles.Reader = class Reader extends Px.Base {

  constructor() {
    super();
    this.queue = [];
    this.active = false;
    this.heic_worker = null;
    this.decoded_file_map = new WeakMap();
    this.check_vertical_squash = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    this.logger = {
      debug: function(file, msg) {
        console.debug(`[${file.name}] ${msg}`);
      },
      warn: function(file, msg) {
        console.warn(`[${file.name}] ${msg}`);
      }
    };
  }

  readImage(file, opts, callback) {
    const item = {
      file: file,
      options: opts,
      callback: callback
    };

    this.queue.push(item);
    // Sort by priority, which defaults to 1. Higher number means lower priority.
    this.queue.sort((i1, i2) => {
      const p1 = i1.options.priority || 1;
      const p2 = i2.options.priority || 1;
      if (p1 === p2) {
        return 0;
      } else if (p1 < p2) {
        return -1;
      } else {
        return 1;
      }
    });

    if (!this.active) {
      this.readNext();
    }
  }

  // private

  readNext() {
    const item = this.queue.shift();

    if (item) {
      this.active = true;
      this.readItem(item);
    } else {
      this.active = false;
    }
  }

  async readItem(item) {
    this.logger.debug(item.file, 'Reading image');
    let input_blob;

    try {
      input_blob = await this.getBlob(item.file);
    } catch (err) {
      _.defer(item.callback, null);
      this.readNext();
      return;
    }

    let result = null;
    let retries = 0;

    while (!result && retries < 3) {
      try {
        result = await this.resizeBlob(item.file, input_blob, item.options);
      } catch (err) {
        retries++;
        this.logger.warn(item.file, `Failed resizing blob (try ${retries}/3), trying again`);
        console.error(err);
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }

    // Defer callback so that it doesn't crash the loop.
    if (result) {
      const resized_file = new File([result.blob], item.file.name, {type: result.blob.type});
      _.defer(item.callback, resized_file, result.width, result.height);
    } else {
      _.defer(item.callback, null);
    }
    this.readNext();
  }

  getBlobFromHeic(file, resolve, reject) {
    if (this.decoded_file_map.has(file)) {
      this.logger.debug(file, 'Reusing cached encoded heic image');
      resolve(this.decoded_file_map.get(file));
      return;
    }

    this.logger.debug(file, 'Decoding heic image in web worker');

    if (!this.heic_worker) {
      const src = document.querySelector('script[src*="/editor_bundle.js"]').src;
      const script_base = src.substr(0, src.lastIndexOf('/') + 1);
      const remote_url = script_base + 'libheif_worker_bundle.js';
      // We have to generate a worker that loads our real worker script dynamically instead of loading
      // the worker script directly to get around CORS issues when using the CDN.
      // See: https://stackoverflow.com/q/21913673/51397
      const script = `importScripts('${remote_url}'); const SCRIPT_BASE = '${script_base}'`;
      const local_url = URL.createObjectURL(new Blob([script], {type: 'text/javascript'}));
      this.heic_worker = new Worker(local_url);
    }

    this.heic_worker.onmessage = evt => {
      if (evt.data.error) {
        this.logger.warn(file, `Failed decoding heic file: ${evt.data.error.name}`);
        console.error(evt.data.error);
        reject(evt.data.error);
      } else {
        const uint8_clamped_array = evt.data.array;
        const dimensions = evt.data.dimensions;
        const canvas = document.createElement('canvas');
        canvas.style.imageRendering = 'smooth';
        const ctx = canvas.getContext('2d');
        canvas.width = dimensions.width;
        canvas.height = dimensions.height;
        try {
          const image_data = new ImageData(uint8_clamped_array, dimensions.width, dimensions.height);
          ctx.putImageData(image_data, 0, 0);
        } catch (err) {
          console.error(err);
          this.logger.warn(file, `Failed drawing decoded heic image: ${err.message}`);
          reject(err);
          return;
        }
        canvas.toBlob(blob => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          this.decoded_file_map.set(file, blob);
          resolve(blob);
        }, 'image/jpeg', Reader.JPEG_QUALITY);
      }
    };

    const reader = new FileReader();

    reader.onload = evt => {
      this.heic_worker.postMessage(new Uint8Array(reader.result), [reader.result]);
    };

    reader.onerror = reader.onabort = evt => {
      this.logger.warn(file, `Failed reading file: ${reader.error.name}`);
      console.error(reader.error);
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  }

  getBlobFromPdf(file, resolve, reject) {
    if (this.decoded_file_map.has(file)) {
      this.logger.debug(file, 'Reusing cached encoded PDF image');
      resolve(this.decoded_file_map.get(file));
      return;
    }

    this.logger.debug(file, 'Decoding PDF file into image');

    const reader = new FileReader();

    reader.onload = () => {
      pdfjsLib.getDocument({data: reader.result}).promise.then(doc => doc.getPage(1)).then(page => {
        const viewport = page.getViewport({scale: 1});
        const width = viewport.width;
        const height = viewport.height;
        const canvas = document.createElement('canvas');
        canvas.style.imageRendering = 'smooth';
        canvas.width = width;
        canvas.height = height;

        const render_opts = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport,
          background: 'rgba(0, 0, 0, 0)'
        };

        page.render(render_opts).promise.then(() => {
          canvas.toBlob(blob => {
            if (!blob) {
              this.logger.warn(file, 'Failed getting blob from canvas');
              reject(new Error('Failed getting blob from canvas'));
            } else {
              resolve(blob);
            }
          });
        }).catch(err => {
          this.logger.warn(file, `Failed rendering PDF page: ${err}`);
          reject(err);
        });
      }).catch(err => {
        this.logger.warn(file, `Failed parsing PDF file: ${err}`);
        reject(err);
      });
    };

    reader.onerror = reader.onabort = evt => {
      this.logger.warn(file, `Failed reading file: ${reader.error.name}`);
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  }

  getBlob(file) {
    return new Promise((resolve, reject) => {
      // Browsers don't support heic images natively, so we use a WASM-compiled libheic to
      // obtain an Uint8Array of raw RGBA values.
      if (file.type === 'image/heic' || (!file.type && file.name.match(/\.heic$/i))) {  // Windows workaround
        this.getBlobFromHeic(file, resolve, reject);
      } else if (file.type === 'application/pdf') {
        this.getBlobFromPdf(file, resolve, reject);
      } else {
        // If it's any other image type, just return the original file (which is a kind of Blob).
        resolve(file);
      }
    });
  }

  async resizeBlob(file, blob, opts) {
    let image = null;
    let cleanup_handler = null;
    let retries = 0;

    while (!image && retries < 3) {
      try {
        [image, cleanup_handler] = await this.loadImage(file, blob);
      } catch (err) {
        retries++;
        this.logger.warn(file, `Failed to read image (try ${retries}/3), trying again`);
        console.error(err);
        await new Promise(resolve => setTimeout(resolve, 2500));
      }
    }

    if (!image) {
      this.logger.warn(file, 'Failed reading image after 3 tries');
      throw new Error('Failed reading image after 3 tries');
    }

    const size = this.getSize(image.width, image.height, opts);
    let resized_blob;

    if (image.width <= size.width && image.height <= size.height) {
      this.logger.debug(file, 'Skipping resize: using original image');
      resized_blob = blob;
    } else {
      this.logger.debug(file, `Resizing image: ${image.width}x${image.height} -> ${size.width}x${size.height}`);
      const bitmap = await this.loadBitmap(file, image, size.width, size.height);
      if (bitmap) {
        resized_blob = await this.resizeWithCanvas(file, bitmap, size.width, size.height);
        bitmap.close();
      } else {
        resized_blob = await this.resizeWithCanvas(file, image, size.width, size.height);
      }
    }

    const result = {blob: resized_blob, width: image.width, height: image.height};
    cleanup_handler();
    return result;
  }

  // Loading an image without displaying it in the browser, is quite quick.
  // It looks like browser avoid decoding image data if you don't show it,
  // however image width and height are still available. This seems to be the
  // most efficient way to obtain image dimensions from a local file.
  async loadImage(file, blob) {
    // Note: you would think `createImageBitmap` would be more efficient, but at least in FF 96 it's crazy slow.
    const img = new Image();
    const object_url = URL.createObjectURL(blob);

    const cleanup = () => {
      img.src = '';
      URL.revokeObjectURL(object_url);
    };

    return new Promise((resolve, reject) => {
      const onError = evt => {
        img.onload = img.onerror = img.onabort = null;
        cleanup();
        this.logger.warn(file, 'Image failed to load');
        reject(evt);
      };

      const onLoad = () => {
        img.onload = img.onerror = img.onabort = null;
        resolve([img, cleanup]);
      };

      img.onload = onLoad;
      img.onerror = img.onabort = onError;
      img.src = object_url;
    });
  }

  async loadBitmap(file, image, width, height) {
    if ('createImageBitmap' in window) {
      try {
        const bitmap = await createImageBitmap(image, {resizeWidth: width, resizeHeight: height, resizeQuality: 'high'});
        return bitmap;
      } catch (err) {
        // Browser support is quite poor.
      }
    }
    return null;
  }

  async resizeWithCanvas(file, image, width, height) {
    let output_mime;
    let output_quality;
    let alpha;
    if (file.type === 'image/png' || file.type === 'application/pdf') {
      output_mime = 'image/png';
      alpha = true;
    } else {
      output_mime = 'image/jpeg';
      output_quality = Reader.JPEG_QUALITY;
      alpha = false;
    }

    const canvas = document.createElement('canvas');
    canvas.style.imageRendering = 'smooth';
    canvas.width = width;
    canvas.height = height;

    let success = false;
    let ctx;
    // If we are dealing with an ImageBitmap, and the ImageBitmap is already properly resized,
    // we can just transfer the data to the canvas for better performance.
    if ('ImageBitmap' in window && image instanceof window.ImageBitmap &&
        image.width === width && image.height === height) {
      try {
        ctx = canvas.getContext('bitmaprenderer', {alpha: alpha});
        ctx.transferFromImageBitmap(image);
        success = true;
      } catch (err) {
        // Browser support is quite poor.
      }
    }

    if (!success) {
      ctx = canvas.getContext('2d', {alpha: alpha});
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      const ratio = this.check_vertical_squash ? this.detectVerticalSquash(image) : 1;

      // FF sometimes still errors out with 'Component not available' or similar weirdo errors.
      try {
        ctx.drawImage(image, 0, 0, image.width * ratio, image.height * ratio, 0, 0, width, height);
        // Firefox (and perhaps other browsers as well?) sometimes fail to draw and the canvas
        // and produce a completely black image. Check pixel data and if all pixels are black,
        // throw an error.
        const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        if (data.every(x => x === 0 || x === 255)) {
          throw new Error('Failed to draw image; canvas is completely black');
        }
      } catch (err) {
        this.logger.warn(file, `Failed drawing image: ${err.message}`);
        throw err;
      }
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        // bitmaprenderer context doesn't have a `clearRect` method,
        // so make sure to only call it if defined on the context.
        if (ctx.clearRect) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        if (blob) {
          resolve(blob);
        } else {
          this.logger.warn(file, 'Failed to get blob from canvas');
          reject(new Error('Failed to get blob from canvas'));
        }
      }, output_mime, output_quality);
    });
  }

  // Detecting vertical squash in loaded image.
  // Fixes a bug which squash image vertically while drawing into canvas for some images.
  // This is a bug in iOS6/iOS7 devices.
  // Taken from: https://github.com/stomita/ios-imagefile-megapixel
  detectVerticalSquash(image) {
    const ih = image.naturalHeight || image.height;
    const canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = ih;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);
    const data = ctx.getImageData(0, 0, 1, ih).data;
    // search image edge pixel position in case it is squashed vertically.
    let sy = 0;
    let ey = ih;
    let py = ih;
    while (py > sy) {
      const alpha = data[(py - 1) * 4 + 3];
      if (alpha === 0) {
        ey = py;
      } else {
        sy = py;
      }
      py = (ey + sy) >> 1;
    }
    const ratio = py / ih;
    return (ratio === 0) ? 1 : ratio;
  }

  // Takes an options object that may contain one of
  // `size`, `width`, `height`, and returns an object
  // of {width: width, height: height}.
  getSize(orig_width, orig_height, opts) {
    let width;
    let height;

    if (opts.width) {
      width = Math.min(opts.width, orig_width);
      height = orig_height * (width/orig_width);
    } else if (opts.height) {
      height = Math.min(opts.height, orig_height);
      width = orig_width * (height/orig_height);
    } else if (opts.size) {
      if (orig_width >= orig_height) {
        width = Math.min(opts.size, orig_width);
        height = orig_height * (width/orig_width);
      } else {
        height = Math.min(opts.size, orig_height);
        width = orig_width * (height/orig_height);
      }
    } else {
      width = orig_width;
      height = orig_height;
    }

    return {width: Math.round(width), height: Math.round(height)};
  }

};

Px.LocalFiles.JPEG_QUALITY = 0.90;
// Default/global instance of Reader.
Px.LocalFiles.reader = Px.LocalFiles.Reader.make();
