Px.Components.ToggleButton = class ToggleButton extends Px.Component {

  template() {
    return Px.template`
      <button class="px-toggle-button"
              title="${this.data.title}"
              data-pressed="${this.isPressed}"
              data-onclick="onClick">
        ${Px.raw(this.data.content)}
      </button>
    `;
  }

  get dataProperties() {
    return {
      pressed: {std: false},
      content: {required: true},
      title: {std: ''},
      onToggle: {std: function(pressed) {
        this.state.internal_pressed = pressed;
      }}
    };
  }

  static get properties() {
    return {
      internal_pressed: {type: 'bool', std: null}
    };
  }

  static get computedProperties() {
    return {
      isPressed: function() {
        if (this.state.internal_pressed !== null) {
          return this.state.internal_pressed;
        } else {
          return this.data.pressed;
        }
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  onClick(evt) {
    this.data.onToggle.call(this, !this.isPressed);
  }

};
