Px.Editor.PageMaskPanel = class PageMaskPanel extends Px.Editor.BaseComponent {
  template() {
    const page = this.data.store.selected_page;
    return Px.template`
      <div class="px-tab-content-panel px-page-mask-panel">
        <div class="px-panel-top-section">
          <h1>${ Px.t('Page Mask:') }</h1>
        </div>

        <div class="px-panel-scrollable-section">
          <div class="px-page-masks px-image-selection">
            ${this.images.map(image => {
              const is_selected = Boolean(page && page.mask === image.id);
              return Px.template`
                <div class="px-image"
                     data-selected="${is_selected}"
                     data-image-id="${image.id}"
                     data-onclick="selectMask">
                  <div class="px-thumbnail">
                    <img src="${image.preview}" />
                  </div>
                  ${is_selected ? Px.raw(Px.Editor.BaseGalleryPanel.icons.tick_mark) : ''}
                </div>
              `;
            })}
          </div>
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      images: function() {
        const store = this.data.store;
        const theme_resources_store = store.galleries.theme_resources;
        const image_store = store.images;
        const images = theme_resources_store.images.map(image => image_store.get(image.id));
        // There may be local images in the gallery that haven't finished uploading yet. Filter these out.
        return images.filter(image => image);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  selectMask(evt) {
    const page = this.data.store.selected_page;
    const mask_id = evt.currentTarget.getAttribute('data-image-id');
    if (mask_id === page.mask) {
      this.withUndo('remove page mask', () => {
        page.mask = null;
      });
    } else {
      this.withUndo('set page mask', () => {
        page.mask = mask_id;
      });
    }
  }

};
