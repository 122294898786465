Px.Editor.ViewSettingsPanel = class ViewSettingsPanel extends Px.Editor.BaseComponent {

  template() {
    const r = this.renderChild;
    const store = this.data.store;
    const ToggleButton = Px.Components.ToggleButton;

    return Px.template`
      <div class="px-view-settings-panel px-tab-content-panel px-edit-panel">
        <div class="px-panel-top-section">
          <h1>${Px.t('View Settings')}</h1>
        </div>
        <div class="px-edit-section">
          <div class="px-edit-controls">

            ${Px.if(this.data.store.theme.pdf_layers.length > 0, () => {
              return Px.template`
                <div class="px-edit-control">
                  <h2>${Px.t('PDF Layers')}</h2>
                  ${this.data.store.theme.pdf_layers.map(layer => {
                    return r(ToggleButton, `layer-toggle-button-${layer.name}`, this.layerButtonProps(layer));
                  })}
                </div>
              `;
            })}

            ${Px.if(this.showBleedDropdown || this.showMarginDropdown, () => {
              return Px.template`
                <div class="px-edit-control">
                  ${Px.if(this.showBleedDropdown, () => {
                    return Px.template`
                      <h2>${Px.t('Bleed')}</h2>
                      ${r(Px.Components.Dropdown, 'bleed-dropdown', this.bleedDropdownProps)}
                    `;
                  })}
                  ${Px.if(this.showMarginDropdown, () => {
                    return Px.template`
                      <h2>${Px.t('Margin')}</h2>
                      ${r(Px.Components.Dropdown, 'margin-dropdown', this.marginDropdownProps)}
                    `;
                  })}
                </div>
              `;
            })}

            <div class="px-edit-control">
              <h2>${Px.t('Grid')}</h2>
              ${r(ToggleButton, 'toggle-grid-button', this.toggleGridButtonProps)}

              ${Px.if(store.ui.show_grid, () => {
                return Px.template`
                  <h2>${Px.t('Grid Size')}</h2>
                  <div class="px-grid-size-inputs">
                    <input type="number"
                            value="${store.ui.grid_hblocks}"
                            data-grid-dir="h"
                            data-onchange="setGrid"
                            min="1"
                            step="1"
                    />
                    <span>&times;</span>
                    <input type="number"
                            value="${store.ui.grid_vblocks}"
                            data-grid-dir="v"
                            data-onchange="setGrid"
                            min="1"
                            step="1"
                    />
                  </div>
                `;
              })}
            </div>

            <div class="px-edit-control">
              <h2>${Px.t('Selection Controls')}</h2>
              ${r(ToggleButton, 'element-outline-toggle-button', this.elementOutlineButtonProps)}
              ${r(ToggleButton, 'resize-handles-toggle-button', this.resizeHandlesButtonProps)}
            </div>

          </div>
        </div>
      </div>
    `;
  }

  get dataProperties() {
    return {
      store: {required: true}
    };
  }

  static get computedProperties() {
    return {
      showBleedDropdown: function() {
        if (this.data.store.resource_type === 'book') {
          return false;
        }
        return this.availableBleedValues.some(v => v !== 0);
      },
      showMarginDropdown: function() {
        if (this.data.store.resource_type === 'book') {
          return false;
        }
        return this.availableMarginValues.some(v => v !== 0);
      },
      elementOutlineButtonProps: function() {
        const ui_store = this.data.store.ui;
        return {
          content: Px.t('Element Outline'),
          pressed: ui_store.show_element_selection_outline,
          onToggle: (visible) => ui_store.show_element_selection_outline = visible
        }
      },
      resizeHandlesButtonProps: function() {
        const ui_store = this.data.store.ui;
        return {
          content: Px.t('Resize Handles'),
          pressed: ui_store.show_element_resize_handles,
          onToggle: (visible) => ui_store.show_element_resize_handles = visible
        }
      },
      toggleGridButtonProps: function() {
        const ui_store = this.data.store.ui;
        return {
          content: Px.t('Show Grid'),
          pressed: ui_store.show_grid,
          onToggle: (visible) => ui_store.show_grid = visible
        }
      },
      bleedDropdownProps: function() {
        const selected_page = this.data.store.selected_page;
        const options = [];
        if (!this.availableBleedValues.includes(0)) {
          options.push({value: 0, name: '0'});
        }
        this.availableBleedValues.forEach(value => {
          options.push({
            value: value,
            name: this.formatInUserUnits(value)
          });
        });
        return {
          value: selected_page.bleed,
          options: options,
          onNewValue: value => selected_page._virtual_bleed = value
        };
      },
      marginDropdownProps: function() {
        const selected_page = this.data.store.selected_page;
        const options = [];
        if (!this.availableMarginValues.includes(0)) {
          options.push({value: 0, name: '0'});
        }
        this.availableMarginValues.forEach(value => {
          options.push({
            value: value,
            name: this.formatInUserUnits(value)
          });
        });
        return {
          value: selected_page.margin,
          options: options,
          onNewValue: value => selected_page._virtual_margin = value
        };
      },
      availableBleedValues: function() {
        const store = this.data.store;
        return store.project.availableBleedValuesForPage(store.selected_page);
      },
      availableMarginValues: function() {
        const store = this.data.store;
        return store.project.availableMarginValuesForPage(store.selected_page);
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  setGrid(evt) {
    const ui_store = this.data.store.ui;
    const direction = evt.target.getAttribute('data-grid-dir');
    const value = parseInt(evt.target.value, 10);
    if (direction === 'h') {
      ui_store.grid_hblocks = value;
    } else {
      ui_store.grid_vblocks = value;
    }
  }

  // -------
  // Private
  // -------

  formatInUserUnits(val) {
    val = this.toUserUnits(val);
    const unit = this.data.store.project.unit === 'mm' ? 'mm' : 'in';
    return `${val} ${unit}`;
  }

  toUserUnits(val) {
    if (this.data.store.project.unit === 'inch') {
      return Px.Util.mm2in(val);
    }
    return val;
  }

  layerButtonProps(layer) {
    return {
      content: layer.name,
      pressed: layer.enabled,
      onToggle: (visible) => layer.enabled = visible
    };
  }

};
