Px.Editor.ImageElementModel = class ImageElementModel extends Px.Editor.BaseElementModel {

  static fromXMLNode(node, params) {
    // Backwards compatibility (boolean 'normalize' filter used to be called 'contrast'),
    // but 'contrast' is now a separate filter stored as an integer.
    let normalize = node.getAttribute('normalize');
    let contrast = node.getAttribute('contrast');
    if (contrast === 'true' || contrast === 'false') {
      normalize = contrast;
      contrast = null;
    }
    const props = {
      id:              node.getAttribute('src'),
      edit:            node.getAttribute('edit') === 'true',
      x:               parseFloat(node.getAttribute('x')) || 0,
      y:               parseFloat(node.getAttribute('y')) || 0,
      z:               parseInt(node.getAttribute('z'), 10) || 0,
      width:           parseFloat(node.getAttribute('width')) || 0,
      height:          parseFloat(node.getAttribute('height')) || 0,
      mask:            node.getAttribute('mask') || null,
      color:           node.getAttribute('color') || '#000000',
      sepia:           node.getAttribute('sepia') === 'true',
      grayscale:       node.getAttribute('grayscale') === 'true',
      equalize:        node.getAttribute('equalize') === 'true',
      flip:            node.getAttribute('flip') === 'true',
      sharpen:         node.getAttribute('sharpen') === 'true',
      blur:            node.getAttribute('blur') === 'true',
      brightness:      node.getAttribute('brightness') === 'true',
      normalize:       normalize === 'true',
      contrast:        contrast,
      rotation:        parseFloat(node.getAttribute('rotate')) || 0,
      erotation:       node.getAttribute('erotation') !== 'false',
      eopacity:        node.getAttribute('eopacity') !== 'false',
      elayer:          node.getAttribute('elayer') !== 'false',
      eborder:         node.getAttribute('eborder') !== 'false',
      emask:           node.getAttribute('emask') !== 'false',
      replace:         node.getAttribute('replace') !== 'false',
      border:          parseFloat(node.getAttribute('border')) || 0,
      bordercolor:     node.getAttribute('bordercolor') || '#000000',
      ebordercolor:    node.getAttribute('ebordercolor') !== 'false',
      eborderradius:   node.getAttribute('eborderradius') !== 'false',
      borderwrap:      parseFloat(node.getAttribute('borderwrap')) || 0,
      opacity:         parseFloat(node.getAttribute('opacity')) || 1,
      crop:            node.getAttribute('crop') !== 'false',
      stretch:         node.getAttribute('stretch') === 'true',
      palette:         node.getAttribute('palette') || null,
      name:            node.getAttribute('name') || null,
      move:            node.getAttribute('move') !== 'false',
      resize:          node.getAttribute('resize') !== 'false',
      'delete':        node.getAttribute('delete') !== 'false',
      crotation:       parseInt(node.getAttribute('crotation'), 10) || 0,
      zoom:            parseFloat(node.getAttribute('zoom')) || 0,
      left:            parseFloat(node.getAttribute('left')) || 0,
      top:             parseFloat(node.getAttribute('top')) || 0,
      placeholder:     node.getAttribute('placeholder') === 'true',
      show_on_preview: node.getAttribute('show_on_preview') === 'true',
      tags:            node.getAttribute('tags') || null,
      clone_id:        node.getAttribute('clone_id') || null,
      radius:          parseInt(node.getAttribute('radius'), 10) || 0,
      layout:          node.getAttribute('layout') === 'true',
      pdf_layer:       node.getAttribute('pdf_layer') || null,
      happyarvid:      node.getAttribute('happyarvid') || null,
      group:           params.group || null,
      page:            params.page,
      image_store:     params.image_store
    };
    return Px.Editor.ImageElementModel.make(props);
  }

  static get properties() {
    return Object.assign(super.properties, {
      id: {std: null, serialize: false},
      eopacity: {std: true},
      elayer: {std: true},
      crotation: {std: 0},
      zoom: {std: 0},
      _left: {std: 0, serialize: false},
      _top: {std: 0, serialize: false},
      border: {std: 0},
      eborder: {std: true},
      bordercolor: {std: '#000000'},
      ebordercolor: {std: true},
      eborderradius: {std: true},
      borderwrap: {std: 0},
      emask: {std: true},
      mask: {std: null},
      sepia: {std: false},
      grayscale: {std: false},
      equalize: {std: false},
      flip: {std: false},
      sharpen: {std: false},
      blur: {std: false},
      brightness: {std: false},
      normalize: {std: false},
      contrast: {std: null},
      color: {std: '#000000'},
      crop: {std: true},
      stretch: {std: false},
      palette: {std: null},
      radius: {std: 0},
      replace: {std: true},
      placeholder: {std: false},
      show_on_preview: {std: false},
      happyarvid: {std: null},
      // Reference to the ImageStore.
      image_store: {std: null, two_page_spread: false, serialize: false}
    });
  }

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      image: function() {
        if (this.id) {
          return this.image_store.get(this.id);
        } else {
          return Px.Editor.MissingImageModel.make();
        }
      },
      mask_image: function() {
        if (this.mask) {
          return this.image_store.get(this.mask);
        } else {
          return null;
        }
      },
      aspect_ratio: function() {
        if (this.width === 0 || this.height === 0) {
          return 1;
        } else {
          return this.width / this.height;
        }
      },
      svg_image_dimensions: function() {
        if (this.image === null) {
          return [0, 0];
        } else {
          return this.calculateSvgDimensions();
        }
      },
      // This returns true if the image element is suitable for being edited by an automated
      // process such as autofill.
      is_unedited: function() {
        return this.is_editable_master_element && (this.placeholder || !this.id);
      },
      _left_limit: function() {
        const img_width = this.svg_image_dimensions[0];
        const minw = Math.abs(this._min_dimensions[0]);
        let limit;
        if (img_width > minw) {
          const x = (img_width - minw)/2;
          limit = x * 100/img_width;
        } else {
          limit = 0;
        }
        return limit;
      },
      _top_limit: function() {
        const img_height = this.svg_image_dimensions[1];
        const minh = Math.abs(this._min_dimensions[1]);
        let limit;
        if (img_height > minh) {
          const y = (img_height - minh)/2;
          limit = y * 100/img_height;
        } else {
          limit = 0;
        }
        return limit;
      },
      // Returns the minimum allowed width and height for the svg image,
      // so that there's no blank space between the svg image element and the border.
      _min_dimensions: function() {
        const width = this.width - (2 * this.borderwrap);
        const height = this.height - (2 * this.borderwrap);
        const dimensions = Px.Util.circumscribedRectangleDimensions(width, height, this.crotation);
        return [dimensions.width, dimensions.height];
      }
    });
  }

  get actions() {
    return Object.assign(super.actions, {
      fillPlaceholder: function(image_id) {
        if (this.is_editable_master_element && (this.placeholder || this.id === null)) {
          this.id = image_id;
          this.placeholder = false;
        }
      }
    });
  }

  clone(params) {
    return super.clone(Object.assign({image_store: this.image_store}, params));
  }

  // ---------------
  // Getters/setters
  // ---------------

  get left() {
    return Math.max(-this._left_limit, Math.min(this._left_limit, this._left));
  }

  set left(val) {
    this._left = val;
  }

  get top() {
    return Math.max(-this._top_limit, Math.min(this._top_limit, this._top));
  }

  set top(val) {
    this._top = val;
  }

  // -------
  // Private
  // -------

  calculateSvgDimensions() {
    const zoom_factor = 1 + this.zoom/100;
    const width = (this.width - (2 * this.borderwrap)) * zoom_factor;
    const height = (this.height - (2 * this.borderwrap)) * zoom_factor;
    const aspect_ratio = this.image.aspect_ratio;

    let w, h;
    if (this.crop) {
      const dimensions = Px.Util.circumscribedRectangleDimensions(width, height, this.crotation);
      w = dimensions.width;
      h = dimensions.height;
      if (!this.stretch) {
        if (aspect_ratio >= w/h) {
          w = h * aspect_ratio;
        } else {
          h = w / aspect_ratio;
        }
      }
    } else {
      let inscribed_image_ar = aspect_ratio;
      if (this.stretch) {
        const circumscribed_dims = Px.Util.circumscribedRectangleDimensions(width, height, this.crotation);
        inscribed_image_ar = circumscribed_dims.width / circumscribed_dims.height;
      }
      const dimensions = Px.Util.inscribedRectangleDimensions(width, height, this.crotation, inscribed_image_ar);
      w = dimensions.width;
      h = dimensions.height;
    }
    return [w, h];
  }

  serializableAttributes() {
    const attrs = super.serializableAttributes();
    attrs.src = this.image.id;  // this.image.id instead of this.id to dereference any local:* ids.
    attrs.left = this.left;
    attrs.top = this.top;
    return attrs;
  }

};

Px.Editor.ImageElementModel.ELEMENT_TYPE = 'image';
// Max zoom value is not enforced in the model,
// but should be respected by editing tools under normal circumstances.
Px.Editor.ImageElementModel.MAX_ZOOM = 200;
