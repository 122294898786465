Px.Editor.DbImageModel = class DbImageModel extends Px.Editor.BaseImageModel {

  static get computedProperties() {
    return Object.assign(super.computedProperties, {
      dimensions: function() {
        return [this.data.width || 0, this.data.height || 0];
      },
      preview: function() {
        return this.data.preview || '';
      }
    });
  }

  src(params) {
    params = _.clone(params);
    const base_url = this.baseUrl(params);
    if (base_url === null) {
      return null;
    }

    delete params.size;
    let url;

    if (this.hasEffects(params)) {
      params.url = base_url;
      url =  `/fbproxy/show?${$j.param(params)}`;
    } else {
      url = base_url;
      const query = $j.param(params);
      if (query.length) {
        const separator = (base_url.indexOf('?') === -1) ? '?' : '&';
        url = url + separator + query;
      }
    }

    return url;
  }

  baseUrl(params) {
    const size = this.getOptimalSize(params.size);

    const thumb = _.findWhere(this.data.thumbnails, {size: size});
    let url = thumb && thumb.url;

    if (!url) {
      console.error(`Could not find thumbnail URL: ${this.id} (size: ${size})`);
      url = null;
    }

    return url;
  }

  getOptimalSize(size) {
    size = size || 0;
    const standard_sizes = DbImageModel.STANDARD_SIZES;
    let optimal = standard_sizes[0];
    let i = 1;
    while (optimal < size && i < standard_sizes.length) {
      optimal = standard_sizes[i];
      i++;
    }
    return optimal;
  }

};

// Keep this in sync with Image.STANDARD_THUMBNAIL_SIZES.
Px.Editor.DbImageModel.STANDARD_SIZES = [150, 600, 900, 1200];
