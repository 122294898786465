Px.Components.DatePicker = class DatePicker extends Px.Component {

  template() {
    const r = this.renderChild;

    return Px.template`
      <div class="px-date-picker">
        <div class="px-top-line">
          <button class="px-prev" title="${Px.t('Previous Month')}" data-onclick="goToPreviousMonth">
            &larr;
          </button>
          <div class="px-month">${this.monthName(this.displayMonth)} ${this.displayYear}</div>
          <button class="px-prev" title="${Px.t('Next Month')}" data-onclick="goToNextMonth">
            &rarr;
          </button>
        </div>
        <div class="px-dates-grid">
          ${this.dayNames().map(name => {
            return Px.template`
              <div class="px-day-name">${name}</div>
            `;
          })}
          ${this.displayDates.map(date => {
            return Px.template`
              <div class="px-date"
                  data-selected="${date.is_selected}"
                  data-selectable="${date.is_selectable}"
                  data-outside-display="${!date.is_display_month}"
                  data-in-range="${date.is_in_range}"
                  data-datestr="${date.datestr}"
                  data-onmouseenter="onDateEnter"
                  data-onmouseleave="onDateLeave"
                  data-onclick="selectDate">
                ${date.date}
              </div>
            `;
          })}
        </div>
      </div>
    `;
  }

  constructor(data) {
    super(data);
    this.current_date = new Date();
  }

  get dataProperties() {
    return {
      selected_dates: {std: null},
      mode: {std: 'single'},  // other option is 'range' for selecting a date range
      first_selectable_date: {std: null},
      last_selectable_date: {std: null},
      onNewValue: {std: function(new_dates) {
        this.state.internal_value = new_dates;
      }}
    };
  }

  static get properties() {
    return {
      display_year: {type: 'int', std: null},
      display_month: {type: 'int', std: null},
      hovered_date: {type: 'str', std: null},
      internal_value: {type: 'array', std: mobx.observable.array()}
    };
  }

  static get computedProperties() {
    return {
      selectedDates: function() {
        return this.data.selected_dates || this.state.internal_value;
      },
      displayYear: function() {
        if (this.state.display_year !== null) {
          return this.state.display_year;
        } else {
          return this.current_date.getFullYear();
        }
      },
      displayMonth: function() {
        if (this.state.display_month !== null) {
          return this.state.display_month;
        } else {
          return this.current_date.getMonth();
        }
      },
      displayDates: function() {
        let start_date = new Date(this.displayYear, this.displayMonth, 1);
        while (start_date.getDay() !== 0) {
          // Move one day back until we reach first Sunday.
          start_date = this.decrementOneDay(start_date);
        }
        const dates = [];
        let date = start_date;
        // Add all dates before start of the display month.
        while (date.getMonth() !== this.displayMonth) {
          dates.push(date);
          date = this.incrementOneDay(date);
        }
        // Add all dates from the display month.
        while (date.getMonth() === this.displayMonth) {
          dates.push(date);
          date = this.incrementOneDay(date);
        }
        // Add all dates required to fill the last week up to Monday.
        while (date.getDay() !== 0) {
          dates.push(date);
          date = this.incrementOneDay(date);
        }
        return dates.map(date => {
          return {
            date: date.getDate(),
            datestr: this.toISODate(date),
            is_display_month: this.isInDisplayMonth(date),
            is_selected: this.isSelected(date),
            is_in_range: this.isInRange(date),
            is_selectable: this.isSelectable(date)
          };
        });
      }
    };
  }

  // --------------
  // Event handlers
  // --------------

  goToPreviousMonth(evt) {
    mobx.runInAction(() => {
      if (this.displayMonth === 0) {
        this.state.display_month = 11;
        this.state.display_year = this.displayYear - 1;
      } else {
        this.state.display_month = this.displayMonth - 1;
      }
    });
  }

  goToNextMonth(evt) {
    mobx.runInAction(() => {
      if (this.displayMonth === 11) {
        this.state.display_month = 0;
        this.state.display_year = this.displayYear + 1;
      } else {
        this.state.display_month = this.displayMonth + 1;
      }
    });
  }

  selectDate(evt) {
    const datestr = evt.currentTarget.getAttribute('data-datestr');
    if (evt.currentTarget.getAttribute('data-selectable') === 'false') {
      return false;
    }
    mobx.runInAction(() => {
      if (this.data.mode === 'single') {
        const new_value = this.selectedDates.includes(datestr) ? [] : [datestr];
        this.data.onNewValue.call(this, new_value);
      } else {
        if (this.selectedDates.length === 0) {
          this.data.onNewValue.call(this, [datestr]);
        } else if (this.selectedDates.length > 1) {
          this.data.onNewValue.call(this, [datestr]);
        } else {
          const new_value = [this.selectedDates[0], datestr];
          new_value.sort();
          this.data.onNewValue.call(this, new_value);
        }
      }
    });
  }

  onDateEnter(evt) {
    const datestr = evt.target.getAttribute('data-datestr');
    if (this.isSelectable(this.fromISODate(datestr))) {
      this.state.hovered_date = datestr;
    }
  }

  onDateLeave(evt) {
    const datestr = evt.target.getAttribute('data-datestr');
    if (this.state.hovered_date === datestr) {
      this.state.hovered_date = null;
    }
  }

  // -------
  // Private
  // -------

  decrementOneDay(date) {
    const new_date = new Date(date);
    new_date.setDate(new_date.getDate() - 1);
    return new_date;
  }

  incrementOneDay(date) {
    const new_date = new Date(date);
    new_date.setDate(new_date.getDate() + 1);
    return new_date;
  }

  toISODate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const monthpad = month < 10 ? '0' : '';
    const day = date.getDate();
    const daypad = day < 10 ? '0' : '';
    return `${year}-${monthpad}${month}-${daypad}${day}`;
  }

  fromISODate(datestr) {
    const numbers = datestr.split('-').map(str => parseInt(str, 10));
    return new Date(numbers[0], numbers[1] - 1, numbers[2]);
  }

  isInDisplayMonth(date) {
    return date.getMonth() === this.displayMonth;
  }

  isSelected(date) {
    const datestr = this.toISODate(date);
    return this.selectedDates.includes(datestr);
  }

  isSelectable(date) {
    const datestr = this.toISODate(date);
    if (this.data.first_selectable_date && datestr < this.data.first_selectable_date) {
      return false;
    }
    if (this.data.last_selectable_date && datestr > this.data.last_selectable_date) {
      return false;
    }
    return true;
  }

  isInRange(date) {
    if (this.data.mode === 'single' || this.selectedDates.length === 0 || !this.isSelectable(date)) {
      return false;
    }
    const datestr = this.toISODate(date);
    if (this.selectedDates.length === 2) {
      return (datestr > this.selectedDates[0]) && (datestr < this.selectedDates[1]);
    } else if (!this.state.hovered_date) {
      return false;
    } else if (this.state.hovered_date > this.selectedDates[0]) {
      return (datestr > this.selectedDates[0]) && (datestr <= this.state.hovered_date);
    } else if (this.state.hovered_date < this.selectedDates[0]) {
      return (datestr >= this.state.hovered_date) && (datestr < this.selectedDates[0]);
    } else {
      return false;
    }
  }

  dayNames() {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(name => Px.t(name));
  }

  monthName(index) {
    const month_names = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    return Px.t(month_names[index]);
  }

};

Px.Components.DatePicker.icons = {
  crosshair: '<svg class="crosshair" width="18px" height="18px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><circle cx="9" cy="9" r="6" stroke="#000" fill="none" /><line x1="9" y1="0" x2="9" y2="6" stroke="#000" /><line x1="18" y1="9" x2="12" y2="9" stroke="#000" /><line x1="9" y1="18" x2="9" y2="12" stroke="#000" /><line x1="0" y1="9" x2="6" y2="9" stroke="#000" /></svg>'
};
